(function () {
    angular.module('springwise').directive('swUserBasicForm', swUserBasicForm);

    swUserBasicForm.$inject = [];

    function swUserBasicForm() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/components/users/userBasicFormView.html',
            scope: {
                user: '=user',
                isSpringwiseManaged: '=isSpringwiseManaged',
                disAttribute: '=disAttribute',
                validation: '='
            },
            link: function ($scope, element, attrs, ctrl) {
                ctrl.init(element);
            },
            controller: 'UserBasicFormCtrl'
        };
    }
})();
