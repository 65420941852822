(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('AttachmentsCtrl', AttachmentsCtrl);

    AttachmentsCtrl.$inject = ['$scope', '$http', '$routeParams', '$window', 'UtilityService', 'permissions', '$route', 'RecurringServicesFactory', 'ngAuthSettings', 'CurrentUserFactory'];

    function AttachmentsCtrl($scope, $http, $routeParams, $window, UtilityService, permissions, $route, RecurringServicesFactory, ngAuthSettings, CurrentUserFactory) {
              
        $scope.page = 1;
        $scope.limit = 20;
        $scope.name = '';
  

        $scope.schedules = [];
        $scope.clean = clean;
        $scope.getFilteredAttachments = getFilteredAttachments;
        $scope.onPaginationChange = onPaginationChange;
        $scope.editAttachment = editAttachment;
        $scope.fileToUpload = new AttachmentFileObj();
        $scope.description = "";
        $scope.recurringScheduleAttachmentId = -1;


        if (CurrentUserFactory.exists()) {
            var currentUser = CurrentUserFactory.get();
            $scope.createEditRecurringServices = currentUser.permissions.indexOf(permissions.createEditRecurringServices) !== -1;
        }

        //$scope.$watch('pageLimit', onPageLimitChange);
        $scope.$watch('limit', onPageLimitChange);

        activate();

        function activate() {
            //$scope.currentPage = 1;
            //$scope.page = 1;
            //$scope.pageLimit = { id: 10, name: '10 results' };
            $scope.limit = { id: 10, name: '10 results' };
            $scope.limitOptions =[
                { id: 10, name: '10 results'  },
                { id: 20, name : '20 results' },
                { id: 30, name: '30 results'  },
                { id: 50, name: '50 results'  },
             ];            
            $scope.webAPIURL = ngAuthSettings.apiServiceBaseUri;
            getFilteredAttachments();
        }


        function editAttachment(sch)
        {
            if(sch.recurringScheduleAttachmentId)                
                RecurringServicesFactory.api.getAttachmentForEdition({ id: sch.recurringScheduleAttachmentId }).$promise.then(function (attachment) {                   
                    if (attachment.recurringScheduleAttachmentId)
                    {
                        $scope.fileToUpload.recurringScheduleAttachmentId = attachment.recurringScheduleAttachmentId; //creo al cargarle el id, como hay un watch supervisando esto en la directiva de elemento, inmediatamente carga el archivo en el control de imagen.
                        $scope.description = attachment.userFileDescription;
                        $scope.recurringScheduleAttachmentId = attachment.recurringScheduleAttachmentId; 
                    }      
            });

        }


        function getFilteredAttachments() {
            var theRecurringScheduleId = $routeParams.recurringScheduleId;
            $scope.params = {
                recurringScheduleId: theRecurringScheduleId,
                //limit: $scope.pageLimit.id,
                limit: $scope.limit.id,
                //page: $scope.currentPage,
                page: $scope.page,
                orderBy: 'recurringScheduleId',
                isDescending: true,
            };


            RecurringServicesFactory.api.getScheduleAttachmentsList($scope.params).$promise.then(function (schedules) {
                $scope.schedules = schedules;
            }, UtilityService.onRequestError);
        }     
          
        $scope.uploadFile = function () {
            if ($scope.description === null || $scope.description === "") {
                alert("Please add a description.");
            }
            else if ($scope.fileToUpload.description === "") {
                $scope.fileToUpload.description = $scope.description;
            }
            else if ($scope.fileToUpload.file === null) {
                alert("Please add a file.");
            }
            else{
                $scope.fileToUpload.send('api/RecurringServices/UploadScheduleAttachment/' + $routeParams.recurringScheduleId + '/' + $scope.description, onTransferCompleted, UtilityService.onRequestError);
            }
        };

        function onTransferCompleted() {
            $scope.transferCompleted = true;                
            clean();
        }

        $scope.goBack = function () {
            $window.history.back();
        };

        function clean() {
            $route.reload();
        }


        function onPaginationChange(page) {
            $scope.page = page;
            //$scope.searchRegions();
            getFilteredAttachments();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.page !== 1) {
                    $scope.page = 1;
                }
                //$scope.searchRegions();
                getFilteredAttachments();
            }
        }
    }
})();