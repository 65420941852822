(function () {
    angular.module('springwise').factory('BaseFieldErrorDirectiveService', BaseFieldErrorDirectiveService);

    BaseFieldErrorDirectiveService.$inject = [];

    function BaseFieldErrorDirectiveService() {
        return function (dir) {
            dir.restrict = 'E';

            if (dir.scope) {
                dir.scope.field = '=field';
            } else {
                dir.scope = {
                    field: '=field'
                };
            }
            
            return dir;
        };
    }
})();