(function () {
    angular.module('springwise').factory('QuestionnaireService', QuestionnaireService);

    QuestionnaireService.$inject = ['$resource'];

    function QuestionnaireService($resource) {
        return $resource(
            'api/questionnaire',
            {}, {
                getByClientService: {
                    url: 'api/clients/:clientId/services/:serviceId/questionnaire',
                    params: {
                        clientId: '@clientId',
                        serviceId: '@serviceId'
                    }
                },
                getByService: {
                    url: 'api/services/:serviceId/questionnaire',
                    params: {
                        serviceId: '@serviceId'
                    }
                },
                getByClientEmergency: {
                    url: 'api/clients/:clientId/emergencies/:emergencyId/questionnaire',
                    params: {
                        clientId: '@clientId',
                        emergencyId: '@emergencyId'
                    }
                },
                getByEmergency: {
                    url: 'api/emergencies/:emergencyId/questionnaire',
                    params: {
                        emergencyId: '@emergencyId'
                    }
                }
            });
    }
})();