(function () {
    angular.module('springwise')
        .directive('swRecurringServiceDayValidator', swRecurringServiceDayValidator);

    swRecurringServiceDayValidator.$inject = ['ngAuthSettings', '$http'];

    function swRecurringServiceDayValidator(ngAuthSettings, $http) {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelCtrl) {
                var keyCode = [8, 9, 37, 39, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110];
                element.bind("keydown", function (event) {
                    if ($.inArray(event.which, keyCode) === -1) {
                        scope.$apply(function () {
                            scope.$eval(attrs.swRecurringServiceDayValidator);
                            event.preventDefault();
                        });
                        event.preventDefault();
                    }
                });
            }
        };
    }

})();