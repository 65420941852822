
(function () {
    'use strict';   
    angular.module('springwise')
        .controller('recurringServicesScheduleViewCtrl', recurringServicesScheduleViewCtrl);

    function recurringServicesScheduleViewCtrl($scope,
        RecurringServicesFactory,
        CurrentUserFactory,
        $location,
        UtilityService,
        $routeParams) {

        var currentUser = CurrentUserFactory.get();
        $scope.recurringServiceId = $routeParams.recurringServiceId;
        $scope.recurringServiceScheduleId = $routeParams.recurringServiceScheduleId;
        $scope.scheduleId = $routeParams.scheduleId;
        
        $scope.showDate = showDate;
        $scope.selectedDate = new Date();
        $scope.activeDateList = [];
        $scope.expiredDateList = [];

        getRecurringService();
        getRecurringServiceActiveDateList();
        getRecurringServiceExpiredDateList();
        showCalendar();

        function getRecurringService() {
            var params = {
                recurringServiceId: $scope.recurringServiceId,
                recurringServiceScheduleId: $scope.recurringServiceScheduleId,
                scheduleId: $scope.scheduleId,
                dateStatus: 0
            };

            RecurringServicesFactory.api.getRecurringServiceDateListTitle(params).$promise.then(function (response) {
                $scope.serviceDescription = response.serviceDescription;
                $scope.locationDescription = response.locationDescription;
                $scope.scheduleDescription = response.scheduleDescription;
            }, UtilityService.onRequestError);
        }
        
        function getRecurringServiceActiveDateList() {
            var params = {
                recurringServiceScheduleId: $scope.recurringServiceScheduleId,
                scheduleId: $scope.scheduleId,
                dateStatus: 1
            };

            RecurringServicesFactory.api.getRecurringServiceDateList(params).$promise.then(function (response) {
                $scope.activeDateList = response;
                $scope.selectedDate = new Date();
            }, UtilityService.onRequestError);
        }

        function getRecurringServiceExpiredDateList() {
            var params = {
                recurringServiceScheduleId: $scope.recurringServiceScheduleId,
                scheduleId: $scope.scheduleId,
                dateStatus: 2
            };

            RecurringServicesFactory.api.getRecurringServiceDateList(params).$promise.then(function (response) {
                $scope.expiredDateList = response;
            }, UtilityService.onRequestError);
        }

        function showCalendar() {
            $scope.today = function () {
                $scope.selectedDate = new Date();
            };
            $scope.today();

            $scope.inlineOptions = {
                customClass: getDayClass,
                minDate: new Date(),
                showWeeks: false
            };
            /*
            $scope.dateOptions = {
                dateDisabled: disabled,
                formatYear: 'yy',
                maxDate: new Date(2020, 5, 22),
                minDate: new Date(),
                startingDay: 1
            };

            // Disable weekend selection
            function disabled(data) {
                var date = data.date,
                  mode = data.mode;
                return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
            }

            $scope.toggleMin = function () {
                $scope.inlineOptions.minDate = $scope.inlineOptions.minDate ? null : new Date();
                $scope.dateOptions.minDate = $scope.inlineOptions.minDate;
            };

            $scope.toggleMin();

            $scope.setDate = function (year, month, day) {
                $scope.selectedDate = new Date(year, month, day);
            };

            $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
            $scope.format = $scope.formats[0];
            $scope.altInputFormats = ['M!/d!/yyyy'];

            var tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            var afterTomorrow = new Date(tomorrow);
            afterTomorrow.setDate(tomorrow.getDate() + 1);
            $scope.events = [
              {
                  date: tomorrow,
                  status: 'full'
              },
              {
                  date: afterTomorrow,
                  status: 'partially'
              }
            ];
            */

            function getDayClass(data) {
                var date = data.date,
                  mode = data.mode;
                if (mode === 'day') {
                    var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
                    var currentDay;

                    for (var i = 0; i < $scope.activeDateList.length; i++) {
                        currentDay = new Date($scope.activeDateList[i]).setHours(0, 0, 0, 0);

                        if (dayToCheck === currentDay) {
                            return 'datepickerActiveDate';
                        }
                    }

                    for (var e = 0; e < $scope.expiredDateList.length; e++) {
                        currentDay = new Date($scope.expiredDateList[e]).setHours(0, 0, 0, 0);

                        if (dayToCheck === currentDay) {
                            return 'datepickerExpiredDate';
                        }
                    }
                }

                return '';
            }
        }

        function showDate(d) {
            $scope.selectedDate = d;
        }
    }
})();