(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('ContractsDashboardCtrl', ContractsDashboardCtrl);

    ContractsDashboardCtrl.$inject = ['$scope', 'AuthFactory', 'CurrentUserFactory', 'ClientsFactory', 'permissions'];

    function ContractsDashboardCtrl($scope, AuthFactory, CurrentUserFactory, ClientsFactory, permissions) {

        var currentUser = CurrentUserFactory.get();

        $scope.canViewContract = currentUser.permissions.indexOf(permissions.viewContracts) !== -1;
        $scope.isClientUser = currentUser.client !== undefined && currentUser.client !== null;
        $scope.isVendorUser = currentUser.vendor !== undefined && currentUser.vendor !== null;
        $scope.isSWAdminUser = !$scope.isVendorUser && !$scope.isClientUser;

        activate();

        function activate() {
        }
    }
})();
