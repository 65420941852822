(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('AuthInterceptorFactory', AuthInterceptorFactory);

    AuthInterceptorFactory.$inject = ['$q', '$injector', '$location', 'localStorageService', 'ngAuthSettings', 'AuthUserService', '$rootScope'];

    function AuthInterceptorFactory($q, $injector, $location, localStorageService, ngAuthSettings, AuthUserService, $rootScope) {
        var reqQueue = [];
        var authInterceptorServiceFactory = {
            request: request,
            responseError: responseError
        };

        return authInterceptorServiceFactory;

        function request(config) {
            appendDomainUrl(config);
            appendToken(config);

            return config;
        }

        function responseError(rejection) {
            var deferred = $q.defer();
            if (rejection.status === 401) {
                var authService = $injector.get('AuthFactory');
                //var authData = localStorageService.get('authorizationData');
                var authData = AuthUserService.get();

                $rootScope.sessionHasExpired = true;
                authService.logOut();
                $location.url('/login');
            } else {
                deferred.reject(rejection);
            }

            return deferred.promise;
        }

        function retryHttpRequest(config, deferred) {
            var $http = $injector.get('$http');
            $http(config).then(function (response) {
                for (var i = 0; i < reqQueue.length; i++) {
                    var req = reqQueue[i];
                    $http(req.req).then(req.def.resolve, req.def.reject);
                }
                reqQueue = [];
                deferred.resolve(response);
            }, function (response) {
                deferred.reject(response);
            });
        }

        function appendDomainUrl(config) {
            if (config.url.indexOf('http') === -1 && config.url.indexOf('api') === 0 && config.url.indexOf('/') !== 0) {
                config.url = ngAuthSettings.apiServiceBaseUri + config.url;
            }
        }

        function appendToken(config) {
            config.headers = config.headers || {};

            //var authData = localStorageService.get('authorizationData');
            var authData = AuthUserService.get();

            if (authData && config.url.indexOf('/token') === -1) {
                config.headers.Authorization = 'Bearer ' + authData.token;
            }
        }
    }
})();
