(function () {
    angular.module('springwise').directive('swValidationIndicator', swValidationIndicator);

    function swValidationIndicator() {
        return {
            templateUrl: '/app/shared/directives/errors/validationIndicatorView.html',
            restrict: 'E',
            scope: {
                validation: '=',
                hideIcon: '='
            },
            controller: "swValidationIndicatorCtrl",
            link: function (scope, element, attrs, ctrl) {
            }
        };
    }

    angular.module('springwise').controller("swValidationIndicatorCtrl", swValidationIndicatorCtrl);

    function swValidationIndicatorCtrl($scope) {
        if (!$scope.validation)
            return;
        $scope.$watch("validation.counter", checkValidations);
        function checkValidations() {
            $scope.alerts = [];
            for (var indexAlert = 0; indexAlert < $scope.validation.alerts.length; indexAlert++) {
                var alertHasTooltip = false;
                for (var indexTooltipAlert = 0; indexTooltipAlert < $scope.validation.tooltips.length; indexTooltipAlert++) {
                    if ($scope.validation.tooltips[indexTooltipAlert].name === $scope.validation.alerts[indexAlert].name) {
                        alertHasTooltip = true;
                        break;
                    }
                }
                if (!alertHasTooltip) {
                    $scope.alerts.push($scope.validation.alerts[indexAlert]);
                }
            }
            for (var indexFormError = 0; indexFormError < $scope.validation.formErrors.length; indexFormError++) {

                var formErrorHasTooltip = false;
                for (var indexTooltipFormError = 0; indexTooltipFormError < $scope.validation.tooltips.length; indexTooltipFormError++) {
                    if ($scope.validation.tooltips[indexTooltipFormError].scope.field === $scope.validation.formErrors[indexFormError].field) {
                        formErrorHasTooltip = true;
                        break;
                    }
                }
                if (!formErrorHasTooltip) {
                    $scope.alerts.push({ message: 'Invalid ' + $scope.validation.formErrors[indexFormError].field.$name });
                }
            }
        }
        $scope.closeAlert = function (alert) {
            alert.closed = true;
        };
    }
})();