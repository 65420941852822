(function () {
    angular.module('springwise').factory('ClientVendorRequiredFieldsFactory', ClientVendorRequiredFieldsFactory);

    ClientVendorRequiredFieldsFactory.$inject = ['$resource'];

    function ClientVendorRequiredFieldsFactory($resource) {
        return $resource(
            'api/clients/:clientId/ClientVendorRequiredFields', {
                clientId: '@clientId'
            }, {


                getAllClientVendorRequiredField: {
                    method: 'GET',
                    url: 'api/clients/:clientId/AllClientVendorRequiredFields',
                    isArray: true
                },                
                getVendorInformationSections: {
                    method: 'GET',
                    url: 'api/ClientVendorRequiredFields/VendorInformationSections',
                    isArray: true
                },    
                update: {
                    method: 'PUT',
                }
            });
    }
})();