var WorkRecord = function () {
    this.referenceNumber = '';
    this.locationId = 0;
    this.clientId = 0;
    this.serviceId = 0;
    this.emergencyType = new EmergencyType();
    this.priorityLevel = new PriorityLevel();
    this.stageId = 0;
    this.statusId = 0;
    this.scope = '';
    this.shortDescription = '';
};