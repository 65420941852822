(function () {
    angular.module('springwise').directive('swCanvasWrPhotoHandling', swCanvasWrPhotoHandling);

    function swCanvasWrPhotoHandling() {
        return {
            restrict: 'E',
            scope: { canvasFile: "=canvasFile", description: "=description", count: "=count", label: "@label", photoFilesCollection: "=photoFilesCollection" },
            controller: "swCanvasWRPhotoHandlingCtrl",
            templateUrl: '/app/shared/directives/canvas/canvasWRPhotoView.html',
            link: function (scope, element, attrs, ctrl) {
                ctrl.init(scope, element);
            }
        };
    }

    angular.module('springwise').controller("swCanvasWRPhotoHandlingCtrl", swCanvasWRPhotoHandlingCtrl);

    swCanvasWRPhotoHandlingCtrl.$inject = ["$scope", "DocumentsReporsitoryFactory", "ngAuthSettings", '$route'];

    function swCanvasWRPhotoHandlingCtrl(scope, DocumentsReporsitoryFactory, ngAuthSettings, $route) {
        scope.showImage = false;
        scope.photoFilesCollection = [];
        //scope.clean = clean;

        this.init = function (scope, element) {
            scope.element = element;
            if (!scope.canvasFile) {              
                scope.canvasFile = new PhotoFileObj();
                scope.photoFilesCollection.push(scope.canvasFile);

            }
            scope.$watch('canvasFile.versionId', function () {
                if (scope.canvasFile.versionId > 0) {
                    scope.showImage = true;
                    var img = scope.element.find("img")[0];
                    img.src = ngAuthSettings.apiServiceBaseUri + "api/DocumentRepository/BrandImage/" + scope.canvasFile.versionId;
                    scope.imageFile = img;
                }
            });
            
        };

        scope.getFileDetails = function (e) {
            scope.imageFile = e.files[0];
            if (scope.validate()) {
                var imagen = scope.element.find("img")[0];
                imagen.style.visibility = "collapsed";
                imagen.width = 0;
                imagen.height = 0;
                var img = document.createElement("img");
                var reader = new FileReader();
                reader.onload = function (e) {
                    img.src = e.target.result;
                };
                img.onload = function (e) {           
                    scope.canvasWidth = img.width;
                    scope.canvasHeight = img.height;                  
                    var canvas = scope.element.find("canvas")[0];
                    canvas.width = scope.canvasWidth;
                    canvas.height = scope.canvasHeight;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, scope.canvasWidth, scope.canvasHeight);
                    scope.canvasFile.file = canvas.toDataURL("image/png");
                    scope.canvasFile.description = scope.description;
                    scope.photoFilesCollection.push(scope.canvasFile);
                };
                reader.readAsDataURL(scope.imageFile);            

                //clean
                    //angular.forEach(
                    //    angular.element("input[type='file']"),
                    //    function (inputElem) {
                    //        angular.element(inputElem).val(null);
                    //    });
      
            }
        };

        var _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
        scope.validate = function () {
            var sFileName = scope.imageFile.name;
            if (sFileName.length > 0) {
                var blnValid = false;
                for (var j = 0; j < _validFileExtensions.length; j++) {
                    var sCurExtension = _validFileExtensions[j];
                    if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                        blnValid = true;
                        break;
                    }
                }

                if (!blnValid) {
                    alert("Sorry, " + sFileName + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "));
                    return false;
                }
            }
            return true;
        };

        scope.clean = function () {
            $route.reload();
        };

        //function clean() {
        //    $route.reload();
        //}

    }





})();