(function () {
    angular.module('springwise').directive('swSearchText', swSearchText);

    swSearchText.$inject = [];

    function swSearchText() {
        return {
            restrict: 'E',
            templateUrl: 'app/shared/directives/table/searchTextView.html',
            scope: {
                name: '@name',
                model: '=model',
                label: '@label'
            }
        };
    }
})();