(function () {
    angular.module('springwise').directive('swNumberField', swNumberField);

    swNumberField.$inject = [];

    function swNumberField() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/directives/fields/numberFieldView.html',
            scope: {
                field: '=field',
                max: '=max',
                min: '=min',
                req: '=required',
                model: '=model',
                name: '@name',
                label: '@label',
                hideLabel: '=hideLabel',
                validation: '=',
                colClass: '@'
            }   
        };
    }
})();