(function () {
    angular.module('springwise').directive('swNumberInlineField', swNumberInlineField);

    swNumberInlineField.$inject = [];

    function swNumberInlineField() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/directives/fields/numberInlineFieldView.html',
            scope: {
                field: '=field',
                max: '=max',
                min: '=min',
                minlength: '=minlength',
                maxlength: '=maxlength',
                req: '=required',
                model: '=model',
                name: '@name',
                label: '@label',
                disabled: '=disabled'
            }
        };
    }
})();