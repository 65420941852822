    (function () {
    'use strict';
    
    angular
        .module('springwise')
        .controller('workRecordsExpenseListCtrl', workRecordsExpenseListCtrl);

    function workRecordsExpenseListCtrl($scope, $routeParams, WorkRecordsFactory, CurrentUserFactory, UtilityService, HelperFactory) {
        //Getting user permissions
        $scope.currentUser = CurrentUserFactory.get();
        $scope.currentUserId = $scope.currentUser.userId;
        $scope.isVendor = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
        $scope.isClient = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
        $scope.isSpringwise = !$scope.isVendor && !$scope.isClient;

        //Getting work record information
        $scope.workRecordId = $routeParams.workRecordId;
        $scope.workRecordExpenseList = [];
        $scope.editMode = false;
        $scope.submitExpense = submitExpense;
        $scope.editExpense = editExpense;
        $scope.cancelExpense = cancelExpense;
        $scope.removeExpense = removeExpense;
        getWorkRecord();
        getWorkRecordExpenseList();        
        
        function getWorkRecord() {
            WorkRecordsFactory.get({ id: $scope.workRecordId }).$promise.then(function (w) {
                $scope.referenceNumber = w.referenceNumber;
            });
        }

        function getWorkRecordExpenseList() {
            WorkRecordsFactory.getWorkRecordExpenseList({ workRecordId: $scope.workRecordId }).$promise.then(function (response) {
                $scope.workRecordExpenseList = response;
            }, UtilityService.onRequestError);
        }

        //Add new expense
        $scope.addExpense = function () {
            if ($scope.editMode) {
                //alert('Make sure to save or cancel any rows in the table before adding another Expense.');
                var modalWindow = HelperFactory.openMessageModalWindow('Make sure to save or cancel any rows in the table before adding another Expense.', 'Item in edition');
                return;
            }

            var wre = new WorkRecordExpense();
            wre.workRecordId = $scope.workRecordId;
            wre.isNew = true;
            wre.editMode = true;

            $scope.workRecordExpenseList.push(wre);
            $scope.editMode = true;
        };

        //Save new expense
        function submitExpense(wre) {
            var req = {
                workRecordExpenseId: wre.id,
                name: wre.editedName,
                quantity: wre.editedQuantity,
                unitPrice: wre.editedUnitPrice,
                workRecordId: wre.workRecordId
            };

            if (wre.isNew) {
                WorkRecordsFactory.saveWorkRecordExpense(req).$promise.then(onAddSucceded, rollbackSubmit);
            } else {
                WorkRecordsFactory.updateWorkRecordExpense(req).$promise.then(onAddSucceded, UtilityService.onRequestError);
            }

            function onAddSucceded() {
                wre.editMode = false;
                wre.isNew = false;
                wre.name = wre.editedName;
                wre.quantity = wre.editedQuantity;
                wre.unitPrice = wre.editedUnitPrice;
                wre.totalPrice = parseInt(wre.quantity) * parseFloat(wre.unitPrice);
                
                getWorkRecordExpenseList();

                $scope.editMode = false;
            }

            function rollbackSubmit(error) {
                UtilityService.onRequestError(error);
                cancelExpense(wre);
            }
        }

        //Edit an expense
        function editExpense(wre) {
            if ($scope.editMode) {
                //alert('Make sure to save or cancel any rows in the table before selecting another expense.');
                var modalWindow = HelperFactory.openMessageModalWindow('Make sure to save or cancel any rows in the table before selecting another Expense.', 'Item in edition');
                return;
            }

            wre.editedName = wre.name;
            wre.editedQuantity = wre.quantity;
            wre.editedUnitPrice = wre.unitPrice;
            wre.editMode = true;
            wre.isNew = false;

            $scope.editMode = true;
        }

        //Cancel new expense
        function cancelExpense(wre) {
            if (wre.isNew) {
                UtilityService.removeElementFromCollection($scope.workRecordExpenseList, wre);
            }
            getWorkRecordExpenseList();
            $scope.editMode = false;
        }

        //Delete expense
        function removeExpense(id) {
            WorkRecordsFactory.deleteWorkRecordExpense({ workRecordExpenseId: id }).$promise.then(getWorkRecordExpenseList, UtilityService.onRequestError);
        }

        //Back
        $scope.goBack = function () {
            window.history.back();
        };
    }
})();

