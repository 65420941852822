(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('notificationTypesIndexCtrl', notificationTypesIndexCtrl);

    function notificationTypesIndexCtrl($scope, $routeParams, $location, ngAuthSettings, UtilityService, CurrentUserFactory, permissions, NotificationTypesFactory, HelperFactory) {
        $scope.clientId = $routeParams.clientId;
        $scope.notificationTypes = [];
        $scope.currentUser = CurrentUserFactory.get();

        $scope.canAccessNotificationTypes = CurrentUserFactory.canAccessNotificationTypes();
        if (!$scope.canAccessNotificationTypes)
            return;

        $scope.edit = function (notificationType) {
            $location.url('/NotificationTypes/Client/' + $scope.clientId + '/Edit/' + notificationType.notificationTypeId);
        };

        activate();
              
        function activate() {
            search();
        }

        function search() {
            var params = {
                clientId: $scope.clientId
            };

            NotificationTypesFactory.api.getList(params).$promise.then(function (response) {
                $scope.notificationTypes = response.notificationTypes;
            }, UtilityService.onRequestError);
        }
    }

})();
