
// https://www.algotech.solutions/blog/javascript/how-to-create-custom-validator-directives-with-angularjs/

(function () {
    angular.module('springwise')
        .directive('swUserNameValidator', swUserNameValidator);

    swUserNameValidator.$inject = ['ngAuthSettings','$http'];

    function swUserNameValidator(ngAuthSettings,$http) {
        return {
            require: 'ngModel',
            link: function ($scope, element, attrs, ngModel) {
                ngModel.$validators.customUserNameValidator = function (modelValue) {

                    // Validate only when changed
                    if (ngModel.$dirty) {
                        $http.get(ngAuthSettings.apiServiceBaseUri + 'api/Users/NewUserNameIsUsed?userName=' + modelValue)
                            .success(function (response) {
                                var isUsed = response && response.isUsed;
                                ngModel.$setValidity("newusernameused",!isUsed);
                                return !isUsed;
                            });                        
                    }

                    return true;    // Default value is not flagged as invalid
                };
            }
        };
    }

})();