(function () {
    angular.module('springwise').controller('ClientWRConfigurationNotificationsFormCtrl', ClientWRConfigurationNotificationsFormCtrl);
       
    ClientWRConfigurationNotificationsFormCtrl.$inject = ['$scope', '$routeParams', 'UtilityService', 'ClientWRConfigurationNotificationsService'];
        
    function ClientWRConfigurationNotificationsFormCtrl($scope, $routeParams, UtilityService, ClientWRConfigurationNotificationsService) {
        init();

        function init() {            
            $scope.clientId = $routeParams.clientId; 
            $scope.searchParams = {     
                client: { value: { id: $scope.clientId } }
            };
            
            $scope.locationId = $routeParams.locationId;
            $scope.serviceId = $routeParams.serviceId;           
            $scope.vslPage = 1;
            $scope.vslLimit = UtilityService.defaultLimitOption;
            $scope.vslLimitOptions = UtilityService.limitOptions;          
            $scope.vslOrderBy = 'userId';
            $scope.vslIsDescending = false;          
            $scope.clientUsers = [];

            $scope.addUser = addUser;
            $scope.removeUser = removeUser;
            $scope.submitUser = submitUser;
            $scope.cancelUser = cancelUser;

            $scope.onVSLPaginationChange = onVSLPaginationChange;

            $scope.$watch('vslLimit', onVSLPageLimitChange);

            queryUsersByClients();
        }

        function addUser(user) {
            if (existsUser(user)) {
                alert('The user is already related to this client.');
                return;
            }
            if ($scope.editMode) {
                alert('Make sure to save or cancel any rows in the table before selecting another user.');
                return;
            }
            
            var vsl = new ClientWRConfigUser();
   
            vsl.userId = user.userId;
            vsl.user = user.userName;       
            if (user.clientId)
            {
                vsl.clientId = user.clientId;
            }
            else
            {
                vsl.clientId = $routeParams.clientId; //Necessary for SW Administrator users, because there are not clientId value in the entities retrieved.
            }
            $scope.clientUsers.push(vsl);
            //$scope.editMode = true;
            $scope.editMode = false;


            submitUser(vsl);
        }

        function existsUser(user) {
            for (var i = 0; i < $scope.clientUsers.length; i++) {
                var userbyClient = $scope.clientUsers[i];                
                if (userbyClient.userId === user.userId) {
                    return true;
                }
            }
            return false;
        }

        function removeUser(id) {
            var req = { userId: id, clientId: $scope.clientId};           
            ClientWRConfigurationNotificationsService.delete(req).$promise.then(queryUsersByClients, UtilityService.onRequestError);
        }

        function submitUser(user) {
            var req = {             
                clientId: user.clientId,
                userId: user.userId                
            };

            if (user.isNew) {                
                ClientWRConfigurationNotificationsService.save(req).$promise.then(onAddSucceded, rollbackSubmit);
            }           

            function onAddSucceded() {
                user.editMode = false;
                user.isNew = false;      
                queryUsersByClients();
                $scope.editMode = false;
            }

            function rollbackSubmit(error) {
                UtilityService.onRequestError(error);
                cancelUser(user);
            }
        }

        function cancelUser(user) {
            if (user.isNew) {
                UtilityService.removeElementFromCollection($scope.clientUsers, user);
            } else {
                user.editMode = false;
            }
            $scope.editMode = false;
        }       

        function onVSLPaginationChange(page) {
            $scope.vslPage = page;
            queryUsersByClients();
        }     

        function onVSLPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.vslPage !== 1) {
                    $scope.vslPage = 1;
                }
                $scope.queryUsersByClients();
            }
        }

        function queryUsersByClients() {
            var params = { clientId: $scope.clientId, page: $scope.vslPage, limit: $scope.vslLimit.id, orderBy: $scope.vslOrderBy, isDescending: $scope.vslIsDescending };            
            ClientWRConfigurationNotificationsService.getTotalRecords(params).$promise.then(onQueryTotalSucceded, UtilityService.onRequestError);

            function onQueryTotalSucceded(total) {
                if ($scope.total !== total.totalRecords) {
                    if ($scope.vslPage !== 1) {
                        $scope.vslPage = 1;
                        params.page = $scope.vslPage;
                    }
                    $scope.total = total.totalRecords;
                }
                               
                ClientWRConfigurationNotificationsService.query(params).$promise.then(onQuerySucceded, UtilityService.onRequestError);
            }


            function onQuerySucceded(records) {
                $scope.clientUsers = records;
            }
        }

    }
})();

