(function () {
    angular.module('springwise').directive('swTradeCategoryAnalysisReport', swTradeCategoryAnalysisReport);

    swTradeCategoryAnalysisReport.$inject = [];

    function swTradeCategoryAnalysisReport() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/components/tradeCategoryAnalysisReport/tradeCategoryAnalysisReport.html',
            scope: {
                currentUser: '=currentUser'
            },
            controller: 'tradeCategoryAnalysisReportCtrl'
        };
    }
})();
