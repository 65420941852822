var SWValidation = function () {
    var swValidation = {};
    swValidation.alerts = [];
    swValidation.formErrors = [];
    swValidation.warnings = [];
    swValidation.successes = [];
    swValidation.tooltips = [];
    swValidation.counter = 0;
    swValidation.anyFormInvalid = false;
    swValidation.start = function () {
        swValidation.alerts = [];
        swValidation.formErrors = [];
        swValidation.warnings = [];
        swValidation.successes = [];
        swValidation.anyFormInvalid = false;
    };
    swValidation.end = function () {
        swValidation.counter++;
    };
    swValidation.addAlert = function (name, message, index) {
        var alert = { name: name, message: message, isError: true, index: index };
        swValidation.alerts.push(alert);
        swValidation.counter++;
    };
    swValidation.addWarning = function (name, message) {
        var alert = { name: name, message: message, isWarning: true };
        swValidation.warnings.push(alert);
        swValidation.counter++;
    };
    swValidation.removeWarning = function (name) {
        var continueCheck = true;
        while (continueCheck) {
            continueCheck = false;
            for (var index = 0; index < swValidation.warnings.length; index++) {
                var warning = swValidation.warnings[index];
                if (warning.name == name) {
                    swValidation.warnings.splice(index, 1);
                    continueCheck = true;
                    break;
                }
            }
        }
        swValidation.counter++;
    };
    swValidation.addSuccess = function (name, message) {
        var alert = { message: message, isSuccess: true };
        swValidation.successes.push(alert);
        swValidation.counter++;
    };
    swValidation.checkHasValue = function (value, name) {
        if (!value) {
            swValidation.addAlert(name, 'Please enter a value');
            return false;
        }
        return true;
    };
    swValidation.checkMaxLenght = function (string, name, max) {
        if (string.length > max) {
            swValidation.addAlert(name, 'Maximum length is ' + max);
            return false;
        }
        return true;
    };
    swValidation.checkForm = function (form) {
        if (form.$invalid) {
            swValidation.anyFormInvalid = true;
            for (var errorProperty in form.$error) {
                var errorArray = form.$error[errorProperty];
                for (var index = 0; index < errorArray.length; index++) {
                    var field = errorArray[index];
                    if (field.$name.indexOf('Form') > -1) {
                        swValidation.checkForm(field);
                        continue;
                    }
                    swValidation.formErrors.push({ field: field });
                }
            }
        }
    };
    swValidation.hasErrors = function () {
        return swValidation.alerts.length > 0 || swValidation.formErrors.length > 0 || swValidation.anyFormInvalid;
    };
    swValidation.addTooltip = function (tooltip) {
        swValidation.tooltips.push(tooltip);
    };
    return swValidation;
};