(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('UsersManagementCtrl', UsersManagement);

    UsersManagement.$inject = ['$scope', '$location', '$window', 'UsersFactory', 'RolesFactory', 'CurrentUserFactory', 'CurrentBrandInfoFactory', 'AuthFactory', 'AuthUserService', 'VendorsFactory', 'ClientsFactory', 'LocationsFactory', 'permissions', 'RegionsFactory', 'DistrictsFactory', 'UtilityService', 'HelperFactory', '$routeParams'];

    function UsersManagement($scope, $location, $window, UsersFactory, RolesFactory, CurrentUserFactory, CurrentBrandInfoFactory, AuthFactory, AuthUserService, VendorsFactory, ClientsFactory, LocationsFactory, permissions, RegionsFactory, DistrictsFactory, UtilityService, HelperFactory, $routeParams) {

        $scope.routeParamsClientId = null; 
        $scope.users = [];
        $scope.roles = [new RoleOption()];
        $scope.clients = [new ClientOption()];
        $scope.regions = [new RegionOption()];
        $scope.districts = [new DistrictOption()];
        $scope.vendors = [new VendorOption()];
        $scope.locations = [new LocationOption()];
        $scope.roleIDFilter = new RoleOption();
        $scope.vendorIDFilter = new VendorOption();
        $scope.clientIDFilter = new ClientOption();
        $scope.regionIDFilter = new RegionOption();
        $scope.districtIDFilter = new DistrictOption();
        $scope.locationIDFilter = new LocationOption();
        $scope.isClient = false;
        $scope.isVendor = false;
        $scope.isSpringwise = false;
        $scope.isSWAdmin = false;
        $scope.canDeactivateUser = false;
        $scope.nameFilter = '';
        $scope.lastNameFilter = '';


        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
            $scope.currentUserId = $scope.currentUser.userId;
            $scope.isVendor = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClient = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSpringwise = !$scope.isVendor && !$scope.isClient;
        } else {
            $scope.currentUserId = 0;
            $scope.currentUser = null;
        }
        if ($scope.currentUser !== null) {
            $scope.isSWAdmin = $scope.currentUser.permissions.indexOf(permissions.SWAdmin) !== -1;
            $scope.canDeactivateUser = $scope.currentUser.permissions.indexOf(permissions.canDeactivateUser) !== -1;
        }

        $scope.getFilteredUsers = getFilteredUsers;
        $scope.activateUser = activateUser;
        $scope.deactivateUser = deactivateUser;
        $scope.resetPassword = resetPassword;
        $scope.cantImpersonate = cantImpersonate;
        $scope.canResetUserPassword = canResetUserPassword;
        $scope.canCreateUsers = canCreateUsers;
        $scope.impersonate = impersonate;
        $scope.isImpersonating = isImpersonating;
        $scope.isEditionAllowed = isEditionAllowed;
        $scope.onPaginationChange = onPaginationChange;

        $scope.$watch('pageLimit', onPageLimitChange);

        $scope.onClientIdChange = onClientIdChange;
        $scope.onRegionIdChange = onRegionIdChange;
        $scope.onDistrictIdChange = onDistrictIdChange;
        $scope.onLocationIdChange = onLocationIdChange;

        $scope.deleteUser = deleteUser;

        activate();

        function activate() {

            if ($routeParams.clientId) {
                $scope.routeParamsClientId = $routeParams.clientId;
            }

            $scope.currentPage = 1;
            $scope.pageLimit = { id: 10, name: '10 results' };
            $scope.limitOptions = [
                { id: 10, name: '10 results' },
                { id: 30, name: '30 results' },
                { id: 50, name: '50 results' },
                { id: 100, name: '100 results' }
            ];
            //$scope.totalPages = $scope.totalRecords / $scope.pageLimit;

            RolesFactory.queryOptions().$promise.then(function (roles) {
                $scope.roles = [new RoleOption()].concat(roles);
                //$scope.roles = roles;
            });

            ClientsFactory.queryOptions().$promise.then(function (clients) {
                $scope.clients = [new ClientOption()].concat(clients);
            });


            //When the logged current user is a Client Administrator User that manages an specific Client, and is accessing the User list from Config section.   
            if ($scope.isClient) {

                if ($scope.currentUser.client.clientId) {
           

                    VendorsFactory.queryOptions({ clientId: $scope.currentUser.client.clientId }).$promise.then(function (vendors) {
                        $scope.vendors = [new VendorOption()].concat(vendors);
                    });

                    $scope.clientIDFilter = { clientId: $scope.currentUser.client.clientId };
                    onClientIdChange($scope.clientIDFilter);
                    if ($scope.currentUser.regionId) {
                        $scope.regionIDFilter = { id: $scope.currentUser.regionId };
                        onRegionIdChange($scope.regionIDFilter);
                    }
                    if ($scope.currentUser.districtId) {
                        $scope.districtIDFilter = { id: $scope.currentUser.districtId };
                        onDistrictIdChange($scope.districtIDFilter);
                    }
                    if ($scope.currentUser.locationId) {
                        $scope.locationIDFilter = { locationId: $scope.currentUser.locationId };
                        onLocationIdChange($scope.locationIDFilter);
                    }
                }
            }

            //When the logged current user is a Sprinwise Administrator User, but is editing an specific client selected. And the user is accessing the User list from the Client edition section, so that it lists the users related to that client.
            else if ($scope.isSpringwise && $scope.routeParamsClientId) {
                VendorsFactory.queryOptions({ clientId: $scope.routeParamsClientId }).$promise.then(function (vendors) {
                    $scope.vendors = [new VendorOption()].concat(vendors);
                });

                $scope.clientIDFilter = { clientId: $scope.routeParamsClientId };
                onClientIdChange($scope.clientIDFilter);
                if ($scope.currentUser.regionId) {
                    $scope.regionIDFilter = { id: $scope.currentUser.regionId };
                    onRegionIdChange($scope.regionIDFilter);
                }
                //if ($scope.currentUser.districtId) {
                //    $scope.districtIDFilter = { id: $scope.currentUser.districtId };
                //    onDistrictIdChange($scope.districtIDFilter);
                //}
                //if ($scope.currentUser.locationId) {
                //    $scope.locationIDFilter = { locationId: $scope.currentUser.locationId };
                //    onLocationIdChange($scope.locationIDFilter);
                //}

            }

            //When the logged current user is a Sprinwise Administrator User or a Vendor User, loads all the vendors
            else {
                var emptyClientId = 0;
                VendorsFactory.queryOptions({ clientId: emptyClientId }).$promise.then(function (vendors) {
                    $scope.vendors = [new VendorOption()].concat(vendors);                 
                });
            }


            getFilteredUsers();
        }

        function impersonate(userId) {
            UsersFactory.impersonate({ id: userId }).$promise.then(function () {
                AuthFactory.refreshToken().then(function (userContext) {
                    CurrentBrandInfoFactory.setFromUserContext(userContext);
                    $location.path(CurrentUserFactory.getDashboardPath());
                });
            });
        }

        function isImpersonating() {
            return CurrentUserFactory.exists() && AuthUserService.exists() && CurrentUserFactory.get().userName !== AuthUserService.get().userName;
        }
        function cantImpersonate() {
            return ($scope.currentUser.permissions.indexOf(permissions.canImpersonate) === -1);
        }
        function canResetUserPassword() {
            return ($scope.currentUser.permissions.indexOf(permissions.canResetUserPassword) !== -1);
        }
        function canCreateUsers() {
            return ($scope.currentUser.permissions.indexOf(permissions.canCreateUsers) !== -1);
        }

        function isEditionAllowed($user) {
            //Is the logged user a SW User?
            //If SWAdmin then yes
            var isSWAdmin = $scope.currentUser.permissions.indexOf(permissions.SWAdmin) !== -1;
            var canEditUsers = $scope.currentUser.permissions.indexOf(permissions.canEditUsers) !== -1;
            if (isSWAdmin === true)
            {
                return true;
            }
            //If has UserEdit Permission
            else if (canEditUsers === true)
            {
                //if is a sprigwise user
                if (angular.isUndefined($scope.currentUser.client) || ($scope.currentUser.client === null) &&
                   (angular.isUndefined($scope.currentUser.vendor) || $scope.currentUser.vendor === null)) {
                    //Is a Client? 
                    if (angular.isDefined($user.clientId) && $user.clientId !== null) {
                        if ($user.clientIsSpringwiseManaged === true) {
                            return true;
                        }
                        else {
                            //Client SW Granted
                            if ($user.clientHasSpringwiseAccess === true) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    }
                    else
                        return true;
                }
                    //The logged user is Client.                
                else if (angular.isDefined($scope.currentUser.client) && $scope.currentUser.client !== null) {
                    //It Checks whether the logged client user is the same client user of the current row.
                    if ($scope.currentUser.client.clientId == $user.clientId) 
                        return true;
                    else if ($scope.currentUser.client.clientId == $user.vendorClientId)
                        return true;
                }
                    //The logged user is Vendor.                
                else if ($scope.currentUser.vendor) {
                    //if the row user vendor is the same as the logged user vendor.
                    if ($scope.currentUser.vendor.vendorId == $user.vendorId)
                        return true;
                }
            }
            return false;
        }

        function getFilteredUsers() {
            var params = {
                pageNumber: $scope.currentPage,
                pageLimit: $scope.pageLimit.id,
                orderBy: 'id',
                name: $scope.nameFilter,
                lastName: $scope.lastNameFilter,
                roleId: $scope.roleIDFilter.roleId,
                vendorId: $scope.vendorIDFilter.vendorId,
                clientId: ($scope.clientIDFilter || {}).clientId,
                regionId: ($scope.regionIDFilter || {}).id,
                districtId: ($scope.districtIDFilter || {}).id,
                locationId: ($scope.locationIDFilter || {}).locationId
            };

            var paramsForTotalRecords = {
                name: $scope.nameFilter,
                lastName: $scope.lastNameFilter,
                roleId: $scope.roleIDFilter.roleId,
                vendorId: $scope.vendorIDFilter.vendorId,
                clientId: ($scope.clientIDFilter || {}).clientId,
                regionId: ($scope.regionIDFilter || {}).id,
                districtId: ($scope.districtIDFilter || {}).id,
                locationId: ($scope.locationIDFilter || {}).locationId

            };

            UsersFactory.getTotalRecords(paramsForTotalRecords).$promise.then(function (obj) {
                if ($scope.totalRecords !== obj.totalRecords) {
                    //if ($scope.currentPage !== 1) {
                    //    $scope.currentPage = 1;
                    //    params.pageNumber = $scope.currentPage;
                    //}
                    $scope.totalRecords = obj.totalRecords;
                }

                UsersFactory.query(params).$promise.then(function (users) {
                $scope.users = users;
            });
            });
        }

        function onPaginationChange(page) {
            $scope.currentPage = page;
            getFilteredUsers();
        }

        function activateUser(userId) {
            UsersFactory.activate({ id: userId })
                .$promise.then(function () {
                    UtilityService.openMessageModalWindow("User activated.");
                    $scope.getFilteredUsers();
                });
        }

        function deactivateUser(userId) {
            UsersFactory.deactivate({ id: userId })
                .$promise.then(function () {
                    UtilityService.openMessageModalWindow("User deactivated.");
                    $scope.getFilteredUsers();
                });
        }

        function resetPassword(userId) {
            UsersFactory.resetPassword({ id: userId })
                .$promise.then(function () {
                    UtilityService.openMessageModalWindow("The password has been reset.");
                    $scope.getFilteredUsers();
                //});
                }, UtilityService.onRequestError);
        }


        function deleteUser(user) {
            var modalWindow = HelperFactory.openAcceptCancelModalWindow('The User is going to be Deleted Permanently. Do you want to proceed?', 'Confirmation');
            modalWindow.result.then(function () {

                removeUser(user);

            }, function () {
            });
        }

        function removeUser(user) {
            UsersFactory.delete({ id: user.userId }).$promise.then(function (user) {                    
                var modalWindow = HelperFactory.openMessageModalWindow('The User "' + user.userName + '" has been deleted.', 'Deleted');
                activate();
            }, UtilityService.onRequestError);
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.currentPage !== 1) {
                    $scope.currentPage = 1;
                }
                $scope.getFilteredUsers();
            }
        }

        function onClientIdChange(theClient) {
            $scope.clientIDFilter = theClient;
            $scope.regionIDFilter = new RegionOption();
            $scope.districtIDFilter = new DistrictOption();
            $scope.locationIDFilter = new LocationOption();

            if ($scope.currentUser.client)
                if ($scope.currentUser.client.clientId && !$scope.clientIDFilter.clientId) {

                    $scope.clientIDFilter.clientId = $scope.currentUser.client.clientId;
                }

            if ($scope.clientIDFilter && $scope.clientIDFilter.clientId) {
                RegionsFactory.queryOptions({ clientId: $scope.clientIDFilter.clientId }).$promise.then(function (regions) {
                    $scope.regions = [new RegionOption()].concat(regions);
                    if ($scope.currentUser.regionId) {
                        $scope.regionIDFilter.id = $scope.currentUser.regionId;
                    }
                });
            }
            else
                $scope.regions = null;
        }

        function onRegionIdChange(theRegion) {
            $scope.regionIDFilter = theRegion;
            $scope.districtIDFilter = new DistrictOption();
            $scope.locationIDFilter = new LocationOption();

            if ($scope.currentUser.client)
                if ($scope.currentUser.regionId && !$scope.regionIDFilter.id) {
                    $scope.regionIDFilter.id = $scope.currentUser.regionId;
                }
            if ($scope.regionIDFilter && $scope.regionIDFilter.id) {
                DistrictsFactory.queryOptionsByRegion({ regionId: $scope.regionIDFilter.id }).$promise.then(function (districts) {
                    $scope.districts = [new DistrictOption()].concat(districts);
                    if ($scope.currentUser.districtId) {
                        $scope.districtIDFilter.id = $scope.currentUser.districtId;
                    }
                });
            }
            else
                $scope.districts = null;
        }

        function onDistrictIdChange(theDistrict) {
            $scope.districtIDFilter = theDistrict;
            $scope.locationIDFilter = new LocationOption();

            if ($scope.currentUser.client)
                if ($scope.currentUser.districtId && !$scope.districtIDFilter.id) {
                    $scope.districtIDFilter.id = $scope.currentUser.districtId;
                }

            if ($scope.districtIDFilter && $scope.districtIDFilter.id) {
                LocationsFactory.queryOptionsByDistrict({ districtId: $scope.districtIDFilter.id }).$promise.then(function (locations) {
                    $scope.locations = [new LocationOption()].concat(locations);
                    if ($scope.currentUser.locationId) {
                         $scope.locationIDFilter.locationId = $scope.currentUser.locationId;
                    }

                });
            }
            else
                $scope.locations = null;
        }
        function onLocationIdChange(theLocation) {
            $scope.locationIDFilter = theLocation;
        }


    }
})();
