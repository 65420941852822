(function () {
    angular.module('springwise').directive('swServiceSearchByClient', swServiceSearchByClient);

    function swServiceSearchByClient() {
		return {
			restrict: 'E',
			scope: {
			    clientId: "=clientId",
			    serviceId: "=serviceId"
			},
			controller: "swServiceSearchByClientCtrl",
			templateUrl: '/app/shared/directives/serviceSearch/serviceSearchByClientView.html',
			link: function (scope, element, attrs, ctrl) {
				ctrl.init(scope, element);
			}
		};
	}

    angular.module('springwise').controller("swServiceSearchByClientCtrl", swServiceSearchByClientCtrl);

   
	function swServiceSearchByClientCtrl($scope, ServicesService, UtilityService) {
	    $scope.categoryId = null;
	    $scope.subCategoryIDSelected = null;
	    $scope.serviceIDSelected = null;
	    $scope.categories = null;
	    $scope.subCategories = null;
	    $scope.services = null;
	    $scope.$watch('clientId', onClientIdChange);
	    $scope.$watch('categoryId', onCategoryIdChange);
	    $scope.$watch('subCategoryId', onSubCategoryIdChange);


	    this.init = function (scope, element) {
		};

	    function onClientIdChange(newVal, oldVal) {
	        if (newVal) {
	            ServicesService.query().$promise.then(function (categories) {
	                $scope.categories = categories;
	            }, UtilityService.onRequestError);
	        }
	        else
	            $scope.categories = null;
        }
	    function onCategoryIdChange(newVal, oldVal) {
	        if (newVal) {
	            ServicesService.getByClient({ id: $scope.categoryId, clientId: $scope.clientId }).$promise.then(function (category) {
	                $scope.category = category;
	            }, UtilityService.onRequestError);
	        }
	        else
	            $scope.category = null;

		}
	    function onSubCategoryIdChange(newVal, oldVal) {
		    if (newVal) {
		        ServicesService.getByClient({ id: $scope.subCategoryId, clientId: $scope.clientId }).$promise.then(function (subCategory) {
		            $scope.subCategory = subCategory;
		        }, UtilityService.onRequestError);
		    }
		    else
		        $scope.subCategory = null;
        }
    }
})();