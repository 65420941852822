(function () {
    angular.module('springwise').factory('BreadcrumbService', BreadcrumbService);

    BreadcrumbService.$inject = [
        'UsersFactory',
        'ClientsFactory',
        'LocationsFactory',
        'RegionsFactory',
        'RolesFactory',
        'VendorsFactory',
        'ServicesService',
        'CurrentUserFactory',
        'EmergencyTypesFactory',
        'WorkRecordsFactory',
        'ClientWRConfigurationNotificationsService',
        'RecurringServicesFactory',
        'ContractsFactory'];

    var Breadcrumb = function (_path, _label) {
        this.path = _path;
        this.label = _label;
    };

    function BreadcrumbService(
        UsersFactory,
        ClientsFactory,
        LocationsFactory,
        RegionsFactory,
        RolesFactory,
        VendorsFactory,
        ServicesService,
        CurrentUserFactory,
        EmergencyTypesFactory,
        WorkRecordsFactory,
        ClientWRConfigurationNotificationsService,
        RecurringServicesFactory,
        ContractsFactory) {

        var config = new Breadcrumb('/', 'Config');
        var clientDashboard = new Breadcrumb('/clientDashboard', 'Client Dashboard');
        var swDashboard = new Breadcrumb('/swDashboard', 'Springwise Dashboard');
        var vendorDashboard = new Breadcrumb('/vendorDashboard', 'Vendor Dashboard');
        var workRecordsHome = new Breadcrumb('/workRecordsHome', 'Work Records');

        var contractsHome = new Breadcrumb('/contractsHome', 'Contracts');
        var contracts = new Breadcrumb('/Contracts', 'Contracts Records List');
        var createContract = new Breadcrumb('/Contracts/CreateContract', 'Create Contract');
        var pendingContractNTEApprovals = new Breadcrumb('/Contracts/PendingContractNTEApprovals', 'Pending Approvals');

        var importsHome = new Breadcrumb('/importsHome', 'Imports');
        var GenerateWRForRecurringSchedule = new Breadcrumb('/GenerateWRForRecurringSchedule', 'Generate Work Records for Recurring Services');
        var reportsHome = new Breadcrumb('/reportsHome', 'Reports');
        var usersIndex = new Breadcrumb('/users', 'Users');
        var usersProfile = new Breadcrumb('/users/profile', 'My Profile');
        var usersPassword = new Breadcrumb('/users/password', 'Change My Password');
        var clientsIndex = new Breadcrumb('/clients', 'Clients');
        var clientsCreate = new Breadcrumb('/clients/create', 'New Client');
        var vendorsIndex = new Breadcrumb('/vendors', 'Vendors');
        var vendorsCreate = new Breadcrumb('/vendors/create', 'New Vendor');
        var alertsIndex = new Breadcrumb('/alerts', 'Alerts');
        var rolesIndex = new Breadcrumb('/roles', 'Roles');
        var servicesIndex = new Breadcrumb('/services', 'Services');
        var priorityLevels = new Breadcrumb('/priorityLevels', 'Priority Levels');
        var emergencyTypesIndex = new Breadcrumb('/emergencyTypes', 'Emergency Types');
        var emergencyTypesCreate = new Breadcrumb('/emergencyTypes/create', 'New Emergency Types');
        var workRecordsCreate = new Breadcrumb('/workRecordsHome/create', 'New Work Record');
        var workRecordsIndex = new Breadcrumb('/workRecordsHome/workRecords', 'List');
        var workRecordsApprovals = new Breadcrumb('/workRecordsHome/approvals', 'Pending Approvals');
        var invoicesIndex = new Breadcrumb('/invoices/InvoicesDashboard', 'Invoices List');
        var newInvoice = new Breadcrumb('/invoices/SelectWorkRecords', 'New Invoice');
        var newInvoiceConfirmation = new Breadcrumb('/invoices/FillInvoiceDetails', 'Confirmation');
        var recurringServices = new Breadcrumb('/RecurringServices', 'Recurring Services');

        var service = {};

        service.paths = {
            '/': [config],
            '/emergencyTypes': [config, emergencyTypesIndex],
            '/emergencyTypes/create': [config, emergencyTypesIndex, emergencyTypesCreate],
            '/emergencyTypes/:id': getEmergencyTypesEditPath,
            '/priorityLevels': [config, priorityLevels],
            '/clientDashboard': [clientDashboard],
            '/vendorDashboard': [vendorDashboard],
            '/swDashboard': [swDashboard],
            '/workRecordsHome': [workRecordsHome],
            '/contractsHome': [contractsHome],
            '/importsHome': [config, importsHome],
            '/GenerateWRForRecurringSchedule': [workRecordsHome, recurringServices, GenerateWRForRecurringSchedule],
            '/reportsHome': [reportsHome],
            '/not-found': [],
            '/users': [config, usersIndex],
            '/users/create/:toCopyId?': getUsersFormCreatePath,
            '/users/edit/:id': getUsersFormPath,
            '/users/profile': [config, usersProfile],
            '/users/password': [config, usersProfile, usersPassword],
            '/users/forgot/password': [],
            '/users/forgot/username': [],
            '/clients': [config, clientsIndex],
            '/clients/create': [config, clientsIndex, clientsCreate],
            '/clients/:id': getClientsFormPath,
            '/clients/:clientId/locations': getClientLocationsIndexPath,
            '/clients/:clientId/locations/create': getClientLocationsCreatePath,
            '/clients/:clientId/locations/:locationId': getClientLocationsEditPath,
            '/clients/:clientId/regions': getClientRegionsIndexPath,
            '/clients/:clientId/regions/create': getClientRegionsCreatePath,
            '/clients/:clientId/regions/:regionId': getClientRegionsEditPath,
            '/clients/brand/edit/:id': getClientBrandEditPath,
            '/clients/:clientId/contracts': getClientContractAttachmentsPath,
            '/clients/:clientId/clientVendorRequiredFields': getClientReqFieldsPath,
            '/vendors': [config, vendorsIndex],
            '/vendors/create': [config, vendorsIndex, vendorsCreate],
            '/vendors/:vendorId': getVendorsEditPath,
            '/clients/:clientId/vendors': getClientVendorsIndexPath,
            '/clients/:clientId/vendors/create': getClientVendorsCreatePath,
            '/clients/:clientId/vendors/:vendorId': getClientVendorsEditPath,
            '/clients/:clientId/roles': getClientRolesIndexPath,
            '/alerts': [config, alertsIndex],
            '/roles': [config, rolesIndex],
            '/roles/create/:clientId?': getRolesCreatePath,
            '/roles/clone/:toCopyId?/:clientId?': getRolesCreatePath,
            '/roles/edit/:id/:clientId?': getRolesEditPath,
            '/services': [config, servicesIndex],
            '/clients/:clientId/services': getClientServicesPath,
            '/locations/:locationId/services': getLocationServicesPath,
            '/locations/:locationId/services/:serviceId/vendors': getServiceLocationVendorsIndexPath,
            '/clients/:clientId/vendors/:vendorId/services': getClientVendorServicesIndexPath,
            '/vendors/:vendorId/services': getVendorServicesIndexPath,
            '/services/:serviceId/questionnaire': getServiceQuestionsIndexPath,
            '/clients/:clientId/services/:serviceId/questionnaire': getClientServiceQuestionsIndexPath,
            '/clients/:clientId/clientPriorityLevels': getClientPriorityLevelsIndexPath,
            '/clients/:clientId/emergencyTypes': getClientEmergencyTypesIndexPath,
            '/clients/:clientId/emergencyTypes/create': getClientEmergencyTypesCreatePath,
            '/clients/:clientId/emergencyTypes/:id': getClientEmergencyTypesEditPath,
            '/login': [],
            '/workRecordsHome/create': [workRecordsHome, workRecordsIndex, workRecordsCreate],
            '/workRecordsHome/:workRecordId/edit/stepOne': getWorkRecordStepOnetPath,
            '/workRecordsHome/:workRecordId/edit/stepTwo': getWorkRecordStepTwoPath,
            '/workRecordsHome/:workRecordId/edit/stepThree': getWorkRecordStepThreePath,
            '/workRecordsHome/:workRecordId/edit/stepFour': getWorkRecordStepFourPath,
            '/workrecords/:workrecordId/vendors/create': getWorkRecordNewVendorPath,
            '/workRecordsHome/:workRecordId/edit/stepFourForm': getWorkRecordStepFourFormPath,
            '/workRecordsHome/:workRecordId/edit/stepFive': getWorkRecordStepFivePath,
            '/workRecordsHome/:workRecordId/edit/stepSix': getWorkRecordStepSixPath,
            '/workRecordsHome/:workRecordId/edit/stepSix/photos': getWorkRecordStepSixPhotoPath,
            '/emergencyTypes/:emergencyId/questionnaire': getEmergencyTypesQuestionsIndexPath,
            '/clients/:clientId/emergencyTypes/:emergencyId/questionnaire': getClientEmergencyTypesQuestionsIndexPath,
            '/clients/:clientId/wrconfiguration': getClientWRconfigurationIndexPath,
            '/clients/:clientId/wrconfiguration/emergencyNotifications': getClientWREmergencyNotificationsIndexPath,
            '/workRecordsHome/:workRecordId/view': createCurrentPathBreadcrumb([workRecordsHome, workRecordsIndex], 'Work Record View'),
            '/workRecordsHome/workRecords': [workRecordsHome, workRecordsIndex],
            '/workRecordsHome/approvals': [workRecordsHome, workRecordsApprovals],
            '/workRecordsHome/exceededNTEApproval/:exceededNTEApprovalId': createCurrentPathBreadcrumb([workRecordsHome, workRecordsApprovals], 'NTE Approval'),
            '/workRecordsHome/:workRecordId/vendorWorkReporting': getWorkRecordVendorWorkReporting([workRecordsHome, workRecordsIndex], 'Work Reporting'),
            '/workRecordsHome/exceededUserNTEApproval/:exceededUserNTEApprovalId': createCurrentPathBreadcrumb([workRecordsHome, workRecordsApprovals], 'User NTE Approval'),
            '/workRecordsHome/:workRecordId/note': getWorkRecordNotePath,
            '/workRecordsHome/:workRecordId/edit/stepSix/uploadSignOffSheet': getSignOffSheetPath,
            '/workRecordsHome/:workRecordId/vendorWorkReporting/photos': getWorkRecordVendorWorkReportingPhotos,
            '/invoices/InvoicesDashboard': [workRecordsHome, invoicesIndex],
            '/workRecordsHome/:workRecordId/expense': getWorkRecordExpensePath,
            '/invoices/SelectWorkRecords': [workRecordsHome, invoicesIndex, newInvoice],
            '/invoices/FillInvoiceDetails': [workRecordsHome, invoicesIndex, newInvoice, newInvoiceConfirmation],
            '/invoices/InvoiceView/:invoiceId': createCurrentPathBreadcrumb([workRecordsHome, invoicesIndex], 'Invoice View'),
            '/RecurringServices': [workRecordsHome, recurringServices],
            '/RecurringServices/:recurringServiceId': createCurrentPathBreadcrumb([workRecordsHome, recurringServices], 'Recurring Service'),
            '/RecurringSchedule/:recurringScheduleId': getRecurringSchedule,
            '/RecurringSchedule/:recurringScheduleId/Attachments': getRecurringScheduleAttachments,
            '/RecurringSchedule/:recurringScheduleId/Notes': getRecurringScheduleNotes,
            '/RecurringSchedule/:recurringServiceScheduleId/:recurringServiceId/:locationId': getNewRecurringSchedule,
            '/RecurringServices/:recurringServiceId/:recurringServiceScheduleId/:scheduleId/schedule': getRecurringServiceDateList,

            '/Contracts': [contractsHome, contracts],
            '/Contracts/CreateContract': [contractsHome, contracts, createContract],
            '/Contracts/ViewContract/:contractId': getContractViewPath,
            '/Contracts/EditContract/:contractId': getContractEditPath,
            '/Contracts/EditContract/:contractId/Locations': getContractEditLocationsPath,
            '/Contracts/EditContract/:contractId/Services': getContractEditServicesPath,
            '/Contracts/EditContract/:contractId/Lines': getContractEditLinesPath,
            '/vendors/:vendorId/Contracts': getContractPathByVendor,
            '/Contracts/CreateContract/:vendorId': getCreateContractPathByVendor,
            '/locations/:clientId/:locationId/Contracts': getContractPathByLocation,
            '/locations/:clientId/:locationId/Contracts/CreateContract': getContractCreateForClientPath,
            '/Contracts/EditContract/:contractId/Lines/NewLine/:locationId/:serviceId': getContractNewLinePath,
            '/Contracts/EditContract/:contractId/Lines/EditLine/:contractLineId': getContractEditLinePath,
            '/Contracts/PendingContractNTEApprovals': [contractsHome, pendingContractNTEApprovals],

            '/Imports/DownloadImportTemplates': getImportTemplatesPath,
            '/Imports/UploadImportFile': getUploadImportFilePath,
            '/clients/:clientId/users': getClientUsersIndexPath,
            '/clients/:clientId/users/create': getClientUsersCreatePath,
            '/clients/:clientId/users/:id': getClientUsersEditPath,
            '/clients/:clientId/users/create/:toCopyId?': getClientUsersCreatePath,
            '/NotificationTypes/Client/:clientId/List': getNotificationTypesIndexPath,
            '/NotificationTypes/Client/:clientId/Edit/:notificationTypeId': getNotificationTypeFormPath
        };

        service.get = get;

        return service;

        function get(path, currentPath, params, callback) {
            var res = this.paths[path];
            if (typeof res === 'function') {
                res(params, currentPath, callback);
            } else {
                callback(res);
            }
        }

        function getUsersFormPath(params, currentPath, callback) {
            UsersFactory.get(params).$promise.then(onGetSucceded);

            function onGetSucceded(user) {
                var res = [config, usersIndex, new Breadcrumb(currentPath, user.userName)];
                callback(res);
            }
        }

        function getUsersFormCreatePath(params, currentPath, callback) {
            callback([config, usersIndex, new Breadcrumb(currentPath, 'New User')]);
        }

        function getClientsFormPath(params, currentPath, callback) {
            ClientsFactory.get(params).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [config, clientsIndex, new Breadcrumb(currentPath, client.name)];
                callback(res);
            }
        }

        function getClientLocationsIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [config, clientsIndex, new Breadcrumb('/clients/' + params.clientId, client.name), new Breadcrumb(currentPath, 'Locations')];
                callback(res);
            }
        }

        function getNotificationTypesIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [config, clientsIndex, new Breadcrumb('/clients/' + params.clientId, client.name), new Breadcrumb(currentPath, 'Notification Templates')];
                callback(res);
            }
        }

        function getNotificationTypeFormPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [config, clientsIndex, new Breadcrumb('/clients/' + params.clientId, client.name), new Breadcrumb('/NotificationTypes/Client/' + params.clientId + '/List', 'Notification Templates'), new Breadcrumb(currentPath, 'Notification Template')];
                callback(res);
            }
        }

        function getClientLocationsCreatePath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb('/clients/' + params.clientId + '/locations', 'Locations'),
                    new Breadcrumb(currentPath, 'New Location')];
                callback(res);
            }
        }

        function getClientLocationsEditPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                LocationsFactory.get({ id: params.locationId }).$promise.then(onLocationGetSucceded);

                function onLocationGetSucceded(location) {
                    var res = [
                        config,
                        clientsIndex,
                        new Breadcrumb('/clients/' + params.clientId, client.name),
                        new Breadcrumb('/clients/' + params.clientId + '/locations', 'Locations'),
                        new Breadcrumb(currentPath, location.name)];
                    callback(res);
                }
            }
        }

        function getClientRegionsIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb(currentPath, 'Regions')];
                callback(res);
            }
        }

        function getClientRegionsCreatePath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb('/clients/' + params.clientId + '/regions', 'Regions'),
                    new Breadcrumb(currentPath, 'New Region')];
                callback(res);
            }
        }

        function getClientRegionsEditPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                RegionsFactory.get({ id: params.regionId }).$promise.then(onRegionsGetSucceded);

                function onRegionsGetSucceded(region) {
                    var res = [
                        config,
                        clientsIndex,
                        new Breadcrumb('/clients/' + params.clientId, client.name),
                        new Breadcrumb('/clients/' + params.clientId + '/regions', 'Regions'),
                        new Breadcrumb(currentPath, region.name)];
                    callback(res);
                }
            }
        }

        function getClientBrandEditPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.id }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.id, client.name),
                    new Breadcrumb(currentPath, 'Edit Brand')];
                callback(res);
            }
        }

        function getClientContractAttachmentsPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb(currentPath, 'Edit Contract Configuration')];
                callback(res);
            }
        }

        function getClientReqFieldsPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb(currentPath, 'Vendor Required Fields')];
                callback(res);
            }
        }

        function getVendorsEditPath(params, currentPath, callback) {
            VendorsFactory.get({ id: params.vendorId }).$promise.then(onGetSuccess);

            function onGetSuccess(vendor) {
                var res = [
                    config,
                    vendorsIndex,
                    new Breadcrumb(currentPath, vendor.name)];
                callback(res);
            }
        }

        function getClientVendorsIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb(currentPath, 'Vendors')];
                callback(res);
            }
        }

        function getClientVendorsCreatePath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb('/clients/' + params.clientId + '/vendors', 'Vendors'),
                    new Breadcrumb(currentPath, 'New Vendor')];
                callback(res);
            }
        }

        function getClientVendorsEditPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                VendorsFactory.get({ id: params.vendorId }).$promise.then(onGetSuccess);

                function onGetSuccess(vendor) {
                    var res = [
                        config,
                        clientsIndex,
                        new Breadcrumb('/clients/' + params.clientId, client.name),
                        new Breadcrumb('/clients/' + params.clientId + '/vendors', 'Vendors'),
                        new Breadcrumb(currentPath, vendor.name)];
                    callback(res);
                }
            }
        }

        function getClientRolesIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb(currentPath, 'Roles')];
                callback(res);
            }
        }

        function getRolesEditPath(params, currentPath, callback) {
            if (params.clientId) {
                RolesFactory.get({ id: params.id }).$promise.then(onGetSuccessFromClient);
            }
            else {
                RolesFactory.get({ id: params.id }).$promise.then(onGetSuccessFromConfig);
            }

            function onGetSuccessFromConfig(role) {
                var res = [
                    config,
                    rolesIndex,
                    new Breadcrumb(currentPath, role.name)];
                callback(res);
            }

            function onGetSuccessFromClient(role) {
                ClientsFactory.get({ id: params.clientId }).$promise.then(function (client) {
                    var res = [
                        config,
                        clientsIndex,
                        new Breadcrumb('/clients/' + params.clientId, client.name),
                        new Breadcrumb('/clients/' + params.clientId + '/roles', 'Roles'),
                        new Breadcrumb(currentPath, role.name)];
                    callback(res);
                });
            }
        }

        function getClientServicesPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb(currentPath, 'Services')];
                callback(res);
            }
        }

        function getLocationServicesPath(params, currentPath, callback) {
            LocationsFactory.get({ id: params.locationId }).$promise.then(onGetSuccess);

            function onGetSuccess(location) {
                ClientsFactory.get({ id: location.clientId }).$promise.then(onClientGetSuccess);

                function onClientGetSuccess(client) {
                    var res = [
                        config,
                        clientsIndex,
                        new Breadcrumb('/clients/' + location.clientId, client.name),
                        new Breadcrumb('/clients/' + location.clientId + '/locations', 'Locations'),
                        new Breadcrumb('/clients/' + location.clientId + '/locations/' + params.locationId, location.name),
                        new Breadcrumb(currentPath, 'Services')];
                    callback(res);
                }
            }
        }

        function getServiceLocationVendorsIndexPath(params, currentPath, callback) {
            LocationsFactory.get({ id: params.locationId }).$promise.then(onLocationGetSuccess);

            function onLocationGetSuccess(location) {
                ClientsFactory.get({ id: location.clientId }).$promise.then(onClientGetSuccess);

                function onClientGetSuccess(client) {
                    ServicesService.get({ id: params.serviceId }).$promise.then(onServiceGetSuccess);

                    function onServiceGetSuccess(service) {
                        var res = [
                            config,
                            clientsIndex,
                            new Breadcrumb('/clients/' + location.clientId, client.name),
                            new Breadcrumb('/clients/' + location.clientId + '/locations', 'Locations'),
                            new Breadcrumb('/clients/' + location.clientId + '/locations/' + params.locationId, location.name),
                            new Breadcrumb('/locations/' + params.locationId + '/services', 'Services'),
                            new Breadcrumb(currentPath, service.keyTerm + "'s Vendors")];
                        callback(res);
                    }
                }
            }
        }

        function getVendorServicesIndexPath(params, currentPath, callback) {
            VendorsFactory.get({ id: params.vendorId }).$promise.then(onGetSuccess);

            function onGetSuccess(vendor) {
                var res = [
                    config,
                    vendorsIndex,
                    new Breadcrumb('/vendors/' + params.vendorId, vendor.name),
                    new Breadcrumb(currentPath, 'Services')];
                callback(res);
            }
        }

        function getContractPathByVendor(params, currentPath, callback) {
            VendorsFactory.get({ id: params.vendorId }).$promise.then(onGetSuccess);

            function onGetSuccess(vendor) {
                var res = [
                    config,
                    vendorsIndex,
                    new Breadcrumb('/vendors/' + params.vendorId, vendor.name),
                    new Breadcrumb(currentPath, 'Contracts')];
                callback(res);
            }
        }

        function getCreateContractPathByVendor(params, currentPath, callback) {
            VendorsFactory.get({ id: params.vendorId }).$promise.then(onGetSuccess);

            function onGetSuccess(vendor) {
                var res = [
                    config,
                    vendorsIndex,
                    new Breadcrumb('/vendors/' + params.vendorId, vendor.name),
                    new Breadcrumb('/vendors/' + params.vendorId + '/Contracts', 'Contracts'),
                    new Breadcrumb(currentPath, 'Create Contract')];
                callback(res);
            }
        }

        function getContractPathByLocation(params, currentPath, callback) {
            LocationsFactory.get({ id: params.locationId }).$promise.then(onGetSuccess);

            function onGetSuccess(location) {
                ClientsFactory.get({ id: location.clientId }).$promise.then(onClientGetSuccess);

                function onClientGetSuccess(client) {
                    var res = [
                        config,
                        clientsIndex,
                        new Breadcrumb('/clients/' + location.clientId, client.name),
                        new Breadcrumb('/clients/' + location.clientId + '/locations', 'Locations'),
                        new Breadcrumb('/clients/' + location.clientId + '/locations/' + params.locationId, location.name),
                        new Breadcrumb(currentPath, 'Contracts')];
                    callback(res);
                }
            }
        }

        function getServiceQuestionsIndexPath(params, currentPath, callback) {
            ServicesService.get({ id: params.serviceId }).$promise.then(onServiceGetSuccess);

            function onServiceGetSuccess(service) {
                var res = [
                    config,
                    servicesIndex,
                    new Breadcrumb(currentPath, service.keyTerm + "'s Questionnaire")
                ];
                callback(res);
            }
        }

        function getClientServiceQuestionsIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                ServicesService.get({ id: params.serviceId }).$promise.then(onServiceGetSuccess);

                function onServiceGetSuccess(service) {
                    var res = [
                        config,
                        clientsIndex,
                        new Breadcrumb('/clients/' + params.clientId, client.name),
                        new Breadcrumb('/clients/' + params.clientId + '/services', 'Services'),
                        new Breadcrumb(currentPath, service.keyTerm + "'s Questionnaire")
                    ];
                    callback(res);
                }
            }
        }

        function getEmergencyTypesQuestionsIndexPath(params, currentPath, callback) {
            EmergencyTypesFactory.get({ id: params.emergencyId }).$promise.then(onServiceGetSuccess);

            function onServiceGetSuccess(emergency) {
                var res = [
                    config,
                    emergencyTypesIndex,
                    new Breadcrumb(currentPath, emergency.description + "'s Questionnaire")
                ];
                callback(res);
            }
        }


        function getClientEmergencyTypesQuestionsIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                EmergencyTypesFactory.get({ id: params.emergencyId }).$promise.then(onServiceGetSuccess);

                function onServiceGetSuccess(emergency) {
                    var res = [
                        config,
                        clientsIndex,
                        new Breadcrumb('/clients/' + params.clientId, client.name),
                        new Breadcrumb('/clients/' + params.clientId + '/emergencyTypes', 'Emergency Types'),
                        new Breadcrumb(currentPath, emergency.description + "'s Questionnaire")
                    ];
                    callback(res);
                }
            }
        }

        function getClientVendorServicesIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                VendorsFactory.get({ id: params.vendorId }).$promise.then(onGetSuccess);

                function onGetSuccess(vendor) {
                    var res = [
                        config,
                        clientsIndex,
                        new Breadcrumb('/clients/' + params.clientId, client.name),
                        new Breadcrumb('/clients/' + params.clientId + '/vendors', 'Vendors'),
                        new Breadcrumb('/clients/' + params.clientId + '/vendors/' + params.vendorId, vendor.name),
                        new Breadcrumb(currentPath, 'Services')];
                    callback(res);
                }
            }
        }
        function getClientPriorityLevelsIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb('/clients/' + params.clientId + '/clientPriorityLevels', 'Priority Levels')];
                callback(res);
            }
        }

        function getEmergencyTypesEditPath(params, currentPath, callback) {
            EmergencyTypesFactory.get({ id: params.id }).$promise.then(onGetSuccess);

            function onGetSuccess(emergency) {
                var res = [
                    config,
                    emergencyTypesIndex,
                    new Breadcrumb(currentPath, emergency.description)];
                callback(res);
            }
        }

        function getClientEmergencyTypesIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb('/clients/' + params.clientId + '/emergencyTypes', 'Emergency Types')];
                callback(res);
            }
        }


        function getClientEmergencyTypesCreatePath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb('/clients/' + params.clientId + '/emergencyTypes', 'Emergency Types'),
                    new Breadcrumb(currentPath, 'New Emergency Types')];
                callback(res);
            }
        }

        function getClientEmergencyTypesEditPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                EmergencyTypesFactory.get({ id: params.id }).$promise.then(onGetSuccess);

                function onGetSuccess(emergency) {
                    var res = [
                        config,
                        clientsIndex,
                        new Breadcrumb('/clients/' + params.clientId, client.name),
                        new Breadcrumb('/clients/' + params.clientId + '/emergencyTypes', 'Emergency Types'),
                        new Breadcrumb(currentPath, emergency.description)];
                    callback(res);
                }
            }
        }

        function createCurrentPathBreadcrumb(path, label) {
            return function (params, currentPath, callback) {
                var newBreadcrumb = new Breadcrumb(currentPath, label);
                var newPath = path.slice(0);
                newPath.push(newBreadcrumb);
                callback(newPath);
            };
        }

        function getWorkRecordStepOnetPath(params, currentPath, callback) {
            WorkRecordsFactory.get({ id: params.workRecordId }).$promise.then(onGetSuccess);

            function onGetSuccess(wr) {
                var res = [
                    workRecordsHome,
                    workRecordsIndex,
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection')];
                callback(res);
            }
        }

        function getWorkRecordStepTwoPath(params, currentPath, callback) {
            WorkRecordsFactory.get({ id: params.workRecordId }).$promise.then(onGetSuccess);

            function onGetSuccess(wr) {
                var res = [
                    workRecordsHome,
                    workRecordsIndex,
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepTwo', 'Triage Questionnaire')];
                callback(res);
            }
        }

        function getWorkRecordStepThreePath(params, currentPath, callback) {
            WorkRecordsFactory.get({ id: params.workRecordId }).$promise.then(onGetSuccess);

            function onGetSuccess(wr) {
                var res = [];
                if (wr.questionnaireId !== null && wr.questionnaireId > 0) {
                    res = [
                    workRecordsHome,
                    workRecordsIndex,
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepTwo', 'Triage Questionnaire'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepThree', 'Work Record Creation')];
                    callback(res);
                } else {
                    res = [
                    workRecordsHome,
                    workRecordsIndex,
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepThree', 'Work Record Creation')];
                    callback(res);
                }

            }
        }

        function getWorkRecordStepFourPath(params, currentPath, callback) {
            WorkRecordsFactory.get({ id: params.workRecordId }).$promise.then(onGetSuccess);

            function onGetSuccess(wr) {
                var res = [];
                if (wr.questionnaireId !== null && wr.questionnaireId > 0) {
                    res = [
                        workRecordsHome,
                        workRecordsIndex,
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepTwo', 'Triage Questionnaire'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepThree', 'Work Record Creation'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFour', 'Vendor Selection')];
                } else {
                    res = [
                    workRecordsHome,
                    workRecordsIndex,
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepThree', 'Work Record Creation'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFour', 'Vendor Selection')];
                }

                callback(res);
            }
        }

        function getWorkRecordNewVendorPath(params, currentPath, callback) {
            WorkRecordsFactory.get({ id: params.workrecordId }).$promise.then(onGetSuccess);

            function onGetSuccess(wr) {
                var res = [];
                if (wr.questionnaireId !== null && wr.questionnaireId > 0) {
                    res = [
                        workRecordsHome,
                        workRecordsIndex,
                        new Breadcrumb('/workRecordsHome/' + params.workrecordId + '/edit/stepOne', 'Service Selection'),
                        new Breadcrumb('/workRecordsHome/' + params.workrecordId + '/edit/stepTwo', 'Triage Questionnaire'),
                        new Breadcrumb('/workRecordsHome/' + params.workrecordId + '/edit/stepThree', 'Work Record Creation'),
                        new Breadcrumb('/workRecordsHome/' + params.workrecordId + '/edit/stepFour', 'Vendor Selection'),
                        new Breadcrumb('/workrecords/' + params.workrecordId + '/vendors/create', 'New Vendor')
                    ];
                } else {
                    res = [
                    workRecordsHome,
                    workRecordsIndex,
                    new Breadcrumb('/workRecordsHome/' + params.workrecordId + '/edit/stepOne', 'Service Selection'),
                    new Breadcrumb('/workRecordsHome/' + params.workrecordId + '/edit/stepThree', 'Work Record Creation'),
                    new Breadcrumb('/workRecordsHome/' + params.workrecordId + '/edit/stepFour', 'Vendor Selection'),
                    new Breadcrumb('/workrecords/' + params.workrecordId + '/vendors/create', 'New Vendor')
                    ];
                }

                callback(res);
            }
        }

        function getWorkRecordStepFourFormPath(params, curentPath, callback) {
            WorkRecordsFactory.get({ id: params.workRecordId }).$promise.then(onGetSuccess);

            function onGetSuccess(wr) {
                var res = [];
                if (wr.questionnaireId !== null && wr.questionnaireId > 0) {
                    res = [
                        workRecordsHome,
                        workRecordsIndex,
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepTwo', 'Triage Questionnaire'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepThree', 'Work Record Creation'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFour', 'Vendor Selection'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFourForm', 'Vendor Selection Form')];
                } else {
                    res = [
                    workRecordsHome,
                    workRecordsIndex,
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepThree', 'Work Record Creation'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFour', 'Vendor Selection'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFourForm', 'Vendor Selection Form')];
                }

                callback(res);
            }
        }

        function getWorkRecordStepFivePath(params, curentPath, callback) {
            WorkRecordsFactory.get({ id: params.workRecordId }).$promise.then(onGetSuccess);

            function onGetSuccess(wr) {
                var res = [];
                if (wr.questionnaireId !== null && wr.questionnaireId > 0) {
                    res = [
                        workRecordsHome,
                        workRecordsIndex,
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepTwo', 'Triage Questionnaire'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepThree', 'Work Record Creation'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFour', 'Vendor Selection'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFourForm', 'Vendor Selection Form'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFive', 'Scope Review')];
                } else {
                    res = [
                    workRecordsHome,
                    workRecordsIndex,
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepThree', 'Work Record Creation'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFour', 'Vendor Selection'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFourForm', 'Vendor Selection Form'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFive', 'Scope Review')];
                }

                callback(res);
            }
        }

        function getWorkRecordVendorWorkReporting(path, label) {
            return function (params, currentPath, callback) {
                var newBreadcrumb = new Breadcrumb(currentPath, label);
                var newPath = path.slice(0);
                newPath.push(newBreadcrumb);
                callback(newPath);
            };
        }

        function getWorkRecordNotePath(params, currentPath, callback) {
            WorkRecordsFactory.get({ id: params.workRecordId }).$promise.then(onGetSuccess);

            function onGetSuccess(wr) {
                var res = [
                    workRecordsHome,
                    workRecordsIndex,
                    new Breadcrumb('/workRecordsHome/' + wr.id + '/vendorWorkReporting', 'Ref #' + wr.referenceNumber + ' Work Reporting'),
                    new Breadcrumb(currentPath, 'Notes')];

                callback(res);
            }
        }

        function getWorkRecordExpensePath(params, currentPath, callback) {
            WorkRecordsFactory.get({ id: params.workRecordId }).$promise.then(onGetSuccess);

            function onGetSuccess(wr) {
                var res = [
                    workRecordsHome,
                    workRecordsIndex,
                    new Breadcrumb('/workRecordsHome/' + wr.id + '/vendorWorkReporting', 'Ref #' + wr.referenceNumber + ' Work Reporting'),
                    new Breadcrumb(currentPath, 'Expenses')];

                callback(res);
            }
        }
        function getWorkRecordStepSixPath(params, curentPath, callback) {
            WorkRecordsFactory.get({ id: params.workRecordId }).$promise.then(onGetSuccess);

            function onGetSuccess(wr) {
                var currentUser = {};
                var res = [];

                if (CurrentUserFactory.exists()) {
                    currentUser = CurrentUserFactory.get();
                }

                if (currentUser.vendor !== null && currentUser.vendor !== undefined) {
                    res = [
                        workRecordsHome,
                        workRecordsIndex,
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepSix', 'Work Reporting')];
                }
                else {
                    if (wr.questionnaireId !== null && wr.questionnaireId > 0) {
                        res = [
                            workRecordsHome,
                            workRecordsIndex,
                            new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                            new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepTwo', 'Triage Questionnaire'),
                            new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepThree', 'Work Record Creation'),
                            new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFour', 'Vendor Selection'),
                            new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFourForm', 'Vendor Selection Form'),
                            new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFive', 'Scope Review'),
                            new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepSix', 'Work Reporting')];
                    } else {
                        res = [
                        workRecordsHome,
                        workRecordsIndex,
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepThree', 'Work Record Creation'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFour', 'Vendor Selection'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFourForm', 'Vendor Selection Form'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFive', 'Scope Review'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepSix', 'Work Reporting')];
                    }
                }

                callback(res);
            }
        }

        function getWorkRecordStepSixPhotoPath(params, curentPath, callback) {
            WorkRecordsFactory.get({ id: params.workRecordId }).$promise.then(onGetSuccess);

            function onGetSuccess(wr) {
                var res = [];
                if (wr.questionnaireId !== null && wr.questionnaireId > 0) {
                    res = [
                        workRecordsHome,
                        workRecordsIndex,
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepTwo', 'Triage Questionnaire'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepThree', 'Work Record Creation'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFour', 'Vendor Selection'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFourForm', 'Vendor Selection Form'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFive', 'Scope Review'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepSix', 'Work Reporting'),
                        new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepSix/photos', 'Photos')];
                } else {
                    res = [
                    workRecordsHome,
                    workRecordsIndex,
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepOne', 'Service Selection'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepThree', 'Work Record Creation'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFour', 'Vendor Selection'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFourForm', 'Vendor Selection Form'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepFive', 'Scope Review'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepSix', 'Work Reporting'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepSix/photos', 'Photos')];
                }

                callback(res);
            }
        }


        function getWorkRecordVendorWorkReportingPhotos(params, curentPath, callback) {
            WorkRecordsFactory.get({ id: params.workRecordId }).$promise.then(onGetSuccess);

            function onGetSuccess(wr) {
                var res = [];
                res = [
                    workRecordsHome,
                    workRecordsIndex,
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/vendorWorkReporting', 'Work Reporting'),
                    new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/vendorWorkReporting/photos', 'Photos')];

                callback(res);
            }
        }

        function getRolesCreatePath(params, currentPath, callback) {
            if (params.clientId !== null) {
                ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);
            }
            else {
                callback([config, rolesIndex, new Breadcrumb(currentPath, 'New Role')]);
            }

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb('/clients/' + params.clientId + '/roles', 'Roles'),
                    new Breadcrumb(currentPath, 'New Role')];
                callback(res);
            }
        }

        function getClientWRconfigurationIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb('/clients/' + params.clientId + '/wrconfiguration', 'Work Record Configuration')];
                callback(res);
            }
        }

        function getClientWREmergencyNotificationsIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb('/clients/' + params.clientId + '/wrconfiguration', 'Work Record Configuration'),
                    new Breadcrumb('/clients/' + params.clientId + '/wrconfiguration/emergencyNotifications', 'Emergency Notifications')];
                callback(res);
            }
        }

        function getSignOffSheetPath(params, curentPath, callback) {
            var res = [
                workRecordsHome,
                workRecordsIndex,
                new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepSix', 'Work Reporting'),
                new Breadcrumb('/workRecordsHome/' + params.workRecordId + '/edit/stepSix/uploadSignOffSheet', 'Upload sign-off sheet')
            ];
            callback(res);
        }

        function getRecurringSchedule(params, curentPath, callback) {

            RecurringServicesFactory.api.editRecurringSchedule({ recurringScheduleId: params.recurringScheduleId }).$promise.then(onGetSuccess);

            function onGetSuccess(sch) {
                var res = [
                    workRecordsHome,
                    recurringServices,
                    new Breadcrumb('/RecurringServices/' + sch.recurringServiceId, 'Recurring Service'),
                    new Breadcrumb('/RecurringSchedule/' + params.recurringScheduleId, 'Edit Schedule')
                ];
                callback(res);
            }
        }

        function getNewRecurringSchedule(params, curentPath, callback) {

            RecurringServicesFactory.api.editRecurringSchedule({ recurringScheduleId: params.recurringScheduleId }).$promise.then(onGetSuccess);

            function onGetSuccess(sch) {
                var res = [
                    workRecordsHome,
                    recurringServices,
                    new Breadcrumb('/RecurringServices/' + sch.recurringServiceId, 'Recurring Service'),
                    new Breadcrumb('/RecurringSchedule/' + params.recurringScheduleId, 'Schedule')
                ];
                callback(res);
            }
        }

        function getRecurringServiceDateList(params, curentPath, callback) {
            var res = [
                workRecordsHome,
                recurringServices,
                new Breadcrumb('/RecurringServices/' + params.recurringServiceId, 'Recurring Service'),
                new Breadcrumb('/RecurringServices/' + params.recurringServiceId + '/' + params.recurringServiceScheduleId + '/' + params.scheduleId + '/schedule', 'View Schedule Dates')
            ];
            callback(res);
        }

        function getRecurringScheduleAttachments(params, curentPath, callback) {
            RecurringServicesFactory.api.editRecurringSchedule({ recurringScheduleId: params.recurringScheduleId }).$promise.then(onGetSuccess);

            function onGetSuccess(sch) {
                var res = [];
                res = [
                    workRecordsHome,
                    recurringServices,
                    new Breadcrumb('/RecurringServices/' + sch.recurringServiceId, 'Recurring Service'),
                    new Breadcrumb('/RecurringSchedule/' + params.recurringScheduleId, 'Edit Schedule'),
                    new Breadcrumb('/RecurringSchedule/' + params.recurringScheduleId + '/Attachments', 'Attachments')
                ];
                callback(res);
            }
        }

        function getRecurringScheduleNotes(params, curentPath, callback) {
            RecurringServicesFactory.api.editRecurringSchedule({ recurringScheduleId: params.recurringScheduleId }).$promise.then(onGetSuccess);

            function onGetSuccess(sch) {
                var res = [];
                res = [
                    workRecordsHome,
                    recurringServices,
                    new Breadcrumb('/RecurringServices/' + sch.recurringServiceId, 'Recurring Service'),
                    new Breadcrumb('/RecurringSchedule/' + params.recurringScheduleId, 'Edit Schedule'),
                    new Breadcrumb('/RecurringSchedule/' + params.recurringScheduleId + '/Notes', 'Notes')
                ];
                callback(res);
            }
        }

        function getContractViewPath(params, curentPath, callback) {

            ContractsFactory.api.getContractForView({ contractId: params.contractId }).$promise.then(onGetSuccess);

            function onGetSuccess(contract) {
                var res = [
                    contractsHome,
                    contracts,
                    new Breadcrumb('/Contracts/ViewContract/' + contract.contractId, "Contract")
                ];
                callback(res);
            }
        }

        function getContractEditPath(params, curentPath, callback) {

            ContractsFactory.api.getContractForView({ contractId: params.contractId }).$promise.then(onGetSuccess);

            function onGetSuccess(contract) {
                var res = [
                    contractsHome,
                    contracts,
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId, "Edit Contract")
                ];
                callback(res);
            }
        }

        function getContractCreateForClientPath(params, currentPath, callback) {
            LocationsFactory.get({ id: params.locationId }).$promise.then(onGetSuccess);

            function onGetSuccess(location) {
                ClientsFactory.get({ id: location.clientId }).$promise.then(onClientGetSuccess);

                function onClientGetSuccess(client) {
                    var res = [
                        config,
                        clientsIndex,
                        new Breadcrumb('/clients/' + location.clientId, client.name),
                        new Breadcrumb('/clients/' + location.clientId + '/locations', 'Locations'),
                        new Breadcrumb('/clients/' + location.clientId + '/locations/' + params.locationId, location.name),
                        new Breadcrumb('/locations/' + location.clientId + '/' + params.locationId + '/Contracts', 'Contracts'),
                        new Breadcrumb(currentPath, 'CreateContract')];
                    callback(res);
                }
            }
        }



        function getContractEditLocationsPath(params, curentPath, callback) {

            ContractsFactory.api.getContractForView({ contractId: params.contractId }).$promise.then(onGetSuccess);

            function onGetSuccess(contract) {
                var res = [
                    contractsHome,
                    contracts,
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId, "Edit Contract"),
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId + "/Locations", "Locations")
                ];
                callback(res);
            }
        }

        function getContractEditServicesPath(params, curentPath, callback) {

            ContractsFactory.api.getContractForView({ contractId: params.contractId }).$promise.then(onGetSuccess);

            function onGetSuccess(contract) {
                var res = [
                    contractsHome,
                    contracts,
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId, "Edit Contract"),
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId + "/Services", "Services")
                ];
                callback(res);
            }
        }

        function getContractEditLinesPath(params, curentPath, callback) {

            ContractsFactory.api.getContractForView({ contractId: params.contractId }).$promise.then(onGetSuccess);

            function onGetSuccess(contract) {
                var res = [
                    contractsHome,
                    contracts,
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId, "Edit Contract"),
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId + "/Lines", "Lines")
                ];
                callback(res);
            }
        }

        function getContractEditLinePath(params, curentPath, callback) {

            ContractsFactory.api.getContractForView({ contractId: params.contractId }).$promise.then(onGetSuccess);

            function onGetSuccess(contract) {
                var res = [
                    contractsHome,
                    contracts,
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId, "Edit Contract"),
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId + "/Lines", "Lines"),
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId + "/Lines/EditLine/" + params.contractLineId, "Edit Line")
                ];
                callback(res);
            }
        }

        function getContractNewLinePath(params, curentPath, callback) {

            ContractsFactory.api.getContractForView({ contractId: params.contractId }).$promise.then(onGetSuccess);

            function onGetSuccess(contract) {
                var res = [
                    contractsHome,
                    contracts,
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId, "Edit Contract"),
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId + "/Lines", "Lines"),
                    new Breadcrumb('/Contracts/EditContract/' + contract.contractId + "/Lines/NewLine/" + params.locationId + "/" + params.serviceId, "New Line")
                ];
                callback(res);
            }
        }

        function getImportTemplatesPath(params, currentPath, callback) {
            var res = [
                config,
                importsHome,
                new Breadcrumb(currentPath, 'Download Import Templates List')
            ];
            callback(res);
        }

        function getUploadImportFilePath(params, currentPath, callback) {
            var res = [
                config,
                importsHome,
                new Breadcrumb(currentPath, 'Upload Bulk Import')
            ];
            callback(res);
        }


        function getClientUsersIndexPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb('/clients/' + params.clientId + '/users', 'Users')];
                callback(res);
            }
        }


        function getClientUsersCreatePath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onGetSuccess);

            function onGetSuccess(client) {
                var res = [
                    config,
                    clientsIndex,
                    new Breadcrumb('/clients/' + params.clientId, client.name),
                    new Breadcrumb('/clients/' + params.clientId + '/users', 'Users'),
                    new Breadcrumb(currentPath, 'New User')];
                callback(res);
            }
        }

        function getClientUsersEditPath(params, currentPath, callback) {
            ClientsFactory.get({ id: params.clientId }).$promise.then(onClientGetSuccess);

            function onClientGetSuccess(client) {
                UsersFactory.get({ id: params.id }).$promise.then(onGetSuccess);

                function onGetSuccess(user) {
                    var res = [
                        config,
                        clientsIndex,
                        new Breadcrumb('/clients/' + params.clientId, client.name),
                        new Breadcrumb('/clients/' + params.clientId + '/users', 'Users'),
                        new Breadcrumb(currentPath, user.name)];
                    callback(res);
                }
            }
        }
    }
})();