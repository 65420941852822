(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('AuthFactory', AuthFactory);

    AuthFactory.$inject = ['$http', '$q', 'localStorageService', 'ngAuthSettings', 'AuthUserService', 'CurrentUserFactory'];

    function AuthFactory($http, $q, localStorageService, ngAuthSettings, AuthUserService, CurrentUserFactory) {
        var serviceBase = ngAuthSettings.apiServiceBaseUri;

        var authServiceFactory = {
            saveRegistration: saveRegistration,
            login: login,
            logOut: logOut,
            fillAuthData: fillAuthData,
            refreshToken: refreshToken,
            getUserInformation: getUserInformation,
            obtainAccessToken: obtainAccessToken,
            registerExternal: registerExternal,
            isLoggedIn: isLoggedIn,
            get: get
        };

        return authServiceFactory;

        function saveRegistration(registration) {
            logOut();

            return $http.post(serviceBase + 'api/account/register', registration).then(function (response) {
                return response;
            });
        }

        function login(loginData) {
            var deferred = $q.defer();
            var data = "grant_type=password&username=" + loginData.userName + "&password=" + encodeURIComponent(loginData.password) + "&client_id=" + ngAuthSettings.clientId;

            $http.post(serviceBase + 'token', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).success(function (response) {

                AuthUserService.set({ token: response.access_token, userName: loginData.userName, refreshToken: response.refresh_token, useRefreshTokens: loginData.useRefreshTokens, refreshTokenDateTime: Date.now() });

                getUserInformation(deferred);
            }).error(function (err, status) {
                logOut();
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function logOut() {
            AuthUserService.remove();
            //localStorageService.remove('authorizationData');
            CurrentUserFactory.remove();
        }

        function fillAuthData() {
            //var authData = localStorageService.get('authorizationData');
            var authData = AuthUserService.get();

            if (authData) {
                getUserInformation().then(function () { }, logOut);
            }
        }

        function refreshToken() {
            var deferred = $q.defer();

            //var authData = localStorageService.get('authorizationData');
            var authData = AuthUserService.get();

            if (authData) {

                var data = "grant_type=refresh_token&refresh_token=" + authData.refreshToken + "&client_id=" + ngAuthSettings.clientId;

                //localStorageService.remove('authorizationData');
                //AuthUserService.remove();

                $http.post(serviceBase + 'token', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).success(function (response) {

                    AuthUserService.set({ token: response.access_token, userName: response.userName, refreshToken: response.refresh_token, useRefreshTokens: authData.useRefreshTokens, refreshTokenDateTime: Date.now() });
                    //localStorageService.set('authorizationData', { token: response.access_token, userName: response.userName, refreshToken: response.refresh_token, useRefreshTokens: true });

                    getUserInformation(deferred);

                }).error(function (err, status) {
                    //logOut();
                    deferred.reject(err);
                });
            }

            return deferred.promise;
        }

        function obtainAccessToken() {
            var deferred = $q.defer();

            $http.get(serviceBase + 'api/account/ObtainLocalAccessToken', { params: { provider: externalData.provider, externalAccessToken: externalData.externalAccessToken } }).success(function (response) {
                AuthUserService.set({ token: response.access_token, userName: response.userName, refreshToken: "", useRefreshTokens: false });
                //localStorageService.set('authorizationData', { token: response.access_token, userName: response.userName, refreshToken: "", useRefreshTokens: false });

                deferred.resolve(response);

            }).error(function (err, status) {
                logOut();
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function registerExternal() {
            var deferred = $q.defer();

            $http.post(serviceBase + 'api/account/registerexternal', registerExternalData).success(function (response) {
                AuthUserService.set({ token: response.access_token, userName: response.userName, refreshToken: "", useRefreshTokens: false });
                //localStorageService.set('authorizationData', { token: response.access_token, userName: response.userName, refreshToken: "", useRefreshTokens: false });

                deferred.resolve(response);

            }).error(function (err, status) {
                logOut();
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function isLoggedIn() {
            //var authData = localStorageService.get('authorizationData');
            var authData = AuthUserService.get();
            return authData !== null && authData !== undefined;
        }

        function get() {
            return authentication.userName;
        }

        function getUserInformation(deferred) {
            if (!deferred) {
                deferred = $q.defer();
            }

            $http.get(serviceBase + 'api/Users/me')
                .success(function (response) {
                    CurrentUserFactory.set(response);
                    deferred.resolve(response);
                })
            .error(function (err, status) {
                deferred.reject(err);
            });

            return deferred.promise;
        }
    }
})();