(function () {
    angular.module('springwise').directive('swTimeForm', swTimeForm);
    
    swTimeForm.$inject = [];

    function swTimeForm() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/directives/time/timeFormView.html',
            scope: {
                time: '=time',
                required: '=required',
                validation: '='
            }
        };
    }
})();