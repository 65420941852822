(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('UploadImportFileCtrl', UploadImportFileCtrl);

    UploadImportFileCtrl.$inject = ['$scope', '$location', '$http', 'ngAuthSettings', 'CurrentUserFactory', '$interval', '$window'];

    function UploadImportFileCtrl($scope, $location, $http, ngAuthSettings, CurrentUserFactory, $interval, $window) {

        var stopUpdateStatus;
        var importResponse;

        $scope.CurrentUserFactory = CurrentUserFactory;
        $scope.percentage = 0;
        $scope.showImporting = true;
        activate();

        function activate() {
            $scope.showUploadControl = true;
        }

        $scope.uploadImportFile = function () {
            $scope.fileToUpload.send('api/Imports/UploadImportFile/', startImport);
        };

        $scope.refresh = function () {
            $window.location.reload();
        };

        function onImportStarted() {
            stopUpdateStatus = $interval(function () {
                $scope.updateStatus();
            }, 300);
        }

        $scope.updateStatus = function () {

            $http.post(ngAuthSettings.apiServiceBaseUri + 'api/imports/GetImportStatus/', importResponse)
                .success(function(statusResponse) {
                    $scope.showStatus = true;
                    $scope.percentage = statusResponse.percentage;
                    if (statusResponse.importStatus > 0) {                        
                        window.console.debug(statusResponse);
                        $scope.percentage = 100;
                        $scope.showImporting = false;
                        $scope.importResults = statusResponse.importResult.results;
                        $interval.cancel(stopUpdateStatus);
                    }
                    if ($scope.percentage !== 0) {
                        $scope.showProgress();
                    }
                });

        };

        function startImport(response) {
            importResponse = response;
            $http.post(ngAuthSettings.apiServiceBaseUri + 'api/imports/StartImport/', response).success(onImportStarted);
        }

        $scope.showProgress = function () {
            $scope.dynamic = $scope.percentage;
        };
    }
})();