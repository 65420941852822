(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('configHomeCtrl', configHomeCtrl);

    configHomeCtrl.$inject = ['$scope', 'AuthFactory', 'CurrentUserFactory', 'ClientsFactory', 'permissions'];

    function configHomeCtrl($scope, AuthFactory, CurrentUserFactory, ClientsFactory, permissions) {
        var currentUser = CurrentUserFactory.get();

        activate();

        function activate() {

            $scope.isLoggedIn = AuthFactory.isLoggedIn;

            if (currentUser) {
                $scope.canQueryClients = currentUser.permissions.indexOf(permissions.canQueryClients) !== -1;
                $scope.hasClient = currentUser.client && currentUser.client.clientId !== 0;
                $scope.isSpringwise = CurrentUserFactory.isSpringwise();
                $scope.client = currentUser.client;
                $scope.canQueryVendors = currentUser.permissions.indexOf(permissions.canQueryVendors) !== -1;
                $scope.canQueryUsers = currentUser.permissions.indexOf(permissions.canQueryUsers) !== -1;
                $scope.isSWAdmin = currentUser.permissions.indexOf(permissions.SWAdmin) !== -1;
                $scope.isCanEditClient = currentUser.permissions.indexOf(permissions.canEditClients) !== -1;//isCanEditClient();
                $scope.canEditVendor = canEditVendor();
                $scope.hasVendor = currentUser.vendor && currentUser.vendor.vendorId !== 0;
                $scope.vendor = currentUser.vendor;
                $scope.viewServices = $scope.currentUser.permissions.indexOf(permissions.viewServices) !== -1;
                $scope.viewEmergencyTypes = $scope.currentUser.permissions.indexOf(permissions.viewEmergencyTypes) !== -1;                
                $scope.viewPriorityLevels = $scope.currentUser.permissions.indexOf(permissions.viewPriorityLevels) !== -1;
                $scope.viewPriorityLevels = $scope.currentUser.permissions.indexOf(permissions.viewPriorityLevels) !== -1;
                $scope.canQueryRoles = $scope.currentUser.permissions.indexOf(permissions.canQueryRoles) !== -1;
            } else {
                $scope.client = {};
                $scope.vendor = {};
            }
        }

        function isCanEditClient() {
            //Is the logged user a SW User?
            if (angular.isUndefined(currentUser.client) || (currentUser.client === null) &&
                (angular.isUndefined(currentUser.vendor) || currentUser.vendor === null)) {

                return false;
                              
            }                
            //The logged user is NOT a SWUser, NOR a Client Admin user, is a Client user.                
            else if (angular.isDefined(currentUser.client) && currentUser.client !== null &&
                angular.isDefined(currentUser.userId) && angular.isDefined($scope.client.userAdministratorId) &&
                currentUser.userId !== $scope.client.userAdministratorId) {

                //It Checks whether the logged client user is the same client user of the current row.
                if (currentUser.client.clientId == $scope.client.clientId) {


                    //Client in current row is Not Activated
                    if ($scope.client.activeFlag === false) {
                        //Client Not Active
                        if ($scope.client.springwiseManaged === false) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }

                        //Client in current row is Activated
                    else {

                        //Client in current row is Not Alive
                        if ($scope.client.alive === false) {
                            return false;
                        }
                            //Client in current row is Alive
                        else {
                            if ($scope.client.springwiseManaged === true) {
                                return false;
                            }
                            else {
                                //Client in current row is SW Granted
                                if ($scope.client.springwiseGranted === true) {
                                    return true;
                                }
                                else {
                                    return true;
                                }
                            }
                        }


                    } //END activeFlag

                }
                    //Not allowed to Edit other client users
                else {
                    return false;
                }
            }
                //The logged user is Client Admin
            else if (currentUser.client && angular.isDefined(currentUser.userId) && angular.isDefined($scope.client.userAdministratorId) &&
                currentUser.userId === $scope.client.userAdministratorId) {

                //Client in current row is Not Activated
                if ($scope.client.activeFlag === false) {
                    //Client Not Active
                    if ($scope.client.springwiseManaged === false) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                    //Client in current row is Activated
                else {

                    //Client in current row is Not Alive
                    if ($scope.client.alive === false) {
                        return false;
                    }
                        //Client in current row is Alive
                    else {
                        //Client in current row SW Granted
                        if ($scope.client.springwiseGranted === true) {
                            return true;
                        }
                        else {
                            return false;
                        }

                    }
                }

            }
                //The logged user is NOT a SW User NOR a Client user.
            else {
                return false;
            }
            
            
        }

        function canEditVendor() {
            return currentUser.vendor && currentUser.vendor.activeFlag &&
                currentUser.permissions.indexOf(permissions.canEditVendors) !== -1 ;            
        }
    }

})();
