(function () {
    angular.module('springwise').controller('VendorsFormCtrl', VendorsFormCtrl);

    VendorsFormCtrl.$inject = ['$scope', '$routeParams', '$location', 'VendorsFactory', '$window', 'UtilityService', 'CurrentUserFactory', 'permissions', 'HelperFactory'];

    function VendorsFormCtrl($scope, $routeParams, $location, VendorsFactory, $window, UtilityService, CurrentUserFactory, permissions, HelperFactory) {
        var ssnLabel = 'SSN';
        var taxIdLabel = 'Tax ID';
        var ssnExample = '333-22-4444, 123-45-6789';
        var taxIdExample = '01-1234567';
        var ssnPattern = /^\d{3}-\d{2}-\d{4}$/;
        var taxIdPattern = /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-\d{7}$/;

        var currentUser = CurrentUserFactory.get();

        $scope.swValidation = new SWValidation();

        $scope.vendor = new Vendor();
        $scope.saveVendor = saveVendor;
        $scope.saveVendorAndAutoSelect = saveVendorAndAutoSelect;
        $scope.getTaxIdLabel = getTaxIdLabel;
        $scope.getTaxIdExample = getTaxIdExample;
        $scope.getTaxIdPattern = getTaxIdPattern;
        $scope.disabled = false;
        $scope.vendorAlreadyExists = false;
        $scope.userAdminExists = false;

        activate();

        function activate() {
            $scope.canViewContracts = false;
            if ($routeParams.vendorId) {
                VendorsFactory.get({ id: $routeParams.vendorId }).$promise.then(assignVendor, returnToHomeOnRequestError);
                $scope.vendorAlreadyExists = true;
                $scope.vendorId = $routeParams.vendorId;
                $scope.viewServices = CurrentUserFactory.viewServices();
                $scope.canViewContracts = CurrentUserFactory.get().permissions.indexOf(permissions.viewContracts) !== -1;
            }

            if ($routeParams.clientId) {
                $scope.clientId = $routeParams.clientId;
            }

            if ($routeParams.workrecordId) {
                $scope.vendor.workrecordId = $routeParams.workrecordId;
            }
        }

        function returnToHomeOnRequestError(error) {
            UtilityService.onRequestError(error);
            $location.path('/');
        }

        function assignVendor(vendor) {
            $scope.vendor = vendor;
            if ($scope.vendor.userAdmin.id) {
                $scope.userAdminExists = true;
            }
            else {
                $scope.userAdminExists = false;
            }
        }

        function saveVendorAndAutoSelect(form) {
            $scope.vendor.autoSelect = true;
            return saveVendor(form);
        }

        function saveVendor(form) {
            checkTaxIdDuplicated(function (duplicated, vendor) {
                $scope.swValidation.start();
                $scope.swValidation.checkForm(form);
                $scope.swValidation.end();
                if ($scope.swValidation.hasErrors() || duplicated)
                {
                    checkTaxIdDuplicatedCallback(duplicated, vendor);
                    return false;
                }                    

                if ($routeParams.vendorId) {
                    if ($routeParams.clientId) {
                        $scope.vendor.$updateByClient({ id: $routeParams.vendorId, clientId: $routeParams.clientId }, navigateToHome, UtilityService.onRequestError);
                    } else {
                        $scope.vendor.$update({ id: $routeParams.vendorId }, navigateToHome, UtilityService.onRequestError);
                    }
                    $scope.vendorAlreadyExists = true;
                } else {
                    if ($routeParams.clientId) {
                        $scope.vendor.clientId = $routeParams.clientId;
                        VendorsFactory.saveByClient($scope.vendor).$promise.then(navigateToHome, UtilityService.onRequestError);
                    } else {
                        VendorsFactory.save($scope.vendor).$promise.then(navigateToHome, UtilityService.onRequestError);
                    }
                    $scope.vendorAlreadyExists = false;
                }
            });
        }

        function navigateToHome() {
            if ($scope.clientId) {
                $location.path('/clients/' + $scope.clientId + '/vendors');
            } else {
                if ($scope.vendor.workrecordId) {
                    if ($scope.vendor.autoSelect) {
                        $location.path('workRecordsHome/' + $scope.vendor.workrecordId + '/edit/stepFourForm');
                    } else {
                        $location.path('workRecordsHome/' + $scope.vendor.workrecordId + '/edit/stepFour');
                    }
                } else {
                    $location.path('/vendors');
                }
            }
        }

        function getTaxIdLabel() {
            if ($scope.vendor.isSsn) {
                return ssnLabel;
            } else {
                return taxIdLabel;
            }
        }

        function getTaxIdExample() {
            if ($scope.vendor.isSsn) {
                return ssnExample;
            } else {
                return taxIdExample;
            }
        }

        function getTaxIdPattern() {
            if ($scope.vendor.isSsn) {
                return ssnPattern.source;
            } else {
                return taxIdPattern.source;
            }
        }

        function checkTaxIdDuplicatedCallback(duplicated, vendor) {
            if (duplicated) {
                if (currentUser.vendor) { //If User is vendor
                    HelperFactory.openMessageModalWindow("The Vendor's Tax Id/SSN is duplicated.", "Vendor duplicated");
                }   
                else if (currentUser.client) { //If User is client
                    //Check if the vendor duplicated belongs to client user
                    if (vendor.clientId !== null && vendor.clientId === currentUser.client.clientId)
                        HelperFactory.openAcceptCancelModalWindow("The Vendor's Tax Id/SSN is duplicated. Do you want to navigate to this vendor?", "Vendor duplicated")
                            .result.then(function () {
                                $location.path("clients/" + vendor.clientId + "/vendors/" + vendor.id);
                            });
                    else
                        HelperFactory.openMessageModalWindow("The Vendor's Tax Id/SSN is duplicated.", "Vendor duplicated");
                }
                else {//If User is SW Administrator
                    //Check if the vendor duplicated belongs to client selected
                    if ($scope.clientId) {
                        if (vendor.clientId !== null && vendor.clientId === parseInt($scope.clientId))
                            HelperFactory.openAcceptCancelModalWindow("The Vendor's Tax Id/SSN is duplicated. Do you want to navigate to this vendor?", "Vendor duplicated")
                                .result.then(function () {
                                    $location.path("clients/" + vendor.clientId + "/vendors/" + vendor.id);
                                });
                        else
                            HelperFactory.openMessageModalWindow("The Vendor's Tax Id/SSN is duplicated.", "Vendor duplicated");
                    }
                    else { //From vendors list
                        if (vendor.clientId === null || vendor.clientId === 0)
                            HelperFactory.openAcceptCancelModalWindow("The Vendor's Tax Id/SSN is duplicated. Do you want to navigate to this vendor?", "Vendor duplicated")
                                .result.then(function () {
                                    $location.path("vendors/" + vendor.id);
                                });
                        else
                            HelperFactory.openMessageModalWindow("The Vendor's Tax Id/SSN is duplicated.", "Vendor duplicated");
                    }
                }
            }
        }

        function checkTaxIdDuplicated(callback) {
            if ($scope.vendorForm.taxId.$valid) {
                VendorsFactory.getVendorsByTaxId({ taxId: $scope.vendor.taxId })
                    .$promise.then(function (vendors) {
                        queryByTaxIdResponse(vendors, callback);
                    },
                    UtilityService.onRequestError);
            }
            else {
                callback(false, null);
            }
        }

        function queryByTaxIdResponse(vendors, callback) {

            var duplicated = false;
            var vendor = null;

            vendors.forEach(function (v) {
                if (v.id !== parseInt($routeParams.vendorId) && v.taxId === $scope.vendor.taxId && v.isSsn === $scope.vendor.isSsn) {
                    vendor = v;
                    duplicated = true;
                }
            });

            callback(duplicated, vendor);

        }
    }
})();