(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('NotificationsFactory', NotificationsFactory);

    NotificationsFactory.$inject = ['$resource'];

    function NotificationsFactory($resource)
    {
        return $resource('api/Notifications/:id', { id: '@notificationId' });
    }
})();