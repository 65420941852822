(function () {
    angular.module('springwise').controller('UserBasicFormCtrl', UserBasicFormCtrl);

    UserBasicFormCtrl.$inject = ['$scope', '$attrs'];

    function UserBasicFormCtrl($scope, $attrs) {
        var self = this;

        $scope.user = new BasicUser();
        //$scope.$watch('isSpringwiseManaged', onIsSpringwiseManagedChange);

        this.init = init;

        function init(element) {
            self.$element = element;
        }

        function onIsSpringwiseManagedChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                self.$element.find('name').attr('ng-required').val(newValue);
                self.$element.find('lastName').attr('ng-required').val(newValue);
                self.$element.find('email').attr('ng-required').val(newValue);
                self.$element.find('username').attr('ng-required').val(newValue);
            }
        }
    }
})();