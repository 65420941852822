(function () {
    angular.module('springwise').directive('swBreadcrumb', swBreadcrumb);

    swBreadcrumb.$inject = ['$rootScope'];

    function swBreadcrumb($rootScope) {
        return {
            restrict: 'E',
            templateUrl: 'app/components/breadcrumb/breadcrumbView.html',
            link: link
        };

        function link($scope, $element, $attrs, ctrl) {
            $rootScope.$watch('crumbs', onCrumbsChange);
            $scope.crumbs = $rootScope.crumbs;

            function onCrumbsChange(n, o) {
                if (o !== n) {
                    $scope.crumbs = n;
                }
            }
        }
    }
})();