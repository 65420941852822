(function () {
    angular.module('springwise').controller('ClientRegionsIndexCtrl', ClientRegionsIndexCtrl);

    ClientRegionsIndexCtrl.$inject = ['$scope', '$routeParams', 'CurrentUserFactory', 'RegionsFactory', 'permissions', 'UtilityService', 'ngAuthSettings', '$window', 'HelperFactory'];

    function ClientRegionsIndexCtrl($scope, $routeParams, CurrentUserFactory, RegionsFactory, permissions, UtilityService, ngAuthSettings, $window, HelperFactory) {
        $scope.clientId = $routeParams.clientId;
        $scope.regions = [];
        $scope.page = 1;
        $scope.name = '';
        $scope.orderBy = 'description';

        $scope.searchRegions = searchRegions;
        $scope.deleteRegion = deleteRegion;
        $scope.onPaginationChange = onPaginationChange;

        $scope.$watch('limit', onPageLimitChange);

        $scope.isDescending = true;
        $scope.vendorId = null;
        $scope.taxId = '';

        //User permissions
        $scope.canCreateLocations = false;
        $scope.canEditLocations = false;

        $scope.exportRegions = exportRegions;

        //Get user
        var currentUser = CurrentUserFactory.get();
        if (currentUser) {
            $scope.canCreateLocations = $scope.currentUser.permissions.indexOf(permissions.createLocations) !== -1;
            $scope.canEditLocations = $scope.currentUser.permissions.indexOf(permissions.editLocations) !== -1;
        }

        activate();

        function activate() {
            $scope.limit = { id: 10, name: '10 results' };
            $scope.limitOptions = [
                { id: 10, name: '10 results' },
                { id: 30, name: '30 results' },
                { id: 50, name: '50 results' },
                { id: 100, name: '100 results' }
            ];
            searchRegions();
        }

        function searchRegions() {
            var params = {
                page: $scope.page,
                limit: $scope.limit.id,
                name: $scope.name,
                orderBy: $scope.orderBy,
                clientId: $scope.clientId
            };

            var paramsForTotalRecords = {
                name: $scope.name,
                clientId: $scope.clientId
            };

            searchrRegionsWithParams(params, paramsForTotalRecords);

        }
        function searchrRegionsWithParams(params, paramsForTotalRecords) {

            RegionsFactory.getTotalRecords(paramsForTotalRecords).$promise.then(function (total) {
                if ($scope.total !== total.totalRecords) {
                    $scope.total = total.totalRecords;
                }
                RegionsFactory.query(params).$promise.then(function (regions) {
                    $scope.regions = regions;
                });

            }, UtilityService.onRequestError);
        }


        function deleteRegion(region) {
            var modalWindow = HelperFactory.openAcceptCancelModalWindow('The Region is going to be Deleted Permanently. Do you want to proceed?', 'Confirmation');
            modalWindow.result.then(function () {

                removeRegion(region);

            }, function () {
            });
        }

        function removeRegion(region) {       
            region.$delete(function (location) {
                var modalWindow = HelperFactory.openMessageModalWindow('The Region "' + region.name + '" has been deleted.', 'Deleted');
                searchRegions();
            }, UtilityService.onRequestError); 
        }

        function onPaginationChange(page) {
            $scope.page = page;
            $scope.searchRegions();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.page !== 1) {
                    $scope.page = 1;
                }

                $scope.searchRegions();
            }
        }

        function exportRegions() {
            var params = {
                page: 1,
                limit: 0,
                clientId: $scope.clientId,
                orderBy: $scope.orderBy,
                name: $scope.name
            };

            RegionsFactory.exportRegions(params).$promise.then(function (msg) {
                if (!msg.hasError) {
                    addAlert(msg.errorMessage, 'success');
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetExportedFile/' + fileName[0] + '/' + fileName[1];
                    $window.open(link, "", "");
                }
                else {
                    addAlert(msg.errorMessage, 'danger');
                }

            }, UtilityService.onRequestError);
        }

        $scope.alerts = [];
        function addAlert(message, type) {
            $scope.alerts.push({ message: message, type: type });
        }
    }
})();