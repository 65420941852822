(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('FillInvoiceDetailsCtrl', FillInvoiceDetailsCtrl);

    function FillInvoiceDetailsCtrl($scope, $rootScope, $routeParams, $location, CurrentUserFactory, WorkRecordService, InvoicesFactory, VendorsFactory, UtilityService, $route) {
        if (!$rootScope.newInvoice) {
            $location.path('/invoices/SelectWorkRecords');
            return;
        }
        $scope.newInvoice = $rootScope.newInvoice;
        $scope.createInvoice = createInvoice;
        $scope.user = CurrentUserFactory;

        activate();

        function activate() {
            var params = {
                clientId: $scope.newInvoice.client.clientId,
                vendorId: $scope.newInvoice.vendor.vendorId,
                workRecordIds: $scope.newInvoice.selectedIds
            };
            InvoicesFactory.api.getFillInvoiceDetails(params).$promise.then(function (response) {
                $scope.invoice = response;
            }, UtilityService.onRequestError);
        }

        function createInvoice() {
            $scope.alerts = [];
            if (!$scope.invoiceNumber) {
                addAlert('Please enter the invoice number');
            }
            if ($scope.alerts.length > 0) {
                return;
            }

            var params = {
                clientId: $scope.newInvoice.client.clientId,
                vendorId: $scope.newInvoice.vendor.vendorId,
                workRecordIds: $scope.newInvoice.selectedIds,
                invoiceNumber: $scope.invoiceNumber
            };
            InvoicesFactory.api.createInvoice(null, params).$promise.then(function (response) {
                $rootScope.newInvoice = undefined;
                $location.path('/invoices/InvoiceView/' + response.invoiceId);
            }, UtilityService.onRequestError);
        }

        $scope.alerts = [];

        function addAlert(message) {
            $scope.alerts.push({ message: message });
        }

        $scope.closeAlert = function (index) {
            $scope.alerts.splice(index, 1);
        };

        $scope.resetNewInvoice = function () {
            $rootScope.newInvoice = undefined;
            $route.reload();
        };
    }
})();