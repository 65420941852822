(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('WorkRecordViewCtrl', WorkRecordViewCtrl);

    function WorkRecordViewCtrl($scope, $routeParams, WorkRecordsFactory, CurrentUserFactory, UtilityService, WorkRecordService, $location, workRecordsStages, workRecordsStatuses) {

        $scope.currentUser = CurrentUserFactory.get();
        $scope.isVendorUser = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
        $scope.isClientUser = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
        $scope.isSWAdminUser = !$scope.isVendorUser && !$scope.isClientUser;

        function activate() {
            WorkRecordsFactory.get({ id: $routeParams.workRecordId }).$promise.then(function (wr) {
                $scope.wr = wr;
            }, UtilityService.onRequestError);
            WorkRecordsFactory.getWorkRecordSummary({ workRecordId: $routeParams.workRecordId }).$promise.then(function (workRecordSummary) {
                $scope.workRecordSummary = workRecordSummary;
            }, UtilityService.onRequestError);
            $scope.stages = workRecordsStages;
            $scope.statuses = workRecordsStatuses;
        }

        activate();
    }
})();