(function () {
    angular.module('springwise').directive('swMinError', swMinError);

    swMinError.$inject = ['BaseFieldErrorDirectiveService'];

    function swMinError(base) {
        return base({
            templateUrl: '/app/shared/directives/errors/min/minErrorView.html',
            scope: {
                min: '@min',
                validation: '='
            }
        });
    }
})();