(function () {
    angular.module('springwise').controller('QuestionnairesIndexCtrl', QuestionnairesIndexCtrl);

    QuestionnairesIndexCtrl.$inject = ['$scope', '$routeParams', 'QuestionnaireService', 'UtilityService', 'QuestionsService', 'AnswersService', 'ServicesService'];

    function QuestionnairesIndexCtrl($scope, $routeParams, QuestionnaireService, UtilityService, QuestionsService, AnswersService, ServicesService) {
        init();

        function init() {
            $scope.clientId = $routeParams.clientId;
            $scope.serviceId = $routeParams.serviceId;
            $scope.emergencyId = $routeParams.emergencyId;
            //$scope.emergencyId = $routeParams.id;
            $scope.questions = [];

            $scope.addQuestion = addQuestion;
            $scope.removeQuestion = removeQuestion;
            $scope.toggleQuestion = toggleQuestion;
            $scope.submitQuestion = submitQuestion;
            $scope.cancelQuestion = cancelQuestion;
            $scope.editQuestion = editQuestion;
            $scope.addAnswer = addAnswer;
            $scope.removeAnswer = removeAnswer;
            $scope.toggleAnswer = toggleAnswer;
            $scope.submitAnswer = submitAnswer;
            $scope.cancelAnswer = cancelAnswer;
            $scope.editAnswer = editAnswer;
            $scope.redefine = redefine;
            $scope.onAnswerIsFreeTextChange = onAnswerIsFreeTextChange;

            canEdit();
            queryQuestionnaire();

            function canEdit() {
                var req = {
                    serviceId: $scope.serviceId,
                    emergencyId: $scope.emergencyId
                };

                if ($scope.clientId) {
                    if ($scope.serviceId) {
                        QuestionsService.existsSpringwiseByService(req).$promise.then(onExistsSucceded, UtilityService.onRequestError);
                    } else {
                        QuestionsService.existsSpringwiseByEmergency(req).$promise.then(onExistsSucceded, UtilityService.onRequestError);
                    }
                } else {
                    $scope.canEdit = true;
                }

                function onExistsSucceded(res) {
                    $scope.canEdit = !res.exists;
                    if (!$scope.canEdit) {
                        req = {
                            clientId: $scope.clientId,
                            serviceId: $scope.serviceId,
                            emergencyId: $scope.emergencyId
                        };
                        if ($scope.serviceId) {
                            QuestionsService.existsByService(req).$promise.then(onClientExistsSucceded, UtilityService.onRequestError);
                        } else {
                            QuestionsService.existsByEmergency(req).$promise.then(onClientExistsSucceded, UtilityService.onRequestError);
                        }
                    }
                }
                function onClientExistsSucceded(res)
                {
                    $scope.canEdit = res.exists;
                }
            }
        }

        function addQuestion(scope) {
             if (scope) {
                var nodeScope = scope.$nodeScope;
                if (nodeScope.collapsed) {
                    $scope.toggleAnswer(scope, addNewQuestion);
                } else {
                    addNewQuestion(scope);
                }
            } else {
                $scope.questions.push({
                    editMode: true,
                    order: $scope.questions.length + 1
                });
                $scope.editMode = true;
            }

            function addNewQuestion(scope) {
                var answer = scope.$nodeScope.$modelValue;

                if (!answer.children) {
                    answer.children = [];
                }

                answer.children.push({
                    editMode: true,
                    answerId: answer.id,
                    order: answer.order + '.' + (answer.children.length + 1)
                });
                $scope.editMode = true;
            }
        }

        function removeQuestion(scope) {
            var question = scope.$nodeScope.$modelValue;
            QuestionsService.delete({ id: question.id }).$promise.then(onRemoveSucceded, UtilityService.onRequestError);

            function onRemoveSucceded() {
                if (scope.$nodeScope.$parentNodeScope) {
                    UtilityService.removeElementFromCollection(scope.$nodeScope.$parentNodeScope.$modelValue.children, question);
                } else {
                    UtilityService.removeElementFromCollection($scope.questions, question);
                }
            }
        }

        function toggleQuestion(scope, callback) {
            var nodeScope = scope.$nodeScope;
            if (nodeScope.collapsed) {
                var question = scope.$nodeScope.$modelValue;
                QuestionsService.get({ id: question.id }).$promise.then(onGetSucceded, UtilityService.onRequestError);
            } else {
                scope.toggle();
            }

            function onGetSucceded(res) {
                nodeScope.$modelValue.children = res.children;
                scope.toggle();
                if (callback) {
                    callback(scope);
                }
            }
        }

        function submitQuestion(scope) {
            var question = scope.$nodeScope.$modelValue;
            var req = {
                id: question.id,
                clientId: $scope.clientId,
                serviceId: $scope.serviceId,
                emergencyId: $scope.emergencyId,
                order: question.order,
                text: question.editedText
            };

            if (scope.$nodeScope.$parentNodeScope) {
                req.answerId = scope.$nodeScope.$parentNodeScope.$modelValue.id;
            }

            if (!question.id) {
                QuestionsService.save(req).$promise.then(onSucceded, rollbackSubmit);
            } else {
                QuestionsService.update(req).$promise.then(onSucceded, UtilityService.onRequestError);
            }

            function onSucceded(res) {
                if (!question.id) {
                    question.id = res.id;
                }
                question.editMode = false;
                question.text = question.editedText;
                $scope.editMode = false;
            }

            function rollbackSubmit(error) {
                UtilityService.onRequestError(error);
                cancelQuestion(scope);
            }
        }

        function cancelQuestion(scope) {
            var question = scope.$nodeScope.$modelValue;

            if (!question.id) {
                if (scope.$nodeScope.$parentNodeScope) {
                    UtilityService.removeElementFromCollection(scope.$nodeScope.$parentNodeScope.$modelValue.children, question);
                } else {
                    UtilityService.removeElementFromCollection($scope.questions, question);
                }
            } else {
                question.editMode = false;
            }

            $scope.editMode = false;
        }

        function editQuestion(scope) {
            var question = scope.$nodeScope.$modelValue;
            question.editedText = question.text;
            question.editMode = true;
            $scope.editMode = true;
        }

        function addAnswer(scope) {
            if (scope) {
                var nodeScope = scope.$nodeScope;
                if (nodeScope.collapsed) {
                    $scope.toggleQuestion(scope, addNewAnswer);
                } else {
                    addNewAnswer(scope);
                }

            }

            function addNewAnswer(scope) {
                var question = scope.$nodeScope.$modelValue;

                if (!question.children) {
                    question.children = [];
                }

                question.children.push({
                    editMode: true,
                    questionId: question.id,
                    order: question.order + '.' + (question.children.length + 1)
                });
                $scope.editMode = true;
            }
        }

        function removeAnswer(scope) {
            var answer = scope.$nodeScope.$modelValue;
            AnswersService.delete({ id: answer.id }).$promise.then(onRemoveSucceded, UtilityService.onRequestError);

            function onRemoveSucceded() {
                if (scope.$nodeScope.$parentNodeScope) {
                    UtilityService.removeElementFromCollection(scope.$nodeScope.$parentNodeScope.$modelValue.children, answer);
                }
            }
        }

        function toggleAnswer(scope, callback) {
            var nodeScope = scope.$nodeScope;
            if (nodeScope.collapsed) {
                var answer = scope.$nodeScope.$modelValue;
                AnswersService.get({ id: answer.id }).$promise.then(onGetSucceded, UtilityService.onRequestError);
            } else {
                scope.toggle();
            }

            function onGetSucceded(res) {
                nodeScope.$modelValue.children = res.children;
                scope.toggle();
                if (callback) {
                    callback(scope);
                }
            }
        }

        function submitAnswer(scope) {
            var answer = scope.$nodeScope.$modelValue;
            var req = {
                id: answer.id,
                clientId: $scope.clientId,
                serviceId: $scope.serviceId,
                emergencyId: $scope.emergencyId,
                order: answer.order,
                isFreeText: answer.editedIsFreeText,
                text: answer.editedText,
                questionId: answer.questionId
            };

            if (!answer.id) {
                AnswersService.save(req).$promise.then(onSucceded, rollbackSubmit);
            } else {
                AnswersService.update(req).$promise.then(onSucceded, UtilityService.onRequestError);
            }

            function onSucceded(res) {
                if (!answer.id) {
                    answer.id = res.id;
                }

                answer.editMode = false;
                answer.text = answer.editedText;
                answer.isFreeText = answer.editedIsFreeText;
                $scope.editMode = false;
            }

            function rollbackSubmit(error) {
                UtilityService.onRequestError(error);
                cancelAnswer(answer);
            }
        }

        function cancelAnswer(scope) {
            var answer = scope.$nodeScope.$modelValue;

            if (!answer.id) {
                if (scope.$nodeScope.$parentNodeScope) {
                    UtilityService.removeElementFromCollection(scope.$nodeScope.$parentNodeScope.$modelValue.children, answer);
                }
            } else {
                answer.editMode = false;
            }

            $scope.editMode = false;
        }

        function editAnswer(scope) {
            var answer = scope.$nodeScope.$modelValue;
            answer.editedText = answer.text;
            answer.editedIsFreeText = answer.isFreeText;
            answer.editMode = true;
            $scope.editMode = true;
        }

        function queryQuestionnaire() {
            var req = {
                clientId: $scope.clientId, emergencyId: $scope.emergencyId, serviceId: $scope.serviceId
            };

            if ($scope.emergencyId) {
                if ($scope.clientId) {
                    QuestionsService.getByClientEmergency(req).$promise.then(onQuerySucceded, UtilityService.onRequestError);
                } else {
                    QuestionsService.getByEmergency(req).$promise.then(onQuerySucceded, UtilityService.onRequestError);
                }
            } else if ($scope.serviceId) {
                if ($scope.clientId) {
                    QuestionsService.getByClientService(req).$promise.then(onQuerySucceded, UtilityService.onRequestError);
                } else {
                    QuestionsService.getByService(req).$promise.then(onQuerySucceded, UtilityService.onRequestError);
                }
            }

            function onQuerySucceded(questions) {
                $scope.questions = questions;
            }
        }

        function redefine() {
            var req = {
                clientId: $scope.clientId,
                serviceId: $scope.serviceId,
                emergencyId: $scope.emergencyId
            };

            QuestionsService.redefine(req).$promise.then(onRedefineSuccess, UtilityService.onRequestError);

            function onRedefineSuccess(questions) {
                $scope.questions = questions;
                $scope.canEdit =true;
            }
        }

        function onAnswerIsFreeTextChange(scope) {
            var answer = scope.$nodeScope.$modelValue;

            if (answer.editedIsFreeText) {
                answer.editedText = null;
            }
        }
    }
})();