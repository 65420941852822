(function () {
    angular.module('springwise').factory('VendorsFactory', VendorsFactory);

    VendorsFactory.$inject = ['$resource'];

    function VendorsFactory($resource) {
        return $resource(
            'api/Vendors/:id',
            { id: '@id' },
            {
                getTotalRecords: {
                    method: 'GET',
                    url: 'api/Vendors/Total',
                    isArray: false
                },
                queryOptions: {
                    method: 'GET',
                    url: 'api/Vendors/options/:clientId',
                    params: {                        
                        clientId: '@clientId'
                    },
                    isArray: true
                },
                update: {
                    method: 'PUT',
                    isArray: false
                },
                updateByClient: {
                    method: 'PUT',
                    url: 'api/clients/:clientId/vendors/:id',
                    params: {
                        id: '@id',
                        clientId: '@clientId'
                    },
                    isArray: false
                },
                saveByClient: {
                    method: 'POST',
                    url: 'api/clients/:clientId/vendors',
                    params: {
                        clientId: '@clientId'
                    },
                    isArray: false
                },
                deactivate: {
                    url: 'api/Vendors/:id/deactivate',
                    method: 'PUT',
                    isArray: false
                },
                activate: {
                    url: 'api/Vendors/:id/activate',
                    method: 'PUT',
                    isArray: false
                },
                getVendorForClientView: {
                    method: 'GET',
                    url: 'api/Vendors/getVendorInfo/:id',
                    params: {
                        id: '@id'                      
                    },
                    isArray: false
                },
                getVendorsByTaxId: {
                    method: 'GET',
//                    url: 'api/Vendors/getVendorsByTaxId/:taxId',
                    params: {
                        taxId: '@taxId'
                    },
                    isArray: true
                },
                getAllVendors: {
                    method: 'GET',
                    url: 'api/Vendors/getAllVendors',
                    isArray: true
                },
                getActiveVendors: {
                    method: 'GET',
                    url: 'api/Vendors/getActiveVendors',
                    isArray: true
                },
                exportVendors: {
                    method: 'GET',
                    url: 'api/Vendors/exportVendors',
                    isArray: false
                },
                exportVendorServices: {
                    method: 'GET',
                    url: 'api/VendorServices/exportVendorServices',
                    isArray: false
                },
                sendVendorRequest: {
                    method: 'GET',
                    url: 'api/mobile/sendVendorRequest',
                    params: {
                        companyName: '@companyName',
                        emailAddress: '@emailAddress',
                        services: '@services',
                    },
                    isArray: false
                }
            });
    }
})();