(function () {
    angular.module('springwise').factory('QuestionsService', QuestionsService);

    QuestionsService.$inject = ['$resource'];

    function QuestionsService($resource) {
        return $resource(
            'api/questions/:id', {
                id: '@id'
            }, {
                getByClientService: {
                    url: 'api/clients/:clientId/services/:serviceId/questions',
                    params: {
                        clientId: '@clientId',
                        serviceId: '@serviceId'
                    },
                    isArray: true
                },
                getByService: {
                    url: 'api/services/:serviceId/questions',
                    params: {
                        serviceId: '@serviceId'
                    },
                    isArray: true
                },
                getByClientEmergency: {
                    url: 'api/clients/:clientId/emergencies/:emergencyId/questions',
                    params: {
                        clientId: '@clientId',
                        emergencyId: '@emergencyId'
                    },
                    isArray: true
                },
                getByEmergency: {
                    url: 'api/emergencies/:emergencyId/questions',
                    params: {
                        emergencyId: '@emergencyId'
                    },
                    isArray: true
                },
                existsByEmergency: {
                    url: 'api/clients/:clientId/emergencies/:emergencyId/questions/exist',
                    params: {
                        clientId: '@clientId',
                        emergencyId: '@emergencyId'
                    }
                },
                existsByService: {
                    url: 'api/clients/:clientId/services/:serviceId/questions/exist',
                    params: {
                        clientId: '@clientId',
                        serviceId: '@serviceId'
                    }
                },
                existsSpringwiseByEmergency: {
                    url: 'api/emergencies/:emergencyId/questions/exist',
                    params: {
                        emergencyId: '@emergencyId'
                    }
                },
                existsSpringwiseByService: {
                    url: 'api/services/:serviceId/questions/exist',
                    params: {
                        serviceId: '@serviceId'
                    }
                },
                redefine: {
                    url: 'api/questions/redefine',
                    method: 'POST',
                    params: {
                        clientId: '@clientId',
                        emergencyId: '@emergencyId',
                        serviceId: '@serviceId'
                    },
                    isArray: true
                },
                update: {
                    method: 'PUT'
                },
                getQuestionnaireQuestions: {
                    url: 'api/questions/GetQuestionnaireQuestions/:baseTriageQuestionnaireId',
                    isArray: true
                }
            });
    }
})();