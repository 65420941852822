(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('workRecordsFormStepSixPhotosCtrl', workRecordsFormStepSixPhotosCtrl);

    workRecordsFormStepSixPhotosCtrl.$inject = ['$scope',
        'CurrentUserFactory',
        'ClientsFactory',
        'permissions',
        'WorkRecordsFactory',
        '$location',
        'UtilityService',
        'workRecordsStages',
        'workRecordsStatuses',
        '$routeParams',
        'ngAuthSettings',
        '$http',
        '$route',
        'HelperFactory'];

    function workRecordsFormStepSixPhotosCtrl($scope,
        CurrentUserFactory,
        ClientsFactory,
        permissions,
        WorkRecordsFactory,
        $location,
        UtilityService,
        workRecordsStages,
        workRecordsStatuses,
        $routeParams,
        ngAuthSettings,
        $http,
        $route,
        HelperFactory) {


        $scope.isVendorUser = false;
        $scope.isSWAdminUser = false;
        $scope.isClientUser = false;

        // GET THE FILE INFORMATION.
        $scope.clientId = "";
        $scope.dataURItoBlob = dataURItoBlob;
        $scope.description = "";
        $scope.workRecordId = 0;
        $scope.count = 1;
        $scope.photoFilesCollection = [];
        $scope.clean = clean;
        $scope.descriptionLabel = "Photo description";

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();         

            $scope.isVendorUser = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClientUser = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSWAdminUser = !$scope.isVendorUser && !$scope.isClientUser;

        }


        activate();

        function activate() {
            if ($routeParams.workRecordId) {
                WorkRecordsFactory.get({ id: $routeParams.workRecordId }).$promise.then(function (wr) {
                    $scope.wr = wr;
                    $scope.wr.id = $routeParams.workRecordId;
                    $scope.workRecordId = $scope.wr.id;
                    $scope.clientId = $scope.wr.clientId;               
                    $scope.photoFile = new PhotoFileObj();                    
                    $scope.versionIdForAllObj = 0;
                    $scope.photoFilesCollection.push($scope.photoFile);

                    // GET THE FILE INFORMATION.
                    WorkRecordsFactory.getWRPhotosData({ id: $scope.clientId }).$promise.then(function (photoData) {
                        $scope.clientPhotoInfo = photoData;
                        if (photoData.photoVersion) {
                            $scope.photoFile.versionId = photoData.photoVersion.documentVersionId;
                            $scope.versionIdForAllObj = photoData.photoVersion.documentVersionId;

                        }
                        else {
                            $scope.photoFile.versionId = null;
                            $scope.versionIdForAllObj = null;
                        }

                    });


                }, UtilityService.onRequestError);

                getWorkRecordImages();
                
                //We are not currently using this now.
                WorkRecordsFactory.getWorkRecordSummary({ workRecordId: $routeParams.workRecordId }).$promise.then(function (workRecordSummary) {
                    $scope.workRecordSummary = workRecordSummary;
                }, UtilityService.onRequestError);                

            }
        }      
        
        function getWorkRecordImages() {
            WorkRecordsFactory.getWRImages({ id: $routeParams.workRecordId }).$promise.then(function (response) {
                $scope.workRecordImages = response;
            }, UtilityService.onRequestError);
        }

        $scope.goBack = function () {
            window.history.back();
        };

        function clean() {
            $route.reload();     
        }

        // NOW UPLOAD THE FILES.
        $scope.uploadFiles = function () {

            if ($scope.description === null || $scope.description === "") {              
                var modalWindowAddDescription = HelperFactory.openMessageModalWindow('Please add a description.');
            }
            else if ($scope.photoFile.file === null)
                {          
                var modalWindowAddFile = HelperFactory.openMessageModalWindow('Please add a file.');
            }
            else {

            //FILL FormData WITH FILE DETAILS.
            var data = new FormData();
            
            ////
            data.append("workRecordId", $scope.workRecordId);
            data.append("clientId", $scope.clientId);           
            data.append("description", $scope.description);
            data.append("userId", $scope.currentUser.userId);
            var blob;
            if ($scope.photoFile.file) {
                blob = dataURItoBlob($scope.photoFile.file);
                data.append("photoFile", blob);
            }

            /////////

            // ADD LISTENERS.
            var objXhr = new XMLHttpRequest();
            objXhr.addEventListener("progress", updateProgress, false);
            objXhr.addEventListener("load", transferComplete, false);

            // SEND FILE DETAILS TO THE API.
            objXhr.open("POST", ngAuthSettings.apiServiceBaseUri + "api/DocumentRepository/UploadWRPhotoInformation/");
            objXhr.send(data);
            $scope.photoFile = new PhotoFileObj();

            }


        };

        // UPDATE PROGRESS BAR.
        function updateProgress(e) {
            if (e.lengthComputable) {
            }
        }

        // CONFIRMATION.
        function transferComplete(e) {
            UtilityService.openMessageModalWindow("Information successfully saved.", "Work Record - Step 06 - Vendor Work Reporting - Photos");
            clean();
        }



        function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], { type: mimeString });
        }

        function goBack() {
   
            if(isClientUser || isSWAdminUser)
            {
                $location.path('/workRecordsHome/' + $scope.wr.id + '/edit/stepSix');
            }
            else if(isVendorUser)
            {
                $location.path('/workRecordsHome/' + $scope.wr.id + '/vendorWorkReporting/uploadSignOffSheet');
            }

        }
    }
})();