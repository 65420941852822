(function () {
    'use strict';
    angular.module('springwise').service('UtilityService', UtilityService);

    function UtilityService(HelperFactory) {

        function showError(message) {
            HelperFactory.openMessageModalWindow(message, 'Error');
        }

        function showSWResult(message, title, type) {
            HelperFactory.openMessageModalWindow(message, title, type);
        }

        this.openMessageModalWindow = function (message, title, type) {
            HelperFactory.openMessageModalWindow(message, title, type);
        };

        this.removeElementFromCollection = function (collection, element) {
            var i = collection.indexOf(element);
            collection.splice(i, 1);
        };
        this.onRequestError = function (error) {
            if (error.status === 403) {
                showError('You are not allowed to perform this operation.\nPlease, contact the system administrator.');
            } else if (error.status === 400) {
                if (error.data.modelState) {
                    angular.forEach(error.data.modelState, function (v) {
                        angular.forEach(v, showError);
                    });
                }
            } else if (error.data) {
                if (error.data.swResult) {
                    showSWResult(error.data.swResult.message, error.data.swResult.title || 'Message', error.data.swResult.type);
                } else {
                    var msg = '';
                    if (error.data.message) {
                        msg = error.data.message;
                    }
                    if (error.data.exceptionMessage) {
                        if (msg !== '') {
                            msg = msg + '\n';
                        }
                        msg = msg + error.data.exceptionMessage;
                    }
                    showError(msg);
                }
            } else {
                showError('There was an error on the operation.\.Please, contact the system administrator.');
            }
        };
        this.limitOptions = [
                { id: 10, name: '10 results' },
                { id: 30, name: '30 results' },
                { id: 50, name: '50 results' },
                { id: 100, name: '100 results' }
        ];
        this.defaultLimitOption = this.limitOptions[0];
    }
})();