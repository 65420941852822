(function () {
    angular.module('springwise').factory('CategoryTypesService', CategoryTypesService);

    CategoryTypesService.$inject = ['categoryTypes'];

    function CategoryTypesService(categoryTypes) {
        var res = {
            category: categoryTypes.category,
            subCategory: categoryTypes.subCategory,
            service: categoryTypes.service,
            categoryTypes: categoryTypes,
            getParent: getParent,
            getChild: getChild
        };

        return res;

        function getParent(categoryType) {
            categoryType = isNaN(categoryType) ? categoryType : getCategoryType(categoryType);
            if (categoryType.parent === categoryTypes.category.id) {
                return categoryTypes.category;
            } else if (categoryType.parent === categoryTypes.subCategory.id) {
                return categoryTypes.subCategory;
            } else {
                return null;
            }
        }

        function getChild(categoryType) {
            categoryType = isNaN(categoryType) ? categoryType : getCategoryType(categoryType);
            if (categoryType.child === categoryTypes.subCategory.id) {
                return categoryTypes.subCategory;
            } else if (categoryType.child === categoryTypes.service.id) {
                return categoryTypes.service;
            } else {
                return null;
            }
        }

        function getCategoryType(id) {
            if (id === categoryTypes.category.id) {
                return categoryTypes.category;
            } else if (id === categoryTypes.subCategory.id) {
                return categoryTypes.subCategory;
            } else if (id === categoryTypes.service.id) {
                return categoryTypes.service;
            } else {
                return null;
            }
        }
    }
})();