var Vendor = function () {
    this.name = '';
    this.taxId = '';
    this.isSsn = false;
    this.address = new Address();
    this.client = new ClientOption();
    this.contact = new Contact();
    this.remitToAddress = new Address();
    this.userAdmin = new BasicUser();
    this.workrecordId = 0;
    this.autoSelect = false;
};