(function () {
    angular.module('springwise').controller('CreateRecurringServiceCtrl', CreateRecurringServiceCtrl);

    function CreateRecurringServiceCtrl($scope, $routeParams, $location, RecurringServicesFactory, UtilityService, ClientsFactory) {

        $scope.clientId = $routeParams.clientId;

        $scope.createRecurringService = function () {
            RecurringServicesFactory.api.createRecurringService(null, { clientId: $scope.clientId, serviceId: $scope.serviceId }).$promise.then(function (response) {
                $location.path('/RecurringServices/' + response.recurringServiceId);
            }, UtilityService.onRequestError);
        };
    }
})();