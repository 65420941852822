(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('clientPriorityLevelsCtrl', clientPriorityLevelsCtrl);

    clientPriorityLevelsCtrl.$inject = ['$scope', '$http', '$routeParams', '$location', 'ngAuthSettings', 'ClientsFactory', 'ClientPriorityLevelsFactory', 'UtilityService', 'clientPriorityLevelDescriptions', 'CurrentUserFactory', 'permissions', 'HelperFactory'];

    function clientPriorityLevelsCtrl($scope, $http, $routeParams, $location, ngAuthSettings, ClientsFactory, ClientPriorityLevelsFactory, UtilityService, clientPriorityLevelDescriptions, CurrentUserFactory, permissions, HelperFactory) {

        $scope.clientId = $routeParams.clientId;     
        $scope.descriptions = [clientPriorityLevelDescriptions.high, clientPriorityLevelDescriptions.medium, clientPriorityLevelDescriptions.low];
        $scope.priorityLevels = [];
        $scope.clients = [];        
        $scope.client = new Client();
        $scope.editMode = false;
        $scope.submitPriorityLevel = submitPriorityLevel;
        $scope.editPriorityLevel = editPriorityLevel;
        $scope.cancelPriorityLevel = cancelPriorityLevel;
        $scope.removePriorityLevel = removePriorityLevel;

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();

            if ($scope.currentUser.client) {
                $scope.currentUserClientId = $scope.currentUser.client.clientId;
            }
            else
            {
                $scope.currentUserClientId = null;
            }

            $scope.viewPriorityLevels = $scope.currentUser.permissions.indexOf(permissions.viewPriorityLevels) !== -1;
            $scope.createPriorityLevels = $scope.currentUser.permissions.indexOf(permissions.createPriorityLevels) !== -1;
            $scope.editPriorityLevels = $scope.currentUser.permissions.indexOf(permissions.editPriorityLevels) !== -1;
            $scope.canEditClients = $scope.currentUser.permissions.indexOf(permissions.canEditClients) !== -1;
        }
   
        activate();
        
        function activate() {

            ClientsFactory.queryOptions().$promise.then(function (clients) {
                $scope.clients = [new ClientOption()].concat(clients);
            }, UtilityService.onRequestError);

            getClient();
            queryPriorityLevelsFactory();
        }

        //ADD
        $scope.addPriorityLevel = function () {

            if ($scope.editMode) {                
                var modalWindow = HelperFactory.openMessageModalWindow('Make sure to save or cancel any rows in the table before adding another Priority Level.', 'Item in edition');
                    return;
            }

            var pl = new PriorityLevel();

            //pl.editedDescription = clientPriorityLevelDescriptions.medium;        
            //pl.client.clientId = $scope.clientId;
            //pl.client.name = $scope.client.name; //
            //pl.client = $scope.client; //
            //pl.description = clientPriorityLevelDescriptions.medium.value;
            pl.editMode = true;

            if($scope.clientId)
            {   
                pl.clientId = $scope.clientId;
            }
            else {
                pl.clientId = 0;
            }

             $scope.priorityLevels.push(pl);
             $scope.editMode = true;

        };


        //SAVE
        
        function submitPriorityLevel(priorityLevel) {
            var clientId = 0;
            if (priorityLevel.clientId && priorityLevel.clientId !== 0) {
                clientId = priorityLevel.clientId;
            }

            var req = {
                priorityLevelId: priorityLevel.priorityLevelId,
                //description: priorityLevel.editedDescription.value,
                description: priorityLevel.editedDescription,
                //clientId: priorityLevel.client.clientId,
                //client: priorityLevel.client,
                clientId: clientId,    
                priorityTime: priorityLevel.editedPriorityTime,               
                nextActionTime: priorityLevel.editedNextActionTime  
                
            };

            if (priorityLevel.isNew) {
                ClientPriorityLevelsFactory.save(req).$promise.then(onAddSucceded, rollbackSubmit);
            } else {
                ClientPriorityLevelsFactory.update(req).$promise.then(onAddSucceded, UtilityService.onRequestError);
            }



            function onAddSucceded() {
                priorityLevel.editMode = false;
                priorityLevel.isNew = false;
                //priorityLevel.description = priorityLevel.editedDescription.value;
                priorityLevel.description = priorityLevel.editedDescription;
                priorityLevel.priorityTime.hour = priorityLevel.editedPriorityTime.hour;
                priorityLevel.priorityTime.minute = priorityLevel.editedPriorityTime.minute;
                priorityLevel.nextActionTime.hour = priorityLevel.editedNextActionTime.hour;
                priorityLevel.nextActionTime.minute = priorityLevel.editedNextActionTime.minute;

                queryPriorityLevelsFactory();

                $scope.editMode = false;
            }

            function rollbackSubmit(error) {
                UtilityService.onRequestError(error);
                cancelPriorityLevel(priorityLevel);
            }
        }

        //EDIT

        function editPriorityLevel(priorityLevel) {
            if ($scope.editMode) {                
                var modalWindow = HelperFactory.openMessageModalWindow('Make sure to save or cancel any rows in the table before editing another Priority Level.', 'Item in edition');
                return;
            }

            //$scope.descriptions.forEach(selectCurrentDescription);

            //function selectCurrentDescription(value, index, ar) {
            //    if (priorityLevel.description === value.value) {
            //        priorityLevel.editedDescription = value;
            //    }
            //}

            priorityLevel.editedDescription = priorityLevel.description;

            priorityLevel.editedPriorityTime = priorityLevel.priorityTime;
            priorityLevel.editedNextActionTime = priorityLevel.nextActionTime;

            priorityLevel.editMode = true;
            $scope.editMode = true;
        }

        function cancelPriorityLevel(pl) {
            if (pl.isNew) {
                UtilityService.removeElementFromCollection($scope.priorityLevels, pl);
            } else {
                pl.editMode = false;
            }
            queryPriorityLevelsFactory();
            $scope.editMode = false;
        }

        //DELETE

        function removePriorityLevel(id) {           
            var req = { clientId: id };            
            ClientPriorityLevelsFactory.delete(req).$promise.then(queryPriorityLevelsFactory, UtilityService.onRequestError);
        }

        function queryPriorityLevelsFactory() {
            var id = 0;
            if ($routeParams.clientId) {
                id = $routeParams.clientId;
            } else if ($scope.clientId) {
                id = $scope.clientId;
            }
            ClientPriorityLevelsFactory.getAllClientPriorityLevels({ clientId: id }).$promise.then(function (priorityLevels) {
                $scope.priorityLevels = priorityLevels;              
            }, UtilityService.onRequestError);
        }

        function getClient() {
            var clientId = $scope.clientId || $scope.currentUserClientId;
            if (clientId) {
                ClientsFactory.get({ id: clientId }).$promise.then(function (client) {
                    $scope.client = client;
                }, UtilityService.onRequestError);
            }
        }

        $scope.setAsDefaultPriorityLevel = function (priorityLevelId) {
            var clientId = $scope.clientId || $scope.currentUserClientId;
            ClientsFactory.setDefaultPriorityLevel({ clientId: clientId, priorityLevelId: priorityLevelId }, '').$promise.then(function (response) {
                getClient();
            }, UtilityService.onRequestError);
        };

        //REDIRECT AND BACK

            function redirectToClient() {
            $location.path('/clients/' +$scope.clientId);
        }

        $scope.goBack = function () {
            window.history.back();
        };

    }

})();