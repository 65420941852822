(function () {
    angular.module('springwise').controller('emergencyTypesFormCtrl', emergencyTypesFormCtrl);

    emergencyTypesFormCtrl.$inject = ['$scope', '$location', '$routeParams', 'CurrentUserFactory', 'permissions', 'UtilityService', 'EmergencyTypesFactory', 'ClientPriorityLevelsFactory'];

    function emergencyTypesFormCtrl($scope, $location, $routeParams, CurrentUserFactory, permissions, UtilityService, EmergencyTypesFactory, ClientPriorityLevelsFactory) {
        var currentUser = CurrentUserFactory.get();
        var createErrorMsg = 'You are not allowed to create emergency types.\nPlease, contact the system administrator.';
        var editErrorMsg = 'You are not allowed to edit emergency types.\nPlease, contact the system administrator.';

        $scope.clientId = null;
        $scope.emergencyTypeId = null;
        $scope.swValidation = new SWValidation();
        $scope.viewEmergencyTypes = false;
        $scope.createEmergencyTypes = false;
        $scope.editEmergencyTypes = false;
        
        if ($routeParams.clientId)
        {
            $scope.clientId = $routeParams.clientId;
        }
        else if ($scope.currentUser.client)
        {            
            $scope.clientId = $scope.currentUser.client.clientId;
            $scope.currentUserClientId = $scope.currentUser.client.clientId;
        }
        else
        {
            $scope.clientId = 0;
            $scope.currentUserClientId = null;
        }

        if ($routeParams.id) {
            $scope.emergencyTypeId = $routeParams.id;
        }     

        if (CurrentUserFactory.exists() && $scope.currentUser) {
            $scope.viewEmergencyTypes = $scope.currentUser.permissions.indexOf(permissions.viewEmergencyTypes) !== -1;
            $scope.createEmergencyTypes = $scope.currentUser.permissions.indexOf(permissions.createEmergencyTypes) !== -1;
            $scope.editEmergencyTypes = $scope.currentUser.permissions.indexOf(permissions.editEmergencyTypes) !== -1;
        }      

        $scope.emergencyType = new EmergencyType(); 
        $scope.saveEmergencyType = saveEmergencyType;
        $scope.priorityLevels = [];

        activate();

        function activate() {

            ClientPriorityLevelsFactory.getAllClientPriorityLevels({ clientId: $scope.clientId }).$promise.then(function (priorityLevels) {
                $scope.priorityLevels = priorityLevels;
            });

            if ($routeParams.id) {
                $scope.emergencyTypeId = $routeParams.id;

                EmergencyTypesFactory.get({ id: $scope.emergencyTypeId }).$promise.then(function (emergency) {
                    $scope.emergencyType = emergency;         

                }, returnToHomeOnRequestError);
            }

        }


        // SAVE

        function saveEmergencyType(form) {

            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            $scope.swValidation.end();
            var error;

            if ($scope.swValidation.hasErrors())
                return;

            if ($scope.emergencyTypeId) {
                if ($scope.editEmergencyTypes) {
                    if ($scope.clientId)
                        $scope.emergencyType.$updateByClient({ id: $scope.emergencyTypeId, clientId: $routeParams.clientId }, redirectToPrevious, returnToHomeOnRequestError);
                    else
                        $scope.emergencyType.$update({ id: $scope.emergencyTypeId }, redirectToPrevious, returnToHomeOnRequestError);
                }
                else {
                    error = new Error(editErrorMsg);
                    error.status = 403;
                    onRequestError(error);
                }
            }
            else {
                if ($scope.createEmergencyTypes) {
                    if ($scope.clientId) {
                        $scope.emergencyType.clientId = $scope.clientId;
                        EmergencyTypesFactory.saveByClient($scope.emergencyType).$promise.then(redirectToPrevious, returnToHomeOnRequestError);
                    }
                    else {
                        EmergencyTypesFactory.save($scope.emergencyType).$promise.then(redirectToPrevious, returnToHomeOnRequestError);
                    }
                }
                else {
                    error = new Error(createErrorMsg);
                    error.status = 403;
                    onRequestError(error);
                }
            }
        }

        // REDIRECT AND ERROR HANDLING  

        function redirectToPrevious() {
            if ($scope.clientId && !$scope.currentUserClientId) {
                $location.path('/clients/' + $scope.clientId + '/emergencyTypes');
            }
            else if($scope.currentUserClientId)
            {
                 $location.path('/emergencyTypes/');
            }
            else {
                $location.path('/emergencyTypes/');
            }
        }

        function onRequestError(error) {
            UtilityService.onRequestError(error);
        }

        function returnToHomeOnRequestError(error) {
            UtilityService.onRequestError(error);

            if ($scope.clientId && !$scope.currentUserClientId) {
                $location.path('/clients/' + $scope.clientId + '/emergencyTypes');
            }
            else if($scope.currentUserClientId)
            {
                 $location.path('/emergencyTypes/');
            }
            else {
                $location.path('/emergencyTypes/');
            }
        }

    }
})();