(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('PermissionsFactory', PermissionsFactory);

    PermissionsFactory.$inject = ['$resource'];

    function PermissionsFactory($resource, domainUrlHelper) {
        return $resource(
            'api/Permissions/:id',
            { id: '@permissionsId' },
            {
                getAllPermissions: {
                    method: 'GET',
                    url: 'api/Permissions',
                    isArray: true
                },
                getAllPermissionCategoriesByParentRole: {
                    method: 'GET',
                    url: 'api/Permissions/getAllPermissionCategoriesByParentRole/:roleId/Parent/:parentId',
                    isArray: true,                   
                    params: {
                        roleId: '@roleId',
                        parentId: '@parentId'
                    }
                },
                getAllPermissionCategoriesByRole: {
                    method: 'GET',
                    url: 'api/Permissions/getAllPermissionCategoriesByRole/:roleId',
                    isArray: true,
                    params: {
                        roleId: '@parentRoleId'
                    }
                },               

            });
    }
})();