(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('WorkRecordsApprovalsListCtrl', WorkRecordsApprovalsListCtrl);

    function WorkRecordsApprovalsListCtrl($scope, $routeParams, WorkRecordsFactory, CurrentUserFactory, UtilityService, exceededNTEApprovalTypes) {
        $scope.types = exceededNTEApprovalTypes;
        getApprovals();

        function getApprovals() {
            WorkRecordsFactory.getPendingApprovals().$promise.then(function (response) {
                $scope.exceededNTEApprovals = response.exceededNTEApprovals;
            }, UtilityService.onRequestError);
        }
    }
})();