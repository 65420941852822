(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('SelectWorkRecordsCtrl', SelectWorkRecordsCtrl);

    function SelectWorkRecordsCtrl($scope, $rootScope, $location, $routeParams, CurrentUserFactory, WorkRecordService, InvoicesFactory, VendorsFactory, ClientsFactory, UtilityService, $route) {
        if (!$rootScope.newInvoice || $rootScope.newInvoice.userId !== CurrentUserFactory.get().userId) {
            $rootScope.newInvoice = {
                userId: CurrentUserFactory.get().userId,
                vendor: { vendorId: null, name: 'Select' },
                client: { clientId: null, name: 'Select' },
                selectedIds: []
            };
        }
        $scope.vendors = [{ vendorId: null, name: 'Select'}];
        $scope.clients = [{ clientId: null, name: 'Select' }];
        $scope.wrs = [];
        $scope.redirectToWR = redirectToWR;
        $scope.selectWR = selectWR;
        $scope.unSelectWR = unSelectWR;
        $scope.isSelected = isSelected;
        $scope.gotoFillInvoiceDetails = gotoFillInvoiceDetails;
        $scope.user = CurrentUserFactory;
        $scope.onPaginationChange = onPaginationChange;
        $scope.currentPage = 1;
        $scope.limitOptions = [
            { id: 10, name: '10 results' },
            { id: 20, name: '20 results' },
            { id: 30, name: '30 results' },
            { id: 50, name: '50 results' },
        ];
        $scope.pageLimit = $scope.limitOptions[0];

        $scope.$watch('newInvoice.client', onClientChange);
        $scope.$watch('newInvoice.vendor', onVendorChange);
        $scope.$watch('pageLimit', onPageLimitChange);

        function onPaginationChange(page) {
            $scope.currentPage = page;
            getList();
        }

        function onPageLimitChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                activate();
            }
        }

        function onVendorChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.newInvoice.selectedIds = [];
                activate();
            }
        }

        function onClientChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.newInvoice.selectedIds = [];
                activate();
            }
        }

        if (CurrentUserFactory.isVendor()) {
            $scope.newInvoice.vendor = CurrentUserFactory.get().vendor;
        }

        activate();

        VendorsFactory.queryOptions({ clientId: 0 }).$promise.then(function (vendors) {
            $scope.vendors = $scope.vendors.concat(vendors);
        }, UtilityService.onRequestError);

        ClientsFactory.queryOptions().$promise.then(function (clients) {
            $scope.clients = $scope.clients.concat(clients);
        }, UtilityService.onRequestError);

        function activate() {
            $scope.wrs = [];
            getList();
        }

        function getList() {
            var params = {
                limit: $scope.pageLimit.id,
                page: $scope.currentPage,
                orderBy: 'WorkRecordId',
                isDescending: true,
                vendorId: $scope.newInvoice.vendor.vendorId,
                clientId: $scope.newInvoice.client.clientId
            };

            InvoicesFactory.api.getTotalWorkRecordsPendingInvoicing(params).$promise.then(function (obj) {
                if ($scope.totalRecords !== obj.totalRecords) {
                    $scope.totalRecords = obj.totalRecords;
                }

                InvoicesFactory.api.getWorkRecordsPendingInvoicing(params).$promise.then(function (response) {
                    $scope.wrs = response.list;
                }, UtilityService.onRequestError);
            });
        }

        function redirectToWR(wr) {
            return '#' + WorkRecordService.getPathToWorkRecordPage(wr);
        }

        function selectWR(wr) {
            $scope.newInvoice.selectedIds.push(wr.workRecordId);
        }

        function unSelectWR(wr) {
            $scope.newInvoice.selectedIds.splice(getSelectedIndex(wr), 1);
        }

        function isSelected(wr) {
            return getSelectedIndex(wr) >= 0;
        }

        function getSelectedIndex(wr) {
            var index;
            for (index = 0; index < $scope.newInvoice.selectedIds.length; index++) {
                if (wr.workRecordId === $scope.newInvoice.selectedIds[index])
                    return index;
            }
            return -1;
        }

        function gotoFillInvoiceDetails() {
            $location.path('/invoices/FillInvoiceDetails');
        }

        $scope.resetNewInvoice = function () {
            $rootScope.newInvoice = undefined;
            $route.reload();
        };
    }
})();