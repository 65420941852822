(function () {
    angular.module('springwise').factory('ClientWRConfigurationNotificationsService', ClientWRConfigurationNotificationsService);

    ClientWRConfigurationNotificationsService.$inject = ['$resource'];

    function ClientWRConfigurationNotificationsService($resource) {
        return $resource(
            'api/clients/:clientId/wrconfiguration/emergencyNotifications/:userId', {
                clientId: '@clientId',
                userId: '@userId'                
            }, {
                getTotalRecords: {
                    url: 'api/clients/:clientId/wrconfiguration/emergencyNotifications/total',
                    method: 'GET',
                    isArray: false
                },
                update: {
                    method: 'PUT',
                    isArray: false
                }
            });
    }
})();