(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('clientDashboardCtrl', clientDashboardCtrl);

    clientDashboardCtrl.$inject = ['$scope',
        'AuthFactory',
        'CurrentUserFactory',
        'ClientsFactory',
        'VendorsFactory', 
        'LocationsFactory', 
        'ServicesService', 
        'StagesFactory',
        'permissions',
        'ClientDashboardFactory',
        'UtilityService'];

    function clientDashboardCtrl(
        $scope,
        AuthFactory,
        CurrentUserFactory,
        ClientsFactory,
        VendorsFactory,
        LocationsFactory,
        ServicesService,
        StagesFactory,
        permissions,
        ClientDashboardFactory, 
        UtilityService) {

            $scope.clientId = null;
            $scope.locationId = null;
            $scope.vendorId = null;
            $scope.categoryId = null;
            $scope.subCategoryId = null;
            $scope.contractStatusId = null;
            $scope.serviceId = null;
            $scope.stageId = null;
            $scope.categories = [];
            $scope.subCategories = [];
            $scope.services = [];
            $scope.locations = [];
            $scope.stages = [];

        $scope.wrs = [];
        $scope.params = {};
        $scope.isSWAdmin = false;
        $scope.canEditWorkRecords = false;
        $scope.canCreateWorkRecords = false;

        $scope.loadDashboard = activate;

        $scope.$watch('categoryId', onCategoryIdChange);
        $scope.$watch('subCategoryId', onSubCategoryIdChange);

        $scope.getClientWRs = getClientWRs;

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
            $scope.currentUserId = $scope.currentUser.userId;
            $scope.isVendor = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClient = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSpringwise = !$scope.isVendor && !$scope.isClient;
        }
        if ($scope.currentUser !== null) {
            $scope.isSWAdmin = $scope.currentUser.permissions.indexOf(permissions.SWAdmin) !== -1;
            $scope.canCreateWorkRecords = $scope.currentUser.permissions.indexOf(permissions.createWorkRecords) !== -1;
            $scope.canEditWorkRecords = $scope.currentUser.permissions.indexOf(permissions.editWorkRecords) !== -1;
            $scope.clientId = ($scope.currentUser.client) ? $scope.currentUser.client.clientId : null;
            $scope.locationId = $scope.currentUser.locationId;
            $scope.clients = ($scope.currentUser.client) ? [{ clientId: $scope.clientId, name: $scope.currentUser.client.name }] : [];
        }

        // Get Client Vendor List
        var params = {
            page: 1,
            limit: 0,
            orderBy: 'name',
            name: '',
            vendorId: null,
            clientId: $scope.clientId,
            queryVendors: true,
            active: true
        };

        VendorsFactory.query(params).$promise.then(function (vendors) {
            $scope.vendors = [{ id: null, name: '' }].concat(vendors);
        }, UtilityService.onRequestError);

        function onCategoryIdChange(newVal, oldVal) {
            if (newVal && $scope.clientId) {
                ServicesService.getByClient({ id: $scope.categoryId, clientId: $scope.clientId }).$promise.then(function (category) {
                    $scope.subCategories = [{ id: null, description: '' }].concat(category.children);
                }, UtilityService.onRequestError);
            }
            else {
                $scope.subCategories = [];
            }
        }

        function onSubCategoryIdChange(newVal) {
            if (newVal) {
                if ($scope.locationId === null)
                    $scope.locationId = 0;
                ServicesService.getServicesByLocation({ subCategoryId: newVal, locationId: 0 }).$promise.then(function (services) {
                    $scope.services = [{ id: null, description: '' }].concat(services);
                }, UtilityService.onRequestError);
            }
            else {
                $scope.services = [];
            }
        }

        //Get Locations list
        LocationsFactory.getLocationsByClient({ clientId: $scope.clientId }).$promise.then(function (locations) {
            $scope.locations = [{ id: null, name: '' }].concat(locations);
        });

        //Gets category list
        ServicesService.query().$promise.then(function (categories) {
            $scope.categories = [{ id: null, description: '' }].concat(categories);
        });

        //Gets stage list
        StagesFactory.getStages().$promise.then(function (stages) {
            $scope.stages = [{ stageId: null, name: '' }].concat(stages);
        });

        $scope.closeAlert = function (index) {
            //Bug# 1730
            var alertId = $scope.alerts[index].alertId;
            ClientDashboardFactory.markAlertAsRead({ id: alertId }).$promise.then(onAlertMarkAsRead, UtilityService.onRequestError);

            $scope.alerts.splice(index, 1);

            function onAlertMarkAsRead() {

            }
        };

        activate();

        function activate() {

            getClientWRs();

        }

        function getClientWRs() {
            var theClientId = null;
            var theLocationId = null;
            var theRegionId = null;
            var theDistrictId = null;
            if ($scope.currentUser.client)
                theClientId = $scope.currentUser.client.clientId;
            if ($scope.locationId)
                theLocationId = $scope.locationId;
            if ($scope.currentUser.districtId) {
                theDistrictId = $scope.currentUser.districtId;
                if ($scope.currentUser.regionId)
                    theRegionId = $scope.currentUser.regionId;
            }
            
            $scope.params = {
                clientId: theClientId,
                locationId: theLocationId,
                districtId: theDistrictId,
                regionId: theRegionId,
                userId: $scope.currentUserId,
                dashboardViewerType: 1

            };

            ClientDashboardFactory.clientDashboardQuery($scope.params).$promise.then(function (data) {
                $scope.emergencyWrs = data.emergencyWorkRecords;
                $scope.overdueWorkRecords = data.overdueWorkRecords;
                $scope.deadlineApproachingWorkRecords = data.deadlineApproachingWorkRecords;
                $scope.newWorkRecords = data.newWorkRecords;
                $scope.alerts = data.alerts;
            });
        }
    }

})();
