(function () {
    angular.module('springwise').controller('VendorsServicesIndexCtrl', VendorsServicesIndexCtrl);

    VendorsServicesIndexCtrl.$inject = ['$scope', '$routeParams', 'UtilityService', 'CategoryTypesService', 'VendorsServicesService', 'ServicesService', 'VendorsFactory', 'ngAuthSettings', 'ImportsFactory', 'CurrentUserFactory', 'HelperFactory'];

    function VendorsServicesIndexCtrl($scope, $routeParams, UtilityService, CategoryTypesService, VendorsServicesService, ServicesService, VendorsFactory, ngAuthSettings, ImportsFactory, CurrentUserFactory, HelperFactory) {
        init();

        function init() {

            $scope.maxNumberValue = Number.MAX_VALUE;
            $scope.orderBy = 'serviceId';
            $scope.isDescending = false;
            $scope.page = 1;
            $scope.limit = UtilityService.defaultLimitOption;
            $scope.limitOptions = UtilityService.limitOptions;
            $scope.vendorId = $routeParams.vendorId;
            $scope.clientId = $routeParams.clientId;
            $scope.services = [];
            $scope.vendorServices = [];
            $scope.categoryTypes = CategoryTypesService.categoryTypes;
            $scope.currentUser = CurrentUserFactory.get();
            $scope.isVendor = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClient = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSpringwise = !$scope.isVendor && !$scope.isClient;
            $scope.addService = addService;
            $scope.removeService = removeService;
            $scope.removeServiceById = removeServiceById;
            $scope.toggleService = toggleService;
            $scope.submitService = submitService;
            $scope.cancelService = cancelService;
            $scope.editService = editService;
            $scope.onPaginationChange = onPaginationChange;
            $scope.queryServicesByVendor = queryServicesByVendor;

            $scope.exportVendorServices = exportVendorServices;
            $scope.getImportTemplate = getImportTemplate;

            $scope.$watch('limit', onPageLimitChange);

            queryServicesByVendor();
            queryServices();
        }

        function exportVendorServices() {
            var params = {
                vendorId: $scope.vendorId
            };

            VendorsFactory.exportVendorServices(params).$promise.then(function (msg) {
                if (!msg.hasError) {
                    addAlert(msg.errorMessage, 'success');
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetExportedFile/' + fileName[0] + '/' + fileName[1];
                    var download_iframe = document.getElementById('download_iframe');
                    download_iframe.src = link;
                }
                else {
                    addAlert(msg.errorMessage, 'danger');
                }

            }, UtilityService.onRequestError);
        }

        function getImportTemplate() {
            var params = {
                templateType: 6,
                vendorId: $scope.vendorId
            };

            ImportsFactory.api.getImportTemplate(params).$promise.then(function (msg) {
                if (!msg.hasError) {
                    addAlert(msg.errorMessage, 'success');
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetFile/' + fileName[0] + '/' + fileName[1];
                    var download_iframe = document.getElementById('download_iframe');
                    download_iframe.src = link;
                }
                else {
                    addAlert(msg.errorMessage, 'danger');
                }

            }, UtilityService.onRequestError);
        }


        function onPaginationChange(page) {
            $scope.page = page;
            $scope.queryServicesByVendor();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.page !== 1) {
                    $scope.page = 1;
                }
                $scope.queryServicesByVendor();
            }
        }

        function queryServicesByVendor() {
            var params = { vendorId: $scope.vendorId, page: $scope.page, limit: $scope.limit.id, orderBy: $scope.orderBy, isDescending: $scope.isDescending, clientId: $scope.clientId };
            if ($scope.clientId) {
                VendorsServicesService.getTotalRecordsByClient(params).$promise.then(onQueryTotalSucceded, UtilityService.onRequestError);
            } else {
                VendorsServicesService.getTotalRecords(params).$promise.then(onQueryTotalSucceded, UtilityService.onRequestError);
            }

            function onQueryServicesByLocationSucceded(records) {
                $scope.vendorServices = records;
                updateServiceNode(0, cancelEditMode, true);
                $scope.editMode = false;
                function cancelEditMode(service) {
                    service.editMode = false;
                }
            }

            function onQueryTotalSucceded(total) {
                if ($scope.total !== total.totalRecords) {
                    if ($scope.page !== 1) {
                        $scope.page = 1;
                        params.page = $scope.page;
                    }
                    $scope.total = total.totalRecords;
                }
                if ($scope.clientId) {
                    VendorsServicesService.queryByClient(params).$promise.then(onQueryServicesByLocationSucceded, UtilityService.onRequestError);
                } else {
                    VendorsServicesService.query(params).$promise.then(onQueryServicesByLocationSucceded, UtilityService.onRequestError);
                }
            }
        }

        function queryServices() {
            ServicesService.query({ vendorId: $scope.vendorId }).$promise.then(onQuerySucceded, UtilityService.onRequestError);

            function onQuerySucceded(records) {
                $scope.services = records;
            }
        }

        function addService(scope) {
            if ($scope.editMode) {
                //alert('Make sure to save or cancel any rows in the table before selecting another service.');
                var modalWindow = HelperFactory.openMessageModalWindow('Make sure to save or cancel any rows in the table before selecting another Service.', 'Item in edition');
                return;
            }

            var service = scope.$nodeScope.$modelValue;
            var vendorService = new VendorService();
            vendorService.vendorId = $scope.vendorId;
            vendorService.serviceId = service.id;
            vendorService.name = service.description;
            vendorService.category = scope.$parentNodeScope.$parentNodeScope.$modelValue.description;
            vendorService.subCategory = scope.$parentNodeScope.$modelValue.description;
            $scope.vendorServices.push(vendorService);
            updateServiceNode(vendorService.serviceId, setToEditMode);
            $scope.editMode = true;

            function setToEditMode(service) {
                service.editMode = true;
            }
        }

        function editService(service) {
            if ($scope.editMode) {
                //alert('Make sure to save or cancel any rows in the table before selecting another service.');
                var modalWindow = HelperFactory.openMessageModalWindow('Make sure to save or cancel any rows in the table before selecting another Service.', 'Item in edition');
                return;
            }

            service.editedPercentage = service.percentage;
            service.editedTechRateHr = service.techRateHr;
            service.editedHelperRateHr = service.helperRateHr;
            service.editMode = true;
            $scope.editMode = true;
        }

        function submitService(vendorService) {
            var req = {
                vendorId: vendorService.vendorId,
                serviceId: vendorService.serviceId,
                cover: true,
                percentage: vendorService.editedPercentage,
                techRateHr: vendorService.editedTechRateHr,
                helperRateHr: vendorService.editedHelperRateHr
            };

            if (vendorService.isNew) {
                VendorsServicesService.save(req).$promise.then(onAddSucceded, rollbackSubmit);
            } else {
                VendorsServicesService.update(req).$promise.then(onAddSucceded, UtilityService.onRequestError);
            }

            function onAddSucceded() {
                if (vendorService.isNew) {
                    updateServiceNode(vendorService.serviceId, setAdded);
                }

                vendorService.editMode = false;
                vendorService.isNew = false;
                vendorService.percentage = vendorService.editedPercentage;
                vendorService.techRateHr = vendorService.editedTechRateHr;
                vendorService.helperRateHr = vendorService.editedHelperRateHr;
                $scope.editMode = false;

                queryServicesByVendor();

                function setAdded(service) {
                    service.added = true;
                    service.editMode = false;
                }
            }

            function rollbackSubmit(error) {
                UtilityService.onRequestError(error);
                cancelService(vendorService);
            }
        }

        function cancelService(service) {
            if (service.isNew) {
                updateServiceNode(service.serviceId, cancelEditMode);
                //UtilityService.removeElementFromCollection($scope.vendorServices, service);
            } else {
                service.editMode = false;
            }

            $scope.editMode = false;

            function cancelEditMode(s) {
                s.editMode = false;
                UtilityService.removeElementFromCollection($scope.vendorServices, s);
            }
        }

        function removeService(scope) {
            var service = scope.$nodeScope.$modelValue;
            var req = { vendorId: $scope.vendorId, serviceId: service.id };
            VendorsServicesService.delete(req).$promise.then(onRemoveSucceded, UtilityService.onRequestError);

            function onRemoveSucceded() {
                service.added = false;
                queryServicesByVendor();
            }
        }

        function removeServiceById(id) {
            var req = { vendorId: $scope.vendorId, serviceId: id };
            VendorsServicesService.delete(req).$promise.then(onRemoveSucceded, UtilityService.onRequestError);

            function onRemoveSucceded() {
                updateServiceNode(id, updateTreeNode);
                queryServicesByVendor();
            }

            function updateTreeNode(service) {
                service.added = false;
            }
        }

        function toggleService(scope, callback) {
            var nodeScope = scope.$nodeScope;
            if (nodeScope.collapsed) {
                var service = scope.$nodeScope.$modelValue;
                var req = { vendorId: $scope.vendorId, serviceId: service.id, clientId: $scope.clientId };
                if ($scope.clientId) {
                    ServicesService.getByClientVendor(req).$promise.then(onGetSucceded, UtilityService.onRequestError);
                } else {
                    ServicesService.getByVendor(req).$promise.then(onGetSucceded, UtilityService.onRequestError);
                }
            } else {
                scope.toggle();
            }

            function onGetSucceded(res) {
                nodeScope.$modelValue.children = res.children;
                scope.toggle();
                if (callback) {
                    callback(scope);
                }
            }
        }

        function updateServiceNode(id, callback, all) {
            for (var i = 0; i < $scope.services.length; i++) {
                var category = $scope.services[i];
                if (category.children) {
                    for (var j = 0; j < category.children.length; j++) {
                        var subCategory = category.children[j];
                        if (subCategory.children) {
                            for (var k = 0; k < subCategory.children.length; k++) {
                                var service = subCategory.children[k];
                                if (all) {
                                    callback(service);
                                } else if (service.id === id) {
                                    callback(service);
                                    return;
                                }
                            }
                        }
                    }
                }
            }
        }

        $scope.alerts = [];
        function addAlert(message, type) {
            $scope.alerts.push({ message: message, type: type });
        }

    }
})();