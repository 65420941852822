(function () {
    'use strict';
    var app = angular.module('springwise', [
        // Angular modules 
        'ngRoute',
        'ngResource',
        'LocalStorageModule',
        'ui.tree',
        'ui.bootstrap',
        'ui.bootstrap.datepicker',
        'textAngular'
        //'gm.datepickerMultiSelect'
        // Custom modules
        // 3rd Party Modules
    ]);
})();