(function () {
    'use strict';

    angular.module('springwise').factory('HelperFactory', HelperFactory);

    function HelperFactory($uibModal, $rootScope) {
        return {
            openAcceptCancelModalWindow: function (message, title, onlyOK, type) {
                var modalWindow = $uibModal.open({
                    templateUrl: '/app/shared/components/modals/AcceptCancelModalWindow.html',
                    controller: 'AcceptCancelModalWindowCtrl',
                    resolve: {
                        data: function () {
                            return {
                                message: message,
                                title: title,
                                onlyOK: onlyOK,
                                type: type
                            };
                        }
                    }
                });
                return modalWindow;
            },
            openMessageModalWindow: function (message, title, type) {
                this.openAcceptCancelModalWindow(message, title, true, type);
            },
            dateToServer: function (date) {
                return date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString() + '-' + date.getDate().toString();
            },
            dateFromSever: function (str) {
                var date = new Date(str);
                return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
            }
        };
    }
})();