(function () {
    angular.module('springwise').factory('WorkRecordsFactory', WorkRecordsFactory);

    WorkRecordsFactory.$inject = ['$resource'];

    function WorkRecordsFactory($resource) {
        return $resource(
            'api/WorkRecords/:id',
            { id: '@id' },
            {
                getTotalRecords: {
                    method: 'GET',
                    url: 'api/WorkRecords/Total'
                },
                advanceStep: {
                    method: 'PUT',
                    url: 'api/WorkRecords/:id/Steps/:step/Advance',
                },
                advanceVendorStep: {
                    method: 'PUT',
                    url: 'api/WorkRecords/:id/Steps/:step/AdvanceVendorWorkRecords',
                },
                getServicesDirective: {
                    method: 'GET',
                    url: 'api/WorkRecords/Services',
                    isArray: true
                },
                getWRImages: {
                    method: 'GET',
                    url: 'api/DocumentRepository/WorkRecordImages/:id',
                    isArray: true
                },
                update: {
                    method: 'PUT',
                    url: 'api/WorkRecords/:id/Steps/:step',
                },
                checkClientReferenceNumberUniqueness: {
                    method: 'POST',
                    url: 'api/WorkRecords/CheckClientReferenceNumberUniqueness',
                },
                sendEmergencyNotificationEmails: {
                    method: 'POST',
                    url: 'api/WorkRecords/SendEmergencyNotificationEmails',
                },
                requestNTEApproval: {
                    method: 'POST',
                    url: 'api/WorkRecords/RequestNTEApproval/:workRecordId',
                },   
                getWorkRecordSummary: {
                    method: 'GET',
                    url: 'api/WorkRecords/GetWorkRecordSummary/:workRecordId',
                },
                respondExceededNTEApproval: {
                    method: 'PUT',
                    url: 'api/WorkRecords/RespondExceededNTEApproval',
                },
                getExceededNTEApproval: {
                    method: 'GET',
                    url: 'api/WorkRecords/GetExceededNTEApproval/:exceededNTEApprovalId',
                },
                getPendingApprovals: {
                    method: 'GET',
                    url: 'api/WorkRecords/GetPendingApprovals'
                },
                setVendorIsOnSite: {
                    method: 'POST',
                    url: 'api/WorkRecords/SetVendorIsOnSite/:workRecordId',
                },
                getWorkRecordNoteList: {
                    method: 'GET',
                    url: 'api/WorkRecords/GetWorkRecordNoteList/:workRecordId',
                    isArray: true
                },
                saveWorkRecordNote: {
                    method: 'PUT',
                    url: 'api/WorkRecords/SaveWorkRecordNote'
                },              
                getWRPhotosData: {
                    method: 'GET',                    
                    url: 'api/workRecords/getWRPhotosData/:id'
                },
                getWorkRecordExpenseList: {
                    method: 'GET',
                    url: 'api/WorkRecords/GetWorkRecordExpenseList/:workRecordId',
                    isArray: true
                },
                sendWRJobDoneNotification: {
                    method: 'POST',
                    url: 'api/WorkRecords/SendWRJobDoneNotification'
                },
                saveWorkRecordExpense: {
                    method: 'PUT',
                    url: 'api/WorkRecords/SaveWorkRecordExpense'
                },
                updateWorkRecordExpense: {
                    method: 'PUT',
                    url: 'api/WorkRecords/UpdateWorkRecordExpense'
                },
                deleteWorkRecordExpense: {
                    method: 'DELETE',
                    url: 'api/WorkRecords/DeleteWorkRecordExpense/:workRecordExpenseId'
                },
                generateWRForRecurringSchedule: {
                    method: 'POST',
                    url: 'api/WorkRecords/GenerateWRForRecurringSchedule'
                },
                cancelWorkRecord: {
                    method: 'POST',
                    url: 'api/WorkRecords/CancelWorkRecord/:workRecordId'
                },
                reAssignVendorToWorkRecord: {
                    method: 'POST',
                    url: 'api/WorkRecords/ReAssignVendorToWorkRecord/:workRecordId'
                },
                mobileWR: {
                    method: 'GET',
                    url: 'api/WorkRecords/GetDashboardWork'
                }
            });
    }
})();