(function () {
    angular.module('springwise').controller('RecurringServiceFormCtrl', RecurringServiceFormCtrl);

    function RecurringServiceFormCtrl($scope, $routeParams, RecurringServicesFactory, UtilityService, ClientsFactory, CountriesFactory, StatesFactory, CurrentUserFactory, permissions, HelperFactory, $location) {

        $scope.createEditRecurringServices = CurrentUserFactory.get().permissions.indexOf(permissions.createEditRecurringServices) !== -1;
        $scope.showAvailableLocations = true;
        $scope.months = [
            { name: 'January', id: 1 },
            { name: 'February', id: 2 },
            { name: 'March', id: 3 },
            { name: 'April', id: 4 },
            { name: 'May', id: 5 },
            { name: 'June', id: 6 },
            { name: 'July', id: 7 },
            { name: 'August', id: 8 },
            { name: 'September', id: 9 },
            { name: 'October', id: 10 },
            { name: 'November', id: 11 },
            { name: 'December', id: 12 }
        ];

        $scope.limitOptions = [
            { id: 10, name: '10 results' },
            { id: 20, name: '20 results' },
            { id: 30, name: '30 results' },
            { id: 40, name: '40 results' }
        ];
        $scope.limit = $scope.limitOptions[0];
        $scope.onPaginationChange = onPaginationChange;
        $scope.$watch('limit', onPageLimitChange);
        function onPaginationChange(page) {
            $scope.page = page;
            activate();
        }
        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                $scope.page = 1;
                $scope.params.limit = $scope.limit.id;
                activate();
            }
        }

        $scope.params = {
            recurringServiceId: $routeParams.recurringServiceId,
            countryId: null,
            stateId: null,
            zipCode: null,
            page: 1,
            limit: 10
        };

        $scope.$watch('response', function (newValue, oldValue) {
            if (newValue && oldValue && newValue != oldValue) {
                for (var indexNewValue = 0; indexNewValue < newValue.recurringServiceSchedules.length; indexNewValue++) {
                    for (var indexOldValue = 0; indexOldValue < oldValue.recurringServiceSchedules.length; indexOldValue++) {
                        if (oldValue.recurringServiceSchedules[indexOldValue].recurringServiceScheduleId === newValue.recurringServiceSchedules[indexNewValue].recurringServiceScheduleId) {
                            newValue.recurringServiceSchedules[indexNewValue].selected = oldValue.recurringServiceSchedules[indexOldValue].selected;
                        }
                    }
                }
            }
        });

        $scope.$watch(function () {
            if ($scope.response) {
                var selected = 0;
                for (var index = 0; index < $scope.response.recurringServiceSchedules.length; index++) {
                    if ($scope.response.recurringServiceSchedules[index].selected) {
                        selected++;
                    }
                }
                $scope.recurringServiceSchedulesAllSelected = selected > 0 && selected === $scope.response.recurringServiceSchedules.length;
                $scope.recurringServiceSchedulesMultipleSelected = selected >= 2;
            }
        });

        $scope.recurringServiceSchedulesCheckBoxChange = function () {
            var allSelected = false;
            if ($scope.response.recurringServiceSchedules.length > 0) {
                allSelected = true;
                for (var index = 0; index < $scope.response.recurringServiceSchedules.length; index++) {
                    if (!$scope.response.recurringServiceSchedules[index].selected) {
                        allSelected = false;
                        break;
                    }
                }
                for (index = 0; index < $scope.response.recurringServiceSchedules.length; index++) {
                    $scope.response.recurringServiceSchedules[index].selected = !allSelected;
                }
            }
        };

        $scope.createMultipleSchedules = function () {
            $location.path('/RecurringSchedule/0/' + $routeParams.recurringServiceId + '/0');
            var ids = [];
            for (var index = 0; index < $scope.response.recurringServiceSchedules.length; index++) {
                if ($scope.response.recurringServiceSchedules[index].selected) {
                    ids.push($scope.response.recurringServiceSchedules[index].recurringServiceScheduleId);
                }
            }
            $location.search('recurringServiceScheduleIds', ids);
        };

        function setResponse(response) {
            if (!$scope.response && !response.thereAreAvailableLocations) {
                $scope.showAvailableLocations = false;
            }
            else {
                $scope.showAvailableLocations = true;
            }
            $scope.response = response;
        }

        function activate() {
            RecurringServicesFactory.api.getRecurringService($scope.params).$promise.then(function (response) {
                setResponse(response);
            }, UtilityService.onRequestError);
        }

        function callAddLocations(locationIds) {
            var params = angular.copy($scope.params);
            params.locationsIdList = locationIds;
            RecurringServicesFactory.api.addLocationsToRecurringService(null, params).$promise.then(function (response) {
                setResponse(response);
            }, UtilityService.onRequestError);
        }

        $scope.removeRecurringServiceSchedule = function (recurringServiceScheduleId) {
            var params = angular.copy($scope.params);
            params.recurringServiceScheduleId = recurringServiceScheduleId;
            RecurringServicesFactory.api.removeRecurringServiceSchedule(null, params).$promise.then(function (response) {
                setResponse(response);
            }, UtilityService.onRequestError);
        };

        $scope.removeRecurringSchedule = function (recurringScheduleId) {
            removeRecurringSchedule(recurringScheduleId);
        };

        function removeRecurringSchedule (recurringScheduleId, confirmation) {
            var params = angular.copy($scope.params);
            params.recurringScheduleId = recurringScheduleId;
            params.confirmation = confirmation;
            RecurringServicesFactory.api.removeRecurringSchedule(null, params).$promise.then(function (response) {
                if (response.hasNotesAndAttachmentsError) {
                    var modalWindow = HelperFactory.openAcceptCancelModalWindow('The schedule has notes or attachments that would be lost. Do you want to proceed?', 'Confirmation');
                    modalWindow.result.then(function () {
                        removeRecurringSchedule(recurringScheduleId, true);
                    }, function () {
                    });
                }
                else {
                    setResponse(response.recurringService);
                }
            }, UtilityService.onRequestError);
        }

        $scope.addLocations = function() {
            var locationIds = [];
            for (var index = 0; index < $scope.response.filteredAvailableLocations.length; index++) {
                if ($scope.response.filteredAvailableLocations[index].selected)
                    locationIds.push($scope.response.filteredAvailableLocations[index].locationId);
            }
            callAddLocations(locationIds);
        };

        $scope.addLocation = function (locationId) {
            callAddLocations([locationId]);
        };

        $scope.searchClick = function () {
            activate();
        };

        CountriesFactory.query().$promise.then(function (countries) {
            $scope.countries = [{ countryId: null, name: '' }].concat(countries);
        }, UtilityService.onRequestError);
        StatesFactory.query().$promise.then(function (states) {
            $scope.states = [{ stateId: null, name:''}].concat(states);
        }, UtilityService.onRequestError);

        activate();
    }
})();