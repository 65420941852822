(function () {
    angular.module('springwise').directive('swBasicDistrictForm', swBasicDistrictForm);

    swBasicDistrictForm.$inject = [];

    function swBasicDistrictForm() {
        return {
            restrict: 'E',
            templateUrl: 'app/shared/components/districts/basicDistrictFormView.html',
            scope: {
                district: '=district',
                validation: '=',
                index: '='
            }
        };
    }
})();