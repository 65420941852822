(function () {
    angular.module('springwise').controller('EditContractServicesCtrl', EditContractServicesCtrl);

    function EditContractServicesCtrl($scope, $routeParams, CurrentUserFactory, ClientsFactory, UtilityService, ContractsFactory, ServicesService) {
        $scope.contractId = $routeParams.contractId;
        $scope.categoryId = null;
        $scope.subCategoryId = null;
        $scope.categories = [];
        $scope.subCategories = [];
        $scope.$watch('categoryId', onCategoryIdChange);
        $scope.limitOptions = [
                { id: 10, name: '10 results' },
                { id: 20, name: '20 results' },
                { id: 30, name: '30 results' },
                { id: 40, name: '40 results' }
        ];

        $scope.limit = $scope.limitOptions[0];
        $scope.page = 1;
        $scope.onPaginationChange = function (page) {
            $scope.page = page;
            search();
        };
        $scope.$watch('limit', onPageLimitChange);
        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                $scope.page = 1;
                search();
            }
        }

        $scope.limitContract = $scope.limitOptions[0];
        $scope.pageContract = 1;
        $scope.onPaginationChangeContract = function (page) {
            $scope.pageContract = page;
            search();
        };
        $scope.$watch('limitContract', onPageLimitChangeContract);
        function onPageLimitChangeContract(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                $scope.pageContract = 1;
                search();
            }
        }

        function onCategoryIdChange(newVal, oldVal) {
            if (newVal && $scope.response) {
                ServicesService.getByClient({ id: $scope.categoryId, clientId: $scope.response.clientId }).$promise.then(function (category) {
                    $scope.subCategories = [{ id: null, description: '' }].concat(category.children);
                }, UtilityService.onRequestError);
            }
            else
                $scope.subCategories = [];
        }

        function getFilterParams() {
            return {
                contractId: $routeParams.contractId,
                page: $scope.page,
                limit: $scope.limit.id,
                categoryId: $scope.categoryId,
                subCategoryId: $scope.subCategoryId,
                pageContractServices: $scope.pageContract,
                limitContractServices: $scope.limitContract.id
            };
        }

        $scope.searchClick = function () {
            $scope.page = 1;
            search();
        };

        function search() {
            ContractsFactory.api.getServicesForEdition(getFilterParams()).$promise.then(function (response) {
                $scope.response = response;
            }, UtilityService.onRequestError);
        }

        $scope.addService = function (serviceId) {
            var params = getFilterParams();
            params.serviceId = serviceId;
            ContractsFactory.api.addService(null, params).$promise.then(function (response) {
                $scope.response = response;
            }, UtilityService.onRequestError);
        };

        $scope.removeService = function (serviceId) {
            var params = getFilterParams();
            params.serviceId = serviceId;
            ContractsFactory.api.removeService(null, params).$promise.then(function (response) {
                $scope.response = response;
            }, UtilityService.onRequestError);
        };

        ServicesService.query().$promise.then(function (categories) {
            $scope.categories = [{ id: null, description: '' }].concat(categories);
        }, UtilityService.onRequestError);

        search();
    }
})();