(function () {
    angular.module('springwise').controller('EditContractLineCtrl', EditContractLineCtrl);

    function EditContractLineCtrl($scope, $routeParams, CurrentUserFactory, ClientsFactory, UtilityService, ContractsFactory, HelperFactory, $location) {
        $scope.contractId = $routeParams.contractId;
        function activate() {
            var params = {
                contractId: $routeParams.contractId
            };
            if ($routeParams.contractLineId) {
                params.contractLineId = $routeParams.contractLineId;
            }
            if ($routeParams.serviceId && $routeParams.locationId) {
                params.locationId = $routeParams.locationId;
                params.serviceId = $routeParams.serviceId;
            }
            ContractsFactory.api.getContractLineForEdition(params).$promise.then(function (response) {
                if ($routeParams.contractLineId) {
                    $scope.price = response.price;
                    $scope.startDate = HelperFactory.dateFromSever(response.startDate);
                    $scope.endDate = HelperFactory.dateFromSever(response.endDate);
                }
                if ($routeParams.serviceId && $routeParams.locationId) {
                }
                $scope.response = response;
            }, UtilityService.onRequestError);
        }

        $scope.save = function () {
            $scope.alerts = [];
            var price = Number($scope.price);
            if (!$scope.price || price <= 0) {
                addAlert('Please enter valid price');
            }
            if (!$scope.startDate) {
                addAlert('Please enter a start date');
            }
            if (!$scope.endDate) {
                addAlert('Please enter an end date');
            }
            if ($scope.endDate <= $scope.startDate) {
                addAlert('The end date should be after the start date');
            }
            if ($scope.alerts.length > 0) {
                return;
            }
            var params = { 
                contractId: $routeParams.contractId,
                price: price,
                startDate: HelperFactory.dateToServer($scope.startDate),
                endDate: HelperFactory.dateToServer($scope.endDate)
            };
            if ($routeParams.contractLineId) {
                params.contractLineId = $routeParams.contractLineId;
            } else {
                params.serviceId = $routeParams.serviceId;
                params.locationId = $routeParams.locationId;
            }
            ContractsFactory.api.saveContractLine(null, params).$promise.then(function (response) {
                if (response.datesRangeNotAvailable) {
                    addAlert('The dates range already has a contract');
                }
                else {
                    $location.path('/Contracts/EditContract/' + $routeParams.contractId + '/Lines');
                }
            }, UtilityService.onRequestError);
        };

        function addAlert(message) {
            $scope.alerts.push({ message: message });
        }

        activate();
    }
})();