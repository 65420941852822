(function () {
    angular.module('springwise').directive('swMaxError', swMaxError);

    swMaxError.$inject = ['BaseFieldErrorDirectiveService'];

    function swMaxError(base) {
        return base({
            templateUrl: '/app/shared/directives/errors/max/maxErrorView.html',
            scope: {
                max: '@max',
                validation: '='
            }
        });
    }
})();