(function () {
    angular.module('springwise').controller('toolbarCtrl', toolbarCtrl);

    toolbarCtrl.$inject = ['$scope', 'AuthFactory', 'CurrentUserFactory', 'ClientsFactory', 'permissions'];

    function toolbarCtrl($scope, AuthFactory, CurrentUserFactory, ClientsFactory, permissions) {
        $scope.permissions = permissions;
        $scope.$watch('currentUser', activate);
        $scope.getHome = getHome;
        activate();

        function activate() {
            $scope.isLoggedIn = AuthFactory.isLoggedIn;

            if ($scope.currentUser) {
                $scope.canQueryClients = $scope.currentUser.permissions.indexOf(permissions.canQueryClients) !== -1;
                $scope.hasClient = $scope.currentUser.client && $scope.currentUser.client.clientId !== 0;
                $scope.isSpringwise = !$scope.currentUser.client && !$scope.currentUser.vendor;
                $scope.client = $scope.currentUser.client;
                $scope.canQueryVendors = $scope.currentUser.permissions.indexOf(permissions.canQueryVendors) !== -1;
                $scope.canQueryUsers = $scope.currentUser.permissions.indexOf(permissions.canQueryUsers) !== -1;
                $scope.isSWAdmin = $scope.currentUser.permissions.indexOf(permissions.SWAdmin) !== -1;
                $scope.canQueryRoles = $scope.currentUser.permissions.indexOf(permissions.canQueryRoles) !== -1;
                $scope.isCanEditClient = isCanEditClient();
                $scope.canEditVendor = canEditVendor();
                $scope.hasVendor = $scope.currentUser.vendor && $scope.currentUser.vendor.vendorId !== 0;
                $scope.vendor = $scope.currentUser.vendor;
                $scope.viewServices = $scope.currentUser.permissions.indexOf(permissions.viewServices) !== -1;
                $scope.canViewInvoices = $scope.currentUser.permissions.indexOf(permissions.viewInvoices) !== -1;
                $scope.canViewContracts = $scope.currentUser.permissions.indexOf(permissions.viewContracts) !== -1;
                $scope.canViewReports = $scope.viewServices = $scope.currentUser.permissions.indexOf(permissions.viewReports) !== -1;
            } else {
                $scope.client = {};
                $scope.vendor = {};
            }



        }

        function isCanEditClient() {
            //Is the logged user a SW User?
            if (angular.isUndefined($scope.currentUser.client) || ($scope.currentUser.client === null) &&
                (angular.isUndefined($scope.currentUser.vendor) || $scope.currentUser.vendor === null)) {

                return false;

            }
                //The logged user is NOT a SWUser, NOR a Client Admin user, is a Client user.                
            else if (angular.isDefined($scope.currentUser.client) && $scope.currentUser.client !== null &&
                angular.isDefined($scope.currentUser.userId) && angular.isDefined($scope.client.userAdministratorId) &&
                $scope.currentUser.userId !== $scope.client.userAdministratorId) {

                //It Checks whether the logged client user is the same client user of the current row.
                if ($scope.currentUser.client.clientId == $scope.client.clientId) {


                    //Client in current row is Not Activated
                    if ($scope.client.activeFlag === false) {
                        //Client Not Active
                        if ($scope.client.springwiseManaged === false) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }

                        //Client in current row is Activated
                    else {

                        //Client in current row is Not Alive
                        if ($scope.client.alive === false) {
                            return false;
                        }
                            //Client in current row is Alive
                        else {
                            if ($scope.client.springwiseManaged === true) {
                                return false;
                            }
                            else {
                                //Client in current row is SW Granted
                                if ($scope.client.springwiseGranted === true) {
                                    return true;
                                }
                                else {
                                    return true;
                                }
                            }
                        }


                    } //END activeFlag

                }
                    //Not allowed to Edit other client users
                else {
                    return false;
                }
            }
                //The logged user is Client Admin
            else if ($scope.currentUser.client && angular.isDefined($scope.currentUser.userId) && angular.isDefined($scope.client.userAdministratorId) &&
                $scope.currentUser.userId === $scope.client.userAdministratorId) {

                //Client in current row is Not Activated
                if ($scope.client.activeFlag === false) {
                    //Client Not Active
                    if ($scope.client.springwiseManaged === false) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                    //Client in current row is Activated
                else {

                    //Client in current row is Not Alive
                    if ($scope.client.alive === false) {
                        return false;
                    }
                        //Client in current row is Alive
                    else {
                        //Client in current row SW Granted
                        if ($scope.client.springwiseGranted === true) {
                            return true;
                        }
                        else {
                            return false;
                        }

                    }
                }

            }
                //The logged user is NOT a SW User NOR a Client user.
            else {
                return false;
            }


        }

        function canEditVendor() {
            return $scope.currentUser.vendor && $scope.currentUser.vendor.activeFlag &&
                $scope.currentUser.permissions.indexOf(permissions.canEditVendors) !== -1;
        }
        function getHome() {
            return '/#' + CurrentUserFactory.getDashboardPath();
        }

    }
})();