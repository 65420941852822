(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('EmailTypesFactory', EmailTypesFactory);

    EmailTypesFactory.$inject = ['$resource'];

    function EmailTypesFactory($resource) {
        return $resource('api/EmailTypes/:id', { id: '@emailTypeId' });
    }
})();