(function () {
    angular.module('springwise').factory('LocationsFactory', LocationsFactory);

    LocationsFactory.$inject = ['$resource'];

    function LocationsFactory($resource) {
        return $resource(
            'api/Locations/:id',
            { id: '@locationId' },
            {
                queryOptions: {
                    method: 'GET',
                    url: 'api/Locations/options',
                    isArray: true
                },
                queryOptionsByDistrict: {
                    method: 'GET',
                    url: 'api/Districts/:districtId/Locations/options',
                    isArray: true
                },
                getTotalRecords: {
                    method: 'GET',
                    url: 'api/Locations/Total',
                    isArray: false
                },
                update: {
                    method: 'PUT',
                },
                activate: {
                    method: 'PUT',
                    url: 'api/Locations/:id/Activate',
                    isArray: false
                },
                deactivate: {
                    method: 'PUT',
                    url: 'api/Locations/:id/Deactivate',
                    isArray: false
                },
                getLocationsByClient: {
                    method: 'GET',
                    url: 'api/Locations/getLocationsByClient/:clientId',
                    isArray: true
                },
                exportLocations: {
                    method: 'GET',
                    url: 'api/Locations/exportLocations',
                    isArray: false
                },
                exportLocationServices: {
                    method: 'POST',
                    url: 'api/Locations/exportLocationServices/:clientId/:locationId'
                }
            });
    }
})();