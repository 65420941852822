(function () {
    angular.module('springwise').directive('swServiceProviderAnalysisReport', swServiceProviderAnalysisReport);

    swServiceProviderAnalysisReport.$inject = [];

    function swServiceProviderAnalysisReport() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/components/serviceProviderAnalysisReport/serviceProviderAnalysisReport.html',
            scope: {
                currentUser: '=currentUser'
            },
            controller: 'serviceProviderAnalysisReportCtrl'
        };
    }
})();
