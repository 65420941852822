(function () {
    angular.module('springwise').controller('ContractsIndexCtrl', ContractsIndexCtrl);

    function ContractsIndexCtrl($scope, $routeParams, $location, ContractsFactory, UtilityService, ClientsFactory, VendorsFactory, CurrentUserFactory, LocationsFactory, ServicesService, contractStatuses, permissions) {

        $scope.isVendorUser = false;
        $scope.isSWAdminUser = false;
        $scope.isClientUser = false;

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
            $scope.canViewContract = $scope.currentUser.permissions.indexOf(permissions.viewContracts) !== -1;
            $scope.canEditContract = $scope.currentUser.permissions.indexOf(permissions.editContracts) !== -1;
            $scope.canCreateContract = $scope.currentUser.permissions.indexOf(permissions.createContracts) !== -1;
            $scope.isVendorUser = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClientUser = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSWAdminUser = !$scope.isVendorUser && !$scope.isClientUser;
        }

        //Client
        if ($routeParams.clientId) {
            $scope.clientId = $routeParams.clientId;
            $scope.locationId = $routeParams.locationId;
            $scope.fromLocationView = true;
        }
        else {
            $scope.clientId = null;
            $scope.locationId = null;
            $scope.fromLocationView = false;
        }

        //Vendor
        if ($routeParams.vendorId) {
            $scope.vendorId = $routeParams.vendorId;
            $scope.fromVendorView = true;
        }
        else {
            $scope.vendorId = null;
            $scope.fromVendorView = false;
        }
        
        $scope.evalContractStatusId = evalContractStatusId;
        $scope.evalContractStatusIsApproved = evalContractStatusIsApproved;
        $scope.contractStatusNew = false;
        $scope.categoryId = null;
        $scope.subCategoryId = null;
        $scope.contractStatusId = null;
        $scope.serviceId = null;
        $scope.categories = [];
        $scope.subCategories = [];
        $scope.services = [];
        $scope.locations = [];
        $scope.$watch('categoryId', onCategoryIdChange);
        $scope.$watch('subCategoryId', onSubCategoryIdChange);
        $scope.$watch('clientId', onClientIdChange);

        $scope.page = 1;
        $scope.limitOptions = [
                { id: 10, name: '10 results' },
                { id: 20, name: '20 results' },
                { id: 30, name: '30 results' },
                { id: 40, name: '40 results' }
        ];
        $scope.limit = $scope.limitOptions[0];
        $scope.onPaginationChange = onPaginationChange;
        $scope.$watch('limit', onPageLimitChange);
        $scope.getVendorList = getVendorList;
        $scope.getCategoryList = getCategoryList;
        $scope.getSubCategoryList = getSubCategoryList;
        $scope.getServiceList = getServiceList;
        $scope.getLocationList = getLocationList;

        function evalContractStatusId (contract)
        {
            if(contract.contractStatusId == contractStatuses.new) {
                $scope.contractStatusNew = true;
                return true;
            }            
        }

        function evalContractStatusIsApproved(contract) {
            return contract.contractStatusId == contractStatuses.reviewedAndAcceptedByClientThenActiveContract;
        }

        function onCategoryIdChange(newVal, oldVal) {
            if (newVal && $scope.clientId) 
                getSubCategoryList();
            else 
                $scope.subCategories = [];
        }

        function onSubCategoryIdChange(newVal) {
            if (newVal)
                getServiceList();
            else
                $scope.services = [];
        }

        function onClientIdChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                getLocationList();
                getVendorList();
                if ($scope.categoryId && newVal)
                    getSubCategoryList();
                else
                    $scope.subCategories = [];
            }
            else
            {
                $scope.locations = [];
            }                
        }

        function search() {
            //Tests if any value is null
            if ($scope.clientId === null)
                $scope.clientId = 0;
            if ($scope.vendorId === null)
                $scope.vendorId = 0;
            if ($scope.locationId === null)
                $scope.locationId = 0;
            if ($scope.contractStatusId === null)
                $scope.contractStatusId = 0;
            if ($scope.categoryId === null)
                $scope.categoryId = 0;
            if ($scope.subCategoryId === null)
                $scope.subCategoryId = 0;
            if ($scope.serviceId === null)
                $scope.serviceId = 0;
            
            var params = {
                page: $scope.page,
                limit: $scope.limit.id,
                clientId: $scope.clientId,
                vendorId: $scope.vendorId,
                locationId: $scope.locationId,
                contractStatusId: $scope.contractStatusId,
                categoryId: $scope.categoryId,
                subCategoryId: $scope.subCategoryId,
                serviceId: $scope.serviceId,
                isVendorUser: $scope.isVendorUser
            };

            ContractsFactory.api.getContracts(params).$promise.then(function (response) {
                $scope.response = response;
            }, UtilityService.onRequestError);            
        }

        function onPaginationChange(page) {
            $scope.page = page;
            search();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                $scope.page = 1;
                search();
            }
        }

        $scope.editContractClick = function (contract) {
            $location.path('/Contracts/EditContract/' + contract.contractId);
        };

        $scope.deleteContractClick = function (contract) {
            ContractsFactory.api.deleteContract({ contractId: contract.contractId }, null).$promise.then(function () {
                $scope.page = 1;
                search();
            }, UtilityService.onRequestError);
        };

        $scope.terminateContractClick = function (contractId) {
            ContractsFactory.api.terminateContract(null, {contractId: contractId}).$promise.then(function () {
                $scope.page = 1;
                search();
            }, UtilityService.onRequestError);
        };

        $scope.viewContractClick = function (contract) {
            $location.path('/Contracts/ViewContract/' + contract.contractId);
        };

        $scope.searchClick = function () {
            $scope.page = 1;
            search();
        };

        //Get client list
        if ($scope.isClientUser || $scope.fromLocationView) {
            $scope.isClient = true;
            if (!$scope.fromLocationView) {
                $scope.clientId = CurrentUserFactory.get().client.clientId;
                $scope.clientName = CurrentUserFactory.get().client.name;

                getLocationList();
            }
            else {
                ClientsFactory.get({ id: $scope.clientId }).$promise.then(function (response) {
                    $scope.clientName = response.name;
                }, UtilityService.onRequestError);

                LocationsFactory.get({ id: $scope.locationId }).$promise.then(function (response) {
                    $scope.locationName = response.name;
                }, UtilityService.onRequestError);
            }
        }
        else if (!$scope.isVendorUser) {
            $scope.isClient = false;
            $scope.clientId = null;
            ClientsFactory.queryOptions().$promise.then(function (clients) {
                $scope.clients = [{ clientId: null, name: '' }].concat(clients);
            }, UtilityService.onRequestError);
        }

        //Get vendor list
        function getVendorList() {
            if ($scope.isVendorUser || $scope.fromVendorView) {
                $scope.isVendor = true;
                if (!$scope.fromVendorView) {
                    $scope.vendorId = CurrentUserFactory.get().vendor.vendorId;
                    $scope.vendorName = CurrentUserFactory.get().vendor.name;
                }
                else {
                    VendorsFactory.get({ id: $scope.vendorId }).$promise.then(function (response) {
                        $scope.vendorName = response.name;
                    }, UtilityService.onRequestError);
                }

                //Load clients according to selected vendor
                ClientsFactory.getClientsByVendorId({ vendorId: $scope.vendorId }).$promise.then(function (clients) {
                    $scope.clients = [{ clientId: null, name: '' }].concat(clients);
                }, UtilityService.onRequestError);
            }
            else {
                $scope.isVendor = false;
                if (!$scope.clientId) {
                    VendorsFactory.getActiveVendors().$promise.then(function (vendors) {
                        $scope.vendors = [{ id: null, name: '' }].concat(vendors);
                    }, UtilityService.onRequestError);
                }
                else {
                    var params = {
                        page: 1,
                        limit: 0,
                        orderBy: 'name',
                        name: '',
                        vendorId: null,
                        clientId: $scope.clientId,
                        queryVendors: true,
                        active: true
                    };
                    VendorsFactory.query(params).$promise.then(function (vendors) {
                        $scope.vendors = [{ id: null, name: '' }].concat(vendors);
                    }, UtilityService.onRequestError);
                }
            }
        }

        getVendorList();

        //Gets contract status list
        if ($scope.isVendorUser) {
            ContractsFactory.api.getContractStatusesForVendor().$promise.then(function (contractStatuses) {
                $scope.contractStatuses = [{ contractStatusId: null, description: '' }].concat(contractStatuses);
            }, UtilityService.onRequestError);
        }
        else {
            ContractsFactory.api.getContractStatuses().$promise.then(function (contractStatuses) {
                $scope.contractStatuses = [{ contractStatusId: null, description: '' }].concat(contractStatuses);
            }, UtilityService.onRequestError);
        }

        //Get category list
        function getCategoryList() {
            ServicesService.query().$promise.then(function (categories) {
                $scope.categories = [{ id: null, description: '' }].concat(categories);
            }, UtilityService.onRequestError);
        }

        //Get subcategory list
        function getSubCategoryList() {
            ServicesService.getByClient({ id: $scope.categoryId, clientId: $scope.clientId }).$promise.then(function (category) {
                $scope.subCategories = [{ id: null, description: '' }].concat(category.children);
            }, UtilityService.onRequestError);
        }

        //Get service list
        function getServiceList() {
            if ($scope.subCategoryId === null)
                $scope.subCategoryId = 0;

            ServicesService.getAllServicesBySubCategory({ subCategoryId: $scope.subCategoryId }).$promise.then(function (services) {
                $scope.services = [{ id: null, description: '' }].concat(services);
            }, UtilityService.onRequestError);
        }

        //Get location list
        function getLocationList() {
            LocationsFactory.getLocationsByClient({ clientId: $scope.clientId }).$promise.then(function (locations) {
                $scope.locations = [{ locationId: null, name: '' }].concat(locations);
            }, UtilityService.onRequestError);
        }

        getCategoryList();

        search();
    }
})();