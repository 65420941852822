(function () {
    angular.module('springwise').factory('ClientPriorityLevelsFactory', ClientPriorityLevelsFactory);

    ClientPriorityLevelsFactory.$inject = ['$resource'];

    function ClientPriorityLevelsFactory($resource) {
        return $resource(
            'api/clients/:clientId/ClientPriorityLevels', {
                clientId: '@clientId'
            }, {
                queryOptions: {
                    method: 'GET',
                    url: 'api/clients/ClientPriorityLevels/options',
                    isArray: true
                },
                getTotalRecords: {
                    method: 'GET',
                    url: 'api/clients/ClientPriorityLevels/Total',
                    isArray: false
                },
                getAllClientPriorityLevels: {
                    method: 'GET',
                    url: 'api/clients/:clientId/AllPriorityLevelsForClientId',
                    isArray: true
                },
                update: {
                    method: 'PUT',
                    isArray: false
                },  
            });
    }
})();