(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('PhoneTypesFactory', PhoneTypesFactory);

    PhoneTypesFactory.$inject = ['$resource'];

    function PhoneTypesFactory($resource) {
        return $resource('api/PhoneTypes/:id', { id: '@phoneTypeId' });
    }
})();