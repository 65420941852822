(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('ClientContractsFormCtrl', ClientContractsFormCtrl);

    ClientContractsFormCtrl.$inject = ['$scope', '$http', '$routeParams', '$window', 'UtilityService', 'permissions', '$route', 'RecurringServicesFactory', 'ngAuthSettings', 'CurrentUserFactory', 'ClientsFactory', 'HelperFactory'];

    function ClientContractsFormCtrl($scope, $http, $routeParams, $window, UtilityService, permissions, $route, RecurringServicesFactory, ngAuthSettings, CurrentUserFactory, ClientsFactory, HelperFactory) {

        $scope.swValidation = new SWValidation();

        $scope.page = 1;
        $scope.limit = 20;
        $scope.name = '';
        $scope.attachments = [];
        $scope.clean = clean;
        $scope.save = save;
        $scope.getFilteredAttachments = getFilteredAttachments;
        $scope.onPaginationChange = onPaginationChange;
        $scope.editAttachment = editAttachment;
        $scope.fileToUpload = new AttachmentFileObj();
        $scope.description = "";       
        $scope.clientContractAttachmentId = -1;
        $scope.saveCompleted = false;
        $scope.canViewContracts = false;
        $scope.canEditContracts = false;
        
        if (CurrentUserFactory.exists()) {
            var currentUser = CurrentUserFactory.get();
            $scope.createEditRecurringServices = currentUser.permissions.indexOf(permissions.createEditRecurringServices) !== -1;
            $scope.canViewContracts = currentUser.permissions.indexOf(permissions.viewContracts) !== -1;
            $scope.canEditContracts = currentUser.permissions.indexOf(permissions.editContracts) !== -1;
        }

        $scope.$watch('limit', onPageLimitChange);

        activate();

        function activate() {
            $scope.limit = { id: 10, name: '10 results' };
            $scope.limitOptions = [
                { id: 10, name: '10 results' },
                { id: 20, name: '20 results' },
                { id: 30, name: '30 results' },
                { id: 50, name: '50 results' },
            ];
            $scope.webAPIURL = ngAuthSettings.apiServiceBaseUri;
            getContractConfiguration();
            getFilteredAttachments();
        }

        function getContractConfiguration() {
            ClientsFactory.get({ id: $routeParams.clientId }).$promise.then(function (response) {
                $scope.canSendOutNoContract = response.canSendOutNoContract;
            }, UtilityService.onRequestError);
        }

        function editAttachment(attach) {
            if (attach.clientContractAttachmentId)                
                ClientsFactory.getContractAttachmentForEdition({ clientContractAttachmentId: attach.clientContractAttachmentId }).$promise.then(function (attachment) {
                    if (attachment.clientContractAttachmentId) {
                        $scope.fileToUpload.clientContractAttachmentId = attachment.clientContractAttachmentId;
                        $scope.description = attachment.userFileDescription;
                        $scope.clientContractAttachmentId = attachment.clientContractAttachmentId;                        
                    }
                });

        }

        function getFilteredAttachments() {            
            var theClientId = $routeParams.clientId;
            $scope.params = {                
                clientContractId: theClientId,
                //limit: $scope.pageLimit.id,
                limit: $scope.limit.id,
                //page: $scope.currentPage,
                page: $scope.page,
                orderBy: 'clientId',
                isDescending: true,
            };
                        
            ClientsFactory.getContractAttachmentsList($scope.params).$promise.then(function (attachments) {
                $scope.attachments = attachments;
            }, UtilityService.onRequestError);
        }

        $scope.uploadFile = function (form) {
            var isInvalid = $scope.description === null || $scope.description === "";
            $scope.clientContractsForm.description.$setValidity("isnodescription", !isInvalid);

            if ($scope.fileToUpload.file === null)
            {
                var modalWindow = HelperFactory.openMessageModalWindow('Please add a file.', 'Upload File');
                return;
            }

            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            $scope.swValidation.end();
            if ($scope.swValidation.hasErrors())
                return;
            
            $scope.fileToUpload.send('api/clients/' + $routeParams.clientId + '/UploadContractAttachment/' + $scope.description, onTransferCompleted, UtilityService.onRequestError);
        };

        function onTransferCompleted() {
            $scope.transferCompleted = true;
            clean();
        }

        $scope.goBack = function () {
            $window.history.back();
        };

        function clean() {
            $route.reload();
        }

        function onPaginationChange(page) {
            $scope.page = page;
            //$scope.searchRegions();
            getFilteredAttachments();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.page !== 1) {
                    $scope.page = 1;
                }
                //$scope.searchRegions();
                getFilteredAttachments();
            }
        }

        function save() {
            $scope.params = {                
                clientId: $routeParams.clientId,
                canSendOutNoContract: $scope.canSendOutNoContract
            };
                        
            ClientsFactory.updateClientContractConfiguration($scope.params).$promise.then(function () {
                $scope.saveCompleted = true;
            }, UtilityService.onRequestError);
        }
    }
})();