(function () {
    angular.module('springwise').controller('notificationTypesFormCtrl', notificationTypesFormCtrl);

    function notificationTypesFormCtrl($scope, $location, $routeParams, CurrentUserFactory, permissions, UtilityService, NotificationTypesFactory, ClientPriorityLevelsFactory) {
        $scope.clientId = $routeParams.clientId;
        $scope.notificationTypeId = $routeParams.notificationTypeId;
        $scope.currentUser = CurrentUserFactory.get();
        $scope.notificationType = {};
        $scope.swValidation = new SWValidation();

        $scope.canAccessNotificationTypes = CurrentUserFactory.canAccessNotificationTypes();
        if (!$scope.canAccessNotificationTypes)
            return;

        $scope.customize = function () {
            $scope.notificationType.isCustomized = true;
        };

        $scope.setDefault = function () {
            $scope.notificationType.isCustomized = false;
        };

        $scope.save = function (form) {
            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            if ($scope.notificationType.isCustomized) {
                if ($scope.swValidation.checkHasValue($scope.notificationType.customizedEmailSubjectTemplate, 'customizedEmailSubjectTemplate')) {
                    $scope.swValidation.checkMaxLenght($scope.notificationType.customizedEmailSubjectTemplate, 'customizedEmailSubjectTemplate', 100);
                }
                if (!$scope.notificationType.customizedEmailBodyTemplate) {
                    $scope.swValidation.addAlert('customizedEmailBodyTemplate' , 'The email text is empty');
                }
            }
            $scope.swValidation.end();

            if ($scope.swValidation.hasErrors())
                return;

            var params = {
                clientId: $scope.clientId,
                notificationTypeId: $scope.notificationTypeId,
                isCustomized: $scope.notificationType.isCustomized,
                customizedEmailSubjectTemplate: $scope.notificationType.customizedEmailSubjectTemplate,
                customizedEmailBodyTemplate: $scope.notificationType.customizedEmailBodyTemplate
            };

            NotificationTypesFactory.api.save(params).$promise.then(function (response) {
                $location.url('/NotificationTypes/Client/' + $scope.clientId + '/List');
            }, UtilityService.onRequestError);
        };

        activate();

        function activate() {
            NotificationTypesFactory.api.getForEdition({ clientId: $scope.clientId, notificationTypeId: $scope.notificationTypeId }).$promise.then(function (response) {
                $scope.notificationType = response.notificationType;
            }, UtilityService.onRequestError);
        }
    }
})();