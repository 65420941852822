(function () {
    'use strict';

    angular.module('springwise')
        .controller('workRecordsFormStepThreeCtrl', workRecordsFormStepThreeCtrl);

    workRecordsFormStepThreeCtrl.$inject = ['$scope',
        'CurrentUserFactory',
        'ClientsFactory',
        'permissions',
        'WorkRecordsFactory',
        '$location',
        'UtilityService',
        'workRecordsStages',
        'workRecordsStatuses',
        '$routeParams',
        'ClientPriorityLevelsFactory',
        'WorkRecordService'];

    function workRecordsFormStepThreeCtrl($scope,
        CurrentUserFactory,
        ClientsFactory,
        permissions,
        WorkRecordsFactory,
        $location,
        UtilityService,
        workRecordsStages,
        workRecordsStatuses,
        $routeParams,
        ClientPriorityLevelsFactory,
        WorkRecordService) {

        $scope.isVendorUser = false;
        $scope.isSWAdminUser = false;
        $scope.isClientUser = false;
        var currentUser = {};

        $scope.swValidation = new SWValidation();

        if (CurrentUserFactory.exists()) {
            currentUser = CurrentUserFactory.get();

            $scope.isVendorUser = currentUser.vendor !== undefined && currentUser.vendor !== null;
            $scope.isClientUser = currentUser.client !== undefined && currentUser.client !== null;
            $scope.isSWAdminUser = !$scope.isVendorUser && !$scope.isClientUser;

            $scope.approveWorkRecords = $scope.currentUser.permissions.indexOf(permissions.approveWorkRecords) !== -1;
        }

        //FUNCTIONS
        $scope.saveWorkRecord = saveWorkRecord;
        $scope.continueWR = continueWR;
        //VARIABLES
        $scope.masterData = [];
        $scope.masterData.priorityLevels = [];
        $scope.clientReferenceNumberWarningMessage = '';
        $scope.approvalNeeded = false;
        //WATCHERS
        $scope.$watch('wr.priorityLevel.priorityLevelId', onPriorityLevelSelected);
        $scope.$watch('wr.referenceNumber', onClientReferenceNumberChange);

        activate();

        function activate() {
            var firstLoad = !$scope.wr;
            WorkRecordsFactory.get({ id: $routeParams.workRecordId }).$promise.then(function (wr) {
                $scope.wr = wr;
                if (firstLoad) {
                    ClientPriorityLevelsFactory.getAllClientPriorityLevels({ clientId: $scope.wr.clientId }).$promise.then(function (priorityLevels) {
                        $scope.masterData.priorityLevels = priorityLevels;
                        loadNextActionDate();
                    });

                    ClientsFactory.get({ id: $scope.wr.clientId }).$promise.then(function (client) {
                        $scope.client = client;
                        if (isStage3AndInProgress()) {
                            $scope.approvalNeeded = true;
                            if ($scope.approveWorkRecords && currentUser.approveWorkRecordLevel >= $scope.wr.serviceNTEValue) {
                                $scope.approvalNeeded = false;
                            }
                        }
                    }, UtilityService.onRequestError);
                }

                //if ($scope.wr.referenceNumber.length == 0) {
                //    var newRefNum = moment().format('YYYYMMdd-hhmmss');
                //};

            }, UtilityService.onRequestError);
        }

        function isStage3AndCanceled() {
            return isStage3() && $scope.wr.statusId === workRecordsStatuses.CanceledStatus.statusId;
        }
        $scope.isStage3AndCanceled = isStage3AndCanceled;

        function isStage3AndInProgress() {
            return isStage3() && $scope.wr.statusId === workRecordsStatuses.InProgressStatus.statusId;
        }
        $scope.isStage3AndInProgress = isStage3AndInProgress;

        function isStage3AndPendingApproval() {
            return isStage3() && $scope.wr.statusId === workRecordsStatuses.PendingApprovalStatus.statusId;
        }
        $scope.isStage3AndPendingApproval = isStage3AndPendingApproval;

        function isStage3() {
            if (!$scope.wr) return false;
            return $scope.wr.stageId === workRecordsStages.WorkRecordCreationStage.stageId;
        }

        function isGreaterStage() {
            if (!$scope.wr) return false;
            return $scope.wr.stageId > workRecordsStages.WorkRecordCreationStage.stageId;
        }
        $scope.isGreaterStage = isGreaterStage;

        function saveWorkRecord(form) {

            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            $scope.swValidation.end();
            if ($scope.swValidation.hasErrors())
                return;

            WorkRecordsFactory.update({ id: $scope.wr.id, step: 3 }, $scope.wr).$promise.then(onSaveSucceeded, UtilityService.onRequestError);
            if ($scope.wr.emergencyType.emergencyTypeId !== null) {
                WorkRecordsFactory.sendEmergencyNotificationEmails({ workRecordId: $scope.wr.id, clientId: $scope.wr.clientId }, $scope.wr).$promise.then(onSendEmergencyListEmailSucceeded, UtilityService.onRequestError);
            }
        }

        function onSendEmergencyListEmailSucceeded() {
        }

        function onSaveSucceeded() {
            if ($scope.approvalNeeded) {
                WorkRecordsFactory.requestNTEApproval({ workRecordId: $scope.wr.id }, null).$promise.then(onRequestNTEApprovalSucceeded, UtilityService.onRequestError);
            }
            else {
                $scope.wr.stageId = workRecordsStages.VendorAssignmentStage.stageId;
                WorkRecordsFactory.advanceStep({ id: $scope.wr.id, step: 3 }, $scope.wr).$promise.then(onAdvanceSucceded, UtilityService.onRequestError);
            }
        }

        function onRequestNTEApprovalSucceeded() {
            activate();
        }

        function onAdvanceSucceded() {
            continueWR();
        }

        function continueWR() {
            $location.path('/workRecordsHome/' + $scope.wr.id + '/edit/stepFour');
        }

        function onPriorityLevelSelected(newValue, oldValue) {
            if (oldValue === newValue) {
                return;
            }

            loadNextActionDate();
        }

        function loadNextActionDate() {
            if ($scope.wr.nextActionDate) {   
                $scope.nextActionDate = $scope.wr.nextActionDateString;
            }
            else if ($scope.wr.priorityLevel) {          
                for (var i = 0; i < $scope.masterData.priorityLevels.length; i++) {
                    if ($scope.masterData.priorityLevels[i].priorityLevelId === $scope.wr.priorityLevel.priorityLevelId) {
                        $scope.nextActionDate = $scope.masterData.priorityLevels[i].nextActionTime.dayMessage;
                    }
                }
            }
        }

        function onClientReferenceNumberChange(newValue, oldValue) {
            if (newValue && $scope.wr.id && isStage3AndInProgress()) {
                WorkRecordsFactory.checkClientReferenceNumberUniqueness({ workRecordId: $scope.wr.id, ClientReferenceNumber: $scope.wr.referenceNumber }).$promise.then(checkClientReferenceNumberUniquenessResponse, UtilityService.onRequestError);
            }
        }

        function checkClientReferenceNumberUniquenessResponse(response) {
            if (response.alreadyExists) {
                $scope.swValidation.addWarning('clientReferenceNumber', "Reference number already used!");
            }
        }

        $scope.cancelWorkRecord = function () {
            WorkRecordService.cancelWorkRecord($scope.wr.id);
        };
    }
})();