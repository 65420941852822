(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('ContractNTEApprovalListCtrl', ContractNTEApprovalListCtrl);

    function ContractNTEApprovalListCtrl($scope, ContractsFactory, UtilityService) {
        
        function getApprovals() {
            ContractsFactory.api.getPendingContractNTEApprovals().$promise.then(function (response) {
                $scope.contractNTEApprovals = response.contractNTEApprovals;
            }, UtilityService.onRequestError);
        }

        getApprovals();
    }
})();