(function () {
    angular.module('springwise').controller('EmailFormCtrl', EmailFormCtrl);

    EmailFormCtrl.$inject = ['$scope', '$attrs', 'EmailTypesFactory'];

    function EmailFormCtrl($scope, $attrs, EmailTypesFactory) {
        var self = this;

        //$scope.email = new Email();
        $scope.emailTypes = [];

        this.init = init;

        function init(element) {
            self.$element = element;

            EmailTypesFactory.query()
                .$promise.then(function (emailTypes) {
                    $scope.emailTypes = emailTypes;
                });
        }
    }
})();