(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('WorkRecordsFormStepSixSignOffSheetCtrl', WorkRecordsFormStepSixSignOffSheetCtrl);

    function WorkRecordsFormStepSixSignOffSheetCtrl($scope, $routeParams, $window, CurrentUserFactory) {

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
        }

        activate();

        function activate() {
        }

        $scope.uploadFile = function () {           
            $scope.fileToUpload.send('api/WorkRecords/UploadSignOffSheet/' + $routeParams.workRecordId + '/' + $scope.currentUser.userId, onTransferCompleted);
        };

        function onTransferCompleted() {
            $scope.transferCompleted = true;
        }

        $scope.goBack = function () {
            $window.history.back();
        };
    }
})();