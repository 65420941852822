(function () {
    angular.module('springwise').filter('locationByClientWithEmptyOption', locationByClientWithEmptyOption);
    
    locationByClientWithEmptyOption.$inject = [];

    function locationByClientWithEmptyOption() {
        return function (locations, client) {
            var result = [];

            result.push(new LocationOption());

            angular.forEach(locations, function (location, key) {
                if (location.clientId && client && location.clientId === client.clientId && location.active === true) {                
                        result.push(location);
                }
            });

            return result;
        };
    }
})();