(function () {
    'use strict';

    angular.module('springwise').controller('NavCtrl', NavCtrl);

    NavCtrl.$inject = ['$scope', '$location', '$window', 'AuthFactory', 'CurrentUserFactory', 'UsersFactory', 'AuthUserService', 'CurrentBrandInfoFactory', '$rootScope'];

    function NavCtrl($scope, $location, $window, AuthFactory, CurrentUserFactory, UsersFactory, AuthUserService, CurrentBrandInfoFactory, $rootScope) {
        $scope.getName = getName;
        $scope.showBrandIcon = showBrandIcon;
        $scope.iconUrl = "";
        $scope.getIconUrl = getBrandIconURL;
        $scope.logOut = logOut;
        $scope.userId = getId();
        $scope.stopImpersonation = stopImpersonation;
        $scope.isImpersonating = isImpersonating;

        $scope.isLoggedIn = AuthFactory.isLoggedIn;

        function logOut() {
            AuthFactory.logOut();
            $scope.$parent.currentUser = null;
            $location.path('/login');
            $rootScope.checkGlobalNotifications();
        }

        function getName() {
            return CurrentUserFactory.exists() ? CurrentUserFactory.get().userName : '';
        }

        function getId() {
            return CurrentUserFactory.exists() ? CurrentUserFactory.get().userId : '';
        }

        function stopImpersonation() {
            UsersFactory.stopImpersonation().$promise.then(function () {
                AuthFactory.refreshToken().then(function (userContext) {
                    CurrentBrandInfoFactory.setFromUserContext(userContext);
                    $scope.$parent.currentUser = userContext;
                    $window.location = '/#' + CurrentUserFactory.getDashboardPath();
                });
            });
        }

        function showBrandIcon() {
            return CurrentBrandInfoFactory.showBrandIcon();
        }

        function getBrandIconURL() {
            if ($scope.iconUrl === "")
                $scope.iconUrl = CurrentBrandInfoFactory.getIconUrl();
            return $scope.iconUrl;
        }
        function isImpersonating() {
            return CurrentUserFactory.exists() && AuthUserService.exists() && CurrentUserFactory.get().userName !== AuthUserService.get().userName;
        }

    }
})();