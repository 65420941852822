(function () {
    angular.module('springwise').controller('CreateContractCtrl', CreateContractCtrl);

    function CreateContractCtrl($scope, $location, $routeParams, VendorsFactory, CurrentUserFactory, ClientsFactory, UtilityService, ContractsFactory) {

        $scope.swValidation = new SWValidation();
        $scope.firstLoad = true;

        var nullVendor = { vendorId: null, name: '' };
        var nullClient = { clientId: null, name: '' };

        if ($routeParams.clientId)
            $scope.clientId = parseInt($routeParams.clientId);

        if ($routeParams.vendorId)
            $scope.vendorId = parseInt($routeParams.vendorId);

        $scope.createContract = function () {
            $scope.swValidation.start();
            $scope.swValidation.checkHasValue($scope.description, "description");
            $scope.swValidation.checkHasValue($scope.clientId, "clientId");
            $scope.swValidation.checkHasValue($scope.vendorId, "vendorId");
            $scope.swValidation.end();
            if ($scope.swValidation.hasErrors())
                return;
            ContractsFactory.api.createContract({ clientId: $scope.clientId, vendorId: $scope.vendorId, description: $scope.description }).$promise.then(function (response) {
                $location.path('/Contracts/EditContract/' + response.contractId);
            }, UtilityService.onRequestError);
        };

        if ($routeParams.vendorId) {
            defaultVendor($scope.vendorId);
        } else if ($routeParams.clientId) {
            defaultClient($scope.clientId);
        } else if (CurrentUserFactory.isClient()) {
            defaultClientForUser();
        } else {
            initDefault();
        }

        $scope.$watch('clientId', onClientIdChange);

        function getVendors() {
            VendorsFactory.queryOptions({ clientId: $scope.clientId }).$promise.then(function (vendors) {
                $scope.vendors = [nullVendor].concat(vendors);
            }, UtilityService.onRequestError);
        }

        function onClientIdChange(newValue, oldValue) {

            if (newValue !== oldValue) {
                // If is the initial load, don't clear the vendorId because is the default value
                if ($scope.firstLoad) {
                    $scope.firstLoad = false;
                } else {
                    $scope.vendorId = null;
                }

                $scope.vendors = [nullVendor];
                if ($scope.clientId) {
                    getVendors();
                }
            }

        }

        function defaultVendor(vendorId) {

            VendorsFactory.get({ id: vendorId }).$promise.then(function (response) {

                $scope.isVendor = true;
                $scope.vendorId = vendorId;
                $scope.clientId = response.clientId;

                if ($scope.clientId) {
                    $scope.isClient = true;
                    ClientsFactory.get({ id: $scope.clientId }).$promise.then(function (response) {
                        $scope.isClientName = response.name;
                        getVendors();
                    }, UtilityService.onRequestError);
                } else {
                    $scope.isClient = false;
                    getClients();
                    getVendors();
                }

            }, UtilityService.onRequestError);

        }

        function defaultClient(clientId) {
            $scope.isClient = true;
            $scope.clientId = clientId;

            ClientsFactory.get({ id: clientId }).$promise.then(function (response) {

                $scope.isClientName = response.name;
                getVendors();

            }, UtilityService.onRequestError);
        }

        function defaultClientForUser() {
            $scope.isClient = true;
            $scope.clientId = CurrentUserFactory.get().client.clientId;
            $scope.isClientName = CurrentUserFactory.get().client.name;
            getVendors();
        }

        function initDefault() {
            $scope.isClient = false;
            $scope.clientId = null;
            getClients();
        }

        function getClients() {
            ClientsFactory.queryOptions().$promise.then(function (clients) {
                $scope.clients = [nullClient].concat(clients);
            }, UtilityService.onRequestError);
        }

    }
})();