(function () {
    'use strict';

    angular.module('springwise')
        .controller('workRecordsFormStepFourFormCtrl', workRecordsFormStepFourFormCtrl);

    workRecordsFormStepFourFormCtrl.$inject = [
        '$scope',
        'CurrentUserFactory',
        'ClientsFactory',
        'permissions',
        'WorkRecordsFactory',
        '$location',
        'UtilityService',
        'workRecordsStages',
        'workRecordsStatuses',
        '$routeParams',
        'ClientPriorityLevelsFactory',
        'WorkRecordService'
    ];

    function workRecordsFormStepFourFormCtrl($scope,
        CurrentUserFactory,
        ClientsFactory,
        permissions,
        WorkRecordsFactory,
        $location,
        UtilityService,
        workRecordsStages,
        workRecordsStatuses,
        $routeParams,
        ClientPriorityLevelsFactory,
        WorkRecordService) {

        var currentUser = CurrentUserFactory.get();
        $scope.approveWorkRecords = currentUser.permissions.indexOf(permissions.approveWorkRecords) !== -1;

        $scope.swValidation = new SWValidation();

        $scope.saveWorkRecord = saveWorkRecord;
        $scope.advanceWorkRecord = advanceWorkRecord;
        $scope.continueWR = continueWR;
        $scope.$watch('wr.scheduledDate', onScheduledDateChange);
        $scope.$watch('wr.vendorPrice', onVendorPriceChange);

        $scope.masterData = [];
        $scope.svcClassNTE = '';
        $scope.vendorPrice = '0';

        activate();

        function activate() {
            var firstLoad = !$scope.wr;
            WorkRecordsFactory.get({ id: $routeParams.workRecordId }).$promise.then(function (wr) {
                $scope.wr = wr;
                if (firstLoad) {
                    $scope.svcClassNTE = 'U$S ' + $scope.wr.serviceNTEValue;
                    ClientPriorityLevelsFactory.getAllClientPriorityLevels({ clientId: $scope.wr.clientId }).$promise.then(function (priorityLevels) {
                        $scope.masterData.priorityLevels = priorityLevels;
                        loadPriorityTime();
                    });
                }
            }, UtilityService.onRequestError);
        }

        function isCanceled() {
            return isStage5() && $scope.wr.statusId === workRecordsStatuses.CanceledStatus.statusId;
        }
        $scope.isCanceled = isCanceled;

        function isInProgress() {
            return isStage5() && $scope.wr.statusId === workRecordsStatuses.InProgressStatus.statusId;
        }
        $scope.isInProgress = isInProgress;

        function isPendingApproval() {
            return isStage5() && $scope.wr.statusId === workRecordsStatuses.PendingApprovalStatus.statusId;
        }
        $scope.isPendingApproval = isPendingApproval;

        function isStage5() {
            if (!$scope.wr) return false;
            return $scope.wr.stageId === workRecordsStages.VendorSelectionBasicForm.stageId;
        }

        function isGreaterStage() {
            if (!$scope.wr) return false;
            return $scope.wr.stageId > workRecordsStages.VendorSelectionBasicForm.stageId;
        }
        $scope.isGreaterStage = isGreaterStage;

        function saveWorkRecord(form) {

            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            if (!$scope.wr.scheduledDate) {
                $scope.swValidation.addAlert('scheduledDate', 'Please, pick a date');
            }
            if (!$scope.wr.expectedCompletionDate) {
                $scope.swValidation.addAlert('expectedCompletionDate', 'Please, pick a date');
            }
            if ($scope.wr.scheduledDate && $scope.wr.expectedCompletionDate && $scope.wr.expectedCompletionDate < $scope.wr.scheduledDate) {
                $scope.swValidation.addAlert('expectedCompletionDate', 'Can not be lower than the Start Date');
            }
            if (!$scope.wr.vendorPrice || $scope.wr.vendorPrice <= 0) {
                $scope.swValidation.addAlert('vendorPrice', 'Should be greater than zero');
            }
            $scope.swValidation.end();
            if ($scope.swValidation.hasErrors())
                return;

            var NTELevelExceeded = isApprovalNeeded();
            
            WorkRecordsFactory.update({ id: $scope.wr.id, step: 5 }, $scope.wr).$promise.then(function () { onSaveSucceeded(NTELevelExceeded); }, UtilityService.onRequestError);
        }

        function onSaveSucceeded(NTELevelExceeded) {
            if (NTELevelExceeded) {
                WorkRecordsFactory.requestNTEApproval({ workRecordId: $scope.wr.id }, null).$promise.then(onRequestNTEApprovalSucceeded, UtilityService.onRequestError);
            }
            else {
                advanceWorkRecord();
            }
        }

        function onRequestNTEApprovalSucceeded() {
            activate();
        }

        function advanceWorkRecord() {
            //if ($scope.wr.vendorQuoting === true) {
            //    $scope.wr.stageId = workRecordsStages.QuotingStage.stageId;
            //} else {
            //    $scope.wr.stageId = workRecordsStages.ScopeReviewStage.stageId;
            //}
            $scope.wr.stageId = workRecordsStages.ScopeReviewStage.stageId;
            WorkRecordsFactory.advanceStep({ id: $scope.wr.id, step: 5 }, $scope.wr).$promise.then(onAdvanceSucceded, UtilityService.onRequestError);
        }

        function onAdvanceSucceded() {
            continueWR();
        }

        function continueWR() {
            $location.path('/workRecordsHome/' + $scope.wr.id + '/edit/stepFive');
        }

        function onScheduledDateChange(newValue, oldValue) {

            if (oldValue === undefined && newValue === undefined ||
                oldValue === newValue || !$scope.priorityTime) {
                return;
            }

            var newDay = newValue.getDate();
            var newMonth = newValue.getUTCMonth() + 1;
            var newYear = newValue.getFullYear();

            var priorityDay = Number($scope.priorityTimeValue.day);
            var priorityMonth = Number($scope.priorityTimeValue.month);
            var priorityYear = Number($scope.priorityTimeValue.year);

            if ((newYear > priorityYear) || (newYear === priorityYear && newMonth > priorityMonth) || (newYear === priorityYear && newMonth === priorityMonth && newDay > priorityDay)) {
                $scope.swValidation.addWarning('scheduledDate', 'Exceeds the Starting date by Priority');
            } else {
                $scope.swValidation.removeWarning('scheduledDate');
            }
        }

        function onVendorPriceChange(newValue, oldValue) {
            if (isInProgress()) {
                if (isApprovalNeeded()) {
                    $scope.swValidation.addWarning('vendorPrice', 'NTE Exceeded');
                } else {
                    $scope.swValidation.removeWarning('vendorPrice');
                }
            }
        }

        function isApprovalNeeded() {
            if ($scope.approveWorkRecords && $scope.wr.vendorPrice <= currentUser.approveWorkRecordLevel) {
                return false;
            }
            return true;
        }

        function loadPriorityTime() {
            for (var i = 0; i < $scope.masterData.priorityLevels.length; i++) {
                if ($scope.masterData.priorityLevels[i].priorityLevelId == $scope.wr.priorityLevel.priorityLevelId) {
                    $scope.priorityTime = $scope.masterData.priorityLevels[i].priorityTime.dayMessage;
                    $scope.priorityTimeValue = $scope.masterData.priorityLevels[i].priorityTime.value;
                    $scope.nextActionDate = $scope.masterData.priorityLevels[i].priorityTime.nextActionDayMessage;
                }
            }
        }

        $scope.cancelWorkRecord = function () {
            WorkRecordService.cancelWorkRecord($scope.wr.id);
        };
    }
})();




