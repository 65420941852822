(function () {
    angular.module('springwise').controller('AcceptCancelModalWindowCtrl', AcceptCancelModalWindowCtrl);

    function AcceptCancelModalWindowCtrl($scope, $uibModalInstance, data) {
        $scope.message = data.message;
        $scope.title = data.title;
        $scope.onlyOK = data.onlyOK;
        $scope.type = data.type;

        $scope.ok = function () {
            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };
    }
})();