(function () {
    angular.module('springwise').factory('AuthUserService', AuthUserService);

    AuthUserService.$inject = ['localStorageService'];

    function AuthUserService(localStorageService) {
        var authUserKey = 'authorizationData';

        var service = {
            get: get,
            set: set,
            exists: exists,
            remove: remove
        };

        return service;

        function get() {
            var authUser = localStorageService.get(authUserKey);
            if (authUser) {
                return authUser;
            }
        }

        function set(authUser) {
            localStorageService.set(authUserKey, authUser);
        }

        function exists() {
            var authUser = get();
            return authUser ? true : false;
        }

        function remove() {
            localStorageService.remove(authUserKey);
        }
    }
})();