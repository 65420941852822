(function () {
    angular.module('springwise').controller('ClientsFormCtrl', ClientsFormCtrl);

    ClientsFormCtrl.$inject = ['$scope', '$location', '$routeParams', 'CurrentUserFactory', 'ClientsFactory', 'permissions', 'UtilityService'];

    function ClientsFormCtrl($scope, $location, $routeParams, CurrentUserFactory, ClientsFactory, permissions, UtilityService) {
        var currentUser = CurrentUserFactory.get();

        $scope.swValidation = new SWValidation();

        $scope.client = new Client();
        $scope.disabled = $location.path().indexOf('details') > -1;
        $scope.clientAlreadyExists = false;
        $scope.userAdminExists = false;
        $scope.clientId = 0;
        
        $scope.saveClient = saveClient;
        $scope.goLive = goLive;

        $scope.$watch('client.isSpringwiseManaged', onIsSpringwiseManaged);

        activate();

        function activate() {
            if ($routeParams.id) {
                $scope.clientId = $routeParams.id;
                $scope.canViewContracts = false;

                if (currentUser) {
                    $scope.viewEmergencyTypes = $scope.currentUser.permissions.indexOf(permissions.viewEmergencyTypes) !== -1;
                    $scope.viewPriorityLevels = $scope.currentUser.permissions.indexOf(permissions.viewPriorityLevels) !== -1;
                    $scope.canViewLocations = $scope.currentUser.permissions.indexOf(permissions.viewLocations) !== -1;
                    $scope.canViewContracts = $scope.currentUser.permissions.indexOf(permissions.viewContracts) !== -1;
                    $scope.canViewClients = currentUser.permissions.indexOf(permissions.canQueryClients) !== -1;
                    $scope.canViewRoles = currentUser.permissions.indexOf(permissions.canQueryRoles) !== -1;
                    $scope.viewServices = CurrentUserFactory.viewServices();
                    $scope.canQueryVendors = CurrentUserFactory.canQueryVendors();
                    $scope.canAccessNotificationTypes = CurrentUserFactory.canAccessNotificationTypes();
                }

                ClientsFactory.get({ id: $routeParams.id }).$promise.then(function (client) {
                    $scope.client = client;
                    $scope.clientAlreadyExists = true;
                    if ($scope.client.userAdministratorId){
                        $scope.userAdminExists = true;
                    }
                    else {
                        $scope.userAdminExists = false;
                    }
                    
                }, returnToHomeOnRequestError);
                ClientsFactory.isAlive({ id: $routeParams.id }).$promise.then(function (isAlive) {
                    $scope.isAlive = isAlive.isAlive;
                }, returnToHomeOnRequestError);
            }
        }

        function saveClient(form) {

            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            $scope.swValidation.end();
            if ($scope.swValidation.hasErrors())
                return;

            if ($scope.clientId) {
                $scope.client.$update({ id: $scope.clientId }, redirectToPrevious, returnToHomeOnRequestError);
                $scope.clientAlreadyExists = true;
            } else {
                ClientsFactory.save($scope.client).$promise.then(redirectToPrevious, returnToHomeOnRequestError);
                $scope.clientAlreadyExists = false;
            }
        }

        function redirectToPrevious() {
            if (currentUser.client) {
                $location.path('/');
            } else if (!currentUser.client && !currentUser.vendor) {
                $location.path('/clients');
            }
        }

        function goLive() {
            ClientsFactory.goLive({ id: $scope.clientId }).$promise.then(function (isAlive) {
                if (isAlive.isAlive) {
                    $scope.isAlive = isAlive.isAlive;
                    $scope.swValidation.addSuccess(null, "The client went live");
                }
            });
        }

        function onRequestError(error) {
            UtilityService.onRequestError(error);
        }

        function returnToHomeOnRequestError(error) {
            UtilityService.onRequestError(error);
            $location.path('/');
        }

        function onIsSpringwiseManaged(newValue, oldValue) {
            if (newValue !== oldValue && newValue) {
                $scope.client.isSpringwiseGranted = true;
            }
        }

    }
})();