(function () {
    'use strict';

    angular
        .module('springwise')
        .run([
            'AuthFactory',
            '$rootScope',
            '$location',
            'AuthUserService',
            'CurrentUserFactory',
            'BreadcrumbService',
            function (AuthFactory, $rootScope, $location, AuthUserService, CurrentUserFactory, BreadcrumbService) {
                AuthFactory.fillAuthData();
                $rootScope.$on('$routeChangeStart', onRouteChangeStart);

                function onRouteChangeStart(event, next, oldUrl) {
                    if (!redirect(event, next, oldUrl)) {
                        handleBreadcrumb(event, next, oldUrl);
                        handleUserLastActivity();
                    }

                    function redirect(event, next, oldUrl) {
                        if (next && next.$$route && !next.$$route.isPublic) {
                            if (next.originalPath !== '/login' && !AuthUserService.exists()) {
                                $rootScope.pathTargetedBeforeLogin = $location.path();
                                $location.path('/login');
                                return true;
                            } else if (next.originalPath !== '/users/password' && CurrentUserFactory.exists() && AuthUserService.exists()) {
                                var currentUser = CurrentUserFactory.get();
                                if (currentUser.mustResetPassword || currentUser.passwordHasExpired || currentUser.mustSetSecurityQuestions) {
                                    $location.path('/users/password');
                                    return true;
                                }
                            }
                        }
                        return false;
                    }

                    function handleBreadcrumb(event, next, oldUrl) {
                        BreadcrumbService.get(next.$$route.originalPath, $location.$$path, next.params, function (crumbs) {
                            $rootScope.crumbs = crumbs;
                        });
                    }

                    function handleUserLastActivity() {
                        $rootScope.userLastActivity = Date.now();
                    }
                }
            }
        ]);
})();