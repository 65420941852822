(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('workRecordsFormStepFiveCtrl', workRecordsFormStepFiveCtrl);

    function workRecordsFormStepFiveCtrl($scope,
        CurrentUserFactory,
        ClientsFactory,
        permissions,
        WorkRecordsFactory,
        $location,
        UtilityService,
        workRecordsStages,
        workRecordsStatuses,
        $routeParams,
        WorkRecordService) {

        $scope.currentUser = CurrentUserFactory.get();
        $scope.wrAlreadyExists = false;

        activate();

        function activate() {
            WorkRecordsFactory.getWorkRecordSummary({ workRecordId: $routeParams.workRecordId }).$promise.then(function (workRecordSummary) {
                $scope.workRecordSummary = workRecordSummary;
            }, UtilityService.onRequestError);

            WorkRecordsFactory.get({ id: $routeParams.workRecordId }).$promise.then(function (wr) {
                $scope.wr = wr;

                if ($scope.wr.stageId > workRecordsStages.ScopeReviewStage.stageId) {
                    $scope.wrAlreadyExists = true;
                }
            }, UtilityService.onRequestError);


       }

        function isInProgress() {
            return isStage() && $scope.wr.statusId === workRecordsStatuses.InProgressStatus.statusId;
        }
        $scope.isInProgress = isInProgress;

        function isStage() {
            if (!$scope.wr) return false;
            return $scope.wr.stageId === workRecordsStages.ScopeReviewStage.stageId;
        }

        function isGreaterStage() {
            if (!$scope.wr) return false;
            return $scope.wr.stageId > workRecordsStages.ScopeReviewStage.stageId;
        }
        $scope.isGreaterStage = isGreaterStage;

        $scope.assignToVendor = function () {
            $scope.wr.stageId = workRecordsStages.WorkReportingStage.stageId;
            WorkRecordsFactory.advanceStep({ id: $scope.wr.id, step: 6 }, $scope.wr).$promise.then(onAdvanceSucceded, UtilityService.onRequestError);
        };

       function onAdvanceSucceded() {
           $location.path('/workRecordsHome/' + $scope.wr.id + '/edit/stepSix');
       }

       function continueWR() {
           $location.path('/workRecordsHome/' + $scope.wr.id + '/edit/stepSix');
       }
       $scope.continueWR = continueWR;

       $scope.cancelWorkRecord = function () {
           WorkRecordService.cancelWorkRecord($scope.wr.id);
       };
    }
})();