(function () {
    angular.module('springwise').controller('SearchAssignVendorsCtrl', SearchAssignVendorsCtrl);

    SearchAssignVendorsCtrl.$inject = ['$scope', 'UtilityService', 'VendorsFactory', 'LocationsFactory', 'LocationServicesVendorsService'];

    function SearchAssignVendorsCtrl($scope, UtilityService, VendorsFactory, LocationsFactory, LocationServicesVendorsService) {
        var self = this;
        self.init = init;

        function init($element) {
            self.$element = $element;

            $scope.vendors = [];
            $scope.locations = [new LocationOption()];

            $scope.name = '';
            $scope.taxId = '';
            $scope.zipCode = '';
            $scope.locationId = new LocationOption();
            $scope.orderBy = 'ranking';
            $scope.isDescending = false;
            $scope.page = 1;
            $scope.limit = UtilityService.defaultLimitOption;
            $scope.limitOptions = UtilityService.limitOptions;
            $scope.onlyRequired = true;
            $scope.orderByRequired = true;
            $scope.orderByRanking = true;
            $scope.orderByPercentage = true;

            $scope.onlyRequiredClicked = false;

            $scope.showAllVendors = showAllVendors;
            $scope.showOnlyRequired = showOnlyRequired; 

            LocationsFactory.queryOptions().$promise.then(function (locations) {
                $scope.locations = locations;
            });

            $scope.onPaginationChange = onPaginationChange;
            $scope.search = search;

            $scope.$watch('limit', onPageLimitChange);
            $scope.$watch('name', resetSearch);
            $scope.$watch('taxId', resetSearch);
            $scope.$watch('zipCode', resetSearch);
            $scope.$watch('locationId', resetSearch);            
        }

        function onPaginationChange(page) {
            $scope.page = page;
            search();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.page !== 1) {
                    $scope.page = 1;
                }
                search();
            }
        }

        function resetSearch() {
            $scope.page = 1;
            $scope.limit = UtilityService.defaultLimitOption;
            $scope.vendors = [];
            $scope.searchExecuted = false;
        }

        function search() {
            var params = {};

            params.orderBy = $scope.orderBy;
            params.isDescending = $scope.isDescending;
            params.page = $scope.page;
            params.limit = $scope.limit.id;
            params.name = ($scope.params.name || {}).value;
            params.taxId = ($scope.params.taxId || {}).value;
            params.zipCode = ($scope.params.zipCode || {}).value;
            params.serviceId = ($scope.params.service || {}).value.id;
            params.locationId = ($scope.params.location || {}).value.locationId;
            params.onlyRequired = $scope.onlyRequired;
            params.orderByRequired = $scope.orderByRequired;
            params.orderByRanking = $scope.orderByRanking;
            params.orderByPercentage = $scope.orderByPercentage;
            params.workRecordId = ($scope.params.workRecord || {}).value.workRecordId;
            params.considerNoContract = ($scope.params.considerNoContract || {}).value;
                       
            LocationServicesVendorsService.getTotalRecordsWRVendorSelection(params).$promise.then(onQueryTotalSucceded, UtilityService.onRequestError);

            function onQueryTotalSucceded(total) {
                if ($scope.total !== total.totalRecords) {
                    if ($scope.page !== 1) {
                        $scope.page = 1;
                        params.page = $scope.page;
                    }
                    $scope.total = total.totalRecords;
                }
                                
                LocationServicesVendorsService.queryWRVendorSelection(params).$promise.then(onSearchSucceded, UtilityService.onRequestError);

                function onSearchSucceded(vendors) {
                    $scope.vendors = vendors;
                    $scope.searchExecuted = true;                    

                    if ($scope.vendors.length > 0) {
                        return true;
                    }
                    else {
                        if ($scope.onlyRequiredClicked !== true)
                        {
                            $scope.onlyRequired = false;
                            search();
                        }                   
                        return false;
                    }
                }
            }

          
        }

        function showAllVendors() {
            $scope.onlyRequired = false; //Show required and defaults vendors (except denied)
            search();
        }

        function showOnlyRequired() {
            $scope.onlyRequiredClicked = true;
            $scope.onlyRequired = true; //Show only required vendors
            search();           
        }

    }
})();