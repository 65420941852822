(function () {
    angular.module('springwise').factory('WorkRecordTriageAnswersService', WorkRecordTriageAnswersService);

    WorkRecordTriageAnswersService.$inject = ['$resource'];

    function WorkRecordTriageAnswersService($resource) {
        return $resource(
            'api/WorkRecordTriageAnswers/:id', {
                id: '@id'
            }, {
                save: {
                    url: 'api/WorkRecordTriageAnswers/',
                    method: 'POST',
                    isArray: false
                },
                update: {
                    method: 'PUT'
                },
                queryAnswers: {
                    url: 'api/WorkRecordTriageAnswers/',
                    method: 'GET',
                    isArray: true,
                },
                deleteAnswer: {
                    url: 'api/WorkRecordTriageAnswers/:id/Delete',
                    method: 'DELETE',
                }
            });
    }
})();