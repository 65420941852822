(function () {
    angular.module('springwise').directive('swValidationTooltip', swValidationTooltip);

    function swValidationTooltip() {
        return {
            templateUrl: '/app/shared/directives/errors/validationTooltipView.html',
            restrict: 'E',
            scope: {
                validation: '=',
                name: '@',
                field: '=',
                maxLength: '=',
                minLength: '=',
                patternExample: '@',
                max: '@',
                min: '@',
                model: '=',
                topPixels: '@',
                index: '='
            },
            controller: "swValidationTooltipCtrl",
            link: function (scope, element, attrs, ctrl) {
                if (!scope.validation)
                    return;
                function touched() {
                    if (scope.alert && scope.alert.message && !scope.alert.closed) {
                        scope.close();
                        scope.$digest();
                    }
                }
                var parentChildren = element[0].parentElement.children;
                for (var index = 0; index < parentChildren.length; index++) {
                    var parentChild = parentChildren[index];
                    var tagName = parentChild.tagName.toLowerCase();
                    if (tagName == 'input' || tagName == 'select' || tagName == 'textarea') {
                        parentChild.addEventListener("focus", touched);
                    }
                }
            }
        };
    }

    angular.module('springwise').controller("swValidationTooltipCtrl", swValidationTooltipCtrl);

    function swValidationTooltipCtrl($scope) {
        $scope.style = {};
        if (!$scope.validation) {
            return;
        }
        if ($scope.topPixels) {
            $scope.style.top = parseInt($scope.topPixels);
        }
        var tooltip = { name: $scope.name, scope: $scope };
        $scope.validation.addTooltip(tooltip);
        $scope.$watch("validation.counter", checkValidations);
        $scope.$watch("model", modelChange);
        $scope.close = function () {
            if ($scope.alert) {
                $scope.alert.closed = true;
            }
        };
        function modelChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                if ($scope.alert && $scope.alert.isError) {
                    $scope.close();
                }
            }
        }
        function checkValidations() {

            var formError;
            for (var index1 = 0; index1 < $scope.validation.formErrors.length; index1++) {
                if ($scope.validation.formErrors[index1].field === $scope.field && !$scope.validation.formErrors[index1].closed) {
                    formError = $scope.validation.formErrors[index1];
                    break;
                }
            }
            if (formError) {
                var message = 'Invalid';
                if ($scope.field.$error.required) {
                    message = 'Please enter a value';
                }
                if ($scope.field.$error.minlength) {
                    if ($scope.minLength)
                        message = $scope.minLength + ' characters minimum';
                    else
                        message = 'No long enough';
                }
                if ($scope.field.$error.maxlength) {
                    if ($scope.maxLength)
                        message = $scope.maxLength + ' characters maximum';
                    else
                        message = 'Too long';
                }
                if ($scope.field.$error.pattern) {
                    if ($scope.patternExample)
                        message = 'Example: ' + $scope.patternExample;
                    else
                        message = 'Invalid format';
                }
                if ($scope.field.$error.min) {
                    if ($scope.min)
                        message = 'Minimum is ' + $scope.min;
                    else
                        message = 'Lower than minimum';
                }
                if ($scope.field.$error.max) {
                    if ($scope.max)
                        message = 'Maximum is ' + $scope.max;
                    else
                        message = 'Greater than maximum';
                }
                if ($scope.field.$error.newusernameused) {
                    message = 'The username specified is in use';
                }
                if ($scope.field.$error.newemailused) {
                    message = 'The email specified is in use';
                }
                if ($scope.field.$error.newclientcodeused) {
                    message = 'The client code specified is in use';
                }
                if ($scope.field.$error.newclientnameused) {
                    message = 'The client name specified is in use';
                }
                if ($scope.field.$error.isinvalidstartdate) {
                    message = 'Please enter a valid start date';
                }
                if ($scope.field.$error.isinvalidenddate) {
                    message = 'Please enter a valid end date';
                }
                if ($scope.field.$error.noweekdayselected) {
                    message = 'Please select at least one day';
                }
                if ($scope.field.$error.isinvalidrecurevery) {
                    message = 'Please enter a valid Recur Every';
                }
                if ($scope.field.$error.isnodescription) {
                    message = 'Please add a description';
                }
                if ($scope.field.$error.isinvalidlatitudeval) {
                    message = 'The latitude value must be between -90 and 90.';
                }
                if ($scope.field.$error.isinvalidlongitudeval) {
                    message = 'The longitude value must be between -180 and 180.';
                }
                formError.message = message;
            }

            var alert;
            for (var index = 0; index < $scope.validation.alerts.length; index++) {
                if ($scope.validation.alerts[index].name === $scope.name && !$scope.validation.alerts[index].closed) {
                    if ($scope.index === undefined || $scope.index !== undefined && $scope.index == $scope.validation.alerts[index].index) {
                        alert = $scope.validation.alerts[index];
                        break;
                    }
                }
            }
            
            var warning;
            for (var indexWarning = 0; indexWarning < $scope.validation.warnings.length; indexWarning++) {
                if ($scope.validation.warnings[indexWarning].name === $scope.name && !$scope.validation.warnings[indexWarning].closed) {
                    warning = $scope.validation.warnings[indexWarning];
                }
            }

            var alertToDisplay;
            if (warning) {
                alertToDisplay = warning;
            }
            if (formError) {
                alertToDisplay = formError;
            }
            if (alert) {
                alertToDisplay = alert;
            }
            $scope.alert = alertToDisplay;
        }
    }
})();