(function () {
    angular.module('springwise').directive('swTextUserEmailField', swTextUserEmailField);

    swTextUserEmailField.$inject = [];

    function swTextUserEmailField() {
        return {
            restrict: 'E',
            templateUrl: 'app/shared/directives/fields/textUserEmailFieldView.html',
            compile: function (element, attrs) {
                if (!attrs.gridcolumn) { attrs.gridcolumn = 'col-sm-3'; }
            },
            scope: {
                field: '=field',
                maxLength: '=maxLength',
                minLength: '=minLength',
                req: '=required',
                model: '=model',
                name: '@name',
                label: '@label',
                dis: '=disabledAttribute',
                warningMessage: '=',
                gridcolumn: '@gridcolumn',
                validation: '=',
                index: '='
            }
        };
    }

})();
