(function () {
    angular.module('springwise').directive('swTextArea', swTextArea);

    swTextArea.$inject = [];

    function swTextArea() {
        return {
            restrict: 'E',
            templateUrl: 'app/shared/directives/fields/textAreaView.html',
            scope: {
                field: '=field',
                maxlength: '=maxlength',
                minlength: '=minlength',
                rows: '=rows',
                req: '=required',
                model: '=model',
                name: '@name',
                label: '@label',               
                dis: '=disabledAttribute',
                cols: '=cols',
                validation: '='
            }
        };
    }
})();