(function () {
    angular.module('springwise').directive('swPhoneField', swPhoneField);

    swPhoneField.$inject = [];

    function swPhoneField() {
        return {
            restrict: 'E',
            templateUrl: 'app/shared/directives/fields/phoneFieldView.html',
            scope: {
                field: '=field',
                maxLength: '=maxLength',
                req: '=required',
                model: '=model',
                name: '@name',
                label: '@label',
                pattern: '=pattern',
                example: '=example'
            }
        };
    }
})();