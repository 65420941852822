(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('InvoiceViewCtrl', InvoiceViewCtrl);

    function InvoiceViewCtrl($scope, $routeParams, $location, $http, $sce, ngAuthSettings, CurrentUserFactory, WorkRecordService, InvoicesFactory, VendorsFactory, UtilityService) {
        $scope.CurrentUserFactory = CurrentUserFactory;
        activate();

        function activate() {
            $scope.showUploadControl = false;
            InvoicesFactory.api.getInvoice({ invoiceId: $routeParams.invoiceId }).$promise.then(function (response) {
                $scope.invoice = response;
                if ($scope.invoice.paperInvoiceId) {
                    $scope.paperInvoiceURL = ngAuthSettings.apiServiceBaseUri + 'api/Invoices/GetPaperInvoice/' + $scope.invoice.paperInvoiceId;
                }
            }, UtilityService.onRequestError);
        }

        $scope.getPdfInvoice = function () {

            // http://stackoverflow.com/questions/21628378/angularjs-display-blob-pdf-in-an-angular-app
            $http.get(ngAuthSettings.apiServiceBaseUri + 'api/Invoices/CreatePdfInvoice?invoiceId=' + $routeParams.invoiceId,
                { responseType: 'arraybuffer' })
                  .success(function (response) {
                      var file = new Blob([(response)], { type: 'application/pdf' });
                      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                          window.navigator.msSaveOrOpenBlob(file);
                      }
                      else {
                          var fileURL = URL.createObjectURL(file);
                          $scope.content = $sce.trustAsResourceUrl(fileURL);
                      }                      
                  });

        };

        $scope.uploadPaperInvoice = function() {
            $scope.fileToUpload.send('api/Invoices/UploadPaperInvoice/' + $scope.invoice.invoiceId, onTransferCompleted);
        };

        function onTransferCompleted() {
            activate();
        }
    }
})();