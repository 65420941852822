(function () {
    angular.module('springwise')
        .directive('swClientCodeValidator', swClientCodeValidator);

    swClientCodeValidator.$inject = ['ngAuthSettings', '$http'];

    function swClientCodeValidator(ngAuthSettings, $http) {
        return {
            require: 'ngModel',
            link: function ($scope, element, attrs, ngModel) {
                ngModel.$validators.customClientCodeValidator = function (modelValue) {
                    // Validate only when changed
                    if (ngModel.$dirty) {
                        var id = (attrs.id === null || attrs.id === undefined) ? 0 : attrs.id;
                        $http.get(ngAuthSettings.apiServiceBaseUri + 'api/Clients/ClientCodeIsUsed?clientCode=' + modelValue + '&clientId=' + id)
                            .success(function (response) {
                                var isUsed = response && response.isUsed;
                                ngModel.$setValidity("newclientcodeused",!isUsed);
                                return !isUsed;
                            });                        
                    }

                    return true;    // Default value is not flagged as invalid
                };
            }
        };
    }

})();