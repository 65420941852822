(function () {
    angular.module('springwise').factory('SWDashboardFactory', SWDashboardFactory);

    SWDashboardFactory.$inject = ['$resource'];

    // TODO: remove resource dependency
    function SWDashboardFactory($resource) {
        return $resource(
            'api/SWDashboard/:id',
            { id: '@id' },
            {
                swDashboardQuery: {
                    method: 'GET',
                    url: 'api/SWDashboard/GetDataForSWDashboard',
                    isArray: false
                },
                markAlertAsRead: {
                    method: 'PUT',
                    url: 'api/SWDashboard/MarkAlertAsRead/:id',
                    isArray: false
                },
            });
    }
})();