(function () {
    angular.module('springwise').directive('swEmailForm', swEmailForm);

    swEmailForm.$inject = [];

    function swEmailForm() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/components/emails/emailFormView.html',
            scope: {
                email: '=email',
                disabled: '=disabled',
                validation: '='
            },
            link: function ($scope, element, attrs, ctrl) {
                ctrl.init(element);
            },
            controller: 'EmailFormCtrl'
        };
    }
})();
