(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('RecurringServicesFactory', RecurringServicesFactory);

    function RecurringServicesFactory($resource) {
        return {
            api: $resource(
                null,
                null,
                {
                    getRecurringServices: {
                        method: 'GET',
                        url: 'api/RecurringServices/GetRecurringServices'
                    },
                    createRecurringService: {
                        method: 'POST',
                        url: 'api/RecurringServices/CreateRecurringService'
                    },
                    getRecurringService: {
                        method: 'GET',
                        url: 'api/RecurringServices/GetRecurringService'
                    },
                    addLocationsToRecurringService: {
                        method: 'POST',
                        url: 'api/RecurringServices/AddLocationsToRecurringService'
                    },
                    getRecurringSchedule: {
                        method: 'GET',
                        url: 'api/RecurringServices/GetRecurringSchedule'
                    },
                    newRecurringSchedule: {
                        method: 'GET',
                        url: 'api/RecurringServices/NewRecurringSchedule'
                    },
                    editRecurringSchedule: {
                        method: 'GET',
                        url: 'api/RecurringServices/EditRecurringSchedule'
                    },
                    saveRecurringSchedule: {
                        method: 'POST',
                        url: 'api/RecurringServices/SaveRecurringSchedule'
                    },
                    removeRecurringSchedule: {
                        method: 'POST',
                        url: 'api/RecurringServices/RemoveRecurringSchedule'
                    },
                    removeRecurringServiceSchedule: {
                        method: 'POST',
                        url: 'api/RecurringServices/RemoveRecurringServiceSchedule'
                    
                    },
                    getScheduleAttachmentsList: {
                        method: 'GET',
                        url: 'api/RecurringServices/GetScheduleAttachmentsList/',
                        isArray: true
                    },
                    getRecurringServiceDateList: {
                        method: 'GET',
                        url: 'api/RecurringServices/GetRecurringServiceDateList',
                        isArray: true
                    },
                    getScheduleNoteList: {
                        method: 'GET',
                        url: 'api/RecurringServices/GetScheduleNotesList/:recurringScheduleId',
                        isArray: true
                    },
                    saveScheduleNote: {
                        method: 'PUT',
                        url: 'api/RecurringServices/SaveWorkRecordNote'
                    }, 
                    getRecurringServiceDateListTitle: {
                        method: 'GET',
                        url: 'api/RecurringServices/GetRecurringServiceDateListTitle'
                    },
                    getAttachmentForEdition: {
                        method: 'GET',
                        url: 'api/RecurringServices/GetAttachmentForEdition/:id'
                    },
                }
            )              
        };
    }
})();