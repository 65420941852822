(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('InvoicesFactory', InvoicesFactory);

    function InvoicesFactory($resource) {
        return {
            exists: function () {
                return currentUser !== null && currentUser !== undefined;
            },
            api: $resource(
                'api/Invoices/:invoiceId',
                { id: '@invoiceId' },
                {
                    getTotalRecords: {
                        method: 'GET',
                        url: 'api/Invoices/Total'
                    },
                    getTotalWorkRecordsPendingInvoicing: {
                        method: 'GET',
                        url: 'api/Invoices/GetTotalWorkRecordsPendingInvoicing'
                    },
                    getWorkRecordsPendingInvoicing: {
                        method: 'GET',
                        url: 'api/Invoices/GetWorkRecordsPendingInvoicing'
                    },
                    getFillInvoiceDetails: {
                        method: 'GET',
                        url: 'api/Invoices/GetFillInvoiceDetails'
                    },
                    getInvoice: {
                        method: 'GET',
                        url: 'api/Invoices/GetInvoice/:invoiceId'
                    },
                    createInvoice: {
                        method: 'POST',
                        url: 'api/Invoices/CreateInvoice'
                    }
                }
            )              
        };
    }
})();