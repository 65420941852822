(function () {
    angular.module('springwise').directive('swSearchUsers', swSearchUsers);

    swSearchUsers.$inject = [];

    function swSearchUsers() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/components/users/searchUsersView.html',
            scope: {
                select: '=select',
                params: '=params'
            },
            link: function ($scope, element, attrs, ctrl) {
                ctrl.init(element);
            },
            controller: 'SearchUsersCtrl'
        };
    }
})();