(function () {
    angular.module('springwise').factory('VendorDashboardFactory', VendorDashboardFactory);

    VendorDashboardFactory.$inject = ['$resource'];

    function VendorDashboardFactory($resource) {
        return $resource(
            'api/VendorDashboard/:id',
            { id: '@id' },
            {
                vendorDashboardQuery: {
                    method: 'GET',
                    url: 'api/VendorDashboard/GetDataForVendorDashboard',
                    isArray: false
                }
            });
    }
})();