/// <reference path="components/password/changePasswordView.html" />
/// <reference path="components/password/changePasswordView.html" />
(function () {
    'use strict';

    angular
        .module('springwise')
        .config([
            '$routeProvider', '$locationProvider', '$injector',
            function ($routeProvider, $locationProvider, $injector) {
                $routeProvider
                 // Home
                    .when('/', {
                        templateUrl: '/app/components/home/configHomeView.html',
                        controller: 'configHomeCtrl'
                    })
                    .when('/reportsHome', {
                        templateUrl: '/app/components/reports/reportsDashboard.html',
                        controller: 'reportsDashboardCtrl'
                    })
                    .when('/workRecordsHome', {
                        templateUrl: 'app/components/workRecords/workRecordsHome.html',
                        controller: 'WorkRecordsHomeCtrl'
                    })
                    .when('/swDashboard', {
                        templateUrl: '/app/components/home/SWDashboard.html',
                        controller: 'swDashboardCtrl'
                    })
                    .when('/clientDashboard', {
                        templateUrl: '/app/components/home/ClientDashboard.html',
                        controller: 'clientDashboardCtrl'
                    })
                    .when('/vendorDashboard', {
                        templateUrl: '/app/components/home/VendorDashboard.html',
                        controller: 'vendorDashboardCtrl'
                    })
                    .when('/login', {
                        templateUrl: '/app/components/login/loginView.html',
                        controller: 'LoginCtrl',
                        isPublic: true
                    })
                    .when('/contractsHome', {
                        templateUrl: '/app/components/contracts/ContractsDashboard.html',
                        controller: 'ContractsDashboardCtrl'
                    })
                    .when('/importsHome', {
                        templateUrl: '/app/components/imports/ImportsDashboard.html',
                        controller: 'ImportsDashboardCtrl'
                    })
                    .when('/not-found', {
                        templateUrl: '/app/components/not-found/notFoundView.html',
                        isPublic: true
                    })
                    .when('/forbidden', {
                        templateUrl: '/app/components/not-found/forbiddenWorkRecordView.html',
                        isPublic: true
                    })
                // Users
                    .when('/users', {
                        templateUrl: '/app/components/users/usersIndexView.html',
                        controller: 'UsersManagementCtrl'
                    })
                    .when('/users/create/:toCopyId?', {
                        templateUrl: '/app/components/users/usersFormView.html',
                        controller: 'UsersFormCtrl'
                    })
                    .when('/users/edit/:id', {
                        templateUrl: '/app/components/users/usersFormView.html',
                        controller: 'UsersFormCtrl'
                    })
                    .when('/users/profile', {
                        templateUrl: '/app/components/users/usersProfileView.html',
                        controller: 'UsersFormCtrl'
                    })
                    .when('/users/password', {
                        templateUrl: '/app/components/password/changePasswordView.html',
                        controller: 'ChangePasswordCtrl'
                    })
                    .when('/users/forgot/password', {
                        templateUrl: '/app/components/password/forgotUsernameView.html',
                        controller: 'forgotUsernameCtrl',
                        isPublic: true,
                    })
                    .when('/users/forgot/username', {
                        templateUrl: '/app/components/password/forgotUsernameView.html',
                        controller: 'forgotUsernameCtrl',
                        isPublic: true,
                    })

                // Users by Client
                    .when('/clients/:clientId/users', {
                        templateUrl: '/app/components/users/usersIndexView.html',
                        controller: 'UsersManagementCtrl'
                    })
                    .when('/clients/:clientId/users/create', {
                        templateUrl: '/app/components/users/usersFormView.html',
                        controller: 'UsersFormCtrl'
                    })
                    .when('/clients/:clientId/users/:id', {
                        templateUrl: '/app/components/users/usersFormView.html',
                        controller: 'UsersFormCtrl'
                    })
                    .when('/clients/:clientId/users/create/:toCopyId?', {
                        templateUrl: '/app/components/users/usersFormView.html',
                        controller: 'UsersFormCtrl'
                    })

                // Clients
                    .when('/clients', {
                        templateUrl: '/app/components/clients/clientsIndexView.html',
                        controller: 'ClientsIndexCtrl'
                    })
                    .when('/clients/create', {
                        templateUrl: '/app/components/clients/clientsFormView.html',
                        controller: 'ClientsFormCtrl'
                    })
                    .when('/clients/:id', {
                        templateUrl: '/app/components/clients/clientsFormView.html',
                        controller: 'ClientsFormCtrl'
                    })
                    .when('/clients/:clientId/locations', {
                        templateUrl: '/app/components/clients/clientLocationsIndexView.html',
                        controller: 'ClientLocationsIndexCtrl'
                    })
                    .when('/clients/:clientId/locations/create', {
                        templateUrl: '/app/components/clients/clientLocationsFormView.html',
                        controller: 'ClientLocationsFormCtrl'
                    })
                    .when('/clients/:clientId/locations/:locationId', {
                        templateUrl: '/app/components/clients/clientLocationsFormView.html',
                        controller: 'ClientLocationsFormCtrl'
                    })
                    .when('/clients/:clientId/regions', {
                        templateUrl: '/app/components/clients/clientRegionsIndexView.html',
                        controller: 'ClientRegionsIndexCtrl'
                    })
                    .when('/clients/:clientId/regions/create', {
                        templateUrl: '/app/components/clients/clientRegionsFormView.html',
                        controller: 'ClientRegionsFormCtrl'
                    })
                    .when('/clients/:clientId/regions/:regionId', {
                        templateUrl: '/app/components/clients/clientRegionsFormView.html',
                        controller: 'ClientRegionsFormCtrl'
                    })
                    .when('/clients/brand/edit/:id',
                    {
                        templateUrl: '/app/components/brandEditor/brandEditorView.html',
                        controller: 'BrandEditorCtrl'
                    })
                    .when('/clients/:clientId/clientVendorRequiredFields',
                    {
                        templateUrl: '/app/components/clients/clientVendorRequiredFieldsView.html',
                        controller: 'ClientVendorRequiredFieldsCtrl'
                    })
                    .when('/priorityLevels',
                    {
                        templateUrl: '/app/components/clients/clientPriorityLevelsView.html',
                        controller: 'clientPriorityLevelsCtrl'
                    })
                    .when('/clients/:clientId/clientPriorityLevels',
                    {
                        templateUrl: '/app/components/clients/clientPriorityLevelsView.html',
                        controller: 'clientPriorityLevelsCtrl'
                    })
                    .when('/clients/:clientId/wrconfiguration', {
                        templateUrl: '/app/components/clients/clientWRConfigurationFormView.html',
                        controller: 'ClientWRConfigurationFormCtrl'
                    })
                    .when('/clients/:clientId/contracts', {
                        templateUrl: '/app/components/clients/clientContractsFormView.html',
                        controller: 'ClientContractsFormCtrl'
                    })
                    .when('/locations/:clientId/:locationId/Contracts', {
                        templateUrl: 'app/components/contracts/ContractsIndexView.html',
                        controller: 'ContractsIndexCtrl'
                    })
                  
                    .when('/clients/:clientId/wrconfiguration/emergencyNotifications', {
                        templateUrl: '/app/components/clients/clientWRConfigurationNotificationsFormView.html',
                        controller: 'ClientWRConfigurationNotificationsFormCtrl'
                    })




                // Vendors
                    .when('/vendors', {
                        templateUrl: '/app/components/vendors/vendorsIndexView.html',
                        controller: 'VendorsIndexCtrl'
                    })
                    .when('/vendors/create', {
                        templateUrl: '/app/components/vendors/vendorsFormView.html',
                        controller: 'VendorsFormCtrl'
                    })
                    .when('/vendors/:vendorId', {
                        templateUrl: '/app/components/vendors/vendorsFormView.html',
                        controller: 'VendorsFormCtrl'
                    })
                    .when('/vendors/:vendorId/Contracts', {
                        templateUrl: 'app/components/contracts/ContractsIndexView.html',
                        controller: 'ContractsIndexCtrl'
                    })
                // Vendors By Client
                    .when('/clients/:clientId/vendors', {
                        templateUrl: '/app/components/vendors/vendorsIndexView.html',
                        controller: 'VendorsIndexCtrl'
                    })
                    .when('/clients/:clientId/vendors/create', {
                        templateUrl: '/app/components/vendors/vendorsFormView.html',
                        controller: 'VendorsFormCtrl'
                    })
                    .when('/clients/:clientId/vendors/:vendorId', {
                        templateUrl: '/app/components/vendors/vendorsFormView.html',
                        controller: 'VendorsFormCtrl'
                    })
                // Vendor for WR
                    .when('/workrecords/:workrecordId/vendors/create', {
                        templateUrl: '/app/components/vendors/vendorsFormView.html',
                        controller: 'VendorsFormCtrl'
                    })
                // Notifications
                    .when('/alerts', {
                        templateUrl: '/app/components/alerts/MyNewAlerts.html',
                        controller: 'myNewAlertsCrtl'
                    })
                // Emergency Types
                    .when('/emergencyTypes', {
                        templateUrl: '/app/components/emergencyTypes/emergencyTypesIndexView.html',
                        controller: 'emergencyTypesIndexCtrl'
                    })
                    .when('/emergencyTypes/create', {
                        templateUrl: '/app/components/emergencyTypes/emergencyTypesFormView.html',
                        controller: 'emergencyTypesFormCtrl'
                    })
                    .when('/emergencyTypes/:id', {
                        templateUrl: '/app/components/emergencyTypes/emergencyTypesFormView.html',
                        controller: 'emergencyTypesFormCtrl'
                    })
                    // Emergency Types By Client
                    .when('/clients/:clientId/emergencyTypes', {
                        templateUrl: '/app/components/emergencyTypes/emergencyTypesIndexView.html',
                        controller: 'emergencyTypesIndexCtrl'
                    })
                    .when('/clients/:clientId/emergencyTypes/create', {
                        templateUrl: '/app/components/emergencyTypes/emergencyTypesFormView.html',
                        controller: 'emergencyTypesFormCtrl'
                    })               
                    .when('/clients/:clientId/emergencyTypes/:id', {
                        templateUrl: '/app/components/emergencyTypes/emergencyTypesFormView.html',
                        controller: 'emergencyTypesFormCtrl'
                    })
                    // Emergency Types - Questionnaires
                    .when('/emergencyTypes/:emergencyId/questionnaire', {
                        templateUrl: '/app/components/questionnaires/questionnairesIndexView.html',
                        controller: 'QuestionnairesIndexCtrl'
                    })
                    .when('/clients/:clientId/emergencyTypes/:emergencyId/questionnaire', {
                        templateUrl: '/app/components/questionnaires/questionnairesIndexView.html',
                        controller: 'QuestionnairesIndexCtrl'
                    })
                // Roles
                    .when('/roles', {
                        templateUrl: '/app/components/roles/rolesIndexView.html',
                        controller: 'RolesManagementCtrl'
                    })
                    .when('/roles/create/:clientId?', {
                        templateUrl: '/app/components/roles/rolesFormView.html',
                        controller: 'RolesFormCtrl'
                    })
                    .when('/roles/clone/:toCopyId?/:clientId?', {
                        templateUrl: '/app/components/roles/rolesFormView.html',
                        controller: 'RolesFormCtrl'
                    })
                    .when('/roles/edit/:id/:clientId?', {
                        templateUrl: '/app/components/roles/rolesFormView.html',
                        controller: 'RolesFormCtrl'
                    })
                // Roles by Client
                    .when('/clients/:clientId/roles', {
                        templateUrl: '/app/components/roles/rolesIndexView.html',
                        controller: 'RolesManagementCtrl'
                    })
                // Services
                    .when('/services', {
                        templateUrl: '/app/components/services/servicesIndexView.html',
                        controller: 'ServicesIndexCtrl'
                    })
                    .when('/clients/:clientId/services', {
                        templateUrl: '/app/components/services/servicesIndexView.html',
                        controller: 'ServicesIndexCtrl'
                    })
                    .when('/locations/:locationId/services', {
                        templateUrl: '/app/components/services/locationServicesIndexView.html',
                        controller: 'LocationServicesIndexCtrl'
                    })
                // Vendors By Services By Locations
                    .when('/locations/:locationId/services/:serviceId/vendors', {
                        templateUrl: '/app/components/services/locationsServicesVendorsIndexView.html',
                        controller: 'LocationsServicesVendorsIndexCtrl'
                    })
                // Services By Vendors
                    .when('/clients/:clientId/vendors/:vendorId/services', {
                        templateUrl: '/app/components/services/vendorsServicesIndexView.html',
                        controller: 'VendorsServicesIndexCtrl'
                    })
                    .when('/vendors/:vendorId/services', {
                        templateUrl: '/app/components/services/vendorsServicesIndexView.html',
                        controller: 'VendorsServicesIndexCtrl'
                    })
                    .when('/services/:serviceId/questionnaire', {
                        templateUrl: '/app/components/questionnaires/questionnairesIndexView.html',
                        controller: 'QuestionnairesIndexCtrl'
                    })
                    .when('/clients/:clientId/services/:serviceId/questionnaire', {
                        templateUrl: '/app/components/questionnaires/questionnairesIndexView.html',
                        controller: 'QuestionnairesIndexCtrl'
                    })

                //Work Records
                    .when('/workRecordsHome/:workRecordId/edit/stepOne', {
                        templateUrl: 'app/components/workRecords/workRecordsFormStepOne.html',
                        controller: 'workRecordsFormStepOneCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/edit/stepTwo', {
                        templateUrl: 'app/components/workRecords/workRecordsFormStepTwo.html',
                        controller: 'workRecordsFormStepTwoCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/edit/stepThree', {
                        templateUrl: 'app/components/workRecords/workRecordsFormStepThree.html',
                        controller: 'workRecordsFormStepThreeCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/edit/stepFour', {
                        templateUrl: 'app/components/workRecords/workRecordsFormStepFour.html',
                        controller: 'workRecordsFormStepFourCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/edit/stepFourForm', {
                        templateUrl: '/app/components/workRecords/workRecordsFormStepFourForm.html',
                        controller: 'workRecordsFormStepFourFormCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/edit/stepFive', {
                        templateUrl: '/app/components/workRecords/workRecordsFormStepFive.html',
                        controller: 'workRecordsFormStepFiveCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/edit/stepSix', {
                        templateUrl: '/app/components/workRecords/workRecordsFormStepSix.html',
                        controller: 'workRecordsFormStepSixCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/vendorWorkReporting', {
                        templateUrl: '/app/components/workRecords/workRecordsFormStepSix.html',
                        controller: 'workRecordsFormStepSixCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/edit/stepSix/photos', {
                        templateUrl: '/app/components/workRecords/workRecordsFormStepSixPhotos.html',
                        controller: 'workRecordsFormStepSixPhotosCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/vendorWorkReporting/photos', {
                        templateUrl: '/app/components/workRecords/workRecordsFormStepSixPhotos.html',
                        controller: 'workRecordsFormStepSixPhotosCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/edit/stepSix/uploadSignOffSheet', {
                        templateUrl: '/app/components/workRecords/workRecordsFormStepSixSignOffSheet.html',
                        controller: 'WorkRecordsFormStepSixSignOffSheetCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/vendorWorkReporting/uploadSignOffSheet', {
                        templateUrl: '/app/components/workRecords/workRecordsFormStepSixSignOffSheet.html',
                        controller: 'WorkRecordsFormStepSixSignOffSheetCtrl'
                    }) 
                    .when('/workRecordsHome/create', {
                        templateUrl: 'app/components/workRecords/workRecordsFormStepOne.html',
                        controller: 'workRecordsFormStepOneCtrl'
                    })
                    .when('/workRecordsHome/exceededNTEApproval/:exceededNTEApprovalId', {
                        templateUrl: 'app/components/workRecords/exceededNTEApproval.html',
                        controller: 'ExceededNTEApprovalCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/redirect', {
                        templateUrl: 'app/components/workRecords/workRecordsRedirect.html',
                        controller: 'WorkRecordsRedirectCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/view', {
                        templateUrl: 'app/components/workRecords/workRecordView.html',
                        controller: 'WorkRecordViewCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/images', {
                        templateUrl: 'app/components/workRecords/workRecordImages.html',
                        controller: 'WorkRecordImagesCtrl'
                    })
                    .when('/workRecordsHome/approvals', {
                        templateUrl: 'app/components/workRecords/workRecordsApprovalsList.html',
                        controller: 'WorkRecordsApprovalsListCtrl'
                    })
                    .when('/workRecordsHome/workRecords', {
                        templateUrl: '/app/components/workRecords/workRecordsDashboard.html',
                        controller: 'workRecordsDashboardCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/note', {
                        templateUrl: 'app/components/workRecords/workRecordsNoteList.html',
                        controller: 'workRecordsNoteListCtrl'
                    })
                    .when('/invoices/SelectWorkRecords', {
                        templateUrl: 'app/components/invoices/SelectWorkRecords.html',
                        controller: 'SelectWorkRecordsCtrl'
                    })
                    .when('/invoices/FillInvoiceDetails', {
                        templateUrl: 'app/components/invoices/FillInvoiceDetails.html',
                        controller: 'FillInvoiceDetailsCtrl'
                    })
                    .when('/invoices/InvoiceView/:invoiceId', {
                        templateUrl: 'app/components/invoices/InvoiceView.html',
                        controller: 'InvoiceViewCtrl'
                    })
                    .when('/invoices/InvoicesDashboard', {
                        templateUrl: '/app/components/invoices/InvoicesDashboard.html',
                        controller: 'InvoicesDashboardCtrl'
                    })
                    .when('/workRecordsHome/:workRecordId/expense', {
                        templateUrl: 'app/components/workRecords/workRecordsExpenseList.html',
                        controller: 'workRecordsExpenseListCtrl'
                    })
                    .when('/GenerateWRForRecurringSchedule', {
                        templateUrl: 'app/components/workRecords/workRecordGenerationView.html',
                        controller: 'workRecordGenerationViewCtrl'
                    })

                    //Recurring Services
                    .when('/RecurringServices', {
                        templateUrl: 'app/components/recurringServices/RecurringServicesIndexView.html',
                        controller: 'RecurringServicesIndexCtrl'
                    })
                    .when('/RecurringServices/:recurringServiceId', {
                        templateUrl: 'app/components/recurringServices/RecurringServiceFormView.html',
                        controller: 'RecurringServiceFormCtrl'
                    })
                    .when('/RecurringSchedule/:recurringScheduleId', {
                        templateUrl: 'app/components/recurringServices/EditRecurringScheduleView.html',
                        controller: 'EditRecurringScheduleCtrl'
                    })
                    .when('/RecurringSchedule/:recurringServiceScheduleId/:recurringServiceId/:locationId', {
                        templateUrl: 'app/components/recurringServices/CreateRecurringScheduleView.html',
                        controller: 'CreateRecurringScheduleCtrl'
                    })
                    .when('/RecurringSchedule/:recurringScheduleId/Attachments', {
                        templateUrl: 'app/components/recurringServices/AttachmentsView.html',
                        controller: 'AttachmentsCtrl'
                    })
                    .when('/RecurringServices/:recurringServiceId/:recurringServiceScheduleId/:scheduleId/schedule', {
                        templateUrl: 'app/components/recurringServices/recurringServicesScheduleView.html',
                        controller: 'recurringServicesScheduleViewCtrl'
                    })
                    .when('/RecurringSchedule/:recurringScheduleId/Notes', {
                        templateUrl: 'app/components/recurringServices/ScheduleNotesList.html',
                        controller: 'ScheduleNotesListCtrl'
                    })
                    //Contracts
                    .when('/Contracts/CreateContract', {
                        templateUrl: 'app/components/contracts/CreateContract.html',
                        controller: 'CreateContractCtrl'
                    })
                    .when('/Contracts/CreateContract/:vendorId', {
                        templateUrl: 'app/components/contracts/CreateContract.html',
                        controller: 'CreateContractCtrl'
                    })
                    .when('/locations/:clientId/:locationId/Contracts/CreateContract', {
                        templateUrl: 'app/components/contracts/CreateContract.html',
                        controller: 'CreateContractCtrl'
                    })
                    .when('/Contracts/EditContract/:contractId', {
                        templateUrl: 'app/components/contracts/EditContract.html',
                        controller: 'EditContractCtrl'
                    })
                    .when('/Contracts/EditContract/:contractId/Locations', {
                        templateUrl: 'app/components/contracts/EditContractLocations.html',
                        controller: 'EditContractLocationsCtrl'
                    })
                    .when('/Contracts/EditContract/:contractId/Services', {
                        templateUrl: 'app/components/contracts/EditContractServices.html',
                        controller: 'EditContractServicesCtrl'
                    })
                    .when('/Contracts/EditContract/:contractId/Lines', {
                        templateUrl: 'app/components/contracts/EditContractLines.html',
                        controller: 'EditContractLinesCtrl'
                    })
                    .when('/Contracts/EditContract/:contractId/Lines/NewLine/:locationId/:serviceId', {
                        templateUrl: 'app/components/contracts/EditContractLine.html',
                        controller: 'EditContractLineCtrl'
                    })
                    .when('/Contracts/EditContract/:contractId/Lines/EditLine/:contractLineId', {
                        templateUrl: 'app/components/contracts/EditContractLine.html',
                        controller: 'EditContractLineCtrl'
                    })                    
                    .when('/Contracts', {
                        templateUrl: 'app/components/contracts/ContractsIndexView.html',
                        controller: 'ContractsIndexCtrl'
                    })
                    .when('/Contracts/ViewContract/:contractId', {
                        templateUrl: 'app/components/contracts/ViewContract.html',
                        controller: 'ViewContractCtrl'
                    })
                    .when('/Contracts/PendingContractNTEApprovals', {
                        templateUrl: 'app/components/contracts/ContractNTEApprovalList.html',
                        controller: 'ContractNTEApprovalListCtrl'
                    })

                     //IMPORTS (BULK IMPORTS)

                    .when('/Imports/DownloadImportTemplates', {
                        templateUrl: 'app/components/imports/DownloadImportTemplatesList.html',
                        controller: 'DownloadImportTemplatesCtrl'
                    })
                    .when('/Imports/UploadImportFile', {
                        templateUrl: 'app/components/imports/UploadImportFileView.html',
                        controller: 'UploadImportFileCtrl'
                    })

                    //EMAIL TEMPLATES

                    .when('/NotificationTypes/Client/:clientId/List', {
                        templateUrl: 'app/components/notificationTypes/notificationTypesIndexView.html',
                        controller: 'notificationTypesIndexCtrl'
                    })
                    .when('/NotificationTypes/Client/:clientId/Edit/:notificationTypeId', {
                        templateUrl: 'app/components/notificationTypes/notificationTypesFormView.html',
                        controller: 'notificationTypesFormCtrl'
                    })
                ;
                $routeProvider.otherwise({ redirectTo: '/not-found' });
            }]);
})();