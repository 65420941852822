(function () {
    angular.module('springwise').controller('ContactFormCtrl', ContactFormCtrl);

    ContactFormCtrl.$inject = ['$scope', '$attrs', 'UtilityService'];

    function ContactFormCtrl($scope, $attrs, UtilityService) {
        var self = this;

        $scope.contact = new Contact();

        $scope.removeEmailInformation = removeEmailInformation;
        $scope.addEmailInformation = addEmailInformation;
        $scope.removePhoneInformation = removePhoneInformation;
        $scope.addPhoneInformation = addPhoneInformation;
        $scope.disabled = false;

        this.init = init;

        function init(element) {
            self.$element = element;
        }

        function removeEmailInformation(index) {
            UtilityService.removeElementFromCollection($scope.contact.emailInformations, index);
        }
        function addEmailInformation() {
            $scope.contact.emailInformations.push({
            });
        }
        function removePhoneInformation(index) {
            UtilityService.removeElementFromCollection($scope.contact.phoneInformations, index);
        }
        function addPhoneInformation() {
            $scope.contact.phoneInformations.push({
            });
        }
    }
})();