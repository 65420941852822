(function () {
    angular.module('springwise').controller('TableSearchFormCtrl', TableSearchFormCtrl);

    TableSearchFormCtrl.$inject = ['$scope', '$attrs'];

    function TableSearchFormCtrl($scope, $attrs) {
        var self = this;

        this.init = init;

        //$scope.$watch('roles', function () {
        //    alert('roles have changed');
        //});

        function init(element, scope) {
            self.$element = element;
            scope.groups = getSearchGroups(scope);
            scope.submitFitsInGroup = canSubmitFitInGroup(scope);
        }

        function getSearchGroups(scope) {
            if (scope.searchFields) {
                var all = [];
                var i = 0;
                var group = [];
                angular.forEach(scope.searchFields, function (obj) {
                    if (i % 4 === 0) {
                        if (group.length > 0) {
                            all.push(group);
                        }
                        group = [];
                    }

                    group.push(obj);
                    i++;
                });
                if (group.length > 0) {
                    all.push(group);
                }
                //var l = $scope.searchFields.length;
                //while (i < l) {
                //    var res = [];
                //    var x = i;
                //    for (var j = x; j < x + 4 && i < l; j++) {
                //        res.push($scope.searchFields[j]);
                //        i++;
                //    }
                //    all.push(res);
                //}

                return all;
            } else {
                return [];
            }
        }

        function canSubmitFitInGroup(scope) {
            if (scope.searchFields) {
                return scope.searchFields.length % 4 !== 0;
            } else {
                return false;
            }
        }
    }
})();