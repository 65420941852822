(function () {
    'use strict';
    
    angular
        .module('springwise')
        .controller('workRecordsNoteListCtrl', workRecordsNoteListCtrl);

    function workRecordsNoteListCtrl($scope, $routeParams, WorkRecordsFactory, CurrentUserFactory, UtilityService, HelperFactory) {
        $scope.workRecordId = $routeParams.workRecordId;
        $scope.editMode = false;
        $scope.submitNote = submitNote;
        $scope.cancelNote = cancelNote;
        getWorkRecord();
        getWorkRecordNoteList();
        
        function getWorkRecord() {
            WorkRecordsFactory.get({ id: $scope.workRecordId }).$promise.then(function (w) {
                $scope.referenceNumber = w.referenceNumber;
            });
        }

        function getWorkRecordNoteList() {
            WorkRecordsFactory.getWorkRecordNoteList({ workRecordId: $scope.workRecordId }).$promise.then(function (response) {
                $scope.workRecordNoteList = response;
            }, UtilityService.onRequestError);
        }

        //Add new note
        $scope.addNote = function () {
            if ($scope.editMode) {                
                var modalWindow = HelperFactory.openMessageModalWindow('Make sure to save or cancel any rows in the table before adding another Note.', 'Item in edition');
                return;
            }

            var wrn = new WorkRecordNote();
            wrn.workRecordId = $scope.workRecordId;
            wrn.editMode = true;
            wrn.isVendorCreator = $scope.isVendorCreator;
            wrn.isClientCreator = $scope.isClientCreator;
            wrn.isSpringwiseCreator = $scope.isSpringwiseCreator;

            $scope.workRecordNoteList.push(wrn);
            $scope.editMode = true;
        };

        //Save new note
        function submitNote(wrn) {
            var req = {
                workRecordNoteId: wrn.workRecordNoteId,
                note: wrn.editedNote,
                workRecordId: wrn.workRecordId
            };

            if (wrn.isNew) {
                WorkRecordsFactory.saveWorkRecordNote(req).$promise.then(onAddSucceded, rollbackSubmit);
            }

            function onAddSucceded() {
                wrn.editMode = false;
                wrn.isNew = false;
                wrn.note = wrn.editedNote;
                
                getWorkRecordNoteList();

                $scope.editMode = false;
            }

            function rollbackSubmit(error) {
                UtilityService.onRequestError(error);
                cancelNote(wrn);
            }
        }

        //Cancel new note
        function cancelNote(wrn) {
            if (wrn.isNew) {
                UtilityService.removeElementFromCollection($scope.workRecordNoteList, wrn);
            }
            getWorkRecordNoteList();
            $scope.editMode = false;
        }

        //Back
        $scope.goBack = function () {
            window.history.back();
        };
    }
})();

