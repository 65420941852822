(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('WorkRecordsHomeCtrl', WorkRecordsHomeCtrl);

    function WorkRecordsHomeCtrl($scope, $routeParams, CurrentUserFactory, UtilityService, permissions) {
        $scope.isVendor = false;
        $scope.viewRecurringServices = false;
        $scope.workRecordsGeneration = false;
        $scope.viewInvoices = false;

        if (CurrentUserFactory.exists()) {

            var currentUser = CurrentUserFactory.get();

            if (currentUser.vendor)
                $scope.isVendor = true;

            $scope.viewRecurringServices = currentUser.permissions.indexOf(permissions.viewRecurringServices) !== -1;
            $scope.workRecordsGeneration = currentUser.permissions.indexOf(permissions.workRecordsGeneration) !== -1;
            $scope.viewInvoices = currentUser.permissions.indexOf(permissions.viewInvoices) !== -1;
        }
    }
})();