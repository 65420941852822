var VendorService = function () {
	this.vendorId = null;
	this.serviceId = null;
	this.category = '';
	this.subCategory = '';
	this.name = '';
	this.cover = true;
	this.percentage = 0;
	this.techRateHr = 0;
	this.helperRateHr = 0;
	this.editedPercentage = 0;
	this.editedTechRateHr = 0;
	this.editedHelperRateHr = 0;
	this.editMode = true;
	this.isNew = true;
};