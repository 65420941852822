(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('workRecordsFormStepOneCtrl', workRecordsFormStepOneCtrl);

    workRecordsFormStepOneCtrl.$inject = [
        '$scope',
        'CurrentUserFactory',
        'ClientsFactory',
        'RegionsFactory',
        'DistrictsFactory',
        'LocationsFactory',
        'permissions',
        'WorkRecordsFactory',
        '$location',
        'UtilityService',
        '$routeParams',
        'EmergencyTypesFactory',
        'workRecordsStages',
        'workRecordsStatuses',
        'QuestionsService'];

    function workRecordsFormStepOneCtrl(
        $scope,
        CurrentUserFactory,
        ClientsFactory,
        RegionsFactory,
        DistrictsFactory,
        LocationsFactory,
        permissions,
        WorkRecordsFactory,
        $location,
        UtilityService,
        $routeParams,
        EmergencyTypesFactory,
        workRecordsStages,
        workRecordsStatuses,
        QuestionsService) {

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
            $scope.isClient = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;

            if ($scope.currentUser.regionId) {
                $scope.hasRegion = true;
                $scope.savedRegionId = $scope.currentUser.regionId;
            } else {
                $scope.hasRegion = false;
            }

            if ($scope.currentUser.districtId) {
                $scope.hasDistrict = true;
                $scope.savedDistrictId = $scope.currentUser.districtId;
            } else {
                $scope.hasDistrict = false;
            }

            if ($scope.currentUser.locationId) {
                $scope.savedLocationId = $scope.currentUser.locationId;
            }

        } else {
            $scope.isClient = false;
            $scope.currentUser = null;
            $scope.hasRegion = false;
            $scope.hasDistric = false;
        }

        $scope.saveWorkRecord = saveWorkRecord;
        $scope.advanceWorkRecord = advanceWorkRecord;
        $scope.continueWR = continueWR;

        $scope.swValidation = new SWValidation();

        $scope.masterData = [];
        $scope.masterData.clients = [];
        $scope.masterData.regions = [];
        $scope.masterData.districts = [];
        $scope.masterData.locations = [];
        $scope.masterData.emergencyTypes = [];

        $scope.wr = new WorkRecord();
        $scope.wr.priorityLevel = {};
        $scope.questionnaireExists = false;
        $scope.wrAlreadyExists = false;

        $scope.$watch('clientIDSelected', onUserClientChange);
        $scope.$watch('regionIDSelected', onUserRegionChange);
        $scope.$watch('districtIDSelected', onUserDistrictChange);

        activate();

        function activate() {
            if ($routeParams.workRecordId) {
                $scope.wrAlreadyExists = true;

                WorkRecordsFactory.get({ id: $routeParams.workRecordId }).$promise.then(function (wr) {
                    $scope.wr = wr;
                    $scope.wr.id = $routeParams.workRecordId;
                    $scope.savedClientId = wr.clientId;
                    $scope.savedEmergencyTypeId = wr.emergencyType.emergencyTypeId;
                    $scope.savedLocationId = wr.locationId;
                    $scope.savedRegionId = wr.regionId;
                    $scope.savedDistrictId = wr.districtId;

                    
                }, UtilityService.onRequestError);
            } else {

                ClientsFactory.queryOptions().$promise.then(function (clients) {
                    $scope.masterData.clients = clients;
                    if ($scope.savedClientId) {
                        $scope.clientIDSelected = $scope.savedClientId;
                    } else if ($scope.isClient) {
                        if ($scope.currentUser.client.clientId) {
                            $scope.clientIDSelected = $scope.currentUser.client.clientId;
                        }
                    }
                    
                });
            }
        }

        function onUserClientChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.districtIDSelected = null;
                $scope.locationIDSelected = null;

                if (newValue) {
                    EmergencyTypesFactory.queryClientOptions({ id: newValue }).$promise.then(function (emergencyTypesOptions) {
                        $scope.masterData.emergencyTypes = [new EmergencyTypeOption()].concat(emergencyTypesOptions);
                        $scope.emergencyTypeIDSelected = null;
                    });


                    RegionsFactory.queryOptions({ clientId: newValue }).$promise.then(function (regions) {
                        $scope.masterData.regions = regions;
                        if ($scope.currentUser && $scope.currentUser.region && $scope.currentUser.region.id) {
                            $scope.regionIDSelected = $scope.currentUser.region.id;
                        }
                        else if ($scope.currentUser.regionId) {
                            $scope.regionIDSelected = $scope.currentUser.regionId;
                        } else if ($scope.savedRegionId)
                            $scope.regionIDSelected = $scope.savedRegionId;

                        if ($scope.savedEmergencyTypeId) {
                            $scope.emergencyTypeIDSelected = $scope.savedEmergencyTypeId;
                        }
                    });
                }
                else {
                    $scope.masterData.regions = null;
                }
            }
        }

        function onUserRegionChange(newValue, oldValue) {
            $scope.districtIDFilter = new DistrictOption();
            $scope.locationIDFilter = new LocationOption();

            if (newValue !== oldValue) {
                $scope.locationIDSelected = null;
                if (newValue) {
                    DistrictsFactory.queryOptionsByRegion({ regionId: newValue }).$promise.then(function (districts) {
                        $scope.masterData.districts = districts;
                        if ($scope.currentUser && $scope.currentUser.district) {
                            $scope.districtIDSelected = $scope.currentUser.district.id;
                        }
                        else if ($scope.currentUser.districtId) {
                            $scope.districtIDSelected = $scope.currentUser.districtId;
                        }
                    });
                    $scope.districtIDSelected = $scope.savedDistrictId;
                }
                else
                    $scope.masterData.districts = null;
            }
        }

        function onUserDistrictChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    LocationsFactory.queryOptionsByDistrict({ districtId: newValue }).$promise.then(function (locations) {
                        $scope.masterData.locations = locations;
                        if ($scope.currentUser.location) {
                            $scope.locationIDSelected = $scope.currentUser.location.locationId;
                        }
                        else if ($scope.currentUser.locationId) {
                            $scope.locationIDSelected = $scope.currentUser.locationId;
                        }
                    });
                    $scope.locationIDSelected = $scope.savedLocationId;
                }
                else
                    $scope.masterData.locations = null;
            }
        }

        function saveWorkRecord(form) {

            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            $scope.swValidation.end();
            if ($scope.swValidation.hasErrors())
                return;

            $scope.wr.locationId = $scope.locationIDSelected;
            $scope.wr.clientId = $scope.clientIDSelected;
            $scope.wr.serviceId = $scope.serviceIDSelected;
            if ($scope.wr.emergencyType.id)
                $scope.wr.emergencyType.emergencyTypeId = $scope.wr.emergencyType.id;
            $scope.wr.stageId = workRecordsStages.ServiceSelectionStage.stageId;
            $scope.wr.statusId = workRecordsStatuses.InProgressStatus.statusId;
            $scope.wr.emergencyType.emergencyTypeId = $scope.emergencyTypeIDSelected;


            WorkRecordsFactory.save($scope.wr).$promise.then(function (wr) {
                $scope.wr = wr;
                advanceWorkRecord();
            }, UtilityService.onRequestError);
        }

        function advanceWorkRecord() {
            if ($scope.wr.questionnaireId > 0) {
                $scope.wr.stageId = workRecordsStages.TriageQuestionnaireStage.stageId;
                WorkRecordsFactory.advanceStep({ id: $scope.wr.id, step: 1 }, $scope.wr).$promise.then(onAdvanceSucceded, UtilityService.onRequestError);
            } else {
                $scope.wr.stageId = workRecordsStages.WorkRecordCreationStage.stageId;
                WorkRecordsFactory.advanceStep({ id: $scope.wr.id, step: 2 }, $scope.wr).$promise.then(onAdvanceSucceded, UtilityService.onRequestError);
            }

            function onAdvanceSucceded() {
                if ($scope.wr.questionnaireId > 0) {
                    $location.path('/workRecordsHome/' + $scope.wr.id + '/edit/stepTwo');
                } else {
                    $location.path('/workRecordsHome/' + $scope.wr.id + '/edit/stepThree');
                } 
            }
        }

        function continueWR() {
            if ($scope.wr.questionnaireId > 0) {
                $location.path('/workRecordsHome/' + $scope.wr.id + '/edit/stepTwo');
            } else {
                $location.path('/workRecordsHome/' + $scope.wr.id + '/edit/stepThree');
            }
        }
    }
})();