(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('resetPasswordCtrl', resetPasswordCtrl);

    resetPasswordCtrl.$inject = ['$location']; 

    function resetPasswordCtrl($location) {
        /* jshint validthis:true */
        var vm = this;
        vm.title = 'resetPasswordCtrl';

        activate();

        function activate() { }
    }
})();
