(function () {
    angular.module('springwise').directive('swPatternError', swPatternError);

    swPatternError.$inject = [];

    function swPatternError() {
        return {
            templateUrl: '/app/shared/directives/errors/pattern/patternErrorView.html',
            restrict: 'E',
            scope: {
                field: '=field',
                example: '@example',
                validation: '='
            }
        };
    }
})();