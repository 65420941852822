(function () {
    angular.module('springwise').controller('ServicesIndexCtrl', ServicesIndexCtrl);

    ServicesIndexCtrl.$inject = ['$scope', '$routeParams', '$location', '$window', 'ServicesService', 'CategoryTypesService', 'CurrentUserFactory', 'permissions', 'ImportsFactory', 'UtilityService', 'ngAuthSettings', 'HelperFactory'];

    function ServicesIndexCtrl($scope, $routeParams, $location, $window, ServicesService, CategoryTypesService, CurrentUserFactory, permissions, ImportsFactory, UtilityService, ngAuthSettings, HelperFactory) {
        init();

        function init() {
            $scope.description = '';
            $scope.keyTerm = '';
            $scope.foundServices = [];
            $scope.findInputText = 'Find';
            $scope.clientId = $routeParams.clientId === undefined ? null : $routeParams.clientId;
            $scope.serviceTreeOptions = getServiceTreeOptions();
            $scope.services = [];
            $scope.categoryTypes = CategoryTypesService.categoryTypes;
            $scope.isSpringwiseUser = CurrentUserFactory.isSpringwise();
            $scope.isClientUser = CurrentUserFactory.isClient();
            $scope.canCreateServices = CurrentUserFactory.canCreateServices();
            $scope.canEditServices = CurrentUserFactory.canEditServices();
            $scope.toggleService = toggleService;
            $scope.newChild = newChild;
            $scope.newCategory = newCategory;
            $scope.findService = findService;
            $scope.activate = activate;
            $scope.deactivate = deactivate;
            $scope.edit = edit;
            $scope.createOrUpdate = createOrUpdate;
            $scope.cancelCreateOrUpdate = cancelCreateOrUpdate;
            $scope.questions = questions;
            $scope.exportServices = exportServices;
            $scope.getImportTemplate = getImportTemplate;
            $scope.deleteThis = deleteThis;

            $scope.$watch('description', onSearchInputChange);
            $scope.$watch('keyTerm', onSearchInputChange);

            queryServices();
        }

        function assignRootServices(services) {
            $scope.services = services;
        }

        function newChild(scope) {
            var nodeScope = scope.$nodeScope;
            if (nodeScope.collapsed) {
                $scope.toggleService(scope, addNewChild);
            } else {
                addNewChild(scope);
            }

            function addNewChild(scope) {
                var service = scope.$nodeScope.$modelValue;
                if (!service.children) {
                    service.children = [];
                }
                service.children.unshift({
                    editMode: true,
                    parentId: service.id,
                    categoryTypeId: CategoryTypesService.getChild(service.categoryTypeId).id
                });                
            }
        }

        function newCategory() {
            var category = { editMode: true, editedKeyTerm: 'category' };
            $scope.services.unshift(category);
        }

        function onSearchInputChange() {
            $scope.findInputText = 'Find';
            $scope.foundServices = [];
        }

        function findService() {
            var search = {
                description: $scope.description,
                keyTerm: $scope.keyTerm,
                excluding: foundServices
            };

            ServicesService.find(search).$promise.then(onFindComplete, onRequestError);

            function onFindComplete(found) {
                $scope.findInputText = 'Find Next';
            }
        }

        function getServiceTreeOptions() {
            return {};
        }

        function queryServices() {
            ServicesService.query().$promise.then(assignRootServices);
        }

        function toggleService(scope, callback) {
            var nodeScope = scope.$nodeScope;
            if (nodeScope.collapsed) {
                var service = scope.$nodeScope.$modelValue;
                if ($scope.clientId) {
                    ServicesService.getByClient({id:service.id, clientId:$scope.clientId}).$promise.then(onGetSucceded, onRequestError);
                } else {
                    ServicesService.get({id:service.id}).$promise.then(onGetSucceded, onRequestError);
                }
            } else {
                scope.toggle();
            }

            function onGetSucceded(res) {
                nodeScope.$modelValue.children = res.children;
                scope.toggle();
                if (callback) {
                    callback(scope);
                }
            }
        }

        function activate(scope) {
            var service = scope.$nodeScope.$modelValue;
            if ($scope.clientId) {
                var req = getByClientRequest(service);
                ServicesService.activateByClient(req).$promise.then(onActiveSucceded, onRequestError);
            }
            else {
                ServicesService.activate(service).$promise.then(onActiveSucceded, onRequestError);
            }
            
            function onActiveSucceded() {
                scope.$nodeScope.$modelValue.active = true;
            }
        }

        function deactivate(scope) {
            var service = scope.$nodeScope.$modelValue;

            if ($scope.clientId) {
                var req = getByClientRequest(service);
                ServicesService.deactivateByClient(req).$promise.then(onDeactivateSucceded, onRequestError);
            } else {
                ServicesService.deactivate(service).$promise.then(onDeactivateSucceded, onRequestError);
            }

            function onDeactivateSucceded(res) {
                service.active = res.active;
                service.id = res.id;
            }
        }

        function edit(scope) {
            var service = scope.$nodeScope.$modelValue;
            service.editMode = true;
            service.editedDescription = service.description;
            service.editedKeyTerm = service.keyTerm;
            service.editedNTEValue = service.nteValue;
        }

        function createOrUpdate(scope) {
            var service = scope.$nodeScope.$modelValue;
            var editedValue = (service.categoryTypeId === 1) ? 'category' : ((service.categoryTypeId === 2) ? service.editedNTEValue : service.editedKeyTerm);
            
            if (service.editedDescription && editedValue) {
                service.description = service.editedDescription;
                service.keyTerm = (service.editedKeyTerm === undefined || service.editedKeyTerm === null) ? '' : service.editedKeyTerm;
                service.nteValue = (service.editedNTEValue === undefined || service.editedNTEValue === null) ? 0 : service.editedNTEValue;

                if ($scope.clientId) {
                    var req = getByClientRequest(service);

                    if (service.id) {
                        ServicesService.updateByClient(req).$promise.then(onUpdateComplete, onRequestError);
                    } else {
                        ServicesService.saveByClient(req).$promise.then(onCreateComplete, onRequestError);
                    }
                } else {
                    if (service.id) {
                        ServicesService.update(service).$promise.then(onUpdateComplete, onRequestError);
                    } else {
                        ServicesService.save(service).$promise.then(onCreateComplete, onRequestError);
                    }
                }
            } else {
                var modalWindow = HelperFactory.openMessageModalWindow('The "Description" and "KeyTerm"/"NTE Value" fields should be not empty.', 'Error');
            }

            function onCreateComplete(newService) {
                service.id = newService.id;
                service.editMode = false;
                service.categoryTypeId = newService.categoryTypeId;
                service.hasChildren = newService.hasChildren;
                service.active = newService.active;
            }

            function onUpdateComplete(res) {
                service.id = res.id;
                service.editMode = false;
            }
        }

        function onRequestError(error) {
            var msg = '';
            if (error.status === 403) {
                msg = 'You are not allowed to perform this operation.\nPlease, contact the system administrator.';
            } else if (error.status === 500) {
                msg = error.data.exceptionMessage;
            } else if (error.status === 400) {
                if (error.data.modelState.form) {
                    msg = error.data.modelState.form[0];
                }
            } else {
                msg = 'There was an error on the operation.\.Please, contact the system administrator.';
            }
            var modalWindow = HelperFactory.openMessageModalWindow(msg, 'Error');
        }

        function cancelCreateOrUpdate(scope) {
            var service = scope.$nodeScope.$modelValue;
            if (!service.id) {
                scope.$nodeScope.remove();
            } else {
                service.editMode = false;
            }
        }

        function getByClientRequest(service) {
            service.clientId = $scope.clientId;
            return service;
        }

        function questions(scope) {
            var service = scope.$nodeScope.$modelValue;

            if ($scope.clientId) {
                $location.path('/clients/' + $scope.clientId + '/services/' + service.id + '/questionnaire');
            } else {
                $location.path('/services/' + service.id + '/questionnaire');
            }
        }

        function getImportTemplate() {
            var params = {
                templateType: 8,
                clientId: $scope.clientId
            };

            ImportsFactory.api.getImportTemplate(params).$promise.then(function (msg) {
                if (!msg.hasError) {
                    addAlert(msg.errorMessage, 'success');
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetFile/' + fileName[0] + '/' + fileName[1];
                    $window.open(link, "", "");
                }
                else {
                    addAlert(msg.errorMessage, 'danger');
                }

            }, UtilityService.onRequestError);
        }

        function exportServices() {
            ServicesService.exportServices({ clientId: ($scope.clientId === null ? 0 : $scope.clientId) }).$promise.then(function (msg) {
                if (!msg.hasError) {
                    addAlert(msg.errorMessage, 'success');
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetExportedFile/' + fileName[0] + '/' + fileName[1];
                    $window.open(link, "", "");
                }
                else {
                    addAlert(msg.errorMessage, 'danger');
                }

            }, UtilityService.onRequestError);
        }

        $scope.alerts = [];
        function addAlert(message, type) {
            $scope.alerts.push({ message: message, type: type });
        }

        function deleteThis(scope) {
            var service = scope.$nodeScope.$modelValue;
            var modalWindow = HelperFactory.openAcceptCancelModalWindow('The Category or Service is going to be Deleted Permanently. Do you want to proceed?', 'Confirmation');
            modalWindow.result.then(function () {

                removeThis(service);

            }, function () {
            });
        }

        function removeThis(service) {
            var description = service.description;
            ServicesService.delete({ id: service.id }).$promise.then(function (service) {
                var modalWindow = HelperFactory.openMessageModalWindow('The Category or Service "' + description + '" has been deleted.', 'Deleted');
                init();
            }, UtilityService.onRequestError);
        }
    }
})();