(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('ChangePasswordCtrl', ChangePasswordCtrl);

    ChangePasswordCtrl.$inject = ['$location', '$routeParams', '$scope', 'UsersFactory', 'CurrentUserFactory', 'UtilityService', 'AuthFactory'];

    function ChangePasswordCtrl($location, $routeParams, $scope, UsersFactory, CurrentUserFactory, UtilityService, AuthFactory) {

        //VARIABLE DEFINITION & INITIALIZATION

        $scope.swValidationPassword = new SWValidation();
        $scope.swValidationQuestions = new SWValidation();

        $scope.user = {
            secAnswers: [],
            newPassword: {},
            confirmNewPassword: {},
        };
        $scope.secAnswerResonse = {
            answer: "",
            secQuestion: {}
        };
        $scope.path = $location.path();
        $scope.toValidateSecAnswers = [];
        $scope.masterData = {};
        $scope.masterData.secQuestions = [];
        $scope.secQuestionsAnswered = false;

        $scope.userId = CurrentUserFactory.get().userId;

        //CHANGE PASSWORD FUNC
        $scope.changePassword = function (form) {

            $scope.swValidationPassword.start();
            $scope.swValidationPassword.checkForm(form);
            if ($scope.user.newPassword != $scope.user.confirmNewPassword) {
                UtilityService.openMessageModalWindow("Your password and confirmation does not match", "Change Password");                
            }
            $scope.swValidationPassword.end();
            if ($scope.swValidationPassword.hasErrors())
                return;

            UsersFactory.changePassword({ id: $scope.userId }, $scope.user).$promise.then(function (response) {
                if (response.success) {
                    AuthFactory.refreshToken().then(function (userContext) {
                        CurrentUserFactory.set(userContext);
                        UtilityService.openMessageModalWindow("Your Password has been successfully modified", "Change Password");
                        $scope.user.password.passwordRecord = '';
                        $scope.user.newPassword = '';
                        $scope.user.confirmNewPassword = '';
                    });
                }
                else if (response.currentPasswordInvalid) {
                    UtilityService.openMessageModalWindow("Your Old Password is incorrect", "Change Password");                    
                }
                else if (response.matchesPasswordHistory) {
                    UtilityService.openMessageModalWindow("You Cannot reuse last 3 passwords", "Change Password");                    
                }
            }, function (e) {
                if (e.status == 500) {
                    $scope.swValidationPassword.addAlert(null, e.data.exceptionMessage);
                }
            });
        };

        $scope.changeSecQuestions = function (form) {

            $scope.swValidationQuestions.start();
            $scope.swValidationQuestions.checkForm(form);
            $scope.swValidationQuestions.end();
            if ($scope.swValidationQuestions.hasErrors())
                return;

            UsersFactory.updSecQuestions({ id: $scope.userId }, $scope.user).$promise.then(function () {
                AuthFactory.refreshToken().then(function (userContext) {
                    CurrentUserFactory.set(userContext);
                    $scope.secQuestionsAnswered = true;
                    UtilityService.openMessageModalWindow("Your Security Questions have been successfully modified", "Change Security Questions");
                    // $scope.swValidationQuestions.addSuccess(null, "Your Security Questions have been successfully modified");
                    //$location.path('/');
                });
            });
        };

        //GO BACK BUTTON
        $scope.goBack = function () {
            $location.path('/users/profile');
        };


        activate();

        function activate() {
            //CHANGE PWD
            if ($scope.userId) {
                UsersFactory.getUserSecQuestions({ id: $scope.userId }).$promise.then(function (userSecQuestion) {
                    $scope.user = userSecQuestion;
                    
                    if ($scope.user.secAnswers.length === 0)
                    {
                        $scope.user.secAnswers.push({ "answer": "", "secQuestion": null });
                        $scope.user.secAnswers.push({ "answer": "", "secQuestion": null });
                        $scope.secQuestionsAnswered = false;
                    }
                    else {
                        $scope.secQuestionsAnswered = true;
                    }
                    $scope.user.password.passwordRecord = "";
                });
        }
            //COMMON
            UsersFactory.getAllSecQuestionsAvailable().$promise.then(function (allSecQuestions) {
                $scope.masterData.secQuestions = allSecQuestions;
            });
        }
    }
})();
