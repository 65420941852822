(function () {
    angular.module('springwise').controller('EditContractLocationsCtrl', EditContractLocationsCtrl);

    function EditContractLocationsCtrl($scope, $routeParams, CurrentUserFactory, ClientsFactory, UtilityService, ContractsFactory, CountriesFactory, StatesFactory) {
        $scope.contractId = $routeParams.contractId;
        $scope.limitOptions = [
            { id: 10, name: '10 results' },
            { id: 20, name: '20 results' },
            { id: 30, name: '30 results' },
            { id: 40, name: '40 results' }
        ];
        $scope.limit = $scope.limitOptions[0];
        $scope.page = 1;
        $scope.locationsFilter = {
            contractId: $routeParams.contractId,
            countryId: null,
            stateId: null,
            zipCode: null
        };
        $scope.onPaginationChange = function (page) {
            $scope.page = page;
            $scope.searchLocations();
        };
        function onPageLimitChange (newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                $scope.page = 1;
                $scope.searchLocations();
            }
        }
        $scope.$watch('limit', onPageLimitChange);

        $scope.limitContract = $scope.limitOptions[0];
        $scope.pageContract = 1;
        $scope.onPaginationChangeContract = function (page) {
            $scope.pageContract = page;
            $scope.searchLocations();
        };
        function onPageLimitChangeContract(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                $scope.pageContract = 1;
                $scope.searchLocations();
            }
        }
        $scope.$watch('limitContract', onPageLimitChangeContract);

        function getParams() {
            var params = angular.copy($scope.locationsFilter);
            params.page = $scope.page;
            params.limit = $scope.limit.id;
            params.pageContractLocations = $scope.pageContract;
            params.limitContractLocations = $scope.limitContract.id;
            return params;
        }

        $scope.searchLocations = function () {
            var params = getParams();
            ContractsFactory.api.getLocationsForEdition(params).$promise.then(function (response) {
                setResponse(response);
            }, UtilityService.onRequestError);
        };

        $scope.addLocation = function(locationId) {
            var params = getParams();
            params.locationId = locationId;
            ContractsFactory.api.addLocation(null, params).$promise.then(function (response) {
                setResponse(response);
            }, UtilityService.onRequestError);
        };

        $scope.removeLocation = function (locationId) {
            var params = getParams();
            params.locationId = locationId;
            ContractsFactory.api.removeLocation(null, params).$promise.then(function (response) {
                setResponse(response);
            }, UtilityService.onRequestError);
        };

        function setResponse(response) {
            $scope.response = response;
        }

        CountriesFactory.query().$promise.then(function (countries) {
            $scope.countries = [{ countryId: null, name: '' }].concat(countries);
        }, UtilityService.onRequestError);
        StatesFactory.query().$promise.then(function (states) {
            $scope.states = [{ stateId: null, name: '' }].concat(states);
        }, UtilityService.onRequestError);

        $scope.searchLocations();
    }
})();