(function () {
    angular.module('springwise').controller('RecurringServicesIndexCtrl', RecurringServicesIndexCtrl);

    function RecurringServicesIndexCtrl($scope, $routeParams, $location, RecurringServicesFactory, UtilityService, ClientsFactory, CurrentUserFactory, ServicesService, permissions) {

        var currentUser = CurrentUserFactory.get();
        $scope.createEditRecurringServices = currentUser.permissions.indexOf(permissions.createEditRecurringServices) !== -1;

        $scope.categoryId = null;
        $scope.subCategoryId = null;
        $scope.categories = [];
        $scope.subCategories = [];
        $scope.$watch('categoryId', onCategoryIdChange);

        $scope.page = 1;
        $scope.limitOptions = [
                { id: 10, name: '10 results' },
                { id: 20, name: '20 results' },
                { id: 30, name: '30 results' },
                { id: 40, name: '40 results' }
        ];
        $scope.limit = $scope.limitOptions[0];
        $scope.onPaginationChange = onPaginationChange;
        $scope.$watch('limit', onPageLimitChange);

        function onCategoryIdChange(newVal, oldVal) {
            if (newVal && $scope.clientId) {
                ServicesService.getByClient({ id: $scope.categoryId, clientId: $scope.clientId }).$promise.then(function (category) {
                    $scope.subCategories = [{id: null, description:''}].concat(category.children);
                }, UtilityService.onRequestError);
            }
            else
                $scope.subCategories = [];
        }

        function search() {
            if (!$scope.clientId)
                return;

            var params = {
                page: $scope.page,
                limit: $scope.limit.id,
                clientId: $scope.clientId,
                categoryId: $scope.categoryId,
                subCategoryId: $scope.subCategoryId
            };
            RecurringServicesFactory.api.getRecurringServices(params).$promise.then(function (response) {
                $scope.response = response;
            }, UtilityService.onRequestError);
        }

        function onPaginationChange(page) {
            $scope.page = page;
            search();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                $scope.page = 1;
                search();
            }
        }

        $scope.editRecurringServiceClick = function (service) {
            if (service.recurringServiceId) {
                $location.path('/RecurringServices/' + service.recurringServiceId);
            }
            else {
                RecurringServicesFactory.api.createRecurringService(null, { clientId: $scope.clientId, serviceId: service.serviceId }).$promise.then(function (response) {
                    $location.path('/RecurringServices/' + response.recurringServiceId);
                }, UtilityService.onRequestError);
            }
        };

        $scope.searchClick = function() {
            $scope.page = 1;
            search();
        };

        ServicesService.query().$promise.then(function (categories) {
            $scope.categories = [{ id: null, description: '' }].concat(categories);
        }, UtilityService.onRequestError);

        if (CurrentUserFactory.isClient()) {
            $scope.isClient = true;
            $scope.clientId = CurrentUserFactory.get().client.clientId;
            $scope.isClientName = CurrentUserFactory.get().client.name;
            search();
        }
        else {
            $scope.isClient = false;
            $scope.clientId = null;
            ClientsFactory.queryOptions().$promise.then(function (clients) {
                $scope.clients = clients;
            }, UtilityService.onRequestError);
        }
    }
})();