(function () {
    angular.module('springwise').directive('contractTabs', contractTabs);

    function contractTabs() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/components/contracts/tabs.html',
            controller: "contractTabsCtrl",
            scope: {
                contractId: '=',
                currentTab: '='
            },
            link: function ($scope, element, attrs, ctrl) {
            }
        };
    }

    angular.module('springwise').controller("contractTabsCtrl", contractTabsCtrl);

    function contractTabsCtrl($scope) {
        $scope.$watch('contractId', function (newValue, oldValue) {
            if ($scope.contractId) {
                var pathBase = '/Contracts/EditContract/' + $scope.contractId;
                $scope.links = [
                    { path: pathBase, name: 'Main' },
                    { path: pathBase + '/Locations', name: 'Locations' },
                    { path: pathBase + '/Services', name: 'Services' },
                    { path: pathBase + '/Lines', name: 'Lines' }
                ];
            }
        });
    }
})();