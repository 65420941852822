(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('workRecordsFormStepFourCtrl', workRecordsFormStepFourCtrl);

    workRecordsFormStepFourCtrl.$inject = ['$scope',
        'CurrentUserFactory',
        'ClientsFactory',
        'permissions',
        'WorkRecordsFactory',
        '$location',
        'UtilityService',
        'workRecordsStages',
        'workRecordsStatuses',
        '$routeParams',
        'LocationsFactory',
        'VendorsFactory',
        'LocationServicesVendorsService',
        'WorkRecordService',
        '$route'];

    function workRecordsFormStepFourCtrl($scope,
        CurrentUserFactory,
        ClientsFactory,
        permissions,
        WorkRecordsFactory,
        $location,
        UtilityService,
        workRecordsStages,
        workRecordsStatuses,
        $routeParams,
        LocationsFactory,
        VendorsFactory,
        LocationServicesVendorsService,
        WorkRecordService,
        $route) {

        var currentUser = CurrentUserFactory.get();

        $scope.saveWorkRecord = saveWorkRecord;
        $scope.continueWR = continueWR;

        $scope.wr = new WorkRecord();
        $scope.locationId = null;
        $scope.serviceId = null;
        $scope.vendorId = null;

        $scope.searchParams = {}; 
        $scope.addedVendor = [];
        $scope.wrAlreadyExists = false;
        $scope.showSelectedVendorInfo = false;
        $scope.limit = UtilityService.defaultLimitOption;
        $scope.vslPage = 1;
        $scope.vslLimit = UtilityService.defaultLimitOption;
        $scope.vslLimitOptions = UtilityService.limitOptions;       
        $scope.vslOrderBy = 'ranking';
        $scope.vslOrderByRequired = true;
        $scope.orderByRanking = true;
        $scope.orderByPercentage = true;
        $scope.saveSucceeded = false;
        $scope.wrVendorSelected = false;

        $scope.serviceByLocationVendors = [];

        $scope.vslIsDescending = false;
        $scope.setSearchParams = setSearchParams;
        $scope.addVendor = addVendor;
        $scope.selectVendor = selectVendor;
        $scope.getVendorServiceByLocationsInfo = getVendorServiceByLocationsInfo;
        $scope.onSearchVendorServiceByLocationsSucceded = onSearchVendorServiceByLocationsSucceded;

        activate();         

        function activate() {
            if ($routeParams.workRecordId) {
                WorkRecordsFactory.get({ id: $routeParams.workRecordId }).$promise.then(function (wr) {
                    $scope.wr = wr;
                    $scope.wr.id = $routeParams.workRecordId;
                    $scope.serviceId = wr.serviceId;
                    $scope.locationId = wr.locationId;
                    $scope.vendorId = wr.vendorId;

                    if ($scope.wr.stageId > workRecordsStages.VendorAssignmentStage.stageId) {
                        $scope.wrAlreadyExists = true;
                    }

                    setSearchParams();

                    if(wr.vendorId)
                    {                        
                        VendorsFactory.getVendorForClientView({ id: wr.vendorId }).$promise.then(selectVendor, UtilityService.onRequestError);
                        getVendorServiceByLocationsInfo();                        
                    }               
                    

                }, UtilityService.onRequestError);
            }
            
        }

        function getVendorServiceByLocationsInfo() {
            var params = {};

            params.serviceId = $scope.wr.serviceId;
            params.locationId = $scope.wr.locationId;
            params.vendorId = $scope.wr.vendorId;
            params.orderBy = $scope.vslOrderBy;
            params.page = 1;
            params.limit = $scope.limit.id;
            params.onlyRequired = false;
            params.considerNoContract = false;

            LocationServicesVendorsService.queryWRVendorSelection(params).$promise.then(onSearchVendorServiceByLocationsSucceded, UtilityService.onRequestError);
        }
      
        function onSearchVendorServiceByLocationsSucceded(vendorServiceByLocations) {
            $scope.serviceByLocationVendors = vendorServiceByLocations[0];
            $scope.showSelectedVendorInfo = true;
        }

        function setSearchParams() {
            LocationsFactory.get({ id: $scope.locationId }).$promise.then(onGetLocationSucceded, UtilityService.onRequestError);

            function onGetLocationSucceded(location) {
                $scope.searchParams = {
                    zipCode: { value: location.address.zipCode, disabled: true },
                    location: { value: { locationId: $scope.locationId }, disabled: true },
                    service: { value: { id: $scope.serviceId } },
                    workRecord: { value: { workRecordId: $scope.wr.id } },
                    considerNoContract: { value: true }
                };              
            }
        }

        function addVendor(vendor) {            
            $scope.wr.vendorId = vendor.vendorId;
            $scope.serviceByLocationVendors = vendor;                        
            VendorsFactory.getVendorForClientView({ id: vendor.vendorId }).$promise.then(selectVendor, UtilityService.onRequestError);
        }

        function selectVendor(vendors) {
            if (vendors.contact.address.city === undefined || vendors.contact.address.city === null) {
                vendors.contact.address.city = "-";
            }

            if (vendors.contact.phoneInformations.length === 0) {
                vendors.contact.phoneInformations.push({ phone: "-" });
                vendors.contact.phoneInformations.push({ phone: "-" });
            }

            if (vendors.contact.emailInformations.length === 0) {
                vendors.contact.emailInformations.push({ email: "-" });
                vendors.contact.emailInformations.push({ email: "-" });
            }

            $scope.vendors = vendors;
            $scope.showSelectedVendorInfo = true;
            $scope.wrVendorSelected = true;
        }

        function saveWorkRecord() {
            if ($scope.wr.id) {
                WorkRecordsFactory.update({ id: $scope.wr.id, step: 4 }, $scope.wr).$promise.then(onSaveSucceded, UtilityService.onRequestError);
            }

            function onSaveSucceded() {              
                advanceWorkRecord();               
            }
        }

        function advanceWorkRecord() {
            $scope.wr.stageId = workRecordsStages.VendorSelectionBasicForm.stageId;
            WorkRecordsFactory.advanceStep({ id: $scope.wr.id, step: 4 }, $scope.wr).$promise.then(onAdvanceSucceded, UtilityService.onRequestError);
            
            function onAdvanceSucceded() {
                continueWR();
            }
        }

        function continueWR() {
            $location.path('/workRecordsHome/' + $scope.wr.id + '/edit/stepFourForm');
        }

        $scope.cancelWorkRecord = function () {
            WorkRecordService.cancelWorkRecord($scope.wr.id);
        };

        $scope.reAssignVendorToWorkRecord = function () {
            WorkRecordsFactory.reAssignVendorToWorkRecord({ workRecordId: $scope.wr.id }, null).$promise.then(onSucceeded, UtilityService.onRequestError);
            function onSucceeded() {
                $route.reload();
            }
        };
    }
})();