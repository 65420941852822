(function () {
    angular.module('springwise').controller('VendorsIndexCtrl', VendorsIndexCtrl);

    VendorsIndexCtrl.$inject = ['$scope', '$routeParams', 'VendorsFactory', 'CurrentUserFactory', 'permissions', 'UtilityService', '$window', 'ngAuthSettings', 'ImportsFactory', 'HelperFactory'];

    function VendorsIndexCtrl($scope, $routeParams, VendorsFactory, CurrentUserFactory, permissions, UtilityService, $window, ngAuthSettings, ImportsFactory, HelperFactory) {
        $scope.vendors = [];
        $scope.page = 1;
        $scope.limit = 20;
        $scope.name = '';
        $scope.orderBy = 'Name';
        $scope.canManageVendors = false;
        $scope.canCreateVendors = false;

        $scope.searchVendors = searchVendors;
        $scope.exportVendors = exportVendors;
        $scope.activate = activate;
        $scope.deactivate = deactivate;
        $scope.onPaginationChange = onPaginationChange;
        $scope.getImportTemplate = getImportTemplate;
        $scope.deleteVendor = deleteVendor;
        //$scope.send = send;

        $scope.vendorId = null;
        $scope.taxId = '';
        $scope.clientId = null;

        $scope.$watch('limit', onPageLimitChange);

        init();

        function init() {
            if ($routeParams.clientId) {
                $scope.clientId = $routeParams.clientId;
            }

            if (CurrentUserFactory.exists()) {
                var currentUser = CurrentUserFactory.get();
                $scope.canManageVendors = CurrentUserFactory.canEditVendors();
                $scope.canCreateVendors = CurrentUserFactory.canCreateVendors();
                $scope.isVendor = currentUser.vendor !== undefined && currentUser.vendor !== null;
                $scope.isClient = currentUser.client !== undefined && currentUser.client !== null;
                $scope.isSpringwise = !$scope.isVendor && !$scope.isClient;
                if (currentUser.vendor) {
                    $scope.vendorId = currentUser.vendor.vendorId;
                }
            }

            $scope.limit = UtilityService.defaultLimitOption;
            $scope.limitOptions = UtilityService.limitOptions;

            searchVendors();
        }

        function searchVendors() {
            var params = {
                page: $scope.page,
                limit: $scope.limit.id,
                orderBy: $scope.orderBy,
                name: $scope.name,
                vendorId: $scope.vendorId,
                clientId: $scope.clientId,
                queryVendors: true
            };

            var paramsForTotalRecords = {
                name: $scope.name,
                vendorId: $scope.vendorId,
                taxId: $scope.taxId,
                clientId: $scope.clientId,
                queryVendors: true
            };

            searchVendorsWithParams(params, paramsForTotalRecords);
        }

        function exportVendors() {
            var params = {
                page: 1,
                limit: 0,
                orderBy: $scope.orderBy,
                name: $scope.name,
                vendorId: $scope.vendorId,
                clientId: $scope.clientId,
                queryVendors: true
            };

            VendorsFactory.exportVendors(params).$promise.then(function (msg) {
                if (!msg.hasError) {
                    addAlert(msg.errorMessage, 'success');
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetExportedFile/' + fileName[0] + '/' + fileName[1];
                    var download_iframe = document.getElementById('download_iframe');
                    download_iframe.src = link;
                }
                else {
                    addAlert(msg.errorMessage, 'danger');
                }
                
            }, UtilityService.onRequestError);
        }

        function getImportTemplate() {
            var params = {
                templateType: 1,
                clientId: $scope.clientId
            };

            ImportsFactory.api.getImportTemplate(params).$promise.then(function (msg) {
                if (!msg.hasError) {
                    addAlert(msg.errorMessage, 'success');
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetFile/' + fileName[0] + '/' + fileName[1];
                    var download_iframe = document.getElementById('download_iframe');
                    download_iframe.src = link;
                }
                else {
                    addAlert(msg.errorMessage, 'danger');
                }

            }, UtilityService.onRequestError);
        }

        function searchVendorsWithParams(params, paramsForTotalRecords) {

            VendorsFactory.getTotalRecords(paramsForTotalRecords).$promise.then(function (obj) {
                if ($scope.total !== obj.totalRecords) {
                    $scope.total = obj.totalRecords;
                }

                VendorsFactory.query(params).$promise.then(assignVendors);
            }, UtilityService.onRequestError);

        }

        function assignVendors(vendors) {
            $scope.vendors = vendors;
        }

        function activate(id) {         
            VendorsFactory.activate({ id: id }).$promise.then(onActivateComplete, UtilityService.onRequestError);
        }

        function onActivateComplete() {
            UtilityService.openMessageModalWindow('The vendor was activated', null, 'success');
            searchVendors();
        }

        function deactivate(id) {           
            VendorsFactory.deactivate({ id: id }).$promise.then(onDectivateComplete, UtilityService.onRequestError);
        }

        function onDectivateComplete() {
            UtilityService.openMessageModalWindow('The vendor was deactivated', null, 'success');
            searchVendors();
        }

        function onPaginationChange(page) {
            $scope.page = page;
            $scope.searchVendors();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.page !== 1) {
                    $scope.page = 1;
                }
                $scope.searchVendors();
            }
        }

        $scope.alerts = [];
        function addAlert(message, type) {
            $scope.alerts.push({ message: message, type: type });
        }

        function deleteVendor(vendor) {
            var modalWindow = HelperFactory.openAcceptCancelModalWindow('The Vendor is going to be Deleted Permanently, with the related Vendor Admin User. Do you want to proceed?', 'Confirmation');
            modalWindow.result.then(function () {

                removeVendor(vendor);

            }, function () {
            });
        }

        function removeVendor(vendor) {
            VendorsFactory.delete({ id: vendor.id }).$promise.then(function (vendor) {
                var modalWindow = HelperFactory.openMessageModalWindow('The Vendor "' + vendor.name + '" has been deleted.', 'Deleted');
                init();
            }, UtilityService.onRequestError);
        }

        //function send() {
        //    var params = {
        //        companyName: "Guille",
        //        emailAddress: "guillito@hotmail.com",
        //        services:"lanscaping-Painting-Repair"
        //    };
        //    VendorsFactory.sendVendorRequest(param).$promise.then(function () { alert('send'); }, UtilityService.onRequestError);
        //}
    }
})();