(function () {
    angular.module('springwise').controller('LocationsServicesVendorsIndexCtrl', LocationsServicesVendorsIndexCtrl);

    LocationsServicesVendorsIndexCtrl.$inject = ['$scope', '$routeParams', 'UtilityService', 'LocationServicesVendorsService', 'LocationsFactory', 'vendorUsageOptions', 'HelperFactory'];

    function LocationsServicesVendorsIndexCtrl($scope, $routeParams, UtilityService, LocationServicesVendorsService, LocationsFactory, vendorUsageOptions, HelperFactory) {
        init();

        function init() {
            $scope.locationId = $routeParams.locationId;
            $scope.serviceId = $routeParams.serviceId;
            $scope.usageOptions = [vendorUsageOptions.default, vendorUsageOptions.required, vendorUsageOptions.denied];
            $scope.vslPage = 1;
            $scope.vslLimit = UtilityService.defaultLimitOption;
            $scope.vslLimitOptions = UtilityService.limitOptions;
            $scope.vslOrderBy = 'ranking';
            $scope.vslIsDescending = false;
            $scope.serviceByLocationVendors = [];

            $scope.addVendor = addVendor;
            $scope.removeVendor = removeVendor;
            $scope.submitVendor = submitVendor;
            $scope.editVendor = editVendor;
            $scope.cancelVendor = cancelVendor;
            $scope.requireUsage = requireUsage;
            $scope.denyUsage = denyUsage;
            $scope.allowUsage = allowUsage;
            $scope.onVSLPaginationChange = onVSLPaginationChange;

            $scope.$watch('vslLimit', onVSLPageLimitChange);

            queryServiceByLocationVendors();
            setSearchParams();

            function setSearchParams() {
                LocationsFactory.get({ id: $scope.locationId }).$promise.then(onGetLocationSucceded, UtilityService.onRequestError);

                function onGetLocationSucceded(location) {
                    $scope.searchParams = {
                        zipCode: { disabled: true },
                        location: { value: { locationId: $scope.locationId }, disabled: true },
                        service: { value: { id: $scope.serviceId } }
                    };
                }
            }
        }

        function addVendor(vendor) {
            if (existsVendor(vendor)) {             
                var modalWindow = HelperFactory.openMessageModalWindow('The vendor is already related to this service.', 'Item already added.');
                return;
            }
            if ($scope.editMode) {
                var modalWindowEditMode = HelperFactory.openMessageModalWindow('Make sure to save or cancel any rows in the table before selecting another vendor.', 'Item in edition');                
                return;
            }

            var vsl = new VendorServiceLocation();
            vsl.vendorId = vendor.id;
            vsl.vendor = vendor.name;
            vsl.locationId = $scope.locationId;
            vsl.serviceId = $scope.serviceId;
            vsl.editedUsage = vendorUsageOptions.default;

            $scope.serviceByLocationVendors.push(vsl);
            $scope.editMode = true;
        }

        function existsVendor(vendor) {
            for (var i = 0; i < $scope.serviceByLocationVendors.length; i++) {
                var serviceByLocationVendor = $scope.serviceByLocationVendors[i];
                if (serviceByLocationVendor.vendorId === vendor.id) {
                    return true;
                }
            }
            return false;
        }

        function removeVendor(id) {
            var req = { vendorId: id, serviceId: $scope.serviceId, locationId: $scope.locationId };
            LocationServicesVendorsService.delete(req).$promise.then(queryServiceByLocationVendors, UtilityService.onRequestError);
        }

        function submitVendor(vendor) {
            var req = {
                vendorId: vendor.vendorId,
                serviceId: vendor.serviceId,
                locationId: vendor.locationId,
                ranking: vendor.editedRanking,
                nteValue: vendor.editedNteValue,
                usage: vendor.editedUsage
            };

            if (vendor.isNew) {
                LocationServicesVendorsService.save(req).$promise.then(onAddSucceded, rollbackSubmit);
            } else {
                LocationServicesVendorsService.update(req).$promise.then(onAddSucceded, UtilityService.onRequestError);
            }

            function onAddSucceded() {
                vendor.editMode = false;
                vendor.isNew = false;
                vendor.ranking = vendor.editedRanking;
                vendor.nteValue = vendor.editedNteValue;
                vendor.usage = vendor.editedUsage;
                queryServiceByLocationVendors();
                $scope.editMode = false;
            }

            function rollbackSubmit(error) {
                UtilityService.onRequestError(error);
                cancelVendor(vendor);
            }
        }

        function editVendor(vendor) {
            if ($scope.editMode) {
                alert('Make sure to save or cancel any rows in the table before selecting another vendor.');
                return;
            }
            vendor.editedRanking = vendor.ranking;
            vendor.editedNteValue = vendor.nteValue;
            vendor.editedUsage = vendor.usage;
            vendor.editMode = true;
            $scope.editMode = true;
        }

        function cancelVendor(vendor) {
            if (vendor.isNew) {
                UtilityService.removeElementFromCollection($scope.serviceByLocationVendors, vendor);
            } else {
                vendor.editMode = false;
            }

            $scope.editMode = false;
        }

        function onVendorPaginationChange(page) {
            $scope.vPage = page;
            queryVendors();
        }

        function onVSLPaginationChange(page) {
            $scope.vslPage = page;
            queryServiceByLocationVendors();
        }

        function onVendorPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.vPage !== 1) {
                    $scope.vPage = 1;
                }
                $scope.queryVendors();
            }
        }

        function onVSLPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.vslPage !== 1) {
                    $scope.vslPage = 1;
                }
                $scope.queryServiceByLocationVendors();
            }
        }

        function queryServiceByLocationVendors() {
            var params = { locationId: $scope.locationId, serviceId: $scope.serviceId, page: $scope.vslPage, limit: $scope.vslLimit.id, orderBy: $scope.vslOrderBy, isDescending: $scope.vslIsDescending };
            LocationServicesVendorsService.getTotalRecords(params).$promise.then(onQueryTotalSucceded, UtilityService.onRequestError);

            function onQueryTotalSucceded(total) {
                if ($scope.total !== total.totalRecords) {
                    if ($scope.vslPage !== 1) {
                        $scope.vslPage = 1;
                        params.page = $scope.vslPage;
                    }
                    $scope.total = total.totalRecords;
                }

                LocationServicesVendorsService
                    .query(params)
                    .$promise.then(onQuerySucceded, UtilityService.onRequestError);
            }


            function onQuerySucceded(records) {
                $scope.serviceByLocationVendors = records;
            }
        }

        function denyUsage(vendor) {
            var req = { locationId: $scope.locationId, serviceId: $scope.serviceId, vendorId: vendor.vendorId };
            LocationServicesVendorsService.denyUsage(req).$promise.then(onDenyUsageSucceded, UtilityService.onRequestError);

            function onDenyUsageSucceded() {
                vendor.doNotUse = true;
                vendor.requireUse = false;
            }
        }

        function requireUsage(vendor) {
            var req = { locationId: $scope.locationId, serviceId: $scope.serviceId, vendorId: vendor.vendorId };
            LocationServicesVendorsService.requireUsage(req).$promise.then(onRequireUsageSucceded, UtilityService.onRequestError);

            function onRequireUsageSucceded() {
                vendor.requireUse = true;
                vendor.doNotUse = false;
            }
        }

        function allowUsage(vendor) {
            var req = { locationId: $scope.locationId, serviceId: $scope.serviceId, vendorId: vendor.vendorId };
            LocationServicesVendorsService.allowUsage(req).$promise.then(onAllowUsageSucceded, UtilityService.onRequestError);

            function onAllowUsageSucceded() {
                vendor.requireUse = false;
                vendor.doNotUse = false;
            }
        }
    }
})();