(function () {
    angular.module('springwise').directive('swPagination', swPagination);

    swPagination.$inject = [];

    function swPagination() {
        return {
            restrict: 'E',
            templateUrl: 'app/shared/directives/pagination/paginationView.html',
            scope: {
                limit: '=limit',
                currentPage: '=currentPage',
                searchRecords: '=searchRecords',
                total: '=total'
            },
            controller: 'PaginationCtrl',
            link: function (scope, element, attrs, ctrl) {
                ctrl.init(scope, element);
            }
        };
    }
})();