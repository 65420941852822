(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('vendorDashboardCtrl', vendorDashboardCtrl);

    vendorDashboardCtrl.$inject = ['$scope',
        'AuthFactory',
        'CurrentUserFactory',
        'ClientsFactory',
        'permissions',
        'VendorDashboardFactory'];

    function vendorDashboardCtrl(
        $scope,
        AuthFactory,
        CurrentUserFactory,
        ClientsFactory,
        permissions,
        VendorDashboardFactory) {

        $scope.wrs = [];
        $scope.params = {};
        $scope.isSWAdmin = false;
        $scope.canEditWorkRecords = false;
        $scope.canCreateWorkRecords = false;

        $scope.getVendorWRs = getVendorWRs;

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
            $scope.currentUserId = $scope.currentUser.userId;
            $scope.isVendor = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClient = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSpringwise = !$scope.isVendor && !$scope.isClient;
        }
        if ($scope.currentUser !== null) {
            $scope.isSWAdmin = $scope.currentUser.permissions.indexOf(permissions.SWAdmin) !== -1;
            $scope.canCreateWorkRecords = $scope.currentUser.permissions.indexOf(permissions.createWorkRecords) !== -1;
            $scope.canEditWorkRecords = $scope.currentUser.permissions.indexOf(permissions.editWorkRecords) !== -1;
        }

        $scope.closeAlert = function (index) { $scope.alerts.splice(index, 1);};

        activate();

        function activate() {

            getVendorWRs();

        }

        function getVendorWRs() {
            var theVendorId = 0;
            if ($scope.currentUser.vendor)
                theVendorId = $scope.currentUser.vendor.vendorId;
            
            $scope.params = {
                vendorId: theVendorId,
                userId: $scope.currentUserId,
                dashboardViewerType: 2

            };

            VendorDashboardFactory.vendorDashboardQuery($scope.params).$promise.then(function (data) {
                $scope.emergencyWrs = data.emergencyWorkRecords;
                $scope.overdueWorkRecords = data.overdueWorkRecords;
                $scope.deadlineApproachingWorkRecords = data.deadlineApproachingWorkRecords;
                $scope.newWorkRecords = data.newWorkRecords;
                $scope.alerts = data.alerts;
            });
        }
    }

})();
