(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('RolesManagementCtrl', RolesManagementCtrl);

    RolesManagementCtrl.$inject = ['$scope', 'RolesFactory', 'PermissionsFactory', 'permissions', 'CurrentUserFactory', '$routeParams', 'HelperFactory', 'UtilityService'];

    function RolesManagementCtrl($scope, RolesFactory, PermissionsFactory, permissions, CurrentUserFactory, $routeParams, HelperFactory, UtilityService) {
        
        var currentUser = CurrentUserFactory.get();
        $scope.canCreateRoles = currentUser.permissions.indexOf(permissions.createRoles) !== -1;
        $scope.canEditRoles = currentUser.permissions.indexOf(permissions.editRoles) !== -1;
        $scope.clientId = ($routeParams.clientId === undefined) ? null : $routeParams.clientId;

        $scope.masterData = {};
        $scope.masterData.allRoles = [];
        $scope.masterData.permissions = [];
        $scope.deleteRole = deleteRole;

        activate();

        function activate() {
            if ($scope.clientId !== null) {
                RolesFactory.getRolesByClientId({ clientId: $scope.clientId }).$promise.then(function (roles) {
                    $scope.masterData.allRoles = roles;
                });
            }
            else {
                RolesFactory.query().$promise.then(function (roles) {
                    $scope.masterData.allRoles = roles;
                });
            }
            
        }

        function deleteRole(role) {
            var modalWindow = HelperFactory.openAcceptCancelModalWindow('The Role is going to be Deleted Permanently. Do you want to proceed?', 'Confirmation');
            modalWindow.result.then(function () {

                removeRole(role);

            }, function () {
            });
        }

        function removeRole(role) {
            RolesFactory.delete({ id: role.id }).$promise.then(function (role) {
                var modalWindow = HelperFactory.openMessageModalWindow('The Role "' + role.name + '" has been deleted.', 'Deleted');
                //init();
                activate();
            }, UtilityService.onRequestError);
        }
    }
})();
