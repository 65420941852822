(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('reportsDashboardCtrl', reportsDashboardCtrl);

    reportsDashboardCtrl.$inject = ['$scope', 'AuthFactory', 'CurrentUserFactory', 'ClientsFactory', 'permissions'];

    function reportsDashboardCtrl($scope, authFactory, currentUserFactory, clientsFactory, permissions) {

        var currentUser = currentUserFactory.get();

        activate();

        function activate() {
        }

    }
})();
