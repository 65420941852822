(function () {
    angular.module('springwise').controller('EditContractCtrl', EditContractCtrl);

    function EditContractCtrl($scope, $routeParams, CurrentUserFactory, ClientsFactory, UtilityService, ContractsFactory, HelperFactory, billingFrequencies, ngAuthSettings, $location, contractStatuses) {


        $scope.isVendorUser = false;
        $scope.isSWAdminUser = false;
        $scope.isClientUser = false;

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();          

            $scope.isVendorUser = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClientUser = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSWAdminUser = !$scope.isVendorUser && !$scope.isClientUser;

        }

        $scope.contractNotReadyToReview = false;
        //$scope.contractAlreadyAccepted = false;


        function activate () {
            ContractsFactory.api.getContractForEdition({ contractId: $routeParams.contractId }).$promise.then(function (contract) {
                setResponse(contract);
            }, UtilityService.onRequestError);
        }

        function setResponse(contract) {
            $scope.contract = contract;
            $scope.customContractId = contract.customContractId;
            $scope.showUploadControl = false;
            if ($scope.currentUser.approveWorkRecordLevel < contract.requiredNTELevel) {
                $scope.NTELevelExceeded = true;
            }
            if (contract.customContractId) {
                $scope.customContractURL = ngAuthSettings.apiServiceBaseUri + 'api/SWFiles/GetFile/' + contract.customContractId;
            }

            if ($scope.contract.contractStatusId < contractStatuses.readyToReviewByVendor) {
                $scope.contractNotReadyToReview = true;
            }

            //if ($scope.contract.contractStatusId >= contractStatuses.reviewedAndAcceptedByVendor) {
            //    $scope.contractAlreadyAccepted = true;
            //}          
        }

        $scope.save = function () {
            $scope.alerts = [];
            var params = { 
                contractId: $scope.contract.contractId,
                description: $scope.contract.description,
                billingFrequencyId: $scope.contract.billingFrequencyId,
                customContractId: $scope.customContractId,
            };
            if ($scope.fileToUpload) {
                $scope.fileToUpload.send('api/SWFiles/UploadFile', function (response) {
                    params.customContractId = response;
                    save(params);
                });
            }
            else {
                save(params);
            }
        };

        $scope.billingFrequencies = [
            billingFrequencies.perService,
            billingFrequencies.seasonal
        ];

        $scope.alerts = [];
        function addAlert(message, type) {
            $scope.alerts.push({ message: message, type: type });
        }

        function save(params) {
            $scope.alerts = [];
            if (!$scope.contract.description) {
                addAlert('Please enter a description', 'danger');
            }
            if ($scope.alerts.length > 0) {
                return;
            }
            ContractsFactory.api.editContract(null, params).$promise.then(function (contract) {
                setResponse(contract);
                addAlert('Saved successfully', 'success');
            }, UtilityService.onRequestError);
        }

        $scope.setAsReadyToReviewByVendor = function () {
            ContractsFactory.api.setAsReadyToReviewByVendor({ contractId: $scope.contract.contractId }, null).$promise.then(function (response) {
                $scope.contractNotReadyToReview = false;
                $location.path('/Contracts/ViewContract/' + $scope.contract.contractId);
            }, UtilityService.onRequestError);
        };


        //$scope.vendorAcceptsContract = function () {

        //    ContractsFactory.api.vendorAcceptsContract({ contractId: $scope.contract.contractId }, null).$promise.then(function (response) {
        //        setResponse(response);
        //        $scope.contractAlreadyAccepted = true;
        //        addAlert('Contract Accepted Successfully', 'success');
        //    }, UtilityService.onRequestError);

        //};

        activate();
    }
})();