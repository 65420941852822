(function () {
    angular.module('springwise').directive('tabsTemplate', tabsTemplate);

    function tabsTemplate() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/directives/tabs/tabsTemplate.html',
            controller: "tabsTemplateCtrl",
            scope: {
                links: '='
            },
            link: function ($scope, element, attrs, ctrl) {
            }
        };
    }

    angular.module('springwise').controller("tabsTemplateCtrl", tabsTemplateCtrl);

    function tabsTemplateCtrl($scope, $location) {
        $scope.currentPath = $location.path();
    }
})();