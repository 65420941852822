(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('swDashboardCtrl', swDashboardCtrl);

    swDashboardCtrl.$inject = ['$scope', 'AuthFactory', 'CurrentUserFactory', 'UtilityService', 'SWDashboardFactory', 'ClientsFactory', 'VendorsFactory', 'LocationsFactory', 'ServicesService', 'StagesFactory', 'permissions'];

    function swDashboardCtrl($scope, AuthFactory, CurrentUserFactory, UtilityService, SWDashboardFactory, ClientsFactory, VendorsFactory, LocationsFactory, ServicesService, StagesFactory, permissions) {
        //Parameters
        $scope.clientId = null;
        $scope.locationId = null;
        $scope.vendorId = null;
        $scope.categoryId = null;
        $scope.subCategoryId = null;
        $scope.contractStatusId = null;
        $scope.serviceId = null;
        $scope.stageId = null;
        $scope.categories = [];
        $scope.subCategories = [];
        $scope.services = [];
        $scope.locations = [];
        $scope.stages = [];
        $scope.emergencyCurrentPage = 1;
        $scope.overdueCurrentPage = 1;
        $scope.deadlineApproachingCurrentPage = 1;
        $scope.$watch('categoryId', onCategoryIdChange);
        $scope.$watch('subCategoryId', onSubCategoryIdChange);
        $scope.$watch('clientId', onClientIdChange);
        $scope.wrs = [];
        $scope.params = {};
        $scope.isSpringwise = false;
        $scope.loadDashboard = loadDashboard;

        $scope.onEmergencyPaginationChange = onEmergencyPaginationChange;
        $scope.onOverduePaginationChange = onOverduePaginationChange;
        $scope.onDeadlineApproachingPaginationChange = onDeadlineApproachingPaginationChange;
        $scope.onNewPaginationChange = onNewPaginationChange;
        $scope.emergencyPageLimit = new DashboardPageOptions();
        $scope.overduePageLimit = new DashboardPageOptions();
        $scope.deadlineApproachingPageLimit = new DashboardPageOptions();
        $scope.newPageLimit = new DashboardPageOptions();

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
            $scope.currentUserId = $scope.currentUser.userId;
            $scope.isVendor = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClient = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSpringwise = !$scope.isVendor && !$scope.isClient;
        }

        function onEmergencyPaginationChange(page) {
            $scope.emergencyCurrentPage = page;
            loadDashboard();
        }

        function onOverduePaginationChange(page) {
            $scope.overdueCurrentPage = page;
            loadDashboard();
        }

        function onNewPaginationChange(page) {
            $scope.newCurrentPage = page;
            loadDashboard();
        }

        function onDeadlineApproachingPaginationChange(page) {
            $scope.emergencyCurrentPage = page;
            loadDashboard();
        }

        function onCategoryIdChange(newVal, oldVal) {
            if (newVal && $scope.clientId) {
                ServicesService.getByClient({ id: $scope.categoryId, clientId: $scope.clientId }).$promise.then(function (category) {
                    $scope.subCategories = [{ id: null, description: '' }].concat(category.children);
                }, UtilityService.onRequestError);
            }
            else {
                $scope.subCategories = [];
            }
        }

        function onSubCategoryIdChange(newVal) {
            if (newVal) {
                if ($scope.locationId === null)
                    $scope.locationId = 0;
                ServicesService.getServicesByLocation({ subCategoryId: newVal, locationId: $scope.locationId }).$promise.then(function (services) {
                    $scope.services = [{ id: null, description: '' }].concat(services);
                }, UtilityService.onRequestError);
            }
            else {
                $scope.services = [];
            }
        }

        function onClientIdChange(newVal, oldVal) {
            if (newVal !== oldVal) {
                //Gets location list
                LocationsFactory.getLocationsByClient({ clientId: newVal }).$promise.then(function (locations) {
                    $scope.locations = [{ locationId: null, name: '' }].concat(locations);
                }, UtilityService.onRequestError);

                getVendorList();
            }
            else {
                $scope.locations = [];
            }
        }

        function loadDashboard() {
            if ($scope.isSpringwise) {
                $scope.params = {
                    emergencyPage: $scope.emergencyCurrentPage,
                    emergencyLimit: $scope.emergencyPageLimit.id,
                    overduePage: $scope.overdueCurrentPage,
                    overdueLimit: $scope.overduePageLimit.id,
                    deadlineApproachingPage: $scope.deadlineApproachingCurrentPage,
                    deadlineApproachingLimit: $scope.deadlineApproachingPageLimit.id,
                    newPage: $scope.newPage,
                    newLimit: $scope.newPageLimit.id,
                    clientId: $scope.clientId,
                    vendorId: $scope.vendorId,
                    locationId: $scope.locationId,
                    stageId: $scope.stageId,
                    serviceId: $scope.serviceId,
                    userId: $scope.currentUserId,
                    dashboardViewerType: 0
                };

                SWDashboardFactory.swDashboardQuery($scope.params).$promise.then(function (data) {
                    $scope.emergencyTotalRecords = data.totalEmergencyWorkRecords;
                    $scope.overdueTotalRecords = data.totalOverdueWorkRecords;
                    $scope.deadlineApproachingTotalRecords = data.totalDeadlineApproachingWorkRecords;
                    $scope.emergencyWrs = data.emergencyWorkRecords;
                    $scope.overdueWorkRecords = data.overdueWorkRecords;
                    $scope.newWorkRecords = data.newWorkRecords;
                    $scope.deadlineApproachingWorkRecords = data.deadlineApproachingWorkRecords;                    
                    $scope.alerts = data.alerts;
                });
            }
        }

        //Gets client list
        ClientsFactory.getClientsSWManaged().$promise.then(function (clients) {
            $scope.clients = [{ clientId: null, name: '' }].concat(clients);
        }, UtilityService.onRequestError);

        //Gets vendor list
        function getVendorList() {
            if (!$scope.clientId) {
                VendorsFactory.getActiveVendors().$promise.then(function (vendors) {
                    $scope.vendors = [{ id: null, name: '' }].concat(vendors);
                }, UtilityService.onRequestError);
            }
            else {
                var params = {
                    page: 1,
                    limit: 0,
                    orderBy: 'name',
                    name: '',
                    vendorId: null,
                    clientId: $scope.clientId,
                    queryVendors: true,
                    active: true
                };
                VendorsFactory.query(params).$promise.then(function (vendors) {
                    $scope.vendors = [{ id: null, name: '' }].concat(vendors);
                }, UtilityService.onRequestError);
            }
        }

        //Gets category list
        ServicesService.query().$promise.then(function (categories) {
            $scope.categories = [{ id: null, description: '' }].concat(categories);
        });

        //Gets stage list
        StagesFactory.getStages().$promise.then(function (stages) {
            $scope.stages = [{ stageId: null, name: '' }].concat(stages);
        });

        $scope.closeAlert = function (index) {
            var alertId = $scope.alerts[index].alertId;
            SWDashboardFactory.markAlertAsRead({ id: alertId }).$promise.then(onAlertMarkAsRead, UtilityService.onRequestError);
            $scope.alerts.splice(index, 1);
            function onAlertMarkAsRead() {
            }
        };

        //Loads lists
        loadDashboard();
    }
})();
