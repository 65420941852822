(function () {
    angular.module('springwise').controller('priorityAnalysisReportCtrl', priorityAnalysisReportCtrl);

    priorityAnalysisReportCtrl.$inject = ['$scope'];

    function priorityAnalysisReportCtrl($scope) {
        $scope.$watch('currentUser', activate);
        activate();

        function activate() {
        }


    }
})();