(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('ExceededNTEApprovalCtrl', ExceededNTEApprovalCtrl);

    function ExceededNTEApprovalCtrl($scope, $routeParams, WorkRecordsFactory, CurrentUserFactory, UtilityService, exceededNTEApprovalStatuses, exceededNTEApprovalTypes) {
        $scope.exceededNTEApprovalId = $routeParams.exceededNTEApprovalId;
        $scope.statuses = exceededNTEApprovalStatuses;
        $scope.types = exceededNTEApprovalTypes;

        getExceededNTEApproval();
        
        $scope.cancelClick = function () {
            respondExceededNTEApproval('cancel');
        };

        $scope.approveClick = function () {
            respondExceededNTEApproval('approve');
        };

        function respondExceededNTEApproval(action) {
            $scope.requestSent = true;
            WorkRecordsFactory.respondExceededNTEApproval({ exceededNTEApprovalId: $scope.exceededNTEApprovalId, action: action }).$promise.then(function (response) {
                getExceededNTEApproval();
            }, UtilityService.onRequestError);
        }

        function getExceededNTEApproval() {
            WorkRecordsFactory.getExceededNTEApproval({ exceededNTEApprovalId: $scope.exceededNTEApprovalId }).$promise.then(function (response) {
                $scope.exceededNTEApproval = response;
                WorkRecordsFactory.getWorkRecordSummary({ workRecordId: $scope.exceededNTEApproval.workRecordId }).$promise.then(function (workRecordSummary) {
                    $scope.workRecordSummary = workRecordSummary;
                }, UtilityService.onRequestError);
            }, UtilityService.onRequestError);
        }
    }
})();