(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('WorkRecordImagesCtrl', WorkRecordImagesCtrl);

    function WorkRecordImagesCtrl($scope, $routeParams, WorkRecordsFactory, CurrentUserFactory, UtilityService) {

        $scope.workRecordId = $routeParams.workRecordId;
        getWorkRecordImages();
        
        function getWorkRecordImages() {
            WorkRecordsFactory.getWRImages({ id: $scope.workRecordId }).$promise.then(function (response) {
                $scope.workRecordImages = response;
            }, UtilityService.onRequestError);
        }
    }
})();