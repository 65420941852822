(function () {
    angular.module('springwise').controller('AddressFormCtrl', AddressFormCtrl);

    AddressFormCtrl.$inject = ['$scope', '$attrs', 'CountriesFactory', 'StatesFactory'];

    function AddressFormCtrl($scope, $attrs, CountriesFactory, StatesFactory) {
        var self = this;

        $scope.selectedCountry = new CountryOption();
        $scope.countries = [];
        $scope.states = [];
        $scope.$watch('address.state', onStateChange);
        $scope.$watch('selectedCountry', onCountryChange);
        //$scope.disabled = false;

        this.init = init;

        function init(element, scope) {
            self.$element = element;
            if (!scope.address) {
                scope.address = new Address();
            }
            if (scope.address && scope.address.state) {
                $scope.selectedCountry = { countryId: scope.address.state.countryId };
            }
            CountriesFactory.query()
                .$promise.then(function (countries) {
                    $scope.countries = countries;
                });
            StatesFactory.query()
                .$promise.then(function (states) {
                    $scope.states = states;
                });
        }

        function onStateChange(newValue, oldValue) {
            if (newValue) {
                $scope.selectedCountry = newValue.country;
            }
        }

        function onCountryChange(newValue) {
            if (newValue) {
                $scope.phonePattern = newValue.phonePattern;
                $scope.phoneExample = newValue.phoneExample;
            }
        }
    }
})();