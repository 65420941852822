(function () {
    angular.module('springwise').controller('SearchUsersCtrl', SearchUsersCtrl);

    SearchUsersCtrl.$inject = ['$scope', 'UtilityService', 'UsersFactory'];

    function SearchUsersCtrl($scope, UtilityService, UsersFactory) {
        
        var self = this;
        self.init = init;

        function init($element) {
            self.$element = $element;    
            $scope.users = [];

            $scope.name = '';
            $scope.orderBy = 'name';
            $scope.isDescending = false;
            $scope.page = 1;
            $scope.limit = UtilityService.defaultLimitOption;
            $scope.limitOptions = UtilityService.limitOptions;

    

            $scope.onPaginationChange = onPaginationChange;
            $scope.search = search;

            $scope.$watch('limit', onPageLimitChange);
            $scope.$watch('name', resetSearch);     
        }

        function onPaginationChange(page) {
            $scope.page = page;
            search();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.page !== 1) {
                    $scope.page = 1;
                }
                search();
            }
        }

        function resetSearch() {
            $scope.page = 1;
            $scope.limit = UtilityService.defaultLimitOption;            
            $scope.users = [];
            $scope.searchExecuted = false;
        }

        function search() {
            var params = {};
            params.orderBy = $scope.orderBy;
            params.isDescending = $scope.isDescending;            
            params.pageNumber = $scope.page;           
            params.pageLimit = $scope.limit.id;
            params.name = ($scope.params.name || {}).value;
            params.clientId = ($scope.params.client || {}).value.id;
                        
            UsersFactory.getTotalRecords(params).$promise.then(onQueryTotalSucceded, UtilityService.onRequestError);

            function onQueryTotalSucceded(total) {
                if ($scope.total !== total.totalRecords) {
                    if ($scope.page !== 1) {
                        $scope.page = 1;
                        params.page = $scope.page;
                    }
                    $scope.total = total.totalRecords;
                }
      
                UsersFactory.query(params).$promise.then(onSearchSucceded, UtilityService.onRequestError);

                function onSearchSucceded(users) {
                    $scope.users = users;
                    $scope.searchExecuted = true;
                }
            }
        }
    }
})();