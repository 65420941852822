(function () {
    angular.module('springwise').directive('swProgramOverviewReport', swProgramOverviewReport);

    swProgramOverviewReport.$inject = [];

    function swProgramOverviewReport() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/components/programOverviewReport/programOverviewReport.html',
            scope: {
                currentUser: '=currentUser'
            },
            controller: 'programOverviewReportCtrl'
        };
    }
})();
