(function () {
    angular.module('springwise').factory('VendorsServicesService', VendorsServicesService);

    VendorsServicesService.$inject = ['$resource'];

    function VendorsServicesService($resource) {
        return $resource(
            'api/vendors/:vendorId/services/:serviceId', {
                vendorId: '@vendorId',
                serviceId: '@serviceId'
            }, {
                getTotalRecords: {
                    url: 'api/vendors/:vendorId/services/total',
                    method: 'GET',
                    isArray: false
                },
                getTotalRecordsByClient: {
                    url: 'api/clients/:clientId/vendors/:vendorId/services/total',
                    method: 'GET',
                    params: {
                        clientId: '@clientId',
                        vendorId: '@vendorId'
                    },
                    isArray: false
                },
                queryByClient: {
                    url: 'api/clients/:clientId/vendors/:vendorId/services',
                    method: 'GET',
                    params: {
                        clientId: '@clientId',
                        vendorId: '@vendorId'
                    },
                    isArray: true
                },
                update: {
                    method: 'PUT',
                    isArray: false
                }
            });
    }
})();