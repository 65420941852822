(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('workRecordGenerationViewCtrl', workRecordGenerationViewCtrl);

    function workRecordGenerationViewCtrl($scope, $routeParams, WorkRecordsFactory, CurrentUserFactory, UtilityService, WorkRecordService, $location, permissions) {

        var currentUser = CurrentUserFactory.get();
        $scope.canGenerateWR = currentUser.permissions.indexOf(permissions.workRecordsGeneration) !== -1;

        $scope.generate = function () {
            WorkRecordsFactory.generateWRForRecurringSchedule().$promise.then(function (response) {
                UtilityService.openMessageModalWindow(response.message);
            }, UtilityService.onRequestError);
        };

    }
})();