(function () {
    angular.module('springwise').directive('swTextField', swTextField);

    swTextField.$inject = [];

    // http://stackoverflow.com/questions/18784520/angular-directive-with-default-options

    function swTextField() {
        return {
            restrict: 'E',
            templateUrl: 'app/shared/directives/fields/textFieldView.html',
            compile: function(element, attrs){
                if (!attrs.gridcolumn) { attrs.gridcolumn = 'col-sm-3'; }
            },
            scope: {
                field: '=field',
                maxLength: '=maxLength',
                minLength: '=minLength',
                req: '=required',
                model: '=model',
                name: '@name',
                label: '@label',
                dis: '=disabledAttribute',
                warningMessage: '=',
                gridcolumn: '@gridcolumn',
                validation: '=',
                index: '='
            }
        };
    }

})();
