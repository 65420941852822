(function () {
    'use strict';
    angular.module('springwise').service('WorkRecordService', WorkRecordService);

    function WorkRecordService(workRecordsStages, workRecordsStatuses, WorkRecordsFactory, UtilityService, $location) {
        this.getPathToWorkRecordPage = function (wr, isVendor, userVendorId) {

            if (isVendor && (wr.statusId === workRecordsStatuses.CanceledStatus.statusId || wr.vendorId !== userVendorId))
                return "/forbidden";

            if (wr.statusId === workRecordsStatuses.CanceledStatus.statusId)
                return "/workRecordsHome/" + wr.id + "/view";

            if (wr.stageId === workRecordsStages.ServiceSelectionStage.stageId) { //1
                return "/workRecordsHome/" + wr.id + "/edit/stepOne";
            } else if (wr.stageId === workRecordsStages.TriageQuestionnaireStage.stageId) { //2
                if (wr.hasQuestionnaire) {
                    return "/workRecordsHome/" + wr.id + "/edit/stepTwo";
                } else {
                    return "/workRecordsHome/" + wr.id + "/edit/stepThree";
                }
            } else if (wr.stageId === workRecordsStages.WorkRecordCreationStage.stageId) { //3
                return "/workRecordsHome/" + wr.id + "/edit/stepThree";
            } else if (wr.stageId === workRecordsStages.VendorAssignmentStage.stageId) { //4 Vendor Selection
                return "/workRecordsHome/" + wr.id + "/edit/stepFour";
            } else if (wr.stageId === workRecordsStages.VendorSelectionBasicForm.stageId) { //5 Vendor Basic Form
                return "/workRecordsHome/" + wr.id + "/edit/stepFourForm";                    
            } else if (wr.stageId === workRecordsStages.ScopeReviewStage.stageId) { //6
                return "/workRecordsHome/" + wr.id + "/edit/stepFive";                
            } else if (wr.stageId === workRecordsStages.WorkReportingStage.stageId && isVendor !== true) { //9
                return "/workRecordsHome/" + wr.id + "/edit/stepSix";            
            } else if (wr.stageId === workRecordsStages.WorkReportingStage.stageId && isVendor === true) { //9
                return "/workRecordsHome/" + wr.id + "/vendorWorkReporting";
            } else if (wr.stageId === workRecordsStages.WorkCompletionStage.stageId) { //stage:11
                return "/workRecordsHome/" + wr.id + "/edit/stepSix"; //Stay on the same last Step, in order to be able to continue uploading information
            }

            return "/workRecordsHome/" + wr.id + "/view";
        };

        this.cancelWorkRecord = function (workRecordId) {
            WorkRecordsFactory.cancelWorkRecord({ workRecordId: workRecordId }, null).$promise.then(onCancelSucceeded, UtilityService.onRequestError);
            function onCancelSucceeded() {
                $location.path('/workRecordsHome/' + workRecordId + '/redirect');
            }
        };
    }
})();