(function () {
    angular.module('springwise').directive('swSearchAssignVendors', swSearchAssignVendors);

    swSearchAssignVendors.$inject = [];

    function swSearchAssignVendors() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/components/vendors/searchAssignVendorsView.html',
            scope: {
                select: '=select',
                params: '=params'
            },
            link: function ($scope, element, attrs, ctrl) {
                ctrl.init(element);
            },
            controller: 'SearchAssignVendorsCtrl'
        };
    }
})();