(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('RolesFactory', RolesFactory);

    RolesFactory.$inject = ['$resource'];

    function RolesFactory($resource) {
        return $resource(
            'api/Roles/:id',
            { id: '@id' },
            {
                queryUserChilds: {
                    method: 'GET',
                    url: 'api/Users/:userId/Roles',
                    isArray: true,
                    params: {
                        userId: '@id'
                    }
                },
                formRoles: {
                    method: 'GET',
                    url: 'api/Roles/Form',
                    isArray: true
                },
                parentPermissions: {
                    method: 'GET', 
                    url: 'api/Roles/:roleId/Parent/:parentId',
                    isArray: true,
                    params: {
                        parentId: '@parentRoleId'
                    }
                },
                update: {
                    method: 'PUT'
                },
                queryOptions: {
                    method: 'GET',
                    url: 'api/Roles/options',
                    isArray: true
                },
                getRolesForRoleEdition: {
                    method: 'GET',
                    isArray: true,
                    params: {
                        parentRoleId: '@parentRoleId',
                        clientId: '@clientId'
                    }
                },
                getRolesByClientId: {
                    method: 'GET',
                    isArray: true,
                    params: {
                        clientId: '@clientId'
                    }
                }
            });
    }
})();