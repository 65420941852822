(function () {
    angular.module('springwise').controller('ClientRegionsFormCtrl', ClientRegionsFormCtrl);

    ClientRegionsFormCtrl.$inject = ['$scope', '$routeParams', '$location', 'CurrentUserFactory', 'RegionsFactory', 'UtilityService'];

    function ClientRegionsFormCtrl($scope, $routeParams, $location, CurrentUserFactory, RegionsFactory, UtilityService) {

        $scope.clientId = $routeParams.clientId;
        $scope.regionId = $routeParams.regionId;
        $scope.region = new ClientRegion();
        $scope.region.clientId = $scope.clientId;

        $scope.saveRegion = saveRegion;
        $scope.addDistrict = addDistrict;
        $scope.removeDistrict = removeDistrict;

        $scope.swValidation = new SWValidation();

        activate();

        function activate() {
            if ($scope.regionId) {
                RegionsFactory.get({ id: $scope.regionId }).$promise.then(assignRegion);
            }
        }

        function saveRegion(form) {
            
            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            for (var index1 = 0; index1 < $scope.region.districts.length; index1++) {
                for (var index2 = 0; index2 < $scope.region.districts.length; index2++) {
                    if (index1 > index2 && $scope.region.districts[index1].name == $scope.region.districts[index2].name) {
                        $scope.swValidation.addAlert('name', 'This name is already used', index1);
                    }
                }
            }
            $scope.swValidation.end();
            if ($scope.swValidation.hasErrors())
                return;

            if ($scope.regionId) {
                RegionsFactory.update($scope.region).$promise.then(saveRegionResponse, UtilityService.onRequestError);
            } else {               
                RegionsFactory.save($scope.region).$promise.then(saveRegionResponse, UtilityService.onRequestError);
            }
        }

        function saveRegionResponse(response) {
            if (response.regionNameAlreadyExists) {
                $scope.swValidation.addAlert('regionName', 'This name is already used');
            }
            else {
                redirectToClient();
            }
        }

        function assignRegion(region) {
            $scope.region = region;
        }

        function redirectToClient() {
            $location.path('/clients/' + $scope.clientId + '/regions');
        }

        function addDistrict() {
            $scope.region.districts.push({});
        }

        function removeDistrict(district) {
            UtilityService.removeElementFromCollection($scope.region.districts, district);
        }
    }
})();