(function () {
    angular.module('springwise').directive('swUploadBulkImport', swUploadBulkImport);

    function swUploadBulkImport() {
        return {
            restrict: 'E',
            scope: {
                selectedFile: '=',
                showFileControl: '='
            },
            controller: "swUploadBulkImportCtrl",
            templateUrl: '/app/shared/directives/uploadFile/uploadBulkImport.html',
            link: function (scope, element, attrs, ctrl) {
            }
        };
    }

    angular.module('springwise').controller("swUploadBulkImportCtrl", swUploadBulkImportCtrl);

    

    function swUploadBulkImportCtrl($scope, ngAuthSettings, $rootScope) {
        //$scope.validate = validate;
        $scope.showFileControl = true;
        $scope.$watch('showFileControl', function () { $scope.uploadError = false; });

        $scope.getFileDetails = function (e) {
            $scope.fileInfo = e.files[0];
            $scope.fileName = $scope.fileInfo.name.substr($scope.fileInfo.name);

            //if (scope.validate()) {
            if (validate($scope.fileInfo.name)) {

                $scope.extension = $scope.fileInfo.name.substr($scope.fileInfo.name.lastIndexOf('.') + 1);
                var fileReader = new FileReader();
                fileReader.onload = function (e) {
                    $scope.fileData = e.target.result;
                    $scope.selectedFile = prepareFileToUpload();
                    $scope.uploadError = false;
                    $rootScope.$digest();
                };
                $scope.$digest();
                fileReader.readAsDataURL($scope.fileInfo);

            }


        };

        function prepareFileToUpload() {
            var result = {};
            result.formData = new FormData();
            result.formData.append("fileExtension", $scope.extension);
            var blob = dataURItoBlob($scope.fileData);
            result.formData.append("fileData", blob);
            result.formData.append("fileName", $scope.fileName);

            result.appendData = function (name, value) {
                result.formData.append(name, value);
            };

            result.send = function (apiUrl, onTransferSucceededCallback) {
                $scope.uploadError = false;
                var objXhr = new XMLHttpRequest();
                objXhr.open("POST", ngAuthSettings.apiServiceBaseUri + apiUrl);
                //objXhr.addEventListener("progress", updateProgress, false);
                objXhr.responseType = "json";
                objXhr.setRequestHeader('Accept', 'application/json, */*');
                objXhr.addEventListener("load", onTransferSucceeded, false);
                objXhr.send(result.formData);

                function onTransferSucceeded() {
                    if (objXhr.status === 200) {
                        if (onTransferSucceededCallback) {
                            onTransferSucceededCallback(objXhr.response);
                        }
                    }
                    else {
                        $scope.uploadError = true;
                    }
                    $rootScope.$digest();
                }
            };

            return result;
        }

        function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], { type: mimeString });
        }

        var _validFileExtensions = [".xml"];

        ////scope.validate = function () {
        function validate (name) {
            //return true;            
            var sFileName = name;
            if (sFileName.length > 0) {
                var blnValid = false;
                for (var j = 0; j < _validFileExtensions.length; j++) {
                    var sCurExtension = _validFileExtensions[j];
                    if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                        blnValid = true;
                        break;
                    }
                }

                if (!blnValid) {
                    alert("Sorry, " + sFileName + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "));
                    return false;
                }
            }
            return true;
        }


    }
})();