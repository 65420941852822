(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('ImportsFactory', ImportsFactory);

    function ImportsFactory($resource, $routeParams) {
        return {
            api: $resource(
                'api/Imports/:importId',
                { importId: '@importId' },
                {
                    getImportTemplateList: {
                        method: 'GET',
                        url: 'api/Imports/GetImportTemplateList',
                        isArray: true
                    },

                    getImportTemplate: {
                        method: 'GET',
                        url: 'api/Imports/GetImportTemplate',
                        isArray: false
                    }
                }
            )
        };
    }
})();