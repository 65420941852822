(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('workRecordsDashboardCtrl', workRecordsDashboardCtrl);

    workRecordsDashboardCtrl.$inject = [
        '$scope',
        'AuthFactory',
        'CurrentUserFactory',
        'UtilityService',
        'ClientsFactory',
        'LocationsFactory',
        'ServicesService',
        'StagesFactory',
        'permissions',
        'WorkRecordsFactory',
        '$location',
        'workRecordsStages',
        'workRecordsStatuses',
        'WorkRecordService'];

    function workRecordsDashboardCtrl($scope,
        AuthFactory,
        CurrentUserFactory,
        UtilityService,
        ClientsFactory,
        LocationsFactory,
        ServicesService,
        StagesFactory,
        permissions,
        WorkRecordsFactory,
        $location,
        workRecordsStages,
        workRecordsStatuses,
        WorkRecordService) {

            $scope.locationId = null;
            $scope.categoryId = null;
            $scope.subCategoryId = null;
            $scope.serviceId = null;
            $scope.stageId = null;
            $scope.categories = [];
            $scope.subCategories = [];
            $scope.services = [];
            $scope.locations = [];
            $scope.stages = [];

            $scope.wrs = [];
        $scope.params = {};
        $scope.isSWAdmin = false;
        $scope.canEditWorkRecords = false;
        $scope.canCreateWorkRecords = false;

        $scope.loadDashboard = activate;

        $scope.getFilteredWRs = getFilteredWRs;
        $scope.redirectToWR = redirectToWR;
        //$scope.mobileWR = mobileWR;

        $scope.$watch('categoryId', onCategoryIdChange);
        $scope.$watch('subCategoryId', onSubCategoryIdChange);

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
            $scope.currentUserId = $scope.currentUser.userId;
            $scope.isVendor = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClient = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSpringwise = !$scope.isVendor && !$scope.isClient;
            $scope.isSWAdmin = $scope.currentUser.permissions.indexOf(permissions.SWAdmin) !== -1;
            $scope.canCreateWorkRecords = $scope.currentUser.permissions.indexOf(permissions.createWorkRecords) !== -1;
            $scope.canEditWorkRecords = $scope.currentUser.permissions.indexOf(permissions.editWorkRecords) !== -1;
        }

        if ($scope.currentUser !== null) {
            $scope.isSWAdmin = $scope.currentUser.permissions.indexOf(permissions.SWAdmin) !== -1;
            $scope.canCreateWorkRecords = $scope.currentUser.permissions.indexOf(permissions.createWorkRecords) !== -1;
            $scope.canEditWorkRecords = $scope.currentUser.permissions.indexOf(permissions.editWorkRecords) !== -1;
            $scope.clientId = ($scope.currentUser !== undefined && $scope.currentUser.client !== null) ? $scope.currentUser.client.clientId : null;
            $scope.locationId = $scope.currentUser.locationId;
            $scope.clients = ($scope.clientId) ? [{ clientId: $scope.clientId, name: $scope.currentUser.client.name }] : [];
        }

        function onCategoryIdChange(newVal, oldVal) {
            if (newVal && $scope.clientId) {
                ServicesService.getByClient({ id: $scope.categoryId, clientId: $scope.clientId }).$promise.then(function (category) {
                    $scope.subCategories = [{ id: null, description: '' }].concat(category.children);
                }, UtilityService.onRequestError);
            }
            else {
                $scope.subCategories = [];
            }
        }

        function onSubCategoryIdChange(newVal) {
            if (newVal) {
                if ($scope.locationId === null)
                    $scope.locationId = 0;
                ServicesService.getServicesByLocation({ subCategoryId: newVal, locationId: 0 }).$promise.then(function (services) {
                    $scope.services = [{ id: null, description: '' }].concat(services);
            }, UtilityService.onRequestError);
            }
            else {
                $scope.services = [];
            }
        }

        //Get Locations list
        LocationsFactory.getLocationsByClient({ clientId: $scope.clientId }).$promise.then(function (locations) {
            $scope.locations = [{ id: null, name: '' }].concat(locations);
        });

        //Gets category list
        ServicesService.query().$promise.then(function (categories) {
            $scope.categories = [{ id: null, description: '' }].concat(categories);
        });

        //Gets stage list
        StagesFactory.getStages().$promise.then(function (stages) {
            $scope.stages = [{ stageId: null, name: '' }].concat(stages);
        });

        $scope.onPaginationChange = onPaginationChange;
        $scope.$watch('pageLimit', onPageLimitChange);

        activate();

        function activate() {
            $scope.currentPage = 1;
            $scope.pageLimit = { id: 20, name: '20 results' };
            $scope.limitOptions = [
                { id: 10, name: '10 results' },
                { id: 20, name: '20 results' },
                { id: 30, name: '30 results' },
                { id: 50, name: '50 results' },
                { id: 100, name: '100 results' },
            ];
            getFilteredWRs();
        }

        function getFilteredWRs() {
            var theClientId = null;
            var theVendorId = null;
            var theLocationId = null;
            var theRegionId = null;
            var theDistrictId = null;
            if ($scope.currentUser.client)
                theClientId = $scope.currentUser.client.clientId;
            if ($scope.currentUser.vendor)
                theVendorId = $scope.currentUser.vendor.vendorId;
            if ($scope.locationId)
                theLocationId = $scope.locationId;
            if ($scope.currentUser.regionId)
                theRegionId = $scope.currentUser.regionId;
            if ($scope.currentUser.districtId)
                theDistrictId = $scope.currentUser.districtId;

            $scope.params = {
                clientId: theClientId,
                vendorId: theVendorId,
                locationId: theLocationId,
                regionId: theRegionId,
                districtId: theDistrictId,
                onlySWManaged: $scope.isSpringwise,
                limit: $scope.pageLimit.id,
                page: $scope.currentPage,
                orderBy: 'WorkRecordId',
                isDescending: true
            };

            WorkRecordsFactory.getTotalRecords($scope.params).$promise.then(function (obj) {
                if ($scope.totalRecords !== obj.totalRecords) {
                    $scope.totalRecords = obj.totalRecords;
                }
                WorkRecordsFactory.query($scope.params).$promise.then(function (workRecords) {
                    $scope.wrs = workRecords;
                });
            });

        }

        function redirectToWR(wr) {
            //$scope.isVendor;
            return '#' + WorkRecordService.getPathToWorkRecordPage(wr, $scope.isVendor);
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.currentPage !== 1) {
                    $scope.currentPage = 1;
                }
                $scope.getFilteredWRs();
            }
        }

        function onPaginationChange(page) {
            $scope.currentPage = page;
            $scope.getFilteredWRs();
        }

    }
})();
