(function () {
	angular.module('springwise').directive('swServiceSearch', swServiceSearch);

	function swServiceSearch() {
		return {
			restrict: 'E',
			scope: {
			    locationId: "=locationId",
			    serviceId: "=serviceId",
			    validation: '='
			},

			controller: "swServiceSearchCtrl",
			templateUrl: '/app/shared/directives/serviceSearch/serviceSearchView.html',
			link: function (scope, element, attrs, ctrl) {
				ctrl.init(scope, element);
			}
		};
	}

	angular.module('springwise').controller("swServiceSearchCtrl", swServiceSearchCtrl);

	swServiceSearchCtrl.$inject = ["$scope", "DocumentsReporsitoryFactory", "ngAuthSettings", "ServicesService", "UtilityService"];
    
	function swServiceSearchCtrl($scope, DocumentsReporsitoryFactory, ngAuthSettings, ServicesService, UtilityService) {
	    $scope.categoryIDSelected = null;
	    $scope.subCategoryIDSelected = null;
	    $scope.serviceIDSelected = null;
	    $scope.categories = null;
	    $scope.subCategories = null;
	    $scope.services = null;
	    $scope.$watch('locationId', onLocationIdChange);
	    $scope.$watch('categoryIDSelected', onCategoryIdChange);
	    $scope.$watch('subCategoryIDSelected', onSubCategoryIdChange);
	    $scope.$watch('serviceIDSelected', onServiceIdChange);


	    this.init = function (scope, element) {
		};

	    function onLocationIdChange(newVal, oldVal) {
	        if (newVal) {
	            ServicesService.getCategoriesByLocation({ locationId: newVal }).$promise.then(function (cat) {
	                $scope.categories = cat;
	            }, UtilityService.onRequestError);
	        }
	        else
	            $scope.categories = null;
        }
	    function onCategoryIdChange(newVal, oldVal) {
	        if (newVal) {
	            ServicesService.getSubCategoriesByLocation({ categoryId: newVal, locationId: $scope.locationId }).$promise.then(function (cat) {
	                $scope.subCategories = cat;
	            }, UtilityService.onRequestError);
	        }
	        else
	            $scope.subCategories = null;

		}
	    function onSubCategoryIdChange(newVal, oldVal) {
		    if (newVal) {
		        ServicesService.getServicesByLocation({ subCategoryId: newVal, locationId: $scope.locationId }).$promise.then(function (serv) {
		            $scope.services = serv;
		        }, UtilityService.onRequestError);
		    }
		    else
		        $scope.services = null;
        }
		function onServiceIdChange(newVal, oldVal) {
		    if (newVal) {
		        $scope.serviceId = newVal;
		    }
		}
    }
})();