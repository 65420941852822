(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('ClientVendorRequiredFieldsCtrl', ClientVendorRequiredFieldsCtrl);

    ClientVendorRequiredFieldsCtrl.$inject = ['$scope', '$http', '$routeParams', '$location', 'ngAuthSettings', 'ClientsFactory', 'ClientVendorRequiredFieldsFactory', 'UtilityService', 'CurrentUserFactory', 'permissions'];

    function ClientVendorRequiredFieldsCtrl($scope, $http, $routeParams, $location, ngAuthSettings, ClientsFactory, ClientVendorRequiredFieldsFactory, UtilityService, CurrentUserFactory, permissions) {

        $scope.vendorInformationSectionRequired = new ClientVendorInformationSectionRequired();
        $scope.clientId = $routeParams.clientId;
        $scope.clientId = $routeParams.Id;

        $scope.vendorInformationSectionRequiredFields = [];
        $scope.vendorInformationSections = [];
        $scope.vendorRequiredExistsForClient = false;

        var currentUser = CurrentUserFactory.get();
        $scope.canEditVendors = currentUser.permissions.indexOf(permissions.canEditVendors) !== -1;

        activate();


        function activate() {
            if ($routeParams.clientId) {
                $scope.clientId = $routeParams.clientId;   

                ClientVendorRequiredFieldsFactory.getAllClientVendorRequiredField({ clientId: $routeParams.clientId }).$promise.then(function (vendorRequiredFields) {
                    $scope.vendorInformationSectionRequiredFields = vendorRequiredFields;
                    $scope.vendorRequiredExistsForClient = true;                  

                    
                    if ($scope.vendorInformationSectionRequiredFields.length === 0) {
                        $scope.vendorRequiredExistsForClient = false;
                       
                        ClientVendorRequiredFieldsFactory.getVendorInformationSections().$promise.then(function (vendorInformationSections) {
                            $scope.vendorInformationSections = vendorInformationSections;
                            if (vendorInformationSections) {
                                angular.forEach(vendorInformationSections, function (v) {
                                    var clientVendorInfoRequired = new ClientVendorInformationSectionRequired();
                                    clientVendorInfoRequired.informationSection = v;
                                    if (!v.allowSkip) {
                                        clientVendorInfoRequired.isRequired = !v.allowSkip;
                                    }                                    
                                    clientVendorInfoRequired.clientId = $scope.clientId;
                                    clientVendorInfoRequired.vendorInformationSectionId = v.vendorInformationSectionId;

                                    $scope.vendorInformationSectionRequiredFields.push(clientVendorInfoRequired);
                                });
                            }
                            
                        });                      
                    }
                    else {
                        $scope.vendorRequiredExistsForClient = true;
                    }


                });
            }
        }    

        $scope.saveRequiredFields = function () {
            if ($scope.vendorRequiredExistsForClient) {
                ClientVendorRequiredFieldsFactory.update($scope.vendorInformationSectionRequiredFields, redirectToClient, UtilityService.onRequestError);                
            } else {
                ClientVendorRequiredFieldsFactory.save($scope.vendorInformationSectionRequiredFields).$promise.then(redirectToClient, UtilityService.onRequestError);              
            }
        };

        function redirectToClient() {
            $location.path('/clients/' + $scope.clientId);
        }

        $scope.goBack = function () {
            window.history.back();
        };


    }
})();