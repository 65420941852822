(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('DownloadImportTemplatesCtrl', DownloadImportTemplatesCtrl);

    DownloadImportTemplatesCtrl.$inject = ['$scope', '$routeParams', '$location', 'ImportsFactory', 'UtilityService', 'ngAuthSettings'];

    function DownloadImportTemplatesCtrl($scope, $routeParams, $location, ImportsFactory, UtilityService, ngAuthSettings) {
        activate();

        function activate() {
            $scope.templateList = [];            
            ImportsFactory.api.getImportTemplateList().$promise.then(function (templates) {
                for (var i = 0; i < templates.length; i++) {
                    var fileName = templates[i].split('.');
                    var name = fileName[0];
                    var extension = (fileName.length > 1) ? fileName[1] : '-';
                    $scope.templateList.push({ fileName: templates[i], fileURL: ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetFile/' + name + '/' + extension });
                }
            }, UtilityService.onRequestError);
        }
    }
})();
