(function () {
    angular.module('springwise').controller('ClientLocationsFormCtrl', ClientLocationsFormCtrl);

    ClientLocationsFormCtrl.$inject = ['$scope', '$routeParams', '$location', 'LocationsFactory', 'TimeZonesFactory', 'RegionsFactory', 'DistrictsFactory', 'CurrentUserFactory', 'UtilityService', 'permissions'];

    function ClientLocationsFormCtrl($scope, $routeParams, $location, LocationsFactory, TimeZonesFactory, RegionsFactory, DistrictsFactory, CurrentUserFactory, UtilityService, permissions) {
        $scope.clientId = $routeParams.clientId;
        $scope.locationId = $routeParams.locationId;
        $scope.location = new ClientLocation();
        $scope.timeZones = [];
        $scope.regions = [];
        $scope.districts = [];

        $scope.swValidation = new SWValidation();

        $scope.saveLocation = saveLocation;
        $scope.searchLocation = searchLocation;

        //Coordinates pattern
        $scope.coordinatesPattern = /^-?[0-9]+(\.?[0-9]{1,14})?$/;
        $scope.coordinatesExample = "45.15487895 (DDD.dddd) Decimal degrees format";

        //Get user
        var currentUser = CurrentUserFactory.get();
        $scope.canViewContracts = false;
        if (currentUser) {
            $scope.canViewContracts = currentUser.permissions.indexOf(permissions.viewContracts) !== -1;
        }

        activate();

        function activate() {
            $scope.canEditServices = CurrentUserFactory.canEditServices();
            TimeZonesFactory.query().$promise.then(function (timeZones) {
                $scope.timeZones = timeZones;
            });
            RegionsFactory.queryOptions({ clientId: $scope.clientId }).$promise.then(function (regions) {
                $scope.regions = regions;
            });
            DistrictsFactory.queryOptions({ clientId: $scope.clientId }).$promise.then(function (districts) {
                $scope.districts = districts;
            });

            $scope.location.clientId = $scope.clientId;
            searchLocation();
        }

        function saveLocation(form) {
            //Validate coordinates
            var isInvalid = false;

            var latitude = parseFloat($scope.location.latitude);
            isInvalid = (latitude < -90 || latitude > 90);
            $scope.locForm.latitude.$setValidity("isinvalidlatitudeval", !isInvalid);
            
            var longitude = parseFloat($scope.location.longitude);
            isInvalid = (longitude < -180 || longitude > 180);
            $scope.locForm.longitude.$setValidity("isinvalidlongitudeval", !isInvalid);

            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            $scope.swValidation.end();
            if ($scope.swValidation.hasErrors())
                return;

            if ($scope.locationId) {
                $scope.location.$update({
                    clientId: $scope.clientId,
                    locationId: $scope.locationId
                }, redirectToClient, UtilityService.onRequestError);
            } else {
                LocationsFactory.save($scope.location).$promise.then(redirectToClient, UtilityService.onRequestError);                
            }
        }

        function onRequestError(error) {
            if (error.status === 403) {
                alert('You are not allowed to create clients.\nPlease, contact the system administrator.');
            } else if (error.status === 400) {
                alert(error.data.modelState.form[0]);
            }
            else if (error.data && error.data.message) {
                alert(error.data.message);
            } else {
                alert('There was an error on the operation.\.Please, contact the system administrator.');
            }
        }

        function redirectToClient() {
            $location.path('/clients/' + $scope.clientId + '/locations');
        }

        function searchLocation() {
            LocationsFactory.get({ id: $scope.locationId }).$promise.then(function (location) {
                $scope.location = location;
                $scope.selectedRegion = location.district.region;
                $scope.location.timeZone = location.timeZone;
            });
        }
    }
})();