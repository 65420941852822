(function () {
    angular.module('springwise').controller('ClientsIndexCtrl', ClientsIndexCtrl);

    ClientsIndexCtrl.$inject = ['$scope', '$window', 'ClientsFactory', 'CurrentUserFactory', 'permissions', 'ngAuthSettings', 'ImportsFactory', 'UtilityService', 'HelperFactory'];

    function ClientsIndexCtrl($scope, $window, ClientsFactory, CurrentUserFactory, permissions, ngAuthSettings, ImportsFactory, UtilityService, HelperFactory) {
        $scope.clients = [];
        $scope.page = 1;
        $scope.limit = 20;
        $scope.name = '';
        $scope.orderBy = 'name';
        $scope.stateName = '';

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
            $scope.currentUserId = $scope.currentUser.userId;
        }
        else {
            $scope.currentUserId = 0;
            $scope.currentUser = null;
        }

        $scope.isVendor = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
        $scope.isClient = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
        $scope.isSpringwise = !$scope.isVendor && !$scope.isClient;

        if ($scope.currentUser !== null) {
            $scope.isSWAdmin = $scope.currentUser.permissions.indexOf(permissions.SWAdmin) !== -1;
        }

        $scope.canEditClients = $scope.currentUser.permissions.indexOf(permissions.canEditClients) !== -1;
        $scope.canCreateClients = $scope.currentUser.permissions.indexOf(permissions.createClients) !== -1;
        $scope.searchClients = searchClients;
        $scope.isEditionAllowed = isEditionAllowed;
        $scope.activate = activate;
        $scope.deactivate = deactivate;
        $scope.onPaginationChange = onPaginationChange;
        $scope.exportClients = exportClients;
        $scope.getImportTemplate = getImportTemplate;
        $scope.deleteClient = deleteClient;

        $scope.$watch('limit', onPageLimitChange);

        init();

        function init() {
            $scope.limit = { id: 10, name: '10 results' };
            $scope.limitOptions = [
                { id: 10, name: '10 results' },
                { id: 30, name: '30 results' },
                { id: 50, name: '50 results' },
                { id: 100, name: '100 results' }
            ];
            searchClientsWithParams({
                page: $scope.page,
                limit: $scope.limit.id,
                orderBy: $scope.orderBy
            });
        }

        function searchClients() {
            var params = {
                page: $scope.page,
                limit: $scope.limit.id,
                name: $scope.name,
                orderBy: $scope.orderBy,
                stateName: $scope.stateName
            };

            searchClientsWithParams(params);
        }

        function searchClientsWithParams(params) {
            ClientsFactory.getTotalRecords(params).$promise.then(function (total) {
                if ($scope.total !== total.totalRecords) {
                    if ($scope.page !== 1) {
                        $scope.page = 1;
                        params.page = $scope.page;
                    }
                    $scope.total = total.totalRecords;
                }
                ClientsFactory.query(params).$promise.then(function (clients) {
                    $scope.clients = clients;
                });
            });
        }

        function isEditionAllowed($client) {
            //Is the logged user a SW User?
            if (angular.isUndefined($scope.currentUser.client) || ($scope.currentUser.client === null) &&
                (angular.isUndefined($scope.currentUser.vendor) || $scope.currentUser.vendor === null)) {

                //Client in current row is Not Activated
                if ($client.activeFlag === false) {
                    //Client Not Active
                    if ($client.springwiseManaged === false) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                    //Client in current row is Activated
                else {
                    //Client in current row is Not Alive
                    if ($client.isAlive === false) {
                        return true;
                    }
                        //Client in current row is Alive
                    else {
                        if ($client.springwiseManaged === true) {
                            return true;
                        }
                        else {
                            //Client in current row SW Granted
                            if ($client.springwiseAccess === true) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    }

                } //END activeFlag
            }

                //The logged user is NOT a SWUser, NOR a Client Admin user, is a Client user.                
            else if (angular.isDefined($scope.currentUser.client) && $scope.currentUser.client !== null &&
                angular.isDefined($scope.currentUserId) && angular.isDefined($client.userAdministratorId) &&
                $scope.currentUserId !== $client.userAdministratorId) {

                //It Checks whether the logged client user is the same client user of the current row.
                if ($scope.currentUser.client.clientId == $client.clientId) {


                    //Client in current row is Not Activated
                    if ($client.activeFlag === false) {
                        //Client Not Active
                        if ($client.springwiseManaged === false) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }

                        //Client in current row is Activated
                    else {

                        //Client in current row is Not Alive
                        if ($client.isAlive === false) {
                            return false;
                        }
                            //Client in current row is Alive
                        else {
                            if ($client.springwiseManaged === true) {
                                return false;
                            }
                            else {
                                //Client in current row is SW Granted
                                if ($client.springwiseAccess === true) {
                                    return true;
                                }
                                else {
                                    return true;
                                }
                            }
                        }


                    } //END activeFlag

                }
                    //Not allowed to Edit other client users
                else {
                    return false;
                }
            }
                //The logged user is Client Admin
            else if (angular.isDefined($scope.currentUserId) && angular.isDefined($client.userAdministratorId) &&
                $scope.currentUserId === $client.userAdministratorId) {

                //Client in current row is Not Activated
                if ($client.activeFlag === false) {
                    //Client Not Active
                    if ($client.springwiseManaged === false) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                    //Client in current row is Activated
                else {

                    //Client in current row is Not Alive
                    if ($client.isAlive === false) {
                        return false;
                    }
                        //Client in current row is Alive
                    else {
                        //Client in current row SW Granted
                        if ($client.springwiseAccess === true) {
                            return true;
                        }
                        else {
                            return false;
                        }

                    }
                }

            }
                //The logged user is NOT a SW User NOR a Client user.
            else {
                return false;
            }
        }

        function onPaginationChange(page) {
            $scope.page = page;
            $scope.searchClients();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.page !== 1) {
                    $scope.page = 1;
                }
                $scope.searchClients();
            }
        }

        function activate(client) {
            ClientsFactory.activate({ id: client.clientId }).$promise.then(onClientActivated, onRequestError);
        }

        function deactivate(client) {
            ClientsFactory.deactivate({ id: client.clientId }).$promise.then(onClientDeactivated, onRequestError);
        }

        function onRequestError(error) {
            if (error.status === 403) {
                alert('You are not allowed to perform this operation.\nPlease, contact the system administrator.');
            } else if (error.status === 400) {
                alert(error.data.modelState.form[0]);
            } else if (error.data && error.data.message) {
                alert(error.data.message);
            } else {
                alert('There was an error on the operation.\.Please, contact the system administrator.');
            }
        }

        function onClientActivated() {
            UtilityService.openMessageModalWindow('The client was activated', null, 'success');
            $scope.searchClients();
        }

        function onClientDeactivated() {
            UtilityService.openMessageModalWindow('The client was deactivated', null, 'success');
            $scope.searchClients();
        }

        function getImportTemplate() {
            var params = {
                templateType: 5,
                clientId: $scope.clientId
            };

            ImportsFactory.api.getImportTemplate(params).$promise.then(function (msg) {
                if (!msg.hasError) {
                    addAlert(msg.errorMessage, 'success');
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetFile/' + fileName[0] + '/' + fileName[1];
                    $window.open(link, "", "");
                }
                else {
                    addAlert(msg.errorMessage, 'danger');
                }

            }, UtilityService.onRequestError);
        }

        function exportClients() {
            var params = {
                page: 1,
                limit: 0,
                orderBy: $scope.orderBy,
                name: $scope.name
            };

            ClientsFactory.exportClients(params).$promise.then(function (msg) {
                if (!msg.hasError) {
                    addAlert(msg.errorMessage, 'success');
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetExportedFile/' + fileName[0] + '/' + fileName[1];
                    $window.open(link, "", "");
                }
                else {
                    addAlert(msg.errorMessage, 'danger');
                }

            }, UtilityService.onRequestError);
        }

        $scope.alerts = [];
        function addAlert(message, type) {
            $scope.alerts.push({ message: message, type: type });
        }


        function deleteClient(client) {
            var modalWindow = HelperFactory.openAcceptCancelModalWindow('The Client is going to be Deleted Permanently, with the related Client Admin User. Do you want to proceed?', 'Confirmation');
            modalWindow.result.then(function () {

                removeClient(client);

            }, function () {
            });
        }

        function removeClient(client) {
            ClientsFactory.delete({ id: client.clientId }).$promise.then(function (client) {
                var modalWindow = HelperFactory.openMessageModalWindow('The Client "' + client.name + '" has been deleted.', 'Deleted');
                init();
            }, UtilityService.onRequestError);
        }

    }
})();