(function () {
    angular.module('springwise').controller('EditContractLinesCtrl', EditContractLinesCtrl);

    function EditContractLinesCtrl($scope, $routeParams, CurrentUserFactory, ClientsFactory, UtilityService, ContractsFactory, HelperFactory, $location) {
        $scope.contractId = $routeParams.contractId;
        $scope.limitOptions = [
            { id: 10, name: '10 results' },
            { id: 20, name: '20 results' },
            { id: 30, name: '30 results' },
            { id: 40, name: '40 results' }
        ];
        $scope.availablesLimit = $scope.limitOptions[0];
        $scope.availablesPage = 1;
        $scope.onAvailablesPaginationChange = function (page) {
            $scope.availablesPage = page;
            activate();
        };
        function onAvailablesPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                $scope.availablesPage = 1;
                activate();
            }
        }
        $scope.$watch('availablesLimit', onAvailablesPageLimitChange);

        $scope.linesLimit = $scope.limitOptions[0];
        $scope.linesPage = 1;
        $scope.onLinesPaginationChange = function (page) {
            $scope.linesPage = page;
            activate();
        };
        function onLinesPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                $scope.linesPage = 1;
                activate();
            }
        }
        $scope.$watch('linesLimit', onLinesPageLimitChange);

        function activate() {
            var params = getFilterParams();
            ContractsFactory.api.getContractLinesForEdition(params).$promise.then(function (response) {
                $scope.response = response;
            }, UtilityService.onRequestError);
        }

        $scope.createLine = function(line) {
            $location.path('/Contracts/EditContract/' + $routeParams.contractId + '/Lines/NewLine/' + line.locationId + '/' + line.serviceId);
        };

        $scope.editLine = function (line) {
            $location.path('/Contracts/EditContract/' + $routeParams.contractId + '/Lines/EditLine/' + line.contractLineId);
        };

        $scope.removeLine = function (line) {
            var params = getFilterParams();
            params.contractLineId = line.contractLineId;
            ContractsFactory.api.removeContractLine(params).$promise.then(function (response) {
                $scope.response = response;
            }, UtilityService.onRequestError);
        };
     
        function getFilterParams() {
            var params = {
                contractId: $routeParams.contractId,
                hasContract: $scope.hasContract,
                limitAvailable: $scope.availablesLimit.id,
                pageAvailable: $scope.availablesPage,
                limitLines: $scope.linesLimit.id,
                pageLines: $scope.linesPage
            };
            if ($scope.startDate) {
                params.rangeStartDate = HelperFactory.dateToServer($scope.startDate);
            }
            if ($scope.endDate) {
                params.rangeEndDate = HelperFactory.dateToServer($scope.endDate);
            }
            return params;
        }

        $scope.searchClick = function () {
            $scope.availablesPage = 1;
            activate();
        };

        activate();
    }
})();