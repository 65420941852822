(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('NotificationTypesFactory', NotificationTypesFactory);

    function NotificationTypesFactory($resource) {
        return {
            api: $resource(
                null,
                null,
                {
                    getList: {
                        method: 'GET',
                        url: 'api/NotificationTypes/GetList'
                    },
                    getForEdition: {
                        method: 'GET',
                        url: 'api/NotificationTypes/GetForEdition'
                    },
                    save: {
                        method: 'POST',
                        url: 'api/NotificationTypes/Save'
                    }
                }
            )              
        };
    }
})();