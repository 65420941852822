(function () {
    angular.module('springwise').directive('swMinLengthError', swMinLengthError);

    swMinLengthError.$inject = [];

    function swMinLengthError() {
        return {
            templateUrl: '/app/shared/directives/errors/min-length/minLengthErrorView.html',
            restrict: 'E',
            scope: {
                field: '=field',
                minLength: '=minLength',
                validation: '='
            }
        };
    }
})();