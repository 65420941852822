(function () {
    angular.module('springwise').directive('swErrorAlert', swErrorAlert);

    function swErrorAlert() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/directives/alerts/swErrorAlert.html',
            controller: "swErrorAlertCtrl",
            scope: {
                swError: '='
            },
            link: function ($scope, element, attrs, ctrl) {
            }
        };
    }

    angular.module('springwise').controller("swErrorAlertCtrl", swErrorAlertCtrl);

    function swErrorAlertCtrl($scope, $location) {
    }
})();