(function () {
    angular.module('springwise').directive('swNumberDecimalInlineField', swNumberDecimalInlineField);

    swNumberDecimalInlineField.$inject = [];

    function swNumberDecimalInlineField() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/directives/fields/numberDecimalInlineFieldView.html',
            scope: {
                field: '=field',
                max: '=max',
                min: '=min',
                minlength: '=minlength',
                maxlength: '=maxlength',
                req: '=required',
                model: '=model',
                name: '@name',
                label: '@label',
                disabled: '=disabled'
            }
        };
    }
})();