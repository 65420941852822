(function () {
    angular.module('springwise').directive('swTable', swTable);

    swTable.$inject = [];

    function swTable() {
        return {
            restrict: 'E',
            templateUrl: 'app/shared/directives/table/tableView.html',
            scope: {
                config: '=config'
            },
            link: function (scope, element, attrs, ctrl) {
                ctrl.init(element, scope);
            },
            controller: 'TableCtrl'
        };
    }
})();