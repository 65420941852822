(function () {
    angular.module('springwise').factory('ServicesByLocationService', ServicesByLocationService);

    ServicesByLocationService.$inject = ['$resource'];

    function ServicesByLocationService($resource) {
        return $resource(
            'api/locations/:locationId/services/:serviceId', {
                locationId: '@locationId',
                serviceId: '@serviceId'
            }, {
                getTotalRecords: {
                    url: 'api/locations/:locationId/services/total',
                    method: 'GET'
                }
            });
    }
})();