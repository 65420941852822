(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('StagesFactory', StagesFactory);

    StagesFactory.$inject = ['$resource'];

    function StagesFactory($resource) {
        return $resource('api/Stages/:id',
            { id: '@stateId' },
            {
                getStages: {
                    url: 'api/Stages/GetStages',
                    method: 'GET',
                    isArray: true
                }
            }
        );
    }
})();