(function () {
    angular.module('springwise').controller('ViewContractCtrl', ViewContractCtrl);

    function ViewContractCtrl($scope, $routeParams, CurrentUserFactory, ClientsFactory, UtilityService, ContractsFactory, HelperFactory, ngAuthSettings, contractStatuses, $route, $location) {


        $scope.isVendorUser = false;
        $scope.isSWAdminUser = false;
        $scope.isClientUser = false;

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();

            $scope.isVendorUser = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClientUser = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSWAdminUser = !$scope.isVendorUser && !$scope.isClientUser;

        }

        $scope.contractIsNew = false;
        $scope.contractNotReadyToReview = false;
        $scope.contractAlreadyReviewedByVendor = false;
        $scope.contractAlreadyReviewedByClient = false;
        $scope.contractIsActive = false;


        $scope.page = 1;
        $scope.limitOptions = [
                { id: 10, name: '10 results' },
                { id: 20, name: '20 results' },
                { id: 30, name: '30 results' },
                { id: 40, name: '40 results' }
        ];
        $scope.limit = $scope.limitOptions[0];
        $scope.onPaginationChange = onPaginationChange;
        $scope.$watch('limit', onPageLimitChange);

        function activate() {            
            ContractsFactory.api.getContractForView({ contractId: $routeParams.contractId }).$promise.then(function (contract) {

                $scope.contract = contract;
                $scope.contractFileId = contract.customContractId || contract.generatedContractId;
                if ($scope.contractFileId) {
                    $scope.contractFileURL = ngAuthSettings.apiServiceBaseUri + 'api/SWFiles/GetFile/' + $scope.contractFileId;
                }

                if ($scope.contract.contractStatusId === contractStatuses.pendingNTEApproval && $scope.currentUser.userId === $scope.contract.approverId) {
                    $scope.showNTEApprovalOptions = true;
                }
                else {
                    $scope.showNTEApprovalOptions = false;
                }

                setResponse(contract);

            }, UtilityService.onRequestError);

            searchForContractLines();

            //ContractsFactory.api.getContractLinesForView({ contractId: $routeParams.contractId }).$promise.then(function (response) {
            //    $scope.response = response;
            //}, UtilityService.onRequestError);
        }

        $scope.terminateLine = function (line) {
            var params = {};
            params.contractLineId = line.contractLineId;
            ContractsFactory.api.terminateContractLine(params).$promise.then(function (response) {
                activate();
            }, UtilityService.onRequestError);
        };

        $scope.goBack = function () {
            $location.url('/Contracts');
        };

        function searchForContractLines()
        {
            var params = {
                page: $scope.page,
                limit: $scope.limit.id,
                contractId: $routeParams.contractId
            };

            ContractsFactory.api.getContractLinesForView(params).$promise.then(function (response) {
               $scope.response = response;
            }, UtilityService.onRequestError);
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                $scope.page = 1;                
                searchForContractLines();
            }
        }

        
        function onPaginationChange(page) {
            $scope.page = page;           
            searchForContractLines();
        }

        $scope.alerts = [];
        function addAlert(message, type) {
            $scope.alerts.push({ message: message, type: type });
        }

        function setResponse(contract) {

            if ($scope.contract.contractStatusId == contractStatuses.new) {
                $scope.contractIsNew = true;
            }

            if ($scope.contract.contractStatusId < contractStatuses.readyToReviewByVendor) {
                $scope.contractNotReadyToReview = true;
            }

            if ($scope.contract.contractStatusId == contractStatuses.readyToReviewByVendor) {
                $scope.contractReadyToReview = true;
            }
            
            if ($scope.contract.contractStatusId >= contractStatuses.reviewedAndAcceptedByVendor) {            
                $scope.contractAlreadyReviewedByVendor = true;
            }
            
            if ($scope.contract.contractStatusId >= contractStatuses.reviewedAndAcceptedByClientThenActiveContract) {
                $scope.contractAlreadyReviewedByClient = true;
            }

            if ($scope.contract.contractStatusId == contractStatuses.reviewedAndAcceptedByVendor) {
                $scope.contractAcceptedByVendor = true;
            }

            if ($scope.contract.contractStatusId == contractStatuses.reviewedAndRejectedByVendor) {
                $scope.contractRejectedByVendor = true;
            }

            if ($scope.contract.contractStatusId == contractStatuses.reviewedAndAcceptedByClientThenActiveContract) {
                $scope.contractIsActive = true;
            }

            if ($scope.contract.contractStatusId == contractStatuses.reviewedAndRejectedByClient) {
                $scope.contractRejectedByClient = true;
            }

            $scope.nteRejected = $scope.contract.contractStatusId === contractStatuses.nteRejected;
            $scope.pendingNTEApproval = $scope.contract.contractStatusId === contractStatuses.pendingNTEApproval;
        }

        $scope.vendorAcceptsContract = function () {

            ContractsFactory.api.vendorAcceptsContract({ contractId: $scope.contract.contractId }).$promise.then(function (response) {
                setResponse(response);
                $scope.contractAlreadyReviewedByVendor = true;
                addAlert('Contract Accepted Successfully', 'success');
                activate();
                $scope.contractReadyToReview = false;
            }, UtilityService.onRequestError);

        };

        $scope.vendorRejectsContract = function () {

            ContractsFactory.api.vendorRejectsContract({ contractId: $scope.contract.contractId }, null).$promise.then(function (response) {
                setResponse(response);
                $scope.contractAlreadyReviewedByVendor = true;
                addAlert('Contract Rejected by the Vendor', 'success');                              
                activate();
                $scope.contractReadyToReview = false;
            }, UtilityService.onRequestError);

        };

        $scope.clientAcceptsContract = function () {

            ContractsFactory.api.clientAcceptsContract({ contractId: $scope.contract.contractId }, null).$promise.then(function (response) {
                setResponse(response);
                $scope.contractAlreadyReviewedByClient = true;
                addAlert('Contract Accepted Successfully', 'success');
                activate();
                $scope.contractAcceptedByVendor = false;
            }, UtilityService.onRequestError);

        };

        $scope.clientRejectsContract = function () {

            ContractsFactory.api.clientRejectsContract({ contractId: $scope.contract.contractId }, null).$promise.then(function (response) {
                setResponse(response);
                $scope.contractAlreadyReviewedByClient = true;
                addAlert('Contract Rejected by the Client', 'success');
                activate();
                $scope.contractAcceptedByVendor = false;
            }, UtilityService.onRequestError);

        };

        $scope.respondNTEApproval = function (action) {
            ContractsFactory.api.respondPendingApproval(null, { contractId: $scope.contract.contractId, action: action }).$promise.then(function (response) {
                activate();
            }, UtilityService.onRequestError);

        };

        activate();


    }
})();