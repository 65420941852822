(function () {
    angular.module('springwise').factory('ClientsFactory', ClientsFactory);

    ClientsFactory.$inject = ['$resource'];

    function ClientsFactory($resource) {
        return $resource(
            'api/Clients/:id',
            { id: '@id' },
            {
                update: {
                    method: 'PUT'
                },
                getTotalRecords: {
                    method: 'GET',
                    url: 'api/Clients/Total',
                    isArray: false
                },
                queryOptions: {
                    method: 'GET',
                    url: 'api/Clients/options',
                    isArray: true
                },
                goLive: {
                    method: 'POST',
                    url: 'api/Clients/:id/Go/Live'
                },
                isAlive: {
                    method: 'GET',
                    url: 'api/Clients/:id/Is/Alive'
                },
                getBrandData: {
                    method: 'GET',
                    url: 'api/Clients/:id/ClientBrand'
                },
                getBrandInfo: {
                    method: 'GET',
                    url: 'api/Clients/:brandUrl/BrandInfo'
                },
                activate: {
                    method: 'PUT',
                    url: 'api/Clients/:id/activate'
                },
                deactivate: {
                    method: 'PUT',
                    url: 'api/Clients/:id/deactivate'
                },
                updateClientWRConfiguration: {
                    method: 'PUT',
                    url: 'api/Clients/UpdateClientWRConfiguration'
                },
                getClientWRConfiguration: {
                    method: 'GET',
                    url: 'api/Clients/GetClientWRConfiguration'
                },
                setDefaultPriorityLevel: {
                    method: 'PUT',
                    url: 'api/clients/:clientId/SetDefaultPriorityLevel/:priorityLevelId'
                },

                //Contract Attachments
                getContractAttachmentsList: {
                    method: 'GET',
                    url: 'api/clients/GetContractAttachmentsList/',
                    isArray: true
                },
                getContractAttachmentForEdition: {
                    method: 'GET',                    
                    url: 'api/clients/GetAttachmentForEdition/:clientContractAttachmentId'
                },
                updateClientContractConfiguration: {
                    method: 'PUT',
                    url: 'api/Clients/UpdateClientContractConfiguration'
                },
                getClientsByVendorId: {
                    method: 'GET',
                    url: 'api/Clients/getClientsByVendorId/:vendorId',
                    isArray: true
                },
                getClientsSWManaged: {
                    method: 'GET',
                    url: 'api/Clients/getClientsSWManaged',
                    isArray: true
                },
                exportClients: {
                    method: 'GET',
                    url: 'api/Clients/exportClients',
                    isArray: false
                }
            });
    }
})();