(function () {
    angular.module('springwise').directive('swAddressForm', addressForm);

    addressForm.$inject = [];

    function addressForm() {
        var directive = {
            templateUrl: '/app/shared/components/address/addressFormView.html',
            restrict: 'E',
            scope: {
                address: '=address',
                disabled: '=disabled',
                phoneExample: '=phoneExample',
                phonePattern: '=phonePattern',
                validation: '='
            },
            link: function ($scope, element, attrs, ctrl) {
                ctrl.init(element, $scope);
            },
            controller: 'AddressFormCtrl'
        };

        return directive;
    }
})();