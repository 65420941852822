(function () {
    angular.module('springwise').controller('ClientLocationsIndexCtrl', ClientLocationsIndexCtrl);

    ClientLocationsIndexCtrl.$inject = ['$scope', '$routeParams', 'LocationsFactory', 'UtilityService', '$window', 'ngAuthSettings', 'permissions', 'CurrentUserFactory', 'ImportsFactory', 'HelperFactory'];

    function ClientLocationsIndexCtrl($scope, $routeParams, LocationsFactory, UtilityService, $window, ngAuthSettings, permissions, CurrentUserFactory, ImportsFactory, HelperFactory) {
        $scope.clientId = $routeParams.clientId;
        $scope.locations = [];
        $scope.page = 1;
        $scope.orderBy = 'name';

        $scope.searchLocations = searchLocations;
        $scope.deactivateLocation = deactivateLocation;
        $scope.activateLocation = activateLocation;
        $scope.onPaginationChange = onPaginationChange;
        $scope.exportLocations = exportLocations;
        $scope.getImportTemplate = getImportTemplate;
        $scope.deleteLocation = deleteLocation;

        $scope.$watch('limit', onPageLimitChange);

        //Get user
        var currentUser = CurrentUserFactory.get();
        $scope.canCreateLocations = false;
        $scope.canEditLocations = false;
        if (currentUser) {
            $scope.canCreateLocations = $scope.currentUser.permissions.indexOf(permissions.createLocations) !== -1;
            $scope.canEditLocations = $scope.currentUser.permissions.indexOf(permissions.editLocations) !== -1;
        }

        activate();

        function activate() {
            $scope.limit = { id: 10, name: '10 results' };
            $scope.limitOptions = [
                { id: 10, name: '10 results' },
                { id: 30, name: '30 results' },
                { id: 50, name: '50 results' },
                { id: 100, name: '100 results' }
            ];
            searchLocationsWithParams({
                page: $scope.page,
                limit: $scope.limit.id,
                orderBy: $scope.orderBy,
                clientId: $scope.clientId
            }, { clientId: $scope.clientId });
        }

        function searchLocations() {
            var params = {
                page: $scope.page,
                limit: $scope.limit.id,
                orderBy: $scope.orderBy,
                clientId: $scope.clientId
            };

            var paramsForTotalRecords = {
                clientId: $scope.clientId
            };

            searchLocationsWithParams(params, paramsForTotalRecords);
        }

        function searchLocationsWithParams(params, paramsForTotalRecords) {

            LocationsFactory.getTotalRecords(paramsForTotalRecords).$promise.then(function (total) {
                if ($scope.total !== total.totalRecords) {
                    $scope.total = total.totalRecords;
                }

                LocationsFactory.query(params).$promise.then(function (locations) {
                    $scope.locations = locations;
                });
            }, UtilityService.onRequestError);
        }

        function exportLocations() {
            var params = {
                page: 1,
                limit: 0,
                orderBy: $scope.orderBy,       
                clientId: $scope.clientId          
            };

            LocationsFactory.exportLocations(params).$promise.then(function (msg) {
                if (!msg.hasError) {
                    addAlert(msg.errorMessage, 'success');
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetExportedFile/' + fileName[0] + '/' + fileName[1];
                    $window.open(link, "", "");
                }
                else {
                    addAlert(msg.errorMessage, 'danger');
                }

            }, UtilityService.onRequestError);
        }

        function getImportTemplate() {
            var params = {
                templateType: 3,  //ImportTemplateType.Location = 3
                clientId: $scope.clientId
            };

            ImportsFactory.api.getImportTemplate(params).$promise.then(function (msg) {
                if (!msg.hasError) {
                    addAlert(msg.errorMessage, 'success');
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetFile/' + fileName[0] + '/' + fileName[1];
                    var download_iframe = document.getElementById('download_iframe');
                    download_iframe.src = link;
                }
                else {
                    addAlert(msg.errorMessage, 'danger');
                }

            }, UtilityService.onRequestError);
        }


        $scope.alerts = [];
        function addAlert(message, type) {
            $scope.alerts.push({ message: message, type: type });
        }

        function deactivateLocation(location) {
            LocationsFactory.deactivate({ locationId: location.locationId }).$promise.then(searchLocations, function (e) {
            //location.$delete(searchLocations, function (e) {
                if (e.status == 500) {
                    alert(e.data.exceptionMessage);
                }
            });
        }

        function activateLocation(location) {
            LocationsFactory.activate({ locationId: location.locationId }).$promise.then(searchLocations, function (e) {
                if (e.status == 500) {
                    alert(e.data.exceptionMessage);
                }
            });
        }

        function onPaginationChange(page) {
            $scope.page = page;
            $scope.searchLocations();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.page !== 1) {
                    $scope.page = 1;
                }

                $scope.searchLocations();
            }
        }

        $scope.exportLocationServices = function () {
            LocationsFactory.exportLocationServices({ locationId: 0, clientId: $scope.clientId }, null).$promise.then(function (msg) {
                if (!msg.hasError) {
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetExportedFile/' + fileName[0] + '/' + fileName[1];
                    var download_iframe = document.getElementById('download_iframe');
                    download_iframe.src = link;
                }
                else {
                    UtilityService.showError(msg.errorMessage);
                }
            }, UtilityService.onRequestError);
        };


        function deleteLocation(location) {
            var modalWindow = HelperFactory.openAcceptCancelModalWindow('The Location is going to be Deleted Permanently. Do you want to proceed?', 'Confirmation');
            modalWindow.result.then(function () {

                removeLocation(location);

            }, function () {
            });
        }

        function removeLocation(location) {
            LocationsFactory.delete({ id: location.locationId }).$promise.then(function (location) {
                var modalWindow = HelperFactory.openMessageModalWindow('The Location "' + location.name + '" has been deleted.', 'Deleted');
                activate();
            }, UtilityService.onRequestError);
        }
    }
})();
