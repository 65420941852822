(function () {
    angular.module('springwise')
        .directive('swUploadFileDescriptionValidator', swUploadFileDescriptionValidator);

    swUploadFileDescriptionValidator.$inject = ['ngAuthSettings', '$http'];

    function swUploadFileDescriptionValidator(ngAuthSettings, $http) {
        return {
            require: 'ngModel',
            link: function ($scope, element, attrs, ngModel) {
                ngModel.$validators.customUploadFileDescriptionValidator = function (modelValue) {
                    if (ngModel.$dirty) {
                        var isEmpty = modelValue === "";
                        ngModel.$setValidity("isnodescription", !isEmpty);
                        return !isEmpty;                
                    }

                    return true;    // Default value is not flagged as invalid
                };
            }
        };
    }

})();