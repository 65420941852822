
// https://www.algotech.solutions/blog/javascript/how-to-create-custom-validator-directives-with-angularjs/

(function () {
    angular.module('springwise')
        .directive('swEmailValidator', swEmailValidator);

    swEmailValidator.$inject = ['ngAuthSettings','$http'];

    function swEmailValidator(ngAuthSettings,$http) {
        return {
            require: 'ngModel',
            link: function ($scope, element, attrs, ngModel) {
                ngModel.$validators.customEmailValidator = function (modelValue) {

                    // Validate only when changed
                    if (ngModel.$dirty) {
                        $http.get(ngAuthSettings.apiServiceBaseUri + 'api/Users/NewEmailIsUsed?email=' + modelValue)
                            .success(function(response) {
                                var isUsed = response && response.isUsed;
                                ngModel.$setValidity("newemailused", !isUsed);
                                return !isUsed;
                            });
                    }

                    return true;    // Default value is not flagged as invalid
                };
            }
        };
    }

})();