(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('CurrentBrandInfoFactory', CurrentBrandInfoFactory);

    CurrentBrandInfoFactory.$inject = ['ngAuthSettings', 'CurrentUserFactory', 'ClientsFactory'];

    function CurrentBrandInfoFactory(ngAuthSettings, CurrentUserFactory, ClientsFactory) {
        var currentBrandInfoKey = 'swCurrentBrandInfo';

        var currentBrandInfoFactory = {
            exists: exists,
            get: get,
            set: set,
            remove: remove,
            getFullPath: getFullPath,
            initialize: initialize,
            getIconUrl: getIconUrl,
            showBrandIcon: showBrandIcon,
            setFromUserContext: setFromUserContext
        };

        function exists() {
            var currentBrandInfo = localStorage.getItem(currentBrandInfoKey);
            return currentBrandInfo !== null && currentBrandInfo !== undefined;
        }

        function get() {
            if (!angular.currentBrandInfo)
                angular.currentBrandInfo = JSON.parse(localStorage.getItem(currentBrandInfoKey));
            return angular.currentBrandInfo;
        }

        function set(currentBrandInfo) {
            while (!currentBrandInfo.iconVersionId)
                currentBrandInfo = JSON.parse(currentBrandInfo);
            angular.currentBrandInfo = currentBrandInfo;
            localStorage.setItem(currentBrandInfoKey, JSON.stringify(currentBrandInfo));
        }
        function remove() {
            localStorage.removeItem(currentBrandInfoKey);
        }

        function getFullPath(versionId)
        {
            return ngAuthSettings.apiServiceBaseUri + "api/DocumentRepository/BrandImage/" + versionId + "_" + Math.round(Math.random() * 10000);
        }

        function initialize() {
            var brandInfoData = get();
            if ((brandInfoData === null) || (brandInfoData === undefined)) {
                brandInfoData = {
                    iconVersionId: -1,
                    logoVersionId: -1,
                    clientName: ""
                };
            }
            set(brandInfoData);
        }

        function showBrandIcon() {
            if (get().iconVersionId > 0) {
                return true;
            }
            else
                return false;
        }

        function getIconUrl() {
            if (get().iconVersionId > 0) {
                return getFullPath(get().iconVersionId);
            }
            else
                return '';

        }

        function setFromUserContext(userContext) {
            if (userContext.client) {
                ClientsFactory.getBrandData({ id: userContext.client.clientId }).$promise.then(function (brandData) {
                    var brandInfo = {
                        iconVersionId: -1,
                        logoVersionId: -1,
                        clientName: ""
                    };
                    if ((brandData !== null) || (brandData !== undefined)) {
                        if (brandData.iconVersion)
                            brandInfo.iconVersionId = brandData.iconVersion.documentVersionId;
                        if (brandData.logoVersion)
                            brandInfo.logoVersionId = brandData.logoVersion.documentVersionId;
                        brandInfo.clientName = brandData.companyName;
                    }
                    set(brandInfo);
                    CurrentUserFactory.set(userContext);
                });
            }
            else {
                var brandInfo = {
                    iconVersionId: -1,
                    logoVersionId: -1,
                    clientName: ""
                };
                set(brandInfo);
                CurrentUserFactory.set(userContext);
            }
        }
        return currentBrandInfoFactory;
    }
})();