(function () {

    angular.module('springwise').directive("addbuttons", addbuttons);
    function addbuttons($compile) {
        return {            
            scope: {
                count: '=count',
                photoFilesCollection: '=photoFilesCollection'
            },
            link: function (scope, element, attrs, ngModel) {

                element.bind("click", function () {
                    scope.count++;
                    angular.element(document.getElementById('space-for-canvas')).append($compile("<sw-canvas-WR-Photo-handling description='description' canvas-file='photoFile" + scope.count + "' count=" + scope.count + " photoFilesCollection='" + scope.photoFilesCollection + "' ng-required=''></sw-canvas-WR-Photo-handling>")(scope));                   

                });
            }
        };
    }
})();