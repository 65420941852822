(function () {
    angular.module('springwise').directive('swToolbar', swToolbar);

    swToolbar.$inject = [];

    function swToolbar() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/components/toolbar/toolbar.html',
            scope: {
                currentUser: '=currentUser'
            },
            controller: 'toolbarCtrl'
        };
    }
})();
