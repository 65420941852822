(function () {
    angular.module('springwise').controller('LocationServicesIndexCtrl', LocationServicesIndexCtrl);

    LocationServicesIndexCtrl.$inject = ['$scope', '$routeParams', 'CurrentUserFactory', 'CategoryTypesService', 'ServicesByLocationService', 'ServicesService', 'UtilityService', 'LocationsFactory', 'ngAuthSettings'];

    function LocationServicesIndexCtrl($scope, $routeParams, CurrentUserFactory, CategoryTypesService, ServicesByLocationService, ServicesService, UtilityService, LocationsFactory, ngAuthSettings) {
        init();

        function init() {
            $scope.orderBy = 'serviceId';
            $scope.isDescending = false;
            $scope.page = 1;
            $scope.limit = UtilityService.defaultLimitOption;
            $scope.limitOptions = UtilityService.limitOptions;
            $scope.locationId = $routeParams.locationId;
            $scope.services = [];
            $scope.locationServices = [];
            $scope.categoryTypes = CategoryTypesService.categoryTypes;

            $scope.addService = addService;
            $scope.removeService = removeService;
            $scope.removeServiceById = removeServiceById;
            $scope.toggleService = toggleService;
            $scope.onPaginationChange = onPaginationChange;
            $scope.$watch('limit', onPageLimitChange);

            queryServicesByLocation();
            queryServices();
        }

        function onPaginationChange(page) {
            $scope.page = page;
            queryServicesByLocation();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.page !== 1) {
                    $scope.page = 1;
                }
                queryServicesByLocation();
            }
        }

        function queryServicesByLocation() {
            var params = { locationId: $scope.locationId, page: $scope.page, limit: $scope.limit.id, orderBy: $scope.orderBy, isDescending: $scope.isDescending };
            ServicesByLocationService.getTotalRecords(params).$promise.then(onQueryTotalSucceded, UtilityService.onRequestError);

            function onQueryServicesByLocationSucceded(records) {
                $scope.locationServices = records;
            }

            function onQueryTotalSucceded(total) {
                if ($scope.total !== total.totalRecords) {
                    if ($scope.page !== 1) {
                        $scope.page = 1;
                        params.page = $scope.page;
                    }
                    $scope.total = total.totalRecords;
                }
                ServicesByLocationService.query(params).$promise.then(onQueryServicesByLocationSucceded, UtilityService.onRequestError);
            }
        }

        function queryServices() {
            ServicesService.query({ locationId: $scope.locationId }).$promise.then(onQuerySucceded, UtilityService.onRequestError);

            function onQuerySucceded(records) {
                $scope.services = records;
            }
        }

        function addService(scope) {
            var service = scope.$nodeScope.$modelValue;
            var req = { locationId: $scope.locationId, serviceId: service.id };
            ServicesByLocationService.save(req).$promise.then(onAddSucceded, UtilityService.onRequestError);

            function onAddSucceded() {
                service.added = true;
                queryServicesByLocation();
            }
        }

        function removeService(scope) {
            var service = scope.$nodeScope.$modelValue;
            var req = { locationId: $scope.locationId, serviceId: service.id };
            ServicesByLocationService.delete(req).$promise.then(onRemoveSucceded, UtilityService.onRequestError);

            function onRemoveSucceded() {
                service.added = false;
                queryServicesByLocation();
            }
        }

        function removeServiceById(id) {
            var req = { locationId: $scope.locationId, serviceId: id };
            ServicesByLocationService.delete(req).$promise.then(onRemoveSucceded, UtilityService.onRequestError);

            function onRemoveSucceded() {
                updateTreeNode(id);
                queryServicesByLocation();
            }

            function updateTreeNode(id) {
                for (var i = 0; i < $scope.services.length; i++) {
                    var category = $scope.services[i];
                    if (category.children) {
                        for (var j = 0; j < category.children.length; j++) {
                            var subCategory = category.children[j];
                            if (subCategory.children) {
                                for (var k = 0; k < subCategory.children.length; k++) {
                                    var service = subCategory.children[k];
                                    if (service.id === id) {
                                        service.added = false;
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        function toggleService(scope, callback) {
            var nodeScope = scope.$nodeScope;
            if (nodeScope.collapsed) {
                var service = scope.$nodeScope.$modelValue;
                var req = { locationId: $scope.locationId, serviceId: service.id };
                ServicesByLocationService.get(req).$promise.then(onGetSucceded, UtilityService.onRequestError);
            } else {
                scope.toggle();
            }

            function onGetSucceded(res) {
                nodeScope.$modelValue.children = res.children;
                scope.toggle();
                if (callback) {
                    callback(scope);
                }
            }
        }

        $scope.exportLocationServices = function () {
            LocationsFactory.exportLocationServices({ locationId: $scope.locationId, clientId: 0 }, null).$promise.then(function (msg) {
                if (!msg.hasError) {
                    var fileName = msg.description.split('.');
                    var link = ngAuthSettings.apiServiceBaseUri + 'api/Imports/GetExportedFile/' + fileName[0] + '/' + fileName[1];
                    var download_iframe = document.getElementById('download_iframe');
                    download_iframe.src = link;
                }
                else {
                    UtilityService.showError(msg.errorMessage);
                }
            }, UtilityService.onRequestError);
        };
    }
})();