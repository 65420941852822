(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('TimeZonesFactory', TimeZonesFactory);

    TimeZonesFactory.$inject = ['$resource'];

    function TimeZonesFactory($resource) {
        return $resource('api/Users/TimeZonesList');
    }
})();