(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('workRecordsFormStepTwoCtrl', workRecordsFormStepTwoCtrl);

    workRecordsFormStepTwoCtrl.$inject = ['$scope',
        'CurrentUserFactory',
        'ClientsFactory',
        'permissions',
        'WorkRecordsFactory',
        '$location',
        'UtilityService',
        'workRecordsStages',
        'workRecordsStatuses',
        '$routeParams',
        'QuestionsService',
        'AnswersService',
        'WorkRecordTriageAnswersService',
        'WorkRecordService'];

    function workRecordsFormStepTwoCtrl($scope,
        CurrentUserFactory,
        ClientsFactory,
        permissions,
        WorkRecordsFactory,
        $location,
        UtilityService,
        workRecordsStages,
        workRecordsStatuses,
        $routeParams,
        QuestionsService,
        AnswersService,
        WorkRecordTriageAnswersService,
        WorkRecordService) {

        if (CurrentUserFactory.exists()) {
            var currentUser = CurrentUserFactory.get();
        }

        $scope.WRanswers = [];
        $scope.questions = [];
        $scope.wrAlreadyExists = false;
        $scope.waitingWRanswers = 0;

        $scope.advanceWorkRecord = advanceWorkRecord;
        $scope.submitWorkRecordAnswer = submitWorkRecordAnswer;
        $scope.continueWorkRecord = continueWorkRecord;

        activate();

        function activate() {
            if ($routeParams.workRecordId) {
                WorkRecordsFactory.get({ id: $routeParams.workRecordId }).$promise.then(function (wr) {
                    $scope.wr = wr;
                    if ($scope.wr.stageId > workRecordsStages.TriageQuestionnaireStage.stageId) {
                        $scope.wrAlreadyExists = true;
                    }
                    queryQuestionnaire();
                }, UtilityService.onRequestError);
            }
        }

        function queryQuestionnaire() {
            QuestionsService.getQuestionnaireQuestions({ baseTriageQuestionnaireId: $scope.wr.questionnaireId }).$promise.then(function (questions) {
                $scope.questions = questions;
                queryWRanswers();
            }, UtilityService.onRequestError);
        }

        function queryWRanswers() {
            $scope.waitingWRanswers++;
            WorkRecordTriageAnswersService.queryAnswers({ questionnaireId: $scope.wr.questionnaireId, workRecordId: $scope.wr.id }).$promise.then(function (WRanswers) {
                $scope.WRanswers = WRanswers;
                displayWRanswersForQuestions($scope.questions);
                $scope.waitingWRanswers--;
            }, UtilityService.onRequestError);
        }

        function advanceWorkRecord() {
            if ($scope.waitingWRanswers > 0)
                return;

            if (questionsPending($scope.questions)) {
                UtilityService.openMessageModalWindow('There are still questions that have not been answered', 'Pending Questions', 'error');
                return;
            }

            $scope.wr.stageId = workRecordsStages.WorkRecordCreationStage.stageId;
            WorkRecordsFactory.advanceStep({ id: $scope.wr.id, step: 2 }, $scope.wr).$promise.then(onAdvanceSucceded, UtilityService.onRequestError);
            function onAdvanceSucceded() {
                $location.path('/workRecordsHome/' + $scope.wr.id + '/edit/stepThree');
            }
        }

        function questionsPending(questions) {
            var pending = false;
            angular.forEach(questions, function (question) {
                if (questionPending(question)){
                    pending = true;
                }
            });
            return pending;
        }

        function questionPending(question) {
            var pending = false;
            if (question.hasChildren) {
                pending = true;
                var answerFound = null;
                if (question.children) {
                    angular.forEach(question.children, function (answer) {
                        angular.forEach($scope.WRanswers, function (wrAnswer) {
                            if (wrAnswer.triageAnswerId == answer.id) {
                                answerFound = answer;
                            }
                        });
                    });
                }
                if (answerFound) {
                    if (answerFound.hasChildren) {
                        if (answerFound.children) {
                            pending = questionsPending(answerFound.children);
                        }
                    } else {
                        pending = false;
                    }
                }
            }
            return pending;
        }

        function displayWRanswersForQuestions(questions) {
            angular.forEach(questions, function (question) {
                displayWRanswersForQuestion(question);
            });
        }

        function displayWRanswersForQuestion(question) {
            if (!question.hasChildren)
                return;
            var isOneChecked = false;
            angular.forEach(question.children, function (answer) {
                answer.expanded = false;
                answer.hide = false;
                answer.isChecked = false;
                answer.freeText = null;
                for (var i = 0; i < $scope.WRanswers.length; i++) {
                    if ($scope.WRanswers[i].triageAnswerId == answer.id) {
                        answer.isChecked = true;
                        answer.freeText = $scope.WRanswers[i].answer;
                        isOneChecked = true;
                    }
                }
            });
            if (isOneChecked) {
                angular.forEach(question.children, function (answer) {
                    if (answer.isChecked) {
                        if (answer.hasChildren) {
                            displayWRanswersForQuestions(answer.children);
                            answer.expanded = true;
                        }
                    } else {
                        answer.hide = true;
                    }
                });
            }
            question.expanded = true;
        }

        function continueWorkRecord() {
            if ($scope.wr.id) {
                $location.path('/workRecordsHome/' + $scope.wr.id + '/edit/stepThree');
            }
        }

        function submitWorkRecordAnswer(scope) {
            var answer = scope.$nodeScope.$modelValue;
            var question = scope.$parent.$parent.$parent.$nodeScope.$modelValue;
            var found = false;
            var WRanswer = null;
            for (var i = 0; i < $scope.WRanswers.length; i++) {
                if ($scope.WRanswers[i].triageAnswerId === answer.id) {
                    found = true;
                    WRanswer = $scope.WRanswers[i];
                }
            }

            if (found) {
                WorkRecordTriageAnswersService.deleteAnswer({ id: WRanswer.id }).$promise.then(onDeleteWRanswerSucceded, UtilityService.onRequestError);
            } else {
                var req = {
                    workRecordId: parseInt($scope.wr.id),
                    baseQuestionnaireId: $scope.wr.questionnaireId,
                    triageAnswerId: answer.id,
                };

                if (answer.freeText !== null || answer.freeText !== undefined) {
                    req.answer = answer.freeText;
                } else {
                    req.answer = answer.text;
                }
                WorkRecordTriageAnswersService.save(req).$promise.then(onSaveWRanswerSucceded, UtilityService.onRequestError);
            }

            function onDeleteWRanswerSucceded() {
                queryWRanswers();
            }

            function onSaveWRanswerSucceded(res) {
                queryWRanswers();
            }
        }

        $scope.cancelWorkRecord = function () {
            WorkRecordService.cancelWorkRecord($scope.wr.id);
        };
    }
})();