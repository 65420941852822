(function () {
	angular.module('springwise').directive('swWarning', swWarning);
	swWarning.$inject = [];

	function swWarning() {
		return {
			templateUrl: '/app/shared/directives/warning/warningView.html',
			restrict: 'E',
			scope: {
				message: '='
			}
		};
	}
})();