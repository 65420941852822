(function () {
    angular.module('springwise').factory('ReportFactory', reportFactory);

    reportFactory.$inject = ['$resource'];

    function reportFactory($resource) {
        return $resource(
            'api/Report/:id',
            { id: '@id' },
            {
                programOverviewQuery: {
                    method: 'GET',
                    url: 'api/Report/GetDataForProgramOverviewReport',
                    isArray: false
                }
            });
    }
})();