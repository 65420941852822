(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('workRecordsFormStepSixCtrl', workRecordsFormStepSixCtrl);

    workRecordsFormStepSixCtrl.$inject = ['$scope',
        'CurrentUserFactory',
        'ClientsFactory',
        'permissions',
        'WorkRecordsFactory',
        '$location',
        'UtilityService',
        'workRecordsStages',
        'workRecordsStatuses',
        '$routeParams',
        'ngAuthSettings',
        'WorkRecordService'];

    function workRecordsFormStepSixCtrl($scope,
        CurrentUserFactory,
        ClientsFactory,
        permissions,
        WorkRecordsFactory,
        $location,
        UtilityService,
        workRecordsStages,
        workRecordsStatuses,
        $routeParams,
        ngAuthSettings,
        WorkRecordService) {

        $scope.saveJobDone = saveJobDone;
        $scope.isVendorUser = false;
        $scope.isSWAdminUser = false;
        $scope.isClientUser = false;

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();

            $scope.viewWRVendorReportingInfo = $scope.currentUser.permissions.indexOf(permissions.viewWRVendorReportingInfo) !== -1;
            $scope.createWRVendorReportingInfo = $scope.currentUser.permissions.indexOf(permissions.createWRVendorReportingInfo) !== -1;
            $scope.editWRVendorReportingInfo = $scope.currentUser.permissions.indexOf(permissions.editWRVendorReportingInfo) !== -1;
            $scope.canSetVendorsJobDoneStatus = $scope.currentUser.permissions.indexOf(permissions.canSetVendorsJobDoneStatus) !== -1;

            $scope.isVendorUser = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClientUser = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSWAdminUser = !$scope.isVendorUser && !$scope.isClientUser;

        }

        activate();

        function activate() {
            if ($routeParams.workRecordId) {
                WorkRecordsFactory.get({ id: $routeParams.workRecordId }).$promise.then(function (wr) {
                    $scope.wr = wr;
                    $scope.wr.id = $routeParams.workRecordId;
                    $scope.signOffSheetURL = ngAuthSettings.apiServiceBaseUri + 'api/WorkRecords/GetSignOffSheet/' + wr.id;
                }, UtilityService.onRequestError);

                WorkRecordsFactory.getWorkRecordSummary({ workRecordId: $routeParams.workRecordId }).$promise.then(function (workRecordSummary) {
                    $scope.workRecordSummary = workRecordSummary;
                }, UtilityService.onRequestError);

            }
        }

        function saveJobDone() {
            if ($scope.wr.id) {
                WorkRecordsFactory.sendWRJobDoneNotification({ workRecordId: $scope.wr.id, step: 9 }, $scope.wr).$promise.then(onsendWRJobDoneNotificationSucceeded, UtilityService.onRequestError);
            }
        }

        function onsendWRJobDoneNotificationSucceeded() {
            $scope.saveSucceeded = true;
            $scope.wr.stageId = workRecordsStages.WorkCompletionStage.stageId;
            WorkRecordsFactory.advanceVendorStep({ id: $scope.wr.id, step: 9 }, $scope.wr).$promise.then(onAdvanceSucceded, UtilityService.onRequestError);
        }

        function onAdvanceSucceded() {
            activate();
        }

        $scope.setVendorIsOnSite = setVendorIsOnSite;

        function setVendorIsOnSite() {
            WorkRecordsFactory.setVendorIsOnSite({ workRecordId: $scope.wr.id }, null).$promise.then(onSetVendorIsOnSiteSucceded, UtilityService.onRequestError);
        }

        function onSetVendorIsOnSiteSucceded() {
            activate();
        }

        $scope.cancelWorkRecord = function () {
            WorkRecordService.cancelWorkRecord($scope.wr.id);
        };
    }
})();
