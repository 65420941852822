(function () {
    angular.module('springwise').directive('swPhoneForm', swPhoneForm);

    swPhoneForm.$inject = [];

    function swPhoneForm() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/components/phones/phoneFormView.html',
            scope: {
                phone: '=phone',
                pattern: '@pattern',
                example: '@example',    
                disabled: '=disabled',
                validation: '='
            },
            link: function ($scope, element, attrs, ctrl) {
                ctrl.init(element);
            },
            controller: 'PhoneFormCtrl'
        };
    }
})();
