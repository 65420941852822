(function () {
    'use strict';

    angular
    .module('springwise')
    .factory('UsersFactory', UsersFactory);

    UsersFactory.$inject = ['$resource'];

    // query params:
    // pageNumber, pageLimit, name, lastName, orderBy, isDescending
    function UsersFactory($resource) {
        return $resource(
            'api/Users/:id',
            { id: '@id' },
            {
                getTotalRecords: {
                    method: 'GET',
                    url: 'api/Users/Total',
                    isArray: false
                },
                activate: {
                    method: 'PUT',
                    url: 'api/Users/:id/Activate',
                    isArray: false
                },
                deactivate: {
                    method: 'PUT',
                    url: 'api/Users/:id/Deactivate',
                    isArray: false
                },
                resetPassword: {
                    method: 'PUT',
                    url: 'api/Users/:id/ResetPassword',
                    isArray: false
                }, 
                update: {
                    method: 'PUT'
                },
                updateMe: {
                    url: 'api/users/me',
                    method: 'PUT'
                },
                getUserSecQuestions: {
                    method: 'GET',
                    url: 'api/Users/:id/SecQuestions',
                    isArray: false
                },
                changePassword: {
                    method: 'PUT',
                    url: 'api/Users/:id/ChangePassword'
                },
                getAllSecQuestionsAvailable: {
                    method: 'GET',
                    url: 'api/Users/AllSecQuestions',
                    isArray: true
                },
                updSecQuestions: {
                    method: 'PUT',
                    url: 'api/Users/:id/UpdSecQuestions'
                },
                retrieveForgottenInfo: {
                    method: 'POST',
                    url: 'api/Users/RetrieveForgottenInfo'
                },
                impersonate: {
                    method: 'POST',
                    url: 'api/Users/me/impersonate/:id'
                },
                stopImpersonation: {
                    method: 'POST',
                    url: 'api/Users/me/impersonate/stop'
                },
                sendPendingEmails: {
                    method: 'POST',
                    url: 'api/Notifications/SendPendingEmails'
                },
                getAdministratorUserByClientId: {
                    method: 'GET',
                    url: 'api/Users/GetAdministratorUserByClientId/:id'                   
                },
            });
    }
})();