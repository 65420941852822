(function () {
    angular.module('springwise').directive('swInfo', swInfo);

    function swInfo() {
        return {
            templateUrl: '/app/shared/directives/info/infoView.html',
            restrict: 'E',
            scope: {
                name: '=',
                label: '=',
                value: '='
            }
        };
    }
})();