(function () {
    angular.module('springwise').directive('swMaxLengthError', swMaxLengthError);

    swMaxLengthError.$inject = [];

    function swMaxLengthError() {
        return {
            templateUrl: '/app/shared/directives/errors/max-length/maxLengthErrorView.html',
            restrict: 'E',
            scope: {
                field: '=field',
                maxLength: '=maxLength',
                validation: '='
            }
        };
    }
})();