(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('CountriesFactory', CountriesFactory);

    CountriesFactory.$inject = ['$resource'];

    function CountriesFactory($resource) {
        return $resource('api/Countries/:id', { id: '@countryId' });
    }
})();