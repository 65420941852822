(function () {
    angular.module('springwise').controller('PhoneFormCtrl', PhoneFormCtrl);

    PhoneFormCtrl.$inject = ['$scope', '$attrs', 'PhoneTypesFactory'];

    function PhoneFormCtrl($scope, $attrs, PhoneTypesFactory) {
        var self = this;

        //$scope.phone = new Phone();
        $scope.phoneTypes = [];
        $scope.disabled = false;

        this.init = init;

        function init(element) {
            self.$element = element;

            PhoneTypesFactory.query()
                .$promise.then(function (phoneTypes) {
                    $scope.phoneTypes = phoneTypes;
                });
        }
    }
})();