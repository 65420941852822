(function () {
    angular.module('springwise').directive('swEmailField', swEmailField);

    swEmailField.$inject = [];

    function swEmailField() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/directives/fields/emailFieldView.html',
            scope: {
                field: '=field',
                name: '@name',
                label: '@label',
                req: '=required',
                model: '=model',
                dis: '=disabledAttribute',
                validation: '='
            }
        };
    }
})();