(function () {
    angular.module('springwise')
        .directive('swClientNameValidator', swClientNameValidator);

    swClientNameValidator.$inject = ['ngAuthSettings', '$http'];

    function swClientNameValidator(ngAuthSettings, $http) {
        return {
            require: 'ngModel',
            link: function ($scope, element, attrs, ngModel) {
                ngModel.$validators.customClientNameValidator = function (modelValue) {
                    // Validate only when changed
                    if (ngModel.$dirty) {
                        var id = (attrs.id === null || attrs.id === undefined) ? 0 : attrs.id;
                        $http.get(ngAuthSettings.apiServiceBaseUri + 'api/Clients/ClientNameIsUsed?clientName=' + modelValue + '&clientId=' + id)
                            .success(function (response) {
                                var isUsed = response && response.isUsed;
                                ngModel.$setValidity("newclientnameused",!isUsed);
                                return !isUsed;
                            });                        
                    }

                    return true;
                };
            }
        };
    }

})();