(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('ContractsFactory', ContractsFactory);

    function ContractsFactory($resource, $routeParams) {
        return {
            api: $resource(
                'api/Contracts/:contractId',
                { contractId: '@contractId' },
                {
                    createContract: {
                        method: 'POST',
                        url: 'api/Contracts/CreateContract',
                        params: {
                            clientId: '@clientId',
                            vendorId: '@vendorId'
                        }
                    },
                    terminateContract: {
                        method: 'POST',
                        url: 'api/Contracts/TerminateContract'
                    },
                    getContractForEdition: {
                        method: 'GET',
                        url: 'api/Contracts/GetContractForEdition/:contractId'
                    },
                    getContractForView: {
                        method: 'GET',
                        url: 'api/Contracts/GetContractForView/:contractId'
                    },
                    editContract: {
                        method: 'POST',
                        url: 'api/Contracts/EditContract'
                    },
                    getLocationsForEdition: {
                        method: 'GET',
                        url: 'api/Contracts/GetLocationsForEdition'
                    },
                    getContracts: {
                        method: 'GET',
                        url: 'api/Contracts/GetContracts'
                    },
                    addLocation: {
                        method: 'POST',
                        url: 'api/Contracts/AddLocation'
                    },
                    removeLocation: {
                        method: 'POST',
                        url: 'api/Contracts/RemoveLocation'
                    },
                    getServicesForEdition: {
                        method: 'GET',
                        url: 'api/Contracts/GetServicesForEdition'
                    },
                    addService: {
                        method: 'POST',
                        url: 'api/Contracts/AddService'
                    },
                    removeService: {
                        method: 'POST',
                        url: 'api/Contracts/RemoveService'
                    },
                    getContractLinesForEdition: {
                        method: 'GET',
                        url: 'api/Contracts/GetContractLinesForEdition'
                    },
                    getContractLinesForView: {
                        method: 'GET',
                        url: 'api/Contracts/GetContractLinesForView'
                    },
                    saveContractLine: {
                        method: 'POST',
                        url: 'api/Contracts/SaveContractLine'
                    },
                    getContractLineForEdition: {
                        method: 'GET',
                        url: 'api/Contracts/GetContractLineForEdition'
                    },
                    removeContractLine: {
                        method: 'POST',
                        url: 'api/Contracts/RemoveContractLine'
                    },
                    terminateContractLine: {
                        method: 'POST',
                        url: 'api/Contracts/TerminateContractLine'
                    },
                    getContractStatuses: {
                        method: 'GET',
                        url: 'api/Contracts/GetContractStatuses',
                        isArray: true
                    },
                    getContractStatusesForVendor: {
                        method: 'GET',
                        url: 'api/Contracts/GetContractStatusesForVendor',
                        isArray: true
                    },
                    setAsReadyToReviewByVendor: {
                        method: 'POST',
                        url: 'api/Contracts/SetAsReadyToReviewByVendor/:contractId'
                    },
                    vendorAcceptsContract: {
                        method: 'POST',
                        url: 'api/Contracts/VendorAcceptsContract/:contractId'
                    },
                    vendorRejectsContract: {
                        method: 'POST',
                        url: 'api/Contracts/VendorRejectsContract/:contractId'
                    },
                    clientAcceptsContract: {
                    method: 'POST',
                    url: 'api/Contracts/ClientAcceptsContract/:contractId'
                    },
                    clientRejectsContract: {
                        method: 'POST',
                        url: 'api/Contracts/ClientRejectsContract/:contractId'
                    },
                    getPendingContractNTEApprovals: {
                        method: 'GET',
                        url: 'api/Contracts/GetPendingContractNTEApprovals'
                    },
                    respondPendingApproval: {
                        method: 'POST',
                        url: 'api/Contracts/RespondPendingApproval'
                    },
                    deleteContract: {
                        method: 'POST',
                        url: 'api/Contracts/DeleteContract/:contractId'
                    }
                }
            )              
        };
    }
})();