(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('StatesFactory', StatesFactory);

    StatesFactory.$inject = ['$resource'];

    function StatesFactory($resource) {
        return $resource('api/States/:id', { id: '@stateId' });
    }
})();