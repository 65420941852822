(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('DocumentsReporsitoryFactory', DocumentsReporsitoryFactory);

    DocumentsReporsitoryFactory.$inject = ['$resource'];

    function DocumentsReporsitoryFactory($resource) {
        return $resource(
            'api/DocumentRepository/BrandImage/:versionId',
            { versionId: '@versionId' },
            {
                getBrandImage: {
                    method: 'GET',
                    url: 'api/DocumentRepository/BrandImage/:versionId',
                    params: {
                        userId: '@versionId'
                    }
                }
            });
    }
})();