(function () {
    angular.module('springwise').factory('RegionsFactory', RegionsFactory);

    RegionsFactory.$inject = ['$resource'];

    function RegionsFactory($resource) {
        return $resource(
            'api/Regions/:id', {
                id: '@id'
            }, {
                queryOptions: {
                    method: 'GET',
                    url: 'api/Clients/:clientId/Regions/options',
                    isArray: true
                },
                getTotalRecords: {
                    method: 'GET',
                    url: 'api/Regions/Total',
                    isArray: false
                },
                update: {
                    method: 'PUT',
                },
                exportRegions: {
                    method: 'GET',
                    url: 'api/Regions/exportRegions',
                    isArray: false
                }
            });
    }
})();