(function () {
        'use strict';

    angular
        .module('springwise')
        .controller('myNewAlertsCrtl', myNewAlertsCrtl);

    myNewAlertsCrtl.$inject = ['$scope', 'NotificationsFactory'];

    function myNewAlertsCrtl($scope, NotificationsFactory) {
        $scope.masterData = {};
        $scope.masterData.myNotifications = [];

        activate();

        function activate() {
            NotificationsFactory.query()
                .$promise.then(function (notifications) {
                    $scope.masterData.myNotifications = notifications;
                });
        }
    }
})();
