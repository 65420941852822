(function () {
    'use strict';

    angular.module('springwise').controller('MainCtrl', MainCtrl);

    function MainCtrl($scope, $location, CurrentUserFactory, $rootScope, NotificationsFactory, $interval, SWDashboardFactory, AuthFactory, AuthUserService) {
        $scope.currentUser = CurrentUserFactory.get();
        $scope.isLogInPage = isLogInPage;
        $scope.hasClient = CurrentUserFactory.exists() ? CurrentUserFactory.get().client !== null : false;
        $scope.getHome = getHome;
        $scope.isLoggedIn = AuthFactory.isLoggedIn;
        $rootScope.userLastActivity = Date.now();

        var bg = Math.random();
        $scope.loginbg = '';
        if (bg <= 0.33) {
            $scope.loginbg = "loginbg1";
        }
        else if (bg >= 0.34 && bg <= 0.66) {
            $scope.loginbg = "loginbg2";
        }
        else {
            $scope.loginbg = "loginbg3";
        }

        function activate() {
            checkSession();
            $interval(checkSession, 5000);
            $rootScope.checkGlobalNotifications();
            $interval($rootScope.checkGlobalNotifications, 20000);
        }

        function checkSession() {
            var refreshToken = false;
            var authData = AuthUserService.get();
            if (CurrentUserFactory.exists() && authData) {
                var currentUser = CurrentUserFactory.get();
                if (currentUser.tokenExpirationTime && authData.refreshTokenDateTime) {
                    if (Date.now() >= authData.refreshTokenDateTime + currentUser.tokenExpirationTime * 1000 * 0.25) {
                        if (authData.useRefreshTokens) {
                            refreshToken = true;
                        }
                        else {
                            if ($rootScope.userLastActivity >= authData.refreshTokenDateTime + currentUser.tokenExpirationTime * 1000 * 0.2) {
                                refreshToken = true;
                            }
                        }
                    }
                }
            }

            if (refreshToken) {
                AuthFactory.refreshToken();
            }
        }

        function isLogInPage() {
            return $location.path().indexOf('/login') > -1;
        }
        function getHome() {
            return '/#' + CurrentUserFactory.getDashboardPath();
        }

        $rootScope.checkGlobalNotifications = function () {
            var authData = AuthUserService.get();
            if (authData) {
                NotificationsFactory.query().$promise.then(function (notifications) {
                    $scope.myNotifications = notifications;
                });
            }
            else {
                $scope.myNotifications = [];
            }
        };

        $scope.closeNotification = function (index) {
            var alertId = $scope.myNotifications[index].alertId;
            $scope.myNotifications.splice(index, 1);
            SWDashboardFactory.markAlertAsRead({ id: alertId }).$promise.then(onAlertMarkAsRead, UtilityService.onRequestError);
            function onAlertMarkAsRead() {
            }
        };

        activate();
    }
})();