(function () {
    angular.module('springwise').directive('swSearchVendors', swSearchVendors);

    swSearchVendors.$inject = [];

    function swSearchVendors() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/components/vendors/searchVendorsView.html',
            scope: {
                select: '=select',
                params: '=params'
            },
            link: function ($scope, element, attrs, ctrl) {
                ctrl.init(element);
            },
            controller: 'SearchVendorsCtrl'
        };
    }
})();