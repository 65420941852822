(function () {
    angular.module('springwise').directive('swUploadAttachment', swUploadAttachment);

    function swUploadAttachment() {
        return {
            restrict: 'E',
            scope: {
                selectedFile: '=',
                description: "=description",
                alreadyExistsFileId: "=alreadyExistsFileId",
                label: "@label",
                disable: "@disable",
                maxlength: '=maxlength',
                validation: '='
            },
            controller: "swUploadAttachmentCtrl",
            templateUrl: '/app/shared/directives/uploadFile/uploadAttachment.html',
            link: function (scope, element, attrs, ctrl) {
            }
        };
    }

    angular.module('springwise').controller("swUploadAttachmentCtrl", swUploadAttachmentCtrl);

    function swUploadAttachmentCtrl($scope, ngAuthSettings, $rootScope, $route) {

        this.init = function (scope, element) {
            scope.element = element;
            if (!scope.selectedFile) {
                scope.selectedFile = new FileObj();
            }            
            scope.$watch('selectedFile.alreadyExistsFileId', function () {
                if (scope.selectedFile.alreadyExistsFileId > 0) {
                    scope.showImage = true;
                    var img = scope.element.find("img")[0];                    
                    img.src = ngAuthSettings.apiServiceBaseUri + "api/RecurringServices/GetAttachmentForEdition/" + scope.selectedFile.recurringScheduleAttachmentId;
                    scope.imageFile = img;
                }
            });
        };
                
        $scope.getFileDetails = function (e) {
            $scope.fileInfo = e.files[0];
            $scope.extension = $scope.fileInfo.name.substr($scope.fileInfo.name.lastIndexOf('.') + 1);
            $scope.fileName = $scope.fileInfo.name.substr($scope.fileInfo.name); //new  
            var fileReader = new FileReader();
            fileReader.onload = function (e) {
                $scope.fileData = e.target.result;
                $scope.selectedFile = prepareFileToUpload();
                $rootScope.$digest();
            };
            $scope.$digest();
            fileReader.readAsDataURL($scope.fileInfo);
        };

        function prepareFileToUpload() {
            var result = {};
            result.formData = new FormData();
            result.formData.append("fileExtension", $scope.extension);
            var blob = dataURItoBlob($scope.fileData);
            result.formData.append("fileData", blob);
            if ($scope.description === null || $scope.description === undefined) {
                $scope.description = "";
            }      
            result.formData.append("fileDescription", $scope.description);
            result.formData.append("fileName", $scope.fileName);

            if ($scope.alreadyExistsFileId === null || $scope.alreadyExistsFileId === undefined) {
                $scope.alreadyExistsFileId = -1;
            }            
            result.formData.append("AlreadyExistsFileId", $scope.alreadyExistsFileId);

            result.send = function (apiUrl, onTransferCompletedCallback) {
                var objXhr = new XMLHttpRequest();
                objXhr.open("POST", ngAuthSettings.apiServiceBaseUri + apiUrl);
                objXhr.responseType = "json";
                objXhr.setRequestHeader('Accept', 'application/json, */*');
                objXhr.addEventListener("load", onTransferCompleted, false);
                objXhr.send(result.formData);

                function onTransferCompleted() {
                    if (onTransferCompletedCallback) {
                        onTransferCompletedCallback();
                        $rootScope.$digest();
                    }
                }
            };

            return result;
        }

        function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], { type: mimeString });
        }

        $scope.clean = function () {
            $route.reload();
        };

    }
})();