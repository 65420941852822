(function () {
    angular.module('springwise').controller('serviceProviderAnalysisReportCtrl', serviceProviderAnalysisReportCtrl);

    serviceProviderAnalysisReportCtrl.$inject = ['$scope'];

    function serviceProviderAnalysisReportCtrl($scope) {
        $scope.$watch('currentUser', activate);
        activate();

        function activate() {
        }


    }
})();