(function () {
    angular.module('springwise').directive('swTextPatternField', swTextPatternField);

    swTextPatternField.$inject = [];

    function swTextPatternField() {
        return {
            restrict: 'E',
            templateUrl: 'app/shared/directives/fields/textPatternFieldView.html',
            scope: {
                field: '=field',
                req: '=required',
                model: '=model',
                name: '@name',
                label: '@label',
                example: '@example',
                pattern: '@pattern',
                disabled: '=disabled',
                validation: '='
            },
            link: function (scope, element, attrs) {
                //if (!(scope.pattern instanceof RegExp)) {
                //    if (scope.pattern) {
                //        scope.pattern = new RegExp(scope.RegExp);
                //    } else {
                //        scope.pattern = '';
                //    }
                //}
            }
        };
    }
})();