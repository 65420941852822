(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('CurrentUserFactory', CurrentUserFactory);

    CurrentUserFactory.$inject = ['localStorageService', 'permissions'];

    function CurrentUserFactory(localStorageService, permissions) {
        var currentUserKey = 'swCurrentUser';
        var currentUser = localStorageService.get(currentUserKey);

        var currentUserFactory = {
            exists: exists,
            get: get,
            set: set,
            remove: remove,
            isSpringwise: isSpringwise,
            isClient: isClient,
            isVendor: isVendor,
            canCreateServices: canCreateServices,
            canEditServices: canEditServices,
            viewServices: viewServices,
            canQueryVendors: canQueryVendors,
            canCreateVendors: canCreateVendors,
            canEditVendors: canEditVendors,
            currentUser: currentUser,
            getDashboardPath: getDashboardPath,
            canAccessNotificationTypes: canAccessNotificationTypes
        };

        function exists() {
            return currentUser !== null && currentUser !== undefined;
        }

        function get() {
            return currentUser;
        }

        function set(obj) {
            currentUser = obj;
            localStorageService.set(currentUserKey, currentUser);
        }
        function remove() {
            currentUser = null;
            localStorageService.remove(currentUserKey);
        }

        function isSpringwise() {
            if (currentUser) {
                return !currentUser.client && !currentUser.vendor;
            } else {
                return false;
            }
        }

        function isClient() {
            if (currentUser) {
                return currentUser.client;
            } else {
                return false;
            }
        }

        function isVendor() {
            if (currentUser) {
                return currentUser.vendor;
            } else {
                return false;
            }
        }
        
        function canCreateServices() {
            return currentUser.permissions.indexOf(permissions.canCreateServices) !== -1;
        }

        function canEditServices() {
            return currentUser.permissions.indexOf(permissions.canEditServices) !== -1;
        }

        function viewServices() {
            return currentUser.permissions.indexOf(permissions.viewServices) !== -1;
        }

        function canQueryVendors() {
            return currentUser.permissions.indexOf(permissions.canQueryVendors) !== -1;
        }

        function canEditVendors() {
            return currentUser.permissions.indexOf(permissions.canEditVendors) !== -1;
        }

        function canCreateVendors() {
            return currentUser.permissions.indexOf(permissions.canCreateVendors) !== -1;
        }

        function canAccessNotificationTypes() {
            return currentUser.permissions.indexOf(permissions.notificationTypes) !== -1;
        }

        function getDashboardPath()
        {
            if (isVendor())
                return '/vendorDashboard';
            else if (isClient())
                return '/clientDashboard';
            else if (isSpringwise())
                return '/swDashboard';
            else
                return '/';
        }

        return currentUserFactory;
    }
})();