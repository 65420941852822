(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('RolesFormCtrl', RolesFormCtrl);

    RolesFormCtrl.$inject = ['$scope', 'RolesFactory', '$routeParams', '$location', 'PermissionsFactory'];

    function RolesFormCtrl($scope, RolesFactory, $routeParams, $location, PermissionsFactory) {

        $scope.masterData = {};
        $scope.masterData.availableRoles = [];
        $scope.masterData.permissions = [];
        $scope.toCopyId = $routeParams.toCopyId;
        $scope.editId = $routeParams.id;
        $scope.clientId = ($routeParams.clientId === undefined) ? null : $routeParams.clientId;
        $scope.getCategoriesByRoleId = null;

        $scope.role = {};
        $scope.ddlRolesValue = null;
        $scope.hasParent = {};
        $scope.permissionCategories = {};

        $scope.swValidation = new SWValidation();

        $scope.saveRole = function (form) {

            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            $scope.swValidation.end();
            if ($scope.swValidation.hasErrors())
                return;

            if ($scope.ddlRolesValue !== null) {
                $scope.role.parentRoleId = $scope.ddlRolesValue.id;
            } else {
                $scope.role.parentRoleId = 1;
            }
            
            // Parse PermissionsArray (role.permissions) leaving only those checked
            for (var i = $scope.role.permissions.length - 1; i >= 0 ; i--) {
                if ($scope.role.permissions[i].check === false) {
                    $scope.role.permissions.splice(i, 1);
                }
            }

            // Persist the Role
            if ($scope.editId) {
                // Edit role if exists
                $scope.role.$update(function () {
                    if ($scope.clientId)
                        $location.path('/clients/' + $scope.clientId + '/roles');
                    else
                        $location.path('/roles');
                });
            } else {
                // Create role in DB
                RolesFactory.save($scope.role).$promise.then(function () {
                    if ($scope.clientId)
                        $location.path('/clients/' + $scope.clientId + '/roles');
                    else
                        $location.path('/roles');
                }, onRequestError);
            }
        };

        function onRequestError(error) {
            if (error.status === 403) {
                alert('You are not allowed to create clients.\nPlease, contact the system administrator.');
            } else if (error.status === 400) {
                alert(error.data.modelState.roleForm[0]);
            }
            else if (error.data && error.data.message) {
                alert(error.data.message);
            } else {
                alert('There was an error on the operation.\.Please, contact the system administrator.');
            }
        }

        $scope.$watch('role.permissions', function (newValue, oldValue) {
            if (newValue == oldValue) {
                return;
            }
            if ($scope.role.parentRoleId === null) {
                for (var i = $scope.role.permissions.length - 1; i >= 0 ; i--) {
                    $scope.role.permissions[i].check = true;
                }
            }
        });

        $scope.$watch('ddlRolesValue.id', function (newValue, oldValue) {
            if (newValue == oldValue) {
                return;
            }
            if ($scope.editId) {
                $scope.role.permissions = RolesFactory.parentPermissions({ roleId: $scope.editId, parentId: $scope.ddlRolesValue.id });                
                PermissionsFactory.getAllPermissionCategoriesByParentRole({ roleId: $scope.editId, parentId: $scope.ddlRolesValue.id })
                   .$promise.then(function (permissionCategories) {
                       $scope.role.permissionCategories = permissionCategories;
                });
            } else if ($scope.toCopyId) {
                $scope.role.permissions = RolesFactory.parentPermissions({ roleId: $scope.toCopyId, parentId: $scope.ddlRolesValue.id });                
                PermissionsFactory.getAllPermissionCategoriesByParentRole({ roleId: $scope.toCopyId, parentId: $scope.ddlRolesValue.id })
                    .$promise.then(function (permissionCategories) {
                    $scope.role.permissionCategories = permissionCategories;
                });
            } else {
                $scope.role.permissions = RolesFactory.parentPermissions({ roleId: $scope.ddlRolesValue.id, parentId: $scope.ddlRolesValue.parentRoleId });
                PermissionsFactory.getAllPermissionCategoriesByParentRole({ roleId: $scope.ddlRolesValue.id, parentId: $scope.ddlRolesValue.parentRoleId })
                   .$promise.then(function (permissionCategories) {
                       $scope.role.permissionCategories = permissionCategories;
                });
            }
        });

        $scope.$watch('ddlRolesValue', function (newValue, oldValue) {
            if (newValue == oldValue) {
                return;
            }
            if ($scope.ddlRolesValue !== null) {
                $scope.hasParent = true;
            } else {
                $scope.hasParent = false;
            }
        });

        activate();

        function activate() {
            if ($scope.toCopyId) {
                //CLONE
                RolesFactory.get({ id: $scope.toCopyId })
                .$promise.then(function (roleDetail) {
                    $scope.ddlRolesValue = roleDetail.parentRole;
                    $scope.role.permissions = roleDetail.permissions;
                    $scope.role.parentRoleId = roleDetail.parentRoleId;
                    $scope.masterData.availableRoles = ($scope.clientId) ?
                        RolesFactory.getRolesByClientId({ clientId: $scope.clientId }) :
                        RolesFactory.query();
                });

            } else if ($scope.editId) {
                //EDIT
                RolesFactory.get({ id: $scope.editId })
                .$promise.then(function (roleDetail) {
                    $scope.role = roleDetail;
                    $scope.ddlRolesValue = roleDetail.parentRole;
                    $scope.masterData.availableRoles = RolesFactory.getRolesForRoleEdition({ parentRoleId: $scope.editId, clientId: ($scope.clientId === null ? 0 : $scope.clientId) });
                });

            } else {
                //CREATE ROLE
                $scope.masterData.availableRoles = ($scope.clientId) ?
                        RolesFactory.getRolesByClientId({ clientId: $scope.clientId }) :
                        RolesFactory.query();
            }       
            
        }

    }
})();
