(function () {
    'use strict';

    angular
        .module('springwise')
        .factory('PdfMakerFactory', pdfMakerFactory);

    pdfMakerFactory.$inject = ['$http', '$q'];

    function pdfMakerFactory($http, $q) {

        var pdfMakerServiceFactory = {
            //getProgramOverviewReport: getProgramOverviewReport,
            saveChart: saveChart,
            get: get
        };

        return pdfMakerServiceFactory;

        function get() {
            return 'pdfMakerServiceFactory';
        }

        //function getProgramOverviewReport(i) {
        //    //var docDefinition = { content: 'This is an sample PDF printed with pdfMake' };
        //    //var i = "data:" + $scope.pngSrc;
        //    var docDefinition = {
        //        content: [
        //            {
        //                text: "Program Overview Report",
        //                style: "subheader"
        //            },
        //            {
        //                image: i,
        //                width: 400,
        //                height: 200
        //            }
        //        ],
        //        styles: {
        //            header: {
        //                fontSize: 18,
        //                bold: true
        //            },
        //            subheader: {
        //                fontSize: 15,
        //                bold: true
        //            },
        //            quote: {
        //                italics: true
        //            },
        //            small: {
        //                fontSize: 8
        //            }
        //        }
        //    };

        //    pdfMake.createPdf(docDefinition).open();
        //}

        function saveChart(chart) {
            //Get an SVG tag. 
            var svgData = chart.getSVG();
            var pngSrc = null;

            // Get a context to the canvas element, which can be used to draw on the canvas.
            var canvas = document.createElement("canvas");
            canvas.width = 600;
            canvas.height = 400;
            var context = canvas.getContext("2d");

            //// Create an image element to be drawn onto the canvas.
            //var image = new Image;
            //// Set the SVG as the source.
            //image.src = $scope.imgSrc;

            var img = document.createElement("img");
            img.setAttribute("src", "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData))));
            img.onload = function () {
                context.drawImage(img, 0, 0);
                pngSrc = canvas.toDataURL("image/png");
            };

            return pngSrc;
        }
    }
})();