(function () {
    angular.module('springwise').directive('swSearchOption', swSearchOption);

    swSearchOption.$inject = [];

    function swSearchOption() {
        return {
            restrict: 'E',
            templateUrl: 'app/shared/directives/table/searchOptionView.html',
            scope: {
                name: '@name',
                model: '=model',
                getOptions: '=options',
                optionExpression: '=optionExpression',
                label: '@label',
                aux: '=aux'
            },
            link: function (scope, element, attrs) {
                scope.getOptions(scope);
            }
        };
    }
})();