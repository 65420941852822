(function () {
    angular.module('springwise').factory('LocationServicesVendorsService', LocationServicesVendorsService);

    LocationServicesVendorsService.$inject = ['$resource'];

    function LocationServicesVendorsService($resource) {
        return $resource(
            'api/locations/:locationId/services/:serviceId/vendors/:vendorId', {
                locationId: '@locationId',
                serviceId: '@serviceId',
                vendorId: '@vendorId'
            }, {
                getTotalRecords: {
                    url: 'api/locations/:locationId/services/:serviceId/vendors/total',
                    method: 'GET',
                    isArray: false
                },
                update: {
                    method: 'PUT',
                    isArray: false
                },
                denyUsage: {
                    url: 'api/locations/:locationId/services/:serviceId/vendors/:vendorId/usage/deny',
                    method: 'PUT',
                    isArray: false
                },
                allowUsage: {
                    url: 'api/locations/:locationId/services/:serviceId/vendors/:vendorId/usage/allow',
                    method: 'PUT',
                    isArray: false
                },
                requireUsage: {
                    url: 'api/locations/:locationId/services/:serviceId/vendors/:vendorId/usage/require',
                    method: 'PUT',
                    isArray: false
                },
                getTotalRecordsWRVendorSelection: {
                    url: 'api/locations/:locationId/services/:serviceId/vendors/WRVendorSelection/total',
                    method: 'GET',
                    isArray: false
                },
                queryWRVendorSelection: {
                    url: 'api/locations/:locationId/services/:serviceId/vendors/WRVendorSelection',
                    method: 'GET',
                    isArray: true
                }
            });
    }
})();