(function () {
    angular.module('springwise').directive('swCheckField', swCheckField);

    swCheckField.$inject = [];

    function swCheckField() {
        return {
            restrict: 'E',
            templateUrl: 'app/shared/directives/fields/checkFieldView.html',
            compile: function (element, attrs) {
                if (!attrs.gridcolumn) { attrs.gridcolumn = 'col-sm-3'; }
            },
            scope: {
                field: '=field',
                model: '=model',
                name: '@name',
                disabled: '=disabled',
                label: '@label',
                gridcolumn: '@gridcolumn'
            }
        };
    }
})();