(function () {
    angular.module('springwise').filter('locationBelongsToClientOrVendor', locationBelongsToClientOrVendorFilter);

    function locationBelongsToClientOrVendorFilter() {
        return function (locations, userType, client, vendor) {
            var result = [];

            angular.forEach(locations, function (location, key) {
                if (userType === 'Client' && location.clientId && client && location.clientId === client.clientId) {
                    result.push(location);
                } else if (userType === 'Vendor' && location.vendorId && vendor && location.vendorId === vendor.vendorId) {
                    result.push(location);
                }
            });

            return result;
        };
    }
})();