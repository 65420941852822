(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('WorkRecordsRedirectCtrl', WorkRecordsRedirectCtrl);

    function WorkRecordsRedirectCtrl($scope, $routeParams, WorkRecordsFactory, CurrentUserFactory, UtilityService, WorkRecordService, $location) {
        $scope.workRecordId = $routeParams.workRecordId;
        $scope.isVendor = false;
        $scope.userVendorId = 0;

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
            $scope.isVendor = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            if ($scope.isVendor)
                $scope.userVendorId = $scope.currentUser.vendor.vendorId;
        }

        getWorkRecord();

        function getWorkRecord() {
            $scope.requestSent = true;
            WorkRecordsFactory.get({ id: $scope.workRecordId }).$promise.then(function (response) {
                $scope.workRecord = response;
                var path = WorkRecordService.getPathToWorkRecordPage($scope.workRecord, $scope.isVendor, $scope.userVendorId);
                $location.path(path);
            }, UtilityService.onRequestError);
        }
    }
})();