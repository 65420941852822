(function () {
    'use strict';

    angular.module('springwise').factory('EmergencyTypesFactory', EmergencyTypesFactory);

    EmergencyTypesFactory.$inject = ['$resource'];

    function EmergencyTypesFactory($resource) {
        return $resource(
            'api/EmergencyTypes/:id',
            { id: '@id' },
            {
                update: {
                    method: 'PUT'
                },
                getTotalRecords: {  //It brings an integer with the total number of Records
                    method: 'GET',
                    url: 'api/EmergencyTypes/Total',
                    isArray: false  
                },
                activate: {
                    method: 'PUT',
                    url: 'api/EmergencyTypes/:id/activate'
                },
                deactivate: {
                    method: 'PUT',
                    url: 'api/EmergencyTypes/:id/deactivate'
                },
                queryOptions: {
                    method: 'GET',
                    url: 'api/EmergencyTypes/Options',
                    isArray: true
                },
                getClientEmergencyTypes: {
                    method: 'GET',
                    url: 'api/Clients/:id/EmergencyTypes',
                    isArray: true
                },
                updateByClient: {
                    method: 'PUT',
                    url: 'api/Clients/:clientId/EmergencyTypes/:id',
                    params: {
                        id: '@id',
                        clientId: '@clientId'
                    },
                    isArray: false
                },
                saveByClient: {
                    method: 'POST',
                    url: 'api/Clients/:clientId/EmergencyTypes',
                    params: {
                        clientId: '@clientId'
                    },
                    isArray: false
                },
                queryClientOptions: {
                    method: 'GET',
                    url: 'api/EmergencyTypes/ClientOptions/:id',
                    params:{
                        id: '@id'
                    },
                    isArray: true
                },
            });
    }
})();