(function () {
    angular.module('springwise').factory('ServicesService', ServicesService);

    ServicesService.$inject = ['$resource'];

    function ServicesService($resource) {
        return $resource(
            'api/services/:id', {
                id: '@id'
            }, {
                update: {
                    method: 'PUT'
                },
                updateByClient: {
                    method: 'PUT',
                    url: 'api/clients/:clientId/services/:id',
                    params: {
                        clientId: '@clientId',
                        id: '@id'
                    }
                },
                saveByClient: {
                    method: 'POST',
                    url: 'api/clients/:clientId/services',
                    params: {
                        clientId: '@clientId'
                    }
                },
                getByClient: {
                    url: 'api/clients/:clientId/services/:id',
                    method: 'GET',
                    params: {
                        clientId: '@clientId',
                        id: '@id'
                    }
                },
                getByVendor: {
                    url: 'api/vendors/:vendorId/services/:serviceId',
                    method: 'GET',
                    params: {
                        vendorId: '@vendorId',
                        serviceId: '@serviceId'
                    }
                },
                getByClientVendor: {
                    url: 'api/clients/:clientId/vendors/:vendorId/services/:serviceId',
                    method: 'GET',
                    params: {
                        clientId: '@clientId',
                        vendorId: '@vendorId',
                        serviceId: '@serviceId'
                    },
                    isArray: false
                },
                deactivateByClient: {
                    url: 'api/clients/:clientId/services/:id/deactivate',
                    method: 'PUT',
                    params: {
                        clientId: '@clientId',
                        id: '@id'
                    }
                },
                deactivate: {
                    url: 'api/services/:id/deactivate',
                    method: 'PUT'
                },
                activateByClient: {
                    url: 'api/clients/:clientId/services/:id/activate',
                    method: 'PUT',
                    params: {
                        clientId: '@clientId',
                        id: '@id'
                    }
                },
                activate: {
                    url: 'api/services/:id/activate',
                    method: 'PUT'
                },
                getCategoriesByLocation: {
                    url: 'api/Services/Categories/byLocation/:locationId',
                    method: 'GET',
                    params: {
                        locationId: '@locationId',
                    },
                    isArray: true
                },
                getSubCategoriesByLocation: {
                    url: 'api/Services/SubCategories/byCategory/:categoryId/byLocation/:locationId',
                    method: 'GET',
                    params: {
                        categoryId: '@categoryId',
                        locationId: '@locationId'
                    },
                    isArray: true
                },
                getServicesByLocation: {
                    url: 'api/Services/Categories/bySubCategory/:subCategoryId/byLocation/:locationId',
                    method: 'GET',
                    params: {
                        subCategoryId: '@subCategoryId',
                        locationId: '@locationId'
                    },
                    isArray: true
                },
                getAllServicesBySubCategory: {
                    url: 'api/Services/Categories/bySubCategory/:subCategoryId',
                    method: 'GET',
                    params: {
                        subCategoryId: '@subCategoryId'
                    },
                    isArray: true
                },
                getServicesForVendor: {
                    url: 'api/Services/GetServicesForVendor/:vendorId',
                    method: 'GET',
                    params: {
                        vendorId: '@vendorId'
                    },
                    isArray: true
                },
                getServicesForClient: {
                    url: 'api/Services/GetServicesForClient/:clientId',
                    method: 'GET',
                    params: {
                        clientId: '@clientId'
                    },
                    isArray: true
                },
                exportServices: {
                    method: 'GET',
                    url: 'api/Services/exportServices/:clientId',
                    params: {
                        clientId: '@clientId'
                    },
                    isArray: false
                }
            });
    }
})();