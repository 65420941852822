(function () {
    angular.module('springwise').filter('locationBelongsToClient', locationBelongsToClientFilter);

    function locationBelongsToClientFilter() {
        return function (locations, client) {
            var result = [];

            angular.forEach(locations, function (location, key) {
                if (location.clientId && client && location.clientId === client.clientId) {
                    result.push(location);
                }
            });

            return result;
        };
    }
})();