(function () {
    'use strict';

    angular
        .module('springwise')
        .constant(
            'permissions',
            {
                canQueryClients: 1,
                viewContracts: 2,
                viewInvoices: 6,
                viewLocations: 7,
                viewRecurringServices: 10,
                canQueryUsers: 12,
                canQueryVendors: 13,
                approveWorkRecords: 16,
                createClients: 21,
                createContracts: 22,
                createInvoices: 26,
                createLocations: 27,
                createRecurringServices: 29,
                canCreateUsers: 31,
                canCreateVendors: 32,
                canEditClients: 34,
                editContracts: 35,
                createEditRecurringServices: 43,
                canEditUsers: 45,
                canEditVendors: 46,
                canDeactivateUser: 49,
                canImpersonate: 50,
                canResetUserPassword: 52,
                SWAdmin: 53,
                ClientAdmin: 55,
                viewServices: 56,
                canCreateServices: 57,
                canEditServices: 58,
                canQueryRoles: 59,
                createRoles: 60,
                editRoles: 61,
                approveWorkRecordLevel: 62,
                approveQuotesLevel: 63,
                viewPriorityLevels: 64,
                createPriorityLevels: 65,
                editPriorityLevels: 66,
                viewEmergencyTypes: 67,
                createEmergencyTypes: 68,
                editEmergencyTypes: 69,
                viewWorkRecords: 70,
                createWorkRecords: 71,
                editWorkRecords: 72,
                viewWRVendorReportingInfo: 73,
                createWRVendorReportingInfo: 74,
                editWRVendorReportingInfo: 75,
                canSetVendorsJobDoneStatus: 76,
                workRecordsGeneration: 77,
                notificationTypes: 78,
                editLocations: 40,
                viewReports: 11
            })
        .constant(
            'vendorUsageOptions', {
                default: { key: 0, value: 'Default'},
                required: { key: 1, value: 'Required' },
                denied: { key: 2, value: 'Denied' }
            })
        .constant(
            'categoryTypes', {
                category: { id: 1, isRoot: true, isLeaf: false, child: 2 },
                subCategory: { id: 2, isRoot: false, isLeaf: false, parent: 1, child: 3 },
                service: { id: 3, isRoot: false, isLeaf: true, parent: 2 }
            })
        .constant(
            'clientPriorityLevelDescriptions', {
                high: { key: 1, value: 'High' },
                medium: { key: 2, value: 'Medium' },
                low: { key: 3, value: 'Low' }
            })
        .constant(
            'workRecordsStages', {
                ServiceSelectionStage: { WorkflowId: 1, stageId: 1 },
                TriageQuestionnaireStage: { WorkflowId: 1, stageId: 2 },
                WorkRecordCreationStage: {WorkflowId: 1, stageId: 3},
                VendorAssignmentStage: { WorkflowId: 1, stageId: 4 }, //Form Step 4: Vendor Selection
                VendorSelectionBasicForm: { WorkflowId: 1, stageId: 5 },  //Form Step 4: Vendor Basic Form
                ScopeReviewStage: { WorkflowId: 1, stageId: 6 }, //Form Step 5: Scope Review Stage
                SchedulingStage: { WorkflowId: 1, stageId: 7 },  
                PriceNegotationStage: { WorkflowId: 1, stageId: 8 },
                WorkReportingStage: { WorkflowId: 1, stageId: 9 },  //Form Step 6: Vendor Work Reporting Stage
                QuotingStage: { WorkflowId: 1, stageId: 10 },
                WorkCompletionStage: { WorkflowId: 1, stageId: 11 },
                QualityAssurancenStage: { WorkflowId: 1, stageId: 12 },
                InvoicingStage: { WorkflowId: 1, stageId: 13 },
            })
        .constant(
            'workRecordsStatuses', {
                InProgressStatus: { statusId: 1 },
                CanceledStatus: { statusId: 2 },
                FinishedStatus: { statusId: 3 },
                OnHoldStatus: { statusId: 4 },
                PendingApprovalStatus: { statusId: 5 },
                VendorQuotingStatus: { statusId: 6 },
                VendorOnSiteStatus: { statusId: 7 }
            })
        .constant(
            'exceededNTEApprovalStatuses', {
                pending: 1,
                approved: 2,
                canceled: 3
            })
        .constant(
            'exceededNTEApprovalTypes', {
                userNTE: 1,
                serviceNTE: 2
            })
        .constant(
            'billingFrequencies', {
                perService: { billingFrequencyId: 1, name: 'Per Service' },
                seasonal: { billingFrequencyId: 2, name: 'Seasonal' }
            })
        .constant(
            'contractStatuses', {
                new: 1,
                pendingNTEApproval: 2,
                readyToReviewByVendor: 3,
                reviewedAndAcceptedByVendor: 4,
                reviewedAndRejectedByVendor: 5,
                reviewedAndAcceptedByClientThenActiveContract: 6,
                reviewedAndRejectedByClient: 7,
                nteRejected: 8
            });
})();
