(function () {
    angular.module('springwise').factory('ClientDashboardFactory', ClientDashboardFactory);

    ClientDashboardFactory.$inject = ['$resource'];

    // TODO: remove resource dependency
    function ClientDashboardFactory($resource) {
        return $resource(
            'api/ClientDashboard/:id',
            { id: '@id' },
            {
                clientDashboardQuery: {
                    method: 'GET',
                    url: 'api/ClientDashboard/GetDataForClientDashboard',
                    isArray: false
                },
                markAlertAsRead: {
                    method: 'PUT',
                    url: 'api/ClientDashboard/MarkAlertAsRead/:id',
                    isArray: false
                },
            });
    }
})();