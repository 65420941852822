var Client = function () {
    this.name = '';
    this.code = '';
    this.isSpringwiseManaged = true;
    this.isSpringwiseGranted = true;
    this.corporateAddress = new Address();
    this.primaryContact = new Contact();
    this.itContact = new Contact();
    this.userAdministrator = null;
    this.securityConfig = new ClientSecurityConfig();
    this.isAlive = true;
};