(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('forgotUsernameCtrl', forgotUsernameCtrl);

    function forgotUsernameCtrl($location, $routeParams, $scope, UsersFactory, CurrentUserFactory, UtilityService, AuthFactory, HelperFactory) {
        $scope.swValidationForgot = new SWValidation();
        $scope.user = {};
        $scope.path = $location.path();
        $scope.secQuestions = [];

        if ($scope.path == "/users/forgot/password") {
            $scope.pwd = true;
            $scope.email = false;
        } else if ($scope.path == "/users/forgot/username") {
            $scope.pwd = false;
            $scope.email = true;
        }

        $scope.recoverUserInformation = function (form) {

            $scope.swValidationForgot.start();
            $scope.swValidationForgot.checkForm(form);
            $scope.swValidationForgot.end();
            if ($scope.swValidationForgot.hasErrors())
                return;

            $scope.user.secAnswers = [];
            var secAnswerOne = {};
            var secAnswerTwo = {};

            secAnswerOne.answer = $scope.answerOne;
            secAnswerOne.secQuestion = $scope.secQuestionOne;
            $scope.user.secAnswers.push(secAnswerOne);
            
            secAnswerTwo.answer = $scope.answerTwo;
            secAnswerTwo.secQuestion = $scope.secQuestionTwo;
            $scope.user.secAnswers.push(secAnswerTwo);


            if ($scope.path == "/users/forgot/username") {
                $scope.user.type = 'username';
                UsersFactory.retrieveForgottenInfo($scope.user).$promise.then(function () {
                    HelperFactory.openMessageModalWindow("A mail has been sent to your email", 'Email sent', 'success');
                    $location.path('/login');
                }, UtilityService.onRequestError);
            } else {
                $scope.user.type = 'password';
                UsersFactory.retrieveForgottenInfo($scope.user).$promise.then(function () {
                    HelperFactory.openMessageModalWindow("A mail has been sent to your email", 'Email sent', 'success');
                    $location.path('/login');
                }, UtilityService.onRequestError);
            }
        };

        function activate() {
            UsersFactory.getAllSecQuestionsAvailable().$promise.then(function (allSecQuestions) {
                $scope.secQuestions = allSecQuestions;
            });
        }

        activate();
    }
})();
