(function () {
    'use strict';

    angular.module('springwise')
    .controller('LoginCtrl', LoginCtrl);

    LoginCtrl.$inject = ['$scope', '$location', '$window', '$interval', 'AuthFactory', 'ngAuthSettings', 'CurrentBrandInfoFactory', 'ClientsFactory', 'CurrentUserFactory', '$rootScope', '$routeParams'];

    function LoginCtrl($scope, $location, $window, $interval, AuthFactory, ngAuthSettings, CurrentBrandInfoFactory, ClientsFactory, CurrentUserFactory, $rootScope, $routeParams) {

        if (AuthFactory.isLoggedIn()) {
            $location.url(CurrentUserFactory.getDashboardPath());
            return;
        }

        $scope.loginData = {
            userName: "",
            password: "",
            useRefreshTokens: true
        };
        $scope.message = '';
        $scope.login = login;
        $scope.isLoggingIn = false;
        $scope.dots = '';
        CurrentBrandInfoFactory.initialize();
        $scope.brandInfoData = CurrentBrandInfoFactory.get();
        if (($scope.brandInfoData === null) || ($scope.brandInfoData === undefined))
        {
            $scope.brandInfoData = CurrentBrandInfoFactory.get();
        }
        activate();

        function activate() {
            if ($scope.brandInfoData.logoVersionId > 0) {
                $scope.logoUrl = CurrentBrandInfoFactory.getFullPath($scope.brandInfoData.logoVersionId);
                $scope.showLogoVisibility = true;
            }
            else
                $scope.showLogoVisibility = false;
            if ($rootScope.sessionHasExpired) {
                $scope.message = 'Your session has expired. Please, log in again.';
                $rootScope.sessionHasExpired = false;
            }
        }

        function login() {
            $scope.message = '';
            var intervalPromise = $interval(function () { $scope.dots = $scope.dots.length > 2 ? '' : $scope.dots + '.'; }, 500);
            $scope.isLoggingIn = true;
            AuthFactory.login($scope.loginData).then(
                function (response) {
                    CurrentBrandInfoFactory.setFromUserContext(response);
                    $scope.$parent.currentUser = response;
                    $interval.cancel(intervalPromise);
                    $rootScope.checkGlobalNotifications();
                    var nextPath = CurrentUserFactory.getDashboardPath();
                    if ($rootScope.pathTargetedBeforeLogin) {
                        nextPath = $rootScope.pathTargetedBeforeLogin;
                        $rootScope.pathTargetedBeforeLogin = null;
                    }
                    $location.path(nextPath);
                },
                 function (err) {
                     // TODO: err is always null, check that webApi should return error description
                     if (err && err.error_description) {
                         $scope.message = err.error_description;
                     } else {
                         $scope.message = 'An error has occured while trying to Log In. Please, try again.';
                     }
                     $interval.cancel(intervalPromise);
                     $scope.isLoggingIn = false;
                 }
            );
        }
    }
})();