(function () {
    angular.module('springwise').controller('TableCtrl', TableCtrl);

    TableCtrl.$inject = ['$scope', '$attrs'];

    function TableCtrl($scope, $attrs) {
        var self = this;

        this.init = init;

        function init(element, scope) {
            self.$element = element;
        }

        function onPageChange() {
            $scope.searchRecords($scope.searchFields, $scope.page, $scope.limit);
        }
    }
})();