(function () {
    angular.module('springwise').controller('EditRecurringScheduleCtrl', EditRecurringScheduleCtrl);

    function EditRecurringScheduleCtrl($scope, $routeParams, RecurringServicesFactory, UtilityService, $location, CurrentUserFactory, permissions) {

        $scope.swValidation = new SWValidation();

        function daysInMonth(month) {
            return new Date(2001, month, 0).getDate();
        }

        $scope.cleanErrorList = function () {
            $scope.recurringScheduleForm.dayOfWeek.$setValidity("noweekdayselected", true);
            $scope.recurringScheduleForm.recurEveryWeekly.$setValidity("isinvalidrecurevery", true);
            $scope.recurringScheduleForm.rangeDayStartWeekly.$setValidity("isinvalidstartdate", true);
            $scope.recurringScheduleForm.rangeDayEndWeekly.$setValidity("isinvalidenddate", true);
        };

        $scope.saveRecurringSchedule = function (form) {

            var isInvalid = false;
            if ($scope.recurringSchedule.recurrencePattern == 1) {

                isInvalid = !$scope.recurringSchedule.dayOfWeekSunday &&
                            !$scope.recurringSchedule.dayOfWeekMonday &&
                            !$scope.recurringSchedule.dayOfWeekTuesday &&
                            !$scope.recurringSchedule.dayOfWeekWednesday &&
                            !$scope.recurringSchedule.dayOfWeekThursday &&
                            !$scope.recurringSchedule.dayOfWeekFriday &&
                            !$scope.recurringSchedule.dayOfWeekSaturday;
                $scope.recurringScheduleForm.dayOfWeek.$setValidity("noweekdayselected", !isInvalid);

                isInvalid = $scope.recurringSchedule.recurEveryWeekly <= 0;
                $scope.recurringScheduleForm.recurEveryWeekly.$setValidity("isinvalidrecurevery", !isInvalid);

                isInvalid = $scope.recurringSchedule.rangeDayStartWeekly > daysInMonth($scope.recurringSchedule.rangeMonthStartWeekly) || $scope.recurringSchedule.rangeDayStartWeekly < 1;
                $scope.recurringScheduleForm.rangeDayStartWeekly.$setValidity("isinvalidstartdate", !isInvalid);

                isInvalid = $scope.recurringSchedule.rangeDayEndWeekly > daysInMonth($scope.recurringSchedule.rangeMonthEndWeekly) || $scope.recurringSchedule.rangeDayEndWeekly < 1;
                $scope.recurringScheduleForm.rangeDayEndWeekly.$setValidity("isinvalidenddate", !isInvalid);

            }

            if ($scope.recurringSchedule.recurrencePattern == 2) {

                isInvalid = $scope.recurringSchedule.recurEveryMonthly <= 0;
                $scope.recurringScheduleForm.recurEveryMonthly.$setValidity("isinvalidrecurevery", !isInvalid);

                isInvalid = $scope.recurringSchedule.rangeDayStartMonthly > daysInMonth($scope.recurringSchedule.rangeMonthStartMonthly) || $scope.recurringSchedule.rangeDayStartMonthly < 1;
                $scope.recurringScheduleForm.rangeDayStartMonthly.$setValidity("isinvalidstartdate", !isInvalid);

                isInvalid = $scope.recurringSchedule.rangeDayEndMonthly > daysInMonth($scope.recurringSchedule.rangeMonthEndMonthly) || $scope.recurringSchedule.rangeDayEndMonthly < 1;
                $scope.recurringScheduleForm.rangeDayEndMonthly.$setValidity("isinvalidenddate", !isInvalid);

            }

            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            $scope.swValidation.end();
            if ($scope.swValidation.hasErrors())
                return;

            RecurringServicesFactory.api.saveRecurringSchedule($scope.recurringSchedule).$promise.then(onSaveSucceded, UtilityService.onRequestError);
        };

        $scope.recurringScheduleId = $routeParams.recurringScheduleId;

        if (CurrentUserFactory.exists()) {
            var currentUser = CurrentUserFactory.get();
            $scope.createEditRecurringServices = currentUser.permissions.indexOf(permissions.createEditRecurringServices) !== -1;
        }


        $scope.goBack = function () {
            window.history.back();
        };

        function onSaveSucceded() {
            $location.path('/RecurringServices/' + $scope.recurringSchedule.recurringServiceId);
        }
        
        function activate() {
            RecurringServicesFactory.api.editRecurringSchedule({recurringScheduleId: $routeParams.recurringScheduleId }).$promise.then(function (response) {
                $scope.recurringSchedule = response;

                $scope.recurrencePatternOptions = [
                    { name: 'Weekly', id: 1 },
                    { name: 'Monthly', id: 2 }
                ];
                $scope.weekOfMonthOptions = [
                    { name: 'First', id: 1 },
                    { name: 'Second', id: 2 },
                    { name: 'Third', id: 3 },
                    { name: 'Fourth', id: 4 },
                    { name: 'Last', id: 5 }
                ];
                $scope.recurringDayOfWeekOptions = [
                    { name: 'Sunday', id: 1 },
                    { name: 'Monday', id: 2 },
                    { name: 'Tuesday', id: 3 },
                    { name: 'Wednesday', id: 4 },
                    { name: 'Thursday', id: 5 },
                    { name: 'Friday', id: 6 },
                    { name: 'Saturday', id: 7 }
                ];
                $scope.months = [
                    { name: 'January', id: 1 },
                    { name: 'February', id: 2 },
                    { name: 'March', id: 3 },
                    { name: 'April', id: 4 },
                    { name: 'May', id: 5 },
                    { name: 'June', id: 6 },
                    { name: 'July', id: 7 },
                    { name: 'August', id: 8 },
                    { name: 'September', id: 9 },
                    { name: 'October', id: 10 },
                    { name: 'November', id: 11 },
                    { name: 'December', id: 12 }
                ];

            }, UtilityService.onRequestError);
        }

        activate();
    }

})();