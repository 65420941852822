(function () {
    angular.module('springwise').controller('PaginationCtrl', PaginationCtrl);

    PaginationCtrl.$inject = ['$scope'];

    function PaginationCtrl($scope) {
        var self = this;
        
        self.init = init;

        function init(scope, element) {
            self.element = element;
            scope.nextPage = nextPage;
            scope.prevPage = prevPage;
            scope.goToPage = goToPage;

            scope.pages = [];
            scope.isLast = true;
            scope.isFirst = true;
            scope.$watch('limit', onPageParamsChange);
            scope.$watch('total', onPageParamsChange);
        }

        function getPages(scope) {
            var pages = [];
            if (scope.total && scope.limit) {
                var pageCount = Math.ceil(scope.total / scope.limit.id);

                for (var i = 1; i <= pageCount; i++) {
                    pages.push(i);
                }
            }

            scope.isLast = scope.currentPage === pages.length;
            scope.isFirst = scope.currentPage === 1;
            return pages;
        }

        function nextPage() {
            if (!$scope.isLast) {
                $scope.currentPage++;
                $scope.isLast = $scope.currentPage === $scope.pages.length;
                $scope.isFirst = $scope.currentPage === 1;
                $scope.searchRecords($scope.currentPage);
            }
        }

        function prevPage() {
            if (!$scope.isFirst) {
                $scope.currentPage--;
                $scope.isLast = $scope.currentPage === $scope.pages.length;
                $scope.isFirst = $scope.currentPage === 1;
                $scope.searchRecords($scope.currentPage);
            }
        }

        function goToPage(page) {
            if ($scope.currentPage !== page) {
                $scope.currentPage = page;
                $scope.isLast = $scope.currentPage === $scope.pages.length;
                $scope.isFirst = $scope.currentPage === 1;
                $scope.searchRecords($scope.currentPage);
            }
        }

        function onPageParamsChange() {
            $scope.pages = getPages($scope);
        }
    }
})();