(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('InvoicesDashboardCtrl', InvoicesDashboardCtrl);

    InvoicesDashboardCtrl.$inject = [
        '$scope',
        'CurrentUserFactory',
        'permissions',
        '$location',
        'InvoicesFactory',
        'UtilityService'];

    function InvoicesDashboardCtrl($scope,
        CurrentUserFactory,
        permissions,
        $location,
        InvoicesFactory,
        UtilityService) {

        $scope.params = {};
        $scope.isSWAdmin = false;
        $scope.canViewInvoices = false;
        $scope.canCreateInvoices = false;
        $scope.canEditInvoices = false;
        $scope.isVendor = false;
        $scope.isClient = false;
        $scope.isSpringwise = false;
        $scope.onPaginationChange = onPaginationChange;
        $scope.getFilteredInvoices = getFilteredInvoices;

        $scope.$watch('pageLimit', onPageLimitChange);

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
            $scope.currentUserId = $scope.currentUser.userId;
            $scope.isVendor = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClient = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSpringwise = !$scope.isVendor && !$scope.isClient;
        }
        if ($scope.currentUser !== null) {
            $scope.isSWAdmin = $scope.currentUser.permissions.indexOf(permissions.SWAdmin) !== -1;
            $scope.canViewInvoices = $scope.currentUser.permissions.indexOf(permissions.viewInvoices) !== -1;
            $scope.canCreateInvoices = $scope.currentUser.permissions.indexOf(permissions.createInvoices) !== -1;
            $scope.canEditInvoices = $scope.currentUser.permissions.indexOf(permissions.editInvoices) !== -1;
        }

        activate();

        function activate() {
            $scope.currentPage = 1;
            $scope.pageLimit = { id: 10, name: '10 results' };
            $scope.limitOptions = [
                { id: 10, name: '10 results' },
                { id: 20, name: '20 results' },
                { id: 30, name: '30 results' },
                { id: 50, name: '50 results' },
            ];
            getFilteredInvoices();
        }

        function getFilteredInvoices() {
            var theClientId = null;
            var theVendorId = null;
            var theCurrentId = null;
            if ($scope.currentUser.client){
                theClientId = $scope.currentUser.client.clientId;
            }                
            if ($scope.currentUser.vendor){
                theVendorId = $scope.currentUser.vendor.vendorId;
            }                
            if ($scope.currentUserId) {
                theCurrentId = $scope.currentUserId;
            }
            $scope.params = {
                clientId: theClientId,
                vendorId: theVendorId,
                currentUserId: theCurrentId,                
                limit: $scope.pageLimit.id,
                page: $scope.currentPage,
                orderBy: 'InvoiceId',
                isDescending: true,
            };

            InvoicesFactory.api.getTotalRecords($scope.params).$promise.then(function (obj) {
                if ($scope.totalRecords !== obj.totalRecords) {
                    $scope.totalRecords = obj.totalRecords;
                }
                InvoicesFactory.api.query($scope.params).$promise.then(function (invoices) {
                    $scope.invoices = invoices;
                }, UtilityService.onRequestError);
            });
        }

        function isEditionAllowed(currentUser) {
            var isSWAdmin = $scope.currentUser.permissions.indexOf(permissions.SWAdmin) !== -1;
            $scope.canEditWorkRecords = $scope.currentUser.permissions.indexOf(permissions.editWorkRecords) !== -1;
            if (isSWAdmin === true) {
                return true;
            } else if ($scope.canEditWorkRecords) {
                return true;
            }

            return false;
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.currentPage !== 1) {
                    $scope.currentPage = 1;
                }
                $scope.getFilteredInvoices();
            }
        }

        function onPaginationChange(page) {
            $scope.currentPage = page;
            $scope.getFilteredInvoices();
        }
    }
})();
