(function () {
    angular.module('springwise').directive('swRequiredError', swRequiredError);
    swRequiredError.$inject = [];

    function swRequiredError() {
        return {
            templateUrl: '/app/shared/directives/errors/required/requiredErrorView.html',
            restrict: 'E',
            scope: {
                field: '=field',
                validation: '='
            }
        };
    }
})();