(function () {
    angular.module('springwise').directive('swAlerts', swAlerts);

    function swAlerts() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/directives/alerts/swAlerts.html',
            controller: "swAlertsCtrl",
            scope: {
                alerts: '='
            },
            link: function ($scope, element, attrs, ctrl) {
            }
        };
    }

    angular.module('springwise').controller("swAlertsCtrl", swAlertsCtrl);

    function swAlertsCtrl($scope, $location) {
        $scope.closeAlert = function (index, alert) {
            if ($scope.alerts[index] === alert) {
                $scope.alerts.splice(index, 1);
            }
        };
    }
})();