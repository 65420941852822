(function () {
    angular.module('springwise').directive('swPriorityAnalysisReport', swPriorityAnalysisReport);

    swPriorityAnalysisReport.$inject = [];

    function swPriorityAnalysisReport() {
        return {
            restrict: 'E',
            templateUrl: '/app/shared/components/priorityAnalysisReport/priorityAnalysisReport.html',
            scope: {
                currentUser: '=currentUser'
            },
            controller: 'priorityAnalysisReportCtrl'
        };
    }
})();
