(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('emergencyTypesIndexCtrl', emergencyTypesIndexCtrl);

    emergencyTypesIndexCtrl.$inject = ['$scope', '$http', '$routeParams', '$location', 'ngAuthSettings', 'UtilityService', 'CurrentUserFactory', 'permissions', 'EmergencyTypesFactory', 'HelperFactory'];

    function emergencyTypesIndexCtrl($scope, $http, $routeParams, $location, ngAuthSettings, UtilityService, CurrentUserFactory, permissions, EmergencyTypesFactory, HelperFactory) {
        
        $scope.clientId = null;
        //$scope.routParamsClientId = $routeParams.id;
        $scope.clientId = $routeParams.clientId;
        $scope.emergencies = [];
        $scope.page = 1;
        $scope.limit = 20;
        $scope.description = ''; //for the "Description" filter, just in case is added in the future.
        $scope.orderBy = 'description';
        $scope.isDescending = false;
        $scope.editEmergencyTypes = false;       

        //$scope.isEditionAllowed = isEditionAllowed;
        $scope.activate = activate;
        $scope.deactivate = deactivate;

        $scope.questions = questions;
        $scope.deleteEmergency = deleteEmergency;
        //$scope.checkDependency = checkDependency;

        //$scope.onPaginationChange = onPaginationChange;
        //$scope.$watch('limit', onPageLimitChange);

        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
            $scope.currentUserId = $scope.currentUser.userId;

            if ($scope.currentUser.client) {
                $scope.currentUserClientId = $scope.currentUser.client.clientId;
            }
            else {
                $scope.currentUserClientId = null;
            }

            $scope.viewEmergencyTypes = $scope.currentUser.permissions.indexOf(permissions.viewEmergencyTypes) !== -1;
            $scope.createEmergencyTypes = $scope.currentUser.permissions.indexOf(permissions.createEmergencyTypes) !== -1;
            $scope.editEmergencyTypes = $scope.currentUser.permissions.indexOf(permissions.editEmergencyTypes) !== -1;
        }
        else {
            $scope.currentUserId = 0;
            $scope.currentUser = null;
        }

        if ($scope.currentUser !== null) {
            $scope.isSWAdmin = $scope.currentUser.permissions.indexOf(permissions.SWAdmin) !== -1;
        }

        
        init();
              
        function init() {

            searchEmergenciesWithParams({
                //page: $scope.page,                
                orderBy: $scope.orderBy,
                isDescending: $scope.isDescending
                //limit: $scope.limit.id
            });  

        }

        //FILTER / SEARCH WITH PARAMETERS

        function searchEmergencies() {
            var params = {
                //page: $scope.page,                
                orderBy: $scope.orderBy,
                //description: $scope.description,
                isDescending: $scope.isDescending
                //limit: $scope.limit.id
            };

            searchEmergenciesWithParams(params);
        }

        function searchEmergenciesWithParams(params) {
           
            if ($scope.clientId) { 
                EmergencyTypesFactory.getClientEmergencyTypes({ id: $scope.clientId }, params).$promise.then(function (emergencies) {
                    $scope.emergencies = emergencies;
                }, UtilityService.onRequestError);
            }
            //In order to load current user client's Emergency Types when a user client admin tries to access to "#/emergencyTypes" throught URL
            else if ($scope.currentUserClientId) {
                EmergencyTypesFactory.getClientEmergencyTypes({ id: $scope.currentUserClientId }, params).$promise.then(function (emergencies) {
                    $scope.emergencies = emergencies;
                }, UtilityService.onRequestError);
            }
            else {
                EmergencyTypesFactory.query(params).$promise.then(function (emergencies) {
                    $scope.emergencies = emergencies;
                }, UtilityService.onRequestError);
            }
                        
        }



        //ACTIVATE / DEACTIVATE

        function activate(emergency) {
            EmergencyTypesFactory.activate({ id: emergency.emergencyTypeId }).$promise.then(onEmergencyActivated, UtilityService.onRequestError);
        }

        function deactivate(emergency) {
            EmergencyTypesFactory.deactivate({ id: emergency.emergencyTypeId }).$promise.then(onEmergencyDeactivated, UtilityService.onRequestError);
        }

        function onEmergencyActivated(emergency) {
            emergency.active = true;          
            var modalWindow = HelperFactory.openMessageModalWindow('The Emergency Type has been activated.', 'Activated');
            init();            
        }

        function onEmergencyDeactivated(emergency) {
            emergency.active = false;          
            var modalWindow = HelperFactory.openMessageModalWindow('The Emergency Type has been deactivated.', 'Deactivated');
            init();            
        }
        

        //REDIRECT AND BACK

        function redirectToEmergencyTypes() {
            $location.path('/emergencyTypes/' + $scope.emergencyTypeId);
        }

        $scope.goBack = function () {
            window.history.back();
        };

        //QUESTIONNAIRE

        function questions(emergency) {
            //var service = emergency.$nodeScope.$modelValue;

            if ($scope.clientId) {
                $location.path('/clients/' + $scope.clientId + '/emergencyTypes/' + emergency.emergencyTypeId + '/questionnaire');
            } else {
                $location.path('/emergencyTypes/' + emergency.emergencyTypeId + '/questionnaire');
            }
        }

        //DELETION

        function deleteEmergency(emergency) {
            var modalWindow = HelperFactory.openAcceptCancelModalWindow('The Emergency Type is going to be Deleted Permanently. Do you want to proceed?', 'Confirmation');
            modalWindow.result.then(function () {              
           
                    removeEmergency(emergency);         

            }, function () {
            });          
        }
        
        function removeEmergency(emergency) {
            EmergencyTypesFactory.delete({ id: emergency.emergencyTypeId }).$promise.then(function (emergency) {                
                var modalWindow = HelperFactory.openMessageModalWindow('The Emergency Type "' + emergency.description + '" has been deleted.', 'Deleted');
                init();
            }, UtilityService.onRequestError);
        } 

    }

})();
