(function () {
    angular.module('springwise').factory('DistrictsFactory', DistrictsFactory);

    DistrictsFactory.$inject = ['$resource'];

    function DistrictsFactory($resource) {
        return $resource(
            'api/Districts/:id', {
                id: '@districtId'
            }, {
                queryOptions: {
                    method: 'GET',
                    url: 'api/Clients/:clientId/Districts/options',
                    isArray: true
                },
                queryOptionsByRegion: {
                method: 'GET',
                url: 'api/Regions/:regionId/Districts/Options',
            isArray: true
        }
    });
    }
})();