(function () {
    'use strict';
    
    angular
        .module('springwise')
        .controller('ScheduleNotesListCtrl', ScheduleNotesListCtrl);

    function ScheduleNotesListCtrl($scope, $routeParams, RecurringServicesFactory, CurrentUserFactory, UtilityService, HelperFactory) {
        //$scope.workRecordId = $routeParams.workRecordId;
        $scope.recurringScheduleId = $routeParams.recurringScheduleId;
        $scope.editMode = false;
        $scope.submitNote = submitNote;
        $scope.cancelNote = cancelNote;
        $scope.getScheduleNoteList = getScheduleNoteList;
        //getWorkRecord();
        getScheduleNoteList();
        
        //function getWorkRecord() {
        //    RecurringServicesFactory.get({ id: $scope.workRecordId }).$promise.then(function (w) {
        //        $scope.referenceNumber = w.referenceNumber;
        //    });
        //}

        function getScheduleNoteList() {
            RecurringServicesFactory.api.getScheduleNoteList({ recurringScheduleId: $scope.recurringScheduleId }).$promise.then(function (response) {
                $scope.ScheduleNoteList = response;
            }, UtilityService.onRequestError);
        }

        //Add new note
        $scope.addNote = function () {
            if ($scope.editMode) {                
                var modalWindow = HelperFactory.openMessageModalWindow('Make sure to save or cancel any rows in the table before adding another Note.', 'Item in edition');
                return;
            }

            var sch = new RecurringScheduleNote();
            sch.recurringScheduleId = $scope.recurringScheduleId;
            sch.editMode = true;
            //sch.isVendorCreator = $scope.isVendorCreator;
            //sch.isClientCreator = $scope.isClientCreator;
            //sch.isSpringwiseCreator = $scope.isSpringwiseCreator;

            $scope.ScheduleNoteList.push(sch);
            $scope.editMode = true;
        };

        //Save new note
        function submitNote(sch) {
            var req = {
                recurringScheduleNoteId: sch.recurringScheduleNoteId,
                note: sch.editedNote,
                recurringScheduleId: sch.recurringScheduleId
            };

            if (sch.isNew) {
                RecurringServicesFactory.api.saveScheduleNote(req).$promise.then(onAddSucceded, rollbackSubmit);
            }

            function onAddSucceded() {
                sch.editMode = false;
                sch.isNew = false;
                sch.note = sch.editedNote;
                
                getScheduleNoteList();

                $scope.editMode = false;
            }

            function rollbackSubmit(error) {
                UtilityService.onRequestError(error);
                cancelNote(sch);
            }
        }

        //Cancel new note
        function cancelNote(sch) {
            if (sch.isNew) {
                UtilityService.removeElementFromCollection($scope.ScheduleNoteList, sch);
            }
            getScheduleNoteList();
            $scope.editMode = false;
        }

        //Back
        $scope.goBack = function () {
            window.history.back();
        };
    }
})();

