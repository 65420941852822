(function () {
    angular.module('springwise').directive('swTableSearchForm', swTableSearchForm);

    swTableSearchForm.$inject = [];

    function swTableSearchForm() {
        return {
            restrict: 'E',
            templateUrl: 'app/shared/directives/table/tableSearchFormView.html',
            scope: {
                searchFields: '=searchFields',
                searchRecords: '=submit'
            },
            controller: 'TableSearchFormCtrl',
            link: {
                post: function (scope, element, attrs, ctrl) {
                    ctrl.init(element, scope);
                }
            }
        };
    }
})();