(function () {
    angular.module('springwise').factory('AnswersService', AnswersService);

    AnswersService.$inject = ['$resource'];

    function AnswersService($resource) {
        return $resource(
            'api/answers/:id', {
                id: '@id'
            }, {
                update: {
                    method: 'PUT'
                }
            });
    }
})();