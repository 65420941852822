(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('UsersFormCtrl', UsersFormCtrl);

    UsersFormCtrl.$inject = [
        '$scope',
        '$routeParams',
        '$location',
        'UsersFactory',
        'RolesFactory',
        'CountriesFactory',
        'TimeZonesFactory',
        'StatesFactory',
        'EmailTypesFactory',
        'PhoneTypesFactory',
        'ClientsFactory',
        'LocationsFactory',
        'VendorsFactory',
        'UtilityService',
        'CurrentUserFactory',
        'RegionsFactory',
        'DistrictsFactory',
        'permissions',
        'AuthFactory'];

    function UsersFormCtrl(
        $scope,
        $routeParams,
        $location,
        UsersFactory,
        RolesFactory,
        CountriesFactory,
        TimeZonesFactory,
        StatesFactory,
        EmailTypesFactory,
        PhoneTypesFactory,
        ClientsFactory,
        LocationsFactory,
        VendorsFactory,
        UtilityService,
        CurrentUserFactory,
        RegionsFactory,
        DistrictsFactory,
        permissions,
        AuthFactory) {

        // #region Functions
        function goBack() {
            window.history.back();
        }
        function setRolesForUserType(userTypeSelection, clientId, vendorId) {
            clientId = clientId || "0";
            vendorId = vendorId || "0";
            RolesFactory.queryOptions({ userTypeSelection: userTypeSelection, clientId: clientId, vendorId: vendorId })
                .$promise.then(function (roles) {
                    $scope.masterData.roles = roles;
                    $scope.masterDataLoaded++;
           });
        }

        function refreshAndReroute(path) {
            if ($scope.userId && $scope.currentUser.userId == $scope.userId)
                AuthFactory.getUserInformation();

            $location.path(path);
        }

        function activate() {

            // Load default roles, used in edition
            RolesFactory.queryOptions()
                .$promise.then(function (roles) {
                    $scope.masterData.roles = roles;
                    $scope.masterDataLoaded++;
                });
            
            CountriesFactory.query()
                .$promise.then(function (countries) {
                    $scope.masterData.countries = countries;
                    $scope.masterDataLoaded++;
                });
            TimeZonesFactory.query()
                .$promise.then(function (timezones) {
                    $scope.masterData.timeZones = timezones;
                    $scope.masterDataLoaded++;
                });
            StatesFactory.query()
                .$promise.then(function (states) {
                    $scope.masterData.states = states;
                    $scope.masterDataLoaded++;
                });
            EmailTypesFactory.query()
                .$promise.then(function (emailTypes) {
                    $scope.masterData.emailTypes = emailTypes;
                    $scope.masterDataLoaded++;
                });
            PhoneTypesFactory.query()
                .$promise.then(function (phoneTypes) {
                    $scope.masterData.phoneTypes = phoneTypes;
                    $scope.masterDataLoaded++;
                });
            ClientsFactory.queryOptions()
                .$promise.then(function (clients) {
                    $scope.masterData.clients = clients;
                    $scope.masterDataLoaded++;
                    //When the logged current user is a Client Administrator User that manages an specific Client, and is accessing the User list from Config section.   
                    if ($scope.isClientUser) {
                        $scope.clientIDSelected = $scope.currentUser.client.clientId;
                    }
                        //When the logged current user is a Sprinwise Administrator User. And the user is accessing the User list from the Client edition section, so that it lists the users related to that client.
                    else if ($scope.isSWAdminUser && $scope.routeParamsClientId) {
                        $scope.clientIDSelected = $scope.routeParamsClientId;
                    }
                });


            //When the logged current user is a Sprinwise Administrator User or a Vendor User, loads all the vendors with emptyClientId = 0
            var emptyClientId = 0;

            //When the logged current user is a Client Administrator User that manages an specific Client, and is accessing the User list from Config section.   
            if ($scope.isClientUser) {
                emptyClientId = $scope.currentUser.client.clientId;
            }
            //When the logged current user is a Sprinwise Administrator User, but is editing an specific client selected. And the user is accessing the User list from the Client edition section, so that it lists the users related to that client.
            else if ($scope.isSWAdminUser && $scope.routeParamsClientId) {
                emptyClientId = $scope.routeParamsClientId;
            }

            VendorsFactory.queryOptions({ clientId: emptyClientId })
                .$promise.then(function (vendors) {
                    $scope.masterData.vendors = vendors;
                    $scope.masterDataLoaded++;
                });
        }

        function getUsers() {
            if ($scope.userId) {
                UsersFactory.get({ id: $scope.userId }).$promise.then(function (user) {
                    setUserType($scope, user);
                    setClientRegionDistrictLocationFromUser(user);
                    setClientIdSelectedFromUser(user);
                    $scope.user = user;
                    $scope.selectedCountry = getSelectedCountry(user.contactInformation.address.state.countryId);
                });
            } else if ($scope.toCopyId) {
                UsersFactory.get({ id: $scope.toCopyId }).$promise.then(function (user) {
                    setUserType($scope, user);
                    setClientRegionDistrictLocationFromUser(user);
                    $scope.user = user;
                    $scope.user.userName = "";
                    $scope.user.name = "";
                    $scope.user.lastName = "";
                    $scope.user.email = "";
                    $scope.user.roleId = user.roleId;
                });
            } else if ($scope.profileId) {
                UsersFactory.get({ id: $scope.profileId }).$promise.then(function (user) {
                    setUserType($scope, user);
                    $scope.user = user;
                    $scope.selectedCountry = getSelectedCountry(user.contactInformation.address.state.countryId);
                });
            }
            else if ($scope.routeParamsClientId) {
                UsersFactory.getAdministratorUserByClientId({ id: $scope.routeParamsClientId }).$promise.then(function (user) {
                    setUserType($scope, user);
                    $scope.user = user;
                    $scope.user = user;
                    $scope.user.userName = "";
                    $scope.user.name = "";
                    $scope.user.lastName = "";
                    $scope.user.email = "";
                    $scope.user.contactInformation.name = "";
                    $scope.user.contactInformation.address.city = "";
                    $scope.user.contactInformation.address.line1 = "";
                    $scope.user.contactInformation.address.line2 = "";
                    $scope.user.contactInformation.address.state = null;
                    $scope.user.contactInformation.address.zipCode = "";
                });
            }

            else {
                setUserType($scope, null);
                setClientRegionDistrictLocation();
            }
        }

        function setClientRegionDistrictLocationFromUser(user) {
            if (user.region)
                $scope.regionIDSelected = user.region.id;
        }

        function setClientRegionDistrictLocation() {
            if ($scope.currentUser.client && $scope.currentUser.client.clientId) {
                $scope.clientIDSelected = $scope.currentUser.client.clientId;
            }
        }

        // Related to Bug #2474
        function setClientIdSelectedFromUser(user) {
            if (user.client && user.client.clientId) {
                $scope.clientIDSelected = user.client.clientId;
            }
        }

        function verifyVendorInList(vendorId, vendorName) {
            var exists = false;
            $scope.masterData.vendors.forEach(function (vendor) {
                if (vendor.vendorId === vendorId)
                    exists = true;
            });

            if (!exists) {
                $scope.masterData.vendors.push({ name: vendorName, vendorId: vendorId });
                $scope.masterDataLoaded++;
            }
        }

        function verifyClientInList(clientId, clientName) {
            var exists = false;
            $scope.masterData.clients.forEach(function (client) {
                if (client.clientId === clientId)
                    exists = true;
            });

            if (!exists) {
                $scope.masterData.clients.push({ name: clientName, clientId: clientId });
                $scope.masterDataLoaded++;
            }
        }

        function setUserType(scope, user) {
            if (scope.isEdition) {  // user should have value when is edition

                if (user.client) {
                    scope.userType = 'Client';
                    scope.clientIDSelected = user.client.clientId;
                    scope.user.vendor = null;
                    verifyClientInList(user.client.clientId, user.client.name);
                } else if (user.vendor) {
                    scope.userType = 'Vendor';
                    scope.clientIDSelected = null;
                    scope.user.vendor = user.vendor.vendorId;
                    verifyVendorInList(user.vendor.vendorId, user.vendor.name);
                } else {
                    scope.userType = 'Springwise';
                    scope.clientIDSelected = null;
                    scope.user.vendor = null;
                }

            } else {
                if (user !== null) {
                    if (user.client || scope.routeParamsClientId) {
                        scope.userType = 'Client';
                        scope.user.vendor = null;

                        //When the logged current user is a Client Administrator User that manages an specific Client, and is accessing the client User data from Config section.   
                        if (scope.isClientUser) {
                            scope.clientIDSelected = user.client.clientId;
                        }
                            //When the logged current user is a Sprinwise Administrator User. And the user is accessing the User list from the Client edition section, so that it loads data related to a client User selected.
                        else if (scope.isSWAdminUser && scope.routeParamsClientId) {
                            scope.clientIDSelected = scope.routeParamsClientId;
                        }
                            //When the logged current user is a Sprinwise Administrator User, and is accessing the User list from the Config Menu, loading data related to a client User selected. .
                        else if (scope.isSWAdminUser && scope.masterData.clients[0]) {
                            scope.clientIDSelected = scope.masterData.clients[0].clientId;
                        }

                    } else if (user.vendor) {
                        scope.userType = 'Vendor';
                        scope.clientIDSelected = null;
                        scope.user.vendor = user.vendor.vendorId;
                    } else {
                        scope.userType = 'Springwise';
                        scope.clientIDSelected = null;
                        scope.user.vendor = null;
                    }
                }
                else {
                    if (scope.isVendorUser) {
                        scope.userType = 'Vendor';
                        scope.clientIDSelected = null;
                    }
                    if (scope.isClientUser) {
                        scope.userType = 'Client';

                        //When the logged current user is a Client Administrator User that manages an specific Client, and is accessing the client User data from Config section.   
                        if (scope.isClientUser && user.client) {
                            scope.clientIDSelected = user.client.clientId;
                        }
                            //When the logged current user is a Sprinwise Administrator User. And the user is accessing the User list from the Client edition section, so that it loads data related to a client User selected.
                        else if (scope.isSWAdminUser && scope.routeParamsClientId) {
                            scope.clientIDSelected = scope.routeParamsClientId;
                        }
                            //When the logged current user is a Sprinwise Administrator User, and is accessing the User list from the Config Menu, loading data related to a client User selected. .
                        else if (scope.isSWAdminUser && scope.masterData.clients[0]) {
                            scope.clientIDSelected = scope.masterData.clients[0].clientId;
                        }
                    }
                }
            }
        }

        function getFullName() {
            return ($scope.user.name || '') + ' ' + ($scope.user.lastName || '');
        }

        function getSelectedCountry(countryId) {
            for (var i = 0; i < $scope.masterData.countries.length; i++) {
                if ($scope.masterData.countries[i].countryId === countryId) {
                    return $scope.masterData.countries[i];
                }
            }
            return null;
        }

        function onUserRoleChange(newValue, oldValue) {
            if (newValue !== oldValue && newValue && newValue.roleId && newValue.roleId === 1) {
                $scope.userType = 'Springwise';
                $scope.user.client = null;
                $scope.user.region = null;
                $scope.user.district = null;
                $scope.user.vendor = null;
                $scope.user.location = null;
            }
        }

        function onUserVendorChange(newValue, oldValue) {
            if (newValue !== oldValue && newValue !== null) {
                setRolesForUserType($scope.userType, null, (newValue && newValue.vendorId) ? newValue.vendorId : null);
            }
        }

        function onUserClientChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.districtIDSelected = null;
                $scope.locationIDSelected = null;

                if (newValue) {
                    setRolesForUserType($scope.userType, newValue, null, 'Client Select');
                    RegionsFactory.queryOptions({ clientId: newValue }).$promise.then(function (regions) {
                        $scope.masterData.regions = [new RegionOption()].concat(regions);
                        if ($scope.user && $scope.user.region && $scope.user.region.id) {
                            $scope.regionIDSelected = $scope.user.region.id;
                        }
                        else if ($scope.currentUser.regionId) {
                            $scope.regionIDSelected = $scope.currentUser.regionId;
                        }
                    });
                }
                else
                    $scope.masterData.regions = null;
            }
        }

        function onUserRegionChange(newValue, oldValue) {
            $scope.districtIDFilter = new DistrictOption();
            $scope.locationIDFilter = new LocationOption();

            if (newValue !== oldValue) {
                $scope.locationIDSelected = null;
                if (newValue) {
                    DistrictsFactory.queryOptionsByRegion({ regionId: newValue }).$promise.then(function (districts) {
                        $scope.masterData.districts = [new DistrictOption()].concat(districts);
                        if ($scope.user && $scope.user.district) {
                            $scope.districtIDSelected = $scope.user.district.id;
                        }
                        else if ($scope.currentUser.districtId) {
                            $scope.districtIDSelected = $scope.currentUser.districtId;
                        }
                    });
                }
                else
                    $scope.masterData.districts = null;
            }
        }

        function onUserDistrictChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    LocationsFactory.queryOptionsByDistrict({ districtId: newValue }).$promise.then(function (locations) {
                        $scope.masterData.locations = [new LocationOption()].concat(locations);
                        if ($scope.user.location) {
                            $scope.locationIDSelected = $scope.user.location.locationId;
                        }
                        else if ($scope.currentUser.locationId) {
                            $scope.locationIDSelected = $scope.currentUser.locationId;
                        }
                    });
                }
                else
                    $scope.masterData.locations = null;
            }
        }
        // #endregion

        // #region Prepare data
        $scope.routeParamsClientId = null;
        if ($routeParams.clientId) {
            $scope.routeParamsClientId = parseInt($routeParams.clientId);
        }

        if ($location.path().indexOf('/users/profile') > -1) {
            $scope.profileId = CurrentUserFactory.get().userId; //ID FOR PROFILE FUNC
        }
        if (CurrentUserFactory.exists()) {
            $scope.currentUser = CurrentUserFactory.get();
            $scope.isVendorUser = $scope.currentUser.vendor !== undefined && $scope.currentUser.vendor !== null;
            $scope.isClientUser = $scope.currentUser.client !== undefined && $scope.currentUser.client !== null;
            $scope.isSWAdminUser = !$scope.isVendorUser && !$scope.isClientUser;

            if ($routeParams.id == 1 && $scope.currentUser.userId != 1) {
                UtilityService.openMessageModalWindow("You are not allowed to access", "Access Denied", "error");
                $location.path('/');
                return;
            }

            $scope.approveWorkRecordLevel = $scope.currentUser.permissions.indexOf(permissions.approveWorkRecordLevel) !== -1;
            $scope.approveQuotesLevel = $scope.currentUser.permissions.indexOf(permissions.approveQuotesLevel) !== -1;
        }
        else {
            $scope.isClient = false;
            $scope.currentUser = null;
        }

        $scope.fromClient = ($routeParams.clientId !== null && $routeParams.clientId !== undefined);
        if ($routeParams.id !== null && $routeParams.id !== undefined) {
            $scope.isEdition = $routeParams.id;
        }
        else {
            $scope.isEdition = false;
        }
           
        $scope.swValidation = new SWValidation();
        $scope.toCopyId = $routeParams.toCopyId;        //User ID FOR CLONING FUNC
        $scope.userId = $routeParams.id;                //User ID FOR EDITING FUNC
        $scope.user = new User();
        $scope.masterData = new UsersFormMasterData();
        $scope.masterDataCount = Object.keys($scope.masterData).length;
        $scope.masterDataLoaded = 0;
        $scope.selectedCountry = {};
        $scope.emailPattern = /^[_a-z0-9A-Z]+(\.[_a-z0-9A-Z]+)*@[a-z0-9A-Z]+(\.[a-z0-9A-Z]+)*(\.[a-z]{2,4})$/;
        $scope.masterData.districts = [new DistrictOption()];
        $scope.masterData.regions = [new RegionOption()];
        $scope.masterData.locations = [new LocationOption()];
        $scope.clientIDSelected = null;
        $scope.regionIDSelected = null;
        $scope.districtIDSelected = null;
        $scope.locationIDSelected = null;
        $scope.goBack = goBack;
        
        $scope.$watch('clientIDSelected', onUserClientChange);
        $scope.$watch('regionIDSelected', onUserRegionChange);
        $scope.$watch('districtIDSelected', onUserDistrictChange);
        $scope.$watch('user.vendor', onUserVendorChange);
        $scope.$watch('masterDataLoaded', function (newValue, oldValue) {
            if (newValue == $scope.masterDataCount) {
                getUsers();
            }
        });
        $scope.$watch('userType', function (newValue, oldValue) {
            if (newValue !== oldValue) {
                switch (newValue) {
                    case 'Client':
                        if ($routeParams.clientId && $scope.clientIDSelected === null)
                            $scope.clientIDSelected = $scope.routeParamsClientId;
                        $scope.user.vendor = null;
                        setRolesForUserType(newValue, $scope.clientIDSelected, null);
                        break;
                    case 'Vendor':
                        $scope.clientIDSelected = null;
                        $scope.user.client = null;
                        $scope.user.region = null;
                        $scope.user.district = null;
                        $scope.user.location = null;
                        setRolesForUserType(newValue, null, ($scope.user.vendor !== null) ? $scope.user.vendor.vendorId : null);
                        break;
                    case 'Springwise':
                        $scope.user.client = null;
                        $scope.user.vendor = null;
                        $scope.user.region = null;
                        $scope.user.district = null;
                        $scope.user.location = null;
                        setRolesForUserType(newValue, $scope.clientIDSelected, null);
                        break;
                    default:
                        $scope.user.client = null;
                        $scope.user.region = null;
                        $scope.user.district = null;
                        $scope.user.vendor = null;
                        $scope.user.location = null;
                        setRolesForUserType(newValue, $scope.clientIDSelected, null);
                        break;
                }
            }
        });
        $scope.$watch('user.role', onUserRoleChange);

        $scope.saveUser = function (form) {

            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            $scope.swValidation.end();
            if ($scope.swValidation.hasErrors())
                return;

            // Assign Email & Phone Ids
            for (var emailInfo in $scope.emailInformations) {
                emailInfo.emailTypeId = emailInfo.emailType.emailTypeId;
            }

            for (var phoneInfo in $scope.phoneInformations) {
                phoneInfo.phoneTypeId = phoneInfo.phoneType.phoneTypeId;
            }

            if ($scope.clientIDSelected) {
                for (var i = 0, len = $scope.masterData.clients.length; i < len; i++)
                    if ($scope.masterData.clients[i].clientId === $scope.clientIDSelected)
                        $scope.user.client = $scope.masterData.clients[i];
            }
            else
                $scope.user.client = null;
            if ($scope.regionIDSelected) {
                for (var j = 0, le = $scope.masterData.regions.length; j < le; j++)
                    if ($scope.masterData.regions[j].id === $scope.regionIDSelected)
                        $scope.user.region = $scope.masterData.regions[j];
            }
            else
                $scope.user.region = null;
            if ($scope.districtIDSelected) {
                for (var k = 0, leng = $scope.masterData.districts.length; k < leng; k++)
                    if ($scope.masterData.districts[k].id === $scope.districtIDSelected)
                        $scope.user.district = $scope.masterData.districts[k];
            }
            else
                $scope.user.district = null;
            if ($scope.locationIDSelected) {
                for (var l = 0, lengt = $scope.masterData.locations.length; l < lengt; l++)
                    if ($scope.masterData.locations[l].locationId === $scope.locationIDSelected)
                        $scope.user.location = $scope.masterData.locations[l];
            }
            else
                $scope.user.location = null;

            if (!$scope.user.approveWorkRecordLevel)
                $scope.user.approveWorkRecordLevel = 0;
            if (!$scope.user.approveQuotesLevel)
                $scope.user.approveQuotesLevel = 0;

            // Save the user
            if ($scope.userId) {
                $scope.user.$update(function () {
                    if ($scope.routeParamsClientId) {
                        refreshAndReroute('/clients/' + $scope.routeParamsClientId + '/users');
                    }
                    else {
                        refreshAndReroute('users');
                    }

                }, UtilityService.onRequestError);
            }
            else if ($scope.toCopyId) {
                UsersFactory.save($scope.user).$promise.then(function () {
                    if ($scope.routeParamsClientId) {
                        refreshAndReroute('/clients/' + $scope.routeParamsClientId + '/users');
                    }
                    else {
                        refreshAndReroute('/users');
                    }
                }, UtilityService.onRequestError);
            }
            else if ($scope.profileId) {
                UsersFactory.updateMe($scope.user).$promise.then(function () {
                    refreshAndReroute(CurrentUserFactory.getDashboardPath());
                }, UtilityService.onRequestError);
            }
            else {
                UsersFactory.save($scope.user).$promise.then(function () {
                    if ($scope.routeParamsClientId) {
                        refreshAndReroute('/clients/' + $scope.routeParamsClientId + '/users');
                    }
                    else {
                        refreshAndReroute('/users');
                    }
                }, UtilityService.onRequestError);
            }
        };
        $scope.removeEmailInformation = function (index) {
            UtilityService.removeElementFromCollection($scope.user.contactInformation.emailInformations, index);
        };
        $scope.addEmailInformation = function () {
            $scope.user.contactInformation.emailInformations.push({});
        };
        $scope.removePhoneInformation = function (index) {
            UtilityService.removeElementFromCollection($scope.user.contactInformation.phoneInformations, index);
        };
        $scope.addPhoneInformation = function () {
            $scope.user.contactInformation.phoneInformations.push({});
        };
        $scope.getFullName = getFullName;
        // #endregion

        activate();
    }
})();
