(function () {
    'use strict';

    angular
        .module('springwise')
        .controller('BrandEditorCtrl', BrandEditorCtrl);

    BrandEditorCtrl.$inject = ['$scope', '$http', '$routeParams', 'ngAuthSettings', 'ClientsFactory', 'CurrentUserFactory', 'UtilityService', 'permissions', 'HelperFactory'];

    function BrandEditorCtrl($scope, $http, $routeParams, ngAuthSettings, ClientsFactory, CurrentUserFactory, UtilityService, permissions, HelperFactory) {

        $scope.swValidation = new SWValidation();

        // GET THE FILE INFORMATION.
        $scope.logoFile = new FileObj();
        $scope.iconFile = new FileObj();
        $scope.clientId = $routeParams.id;

        //Get user information
        var currentUser = CurrentUserFactory.get();
        $scope.canViewClients = false;
        $scope.canEditClients = false;

        if (currentUser) {
            $scope.canViewClients = currentUser.permissions.indexOf(permissions.canQueryClients) !== -1;
            $scope.canEditClients = currentUser.permissions.indexOf(permissions.canEditClients) !== -1;
        }

        ClientsFactory.getBrandData({ id: $routeParams.id }).$promise.then(function (brandData) {
            $scope.clientBrandInfo = brandData;
            if (brandData.iconVersion)
                $scope.iconFile.versionId = brandData.iconVersion.documentVersionId;
            else
                $scope.iconFile.versionId = null;
            if (brandData.logoVersion)
                $scope.logoFile.versionId = brandData.logoVersion.documentVersionId;
            else
                $scope.logoFile.versionId = null;
            $scope.brandUrl = brandData.brandURL || '';
        }, UtilityService.onRequestError);

        $scope.goBack = function () {
            window.history.back();
        };
        // NOW UPLOAD THE FILES.
        $scope.uploadFiles = function (form) {

            $scope.swValidation.start();
            $scope.swValidation.checkForm(form);
            if ($scope.brandUrl && $scope.brandUrl.search(/^[a-zA-Z0-9]+$/) < 0)
                $scope.swValidation.addAlert('brandUrl', 'Only letters and numbers (no spaces or special characters)');
            $scope.swValidation.end();
            if ($scope.swValidation.hasErrors())
                return;

            //FILL FormData WITH FILE DETAILS.
            var data = new FormData();
            data.append("clientId", $scope.clientId);
            data.append("brandUrl", $scope.brandUrl);
            var blob;
            if ($scope.logoFile.file) {
                blob = dataURItoBlob($scope.logoFile.file);
                data.append("logoFile", blob);
            }
            if ($scope.iconFile.file) {
                blob = dataURItoBlob($scope.iconFile.file);
                data.append("iconFile", blob);
            }
            // ADD LISTENERS.
            var objXhr = new XMLHttpRequest();
            objXhr.addEventListener("progress", updateProgress, false);
            objXhr.addEventListener("load", transferComplete, false);

            // SEND FILE DETAILS TO THE API.
            objXhr.open("POST", ngAuthSettings.apiServiceBaseUri + "api/DocumentRepository/UploadBrandInformation/");
            objXhr.send(data);
        };

        // UPDATE PROGRESS BAR.
        function updateProgress(e) {
            if (e.lengthComputable) {
            }
        }

        // CONFIRMATION.
        function transferComplete(e) {            
            var ModalWindows = HelperFactory.openMessageModalWindow("Information successfully saved.", "Saved");
        }
    }
})();

function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}

var FileObj = function () {
    this.file = null;
    this.versionId = -1;
    this.versionExtension = null;
};