(function () {
    angular.module('springwise').controller('SearchVendorsCtrl', SearchVendorsCtrl);

    SearchVendorsCtrl.$inject = ['$scope', 'UtilityService', 'VendorsFactory', 'LocationsFactory'];

    function SearchVendorsCtrl($scope, UtilityService, VendorsFactory, LocationsFactory) {
        var self = this;
        self.init = init;

        function init($element) {
            self.$element = $element;
            
            $scope.vendors = [];
            $scope.locations = [new LocationOption()];

            $scope.name = '';
            $scope.taxId = '';
            $scope.zipCode = '';
            $scope.locationId = new LocationOption();
            $scope.orderBy = 'name';
            $scope.isDescending = false;
            $scope.page = 1;
            $scope.limit = UtilityService.defaultLimitOption;
            $scope.limitOptions = UtilityService.limitOptions;
            


            LocationsFactory.queryOptions().$promise.then(function (locations) {
                $scope.locations = locations;
            });
            
            $scope.onPaginationChange = onPaginationChange;
            $scope.search = search;

            $scope.$watch('limit', onPageLimitChange);
            $scope.$watch('name', resetSearch);
            $scope.$watch('taxId', resetSearch);
            $scope.$watch('zipCode', resetSearch);
            $scope.$watch('locationId', resetSearch);
        }

        function onPaginationChange(page) {
            $scope.page = page;
            search();
        }

        function onPageLimitChange(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if ($scope.page !== 1) {
                    $scope.page = 1;
                }
                search();
            }
        }

        function resetSearch() {
            $scope.page = 1;
            $scope.limit = UtilityService.defaultLimitOption;
            $scope.vendors = [];
            $scope.searchExecuted = false;
        }

        function search(showLoading) {
            var params = {};
            params.orderBy = $scope.orderBy;
            params.isDescending = $scope.isDescending;
            params.page = $scope.page;
            params.limit = $scope.limit.id;
            params.name = ($scope.params.name || {}).value;
            params.taxId = ($scope.params.taxId || {}).value;
            params.zipCode = ($scope.params.zipCode || {}).value;
            params.serviceId = ($scope.params.service || {}).value.id;
            params.locationId = ($scope.params.location || {}).value.locationId;

            if (showLoading)
                $scope.loading = true;

            VendorsFactory.getTotalRecords(params).$promise.then(onQueryTotalSucceded, UtilityService.onRequestError);

            function onQueryTotalSucceded(total) {
                if ($scope.total !== total.totalRecords) {
                    if ($scope.page !== 1) {
                        $scope.page = 1;
                        params.page = $scope.page;
                    }
                    $scope.total = total.totalRecords;
                }

                VendorsFactory.query(params).$promise.then(onSearchSucceded, UtilityService.onRequestError);
                
                function onSearchSucceded(vendors) {
                    $scope.vendors = vendors;
                    $scope.searchExecuted = true;
                    $scope.loading = false;
                }
            }
        }
    }
})();